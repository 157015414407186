/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><g><circle cx="16" cy="16" r="13" className="white-shape"/><g data-name="no-invert line-dash-a"><circle cx="16" cy="16" r="13" className="black-line-20"/></g></g><g><polyline points="18.5 9 21 11.8 18.5 14.6" className="black-line-10"/><line x1="11" y1="11.8" x2="21" y2="11.8" className="black-line-10"/></g><g><polyline points="13.5 23 11 20.2 13.5 17.4" className="black-line-10"/><line x1="11" y1="20.2" x2="21" y2="20.2" className="black-line-10"/></g></Svg>
  );
}
