/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import InfluenceEnum from 'galax/enum/influence';

import { makeFragmentInfluence, makeSectorInfluence } from 'galax/util/influence';
import InfluenceCollection from 'galax/util/influence/user.collection';

import * as UserValue from 'galax/value/user';
import * as Array from 'fierry/util/array';


export default class
{
  constructor (scope)
  {
    this.scope = scope;

    this.sectorInfluences = new InfluenceCollection();
  }


  install ()
  {
    if (this.scope.getCass().getUser() === UserValue.getAncient())
    {
      this.distributeUniformSectorInfluence(UserValue.getAncientReference());

      //this.distributeAncientSectorInfluence();
    }
    else
    {
      this.distributeUniformSectorInfluence(this.scope.getUser().getReference());

      //this.distributeUserSectorInfluence();
    }
  }


  installSplashInfluence (influence, center)
  {
    const distribution = this.scope.getZones().getSplashDistribution(center);

    const total = distribution.getTotal();

    distribution.eachZone( (zone, weight) =>
    {
      if (weight)
      {
        const ratio = weight / total;

        zone.getUsers().installInfluence(makeFragmentInfluence(influence, ratio));
      }
    });
  }


  installSectorInfluence (influence)
  {
    this.sectorInfluences.push(influence);
  }


  distributeUserSectorInfluence ()
  {
    let distribution = this.__getPassiveDistribution__();

    let totalInfluence = this.__getPassiveTotal__();
    let totalWeight = distribution.getTotal();

    distribution.eachZone( (zone, zoneWeight) =>
    {
      if (totalWeight !== 0)
      {
        let zoneInfluence = Math.round(totalInfluence * zoneWeight / totalWeight);

        totalInfluence -= zoneInfluence;
        totalWeight -= zoneWeight;

        let user = zone.getSector().getUser().getReference();

        if (zoneInfluence < 0)
        {
          user = UserValue.getEmptyReference();

          zoneInfluence = - zoneInfluence;
        }

        const singleInfluence = makeSectorInfluence(user, zone.getLocation().getCass(), zoneInfluence);

        this.scope.getPlanet().getAttributes().installSingleInfluence(zone.getLocation().getCass(), singleInfluence);
      }
    });
  }


  distributeAncientSectorInfluence ()
  {
    const influence = this.scope.getConstants().getAncientZoneInfluence();

    this.scope.getZones().eachZone((zone) =>
    {
      const singleInfluence = makeSectorInfluence(UserValue.getAncientReference(), zone.getLocation().getCass(), influence);

      this.scope.getPlanet().getAttributes().installSingleInfluence(zone.getLocation().getCass(), singleInfluence);
    });
  }


  distributeUniformSectorInfluence (user)
  {
    const influence = this.scope.getConstants().getAncientZoneInfluence();

    this.scope.getZones().eachZone((zone) =>
    {
      const multiplier = zone.getAttributes().getYieldProduction();

      const singleInfluence = makeSectorInfluence(user, zone.getLocation().getCass(), influence * multiplier);

      this.scope.getPlanet().getAttributes().installSingleInfluence(zone.getLocation().getCass(), singleInfluence);
    });

  }


  __getPassiveDistribution__ ()
  {
    const zones = this.scope.getZones();

    return (this.__getPassiveTotal__() > 0) ? zones.getMissingEnergyDistribution() : zones.getAvailableEnergyDistribution();
  }


  __getPassiveTotal__ ()
  {
    return this.getTotalYield() + this.getTotalTransfers();
  }


  getTotalInfluence ()
  {
    return this.getTotalYield() + this.getTotalTransfers() + this.getTotalSpending() + this.getTotalMining() + this.getTotalAggressive();
  }


  getTotalYield ()
  {
    let count = 0;

    this.scope.getZones().eachZone(zone =>
    {
      count += zone.getAttributes().getYieldProduction();
    });

    return count * this.scope.getUser().getZoneYield();
  }


  getTotalTransfers ()
  {
    const currentOwner = this.scope.getCass().getUser();

    const positiveInfluence = this.sectorInfluences.getPassiveInfluence(currentOwner);
    const negativeInfluence = this.sectorInfluences.getPassiveInfluence(UserValue.getEmpty());

    return positiveInfluence - negativeInfluence;
  }


  getTotalSpending ()
  {
    let total = 0;

    this.scope.getZones().eachZone(zone =>
    {
      zone.getAttributes().eachInfluence(influence =>
      {
        if (influence.getType() === InfluenceEnum.Attrition)
        {
          total -= influence.getValue();
        }
      });
    });

    return total;
  }


  getTotalMining ()
  {
    let total = 0;

    this.scope.getZones().eachZone(zone =>
    {
      zone.getAttributes().eachInfluence(influence =>
      {
        if (influence.getType() === InfluenceEnum.Excavation)
        {
          total -= influence.getValue();
        }
      });
    });

    return total;
  }


  getTotalAggressive ()
  {
    let total = 0;

    const currentOwner = this.scope.getCass().getUser();

    this.scope.getZones().eachZone(zone =>
    {
      zone.getAttributes().eachInfluence(influence =>
      {
        if (influence.getType() === InfluenceEnum.Attack)
        {
          if (influence.getUserID() === currentOwner)
          {
            total += influence.getValue();
          }
          else
          {
            total -= influence.getValue();
          }
        }
      });
    });

    return total;
  }


  getEnemyUnits (userID)
  {
    let units = [];

    const zones = this.scope.getZones();
    const planet = this.scope.getPlanet();

    zones.eachZone(zone =>
    {
      zone.getAttributes().eachInfluence(influence =>
      {
        if (influence.getType() === InfluenceEnum.Attack)
        {
          if (influence.getUserID() === userID)
          {
            const { id, parent } = influence.getSource();

            if (parent.id === planet.getID())
            {
              units.push(planet.getUnit(id));
            }
          }
        }
      });
    });

    return Array.unique(units);
  }

}
