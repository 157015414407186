/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Status from 'galax/server/unit/status';

import { makeAttritionInfluence } from 'galax/util/influence';


export default class extends Status
{
  install ()
  {
    // no-op.
  }


  getStartDate ()
  {
    return this.parent.getCass().create_date;
  }


}
