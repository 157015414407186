/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Cache from 'fierry/flux/cache';
import Client from 'fierry/flux/entity/client';

import Rpc from 'galax/rpc';

import { isOffline } from 'galax/util/offline';


export default new Client ([ Rpc ], class
{
  constructor ([ state, hierarchy ])
  {
    this.state = state;
    this.hierarchy = hierarchy;
  }


  log (location)
  {
    this.hierarchy.execute('user/log', { galaxy: '1f98bde1-8f99-4731-be8c-2bfa11abe829', location });
  }


  loginUser (token)
  {
    console.log('Login User', token != null);

    if (isOffline())
    {
      this.hierarchy.execute('user/login', { offline_token: token });
    }
    else
    {
      this.hierarchy.execute('user/login', { firebase_token: token });
    }
  }


  logoutUser ()
  {
    this.hierarchy.execute('user/logout', { });
  }


  getSession()
  {
    return this.state.get('galaxy');
  }


  isReady ()
  {
    return this.state != null;
  }


  isSessionReady ()
  {
    if (!this.isReady())
    {
      return false;
    }

    const session = this.state.get('galaxy');

    // Somehow we still need to check for session.state to exist. Why?
    return session && session.state;
  }


});
