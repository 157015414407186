/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import View from 'fierry/client/view';

import Server from 'galax/server';

import Icon from 'svg/shared/modern/attack-stroke';


const OnClick = (event, spaceship, server, view) =>
{
  if (spaceship.getStatus().isAttack())
  {
    server.cancelSpaceshipAction(spaceship);
  }
  else
  {
    view.pushForeground('/spaceship.attack');
  }
};


export default ({ currentLocation, ...props }) =>
{
  const [ server, view ] = useFlux(Server, View);

  const spaceship = currentLocation.getSpaceship();

  const selected = spaceship.getStatus().isAttack();
  const disabled = (!selected && !spaceship.getStatus().isDefault())/* || spaceship.isCriticalEnergy()*/;

  const onClick = useCallback(OnClick, [ spaceship, server, view ]);

  const dock = spaceship.getSource().getDock()

  if (!spaceship.getUser().isCurrentUser() || (dock && dock.isActive()))
  {
    return null;
  }

  const tooltip = selected ? 'Cancel Attack' : 'Attack';

  return <Modern.Button tooltip = {tooltip} icon = {<Icon />} contained = {selected} disabled = {disabled} onClick = {onClick} />;
}
