/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux, Modern } from 'fierry/react';

import Behavior from 'galax/client/behavior';

import Done from 'svg/shared/modern/done-new';


const OnClick = (event, behavior) =>
{
  behavior.onDone();
}


export default (props) =>
{
  const [ behavior ] = useFlux(Behavior);

  const onClick = useCallback(OnClick, [ behavior ]);

  return <Modern.Button icon = {<Done />} tooltip = 'Done' disabled = {behavior.isInitial() || behavior.isError()} onClick = {onClick} />;
}
