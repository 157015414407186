/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><path d="M19,10c0,3.87-7,11-7,11S5,13.87,5,10a7,7,0,0,1,14,0Z" transform="translate(0 0)" className="black-line-20"/><circle cx="12" cy="10" r="2" className="black-shape"/></Svg>
  );
}
