/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';

import SpaceshipState from 'galax/fake/state/spaceship';


export default (spaceships) =>
{
  let state = new Immutable.Map();

  for (const spaceship of spaceships)
  {
    state = state.set(spaceship.getID(), SpaceshipState(spaceship));
  }

  return state;
}
