/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, styled } from 'fierry/react';

import * as EnergyValue from 'galax/value/energy';

import CoordsElement from 'galax/react/element/coords';
import DamageElement from 'galax/react/element/damage';
import CountdownElement from 'galax/react/element/countdown';

import ProjectileToIcon from 'svg/shared/modern/transfer-to';
import ProjectileFromIcon from 'svg/shared/modern/transfer-from';


const getProjectileLocation = ({ source, target, reverse }) =>
{
  return reverse ? target : source;
}


const getProjectileStrength = ({ target, projectile }) =>
{
  if (target.getSpaceship())
  {
    return projectile.getConstants().getSpaceshipStrength();
  }

  return projectile.getConstants().getPlanetStrength();
}


const getProjectileTime = ({ projectile }, now) =>
{
  return projectile.getRemainingTime(now);
}


const getProjectileTargetCount = ({ projectile }) =>
{
  return projectile.getTargetCount();
}


const ProjectileIcon = ({ reverse }) =>
{
  return reverse ? <ProjectileToIcon /> : <ProjectileFromIcon />;
}


const Component = (props) =>
(
  <Modern.Group>

    <CoordsElement param
      icon = {<ProjectileIcon {...props}/>}
      location = {getProjectileLocation(props)}
      tooltip = 'Target Coords'
    />

    <DamageElement
      damage = {getProjectileStrength(props)}
      count = {getProjectileTargetCount(props)}
    />

    <CountdownElement {...props}
      value = {getProjectileTime}
    />

  </Modern.Group>
);


export default (props) =>
{
  const { galaxy, projectile } = props;

  const source = galaxy.getLocation(projectile.getSource());
  const target = galaxy.getLocation(projectile.getTarget());

  return <Component {...props} source = {source} target = {target} />;
};
