/*
 * Copyright (C) 2019 Marek Kuzora - All Rights Reserved.
 */


import InfluenceEnum from 'galax/enum/influence';

import * as UserValue from 'galax/value/user';


class Influence
{
  constructor (type, user, source, value)
  {
    this.type = type;
    this.user = user;
    this.source = source;

    this.value = value;
  }


  getUser ()
  {
    return this.user;
  }


  getUserID ()
  {
    return this.user.id;
  }


  getType ()
  {
    return this.type;
  }


  getSource ()
  {
    return this.source;
  }


  getValue ()
  {
    return this.value;
  }


}


export const makeAttritionInfluence = (source, value) =>
{
  return new Influence(InfluenceEnum.Attrition, UserValue.getEmptyReference(), source, value);
}


export const makeExcavationInfluence = (source, value) =>
{
  return new Influence(InfluenceEnum.Excavation, UserValue.getEmptyReference(), source, value);
}


export const makeAnchorInfluence = (user, source, value) =>
{
  return new Influence(InfluenceEnum.Anchor, user, source, value);
}


export const makeAttackInfluence = (user, source, value) =>
{
  return new Influence(InfluenceEnum.Attack, user, source, value);
}


export const makeDisbandInfluence = (user, source, value) =>
{
  return new Influence(InfluenceEnum.Disband, user, source, value);
}


export const makeSectorInfluence = (user, source, value) =>
{
  return new Influence(InfluenceEnum.Sector, user, source, value);
}


export const makeTransferInfluence = (user, source, value) =>
{
  return new Influence(InfluenceEnum.Transfer, user, source, value);
}


export const makeFragmentInfluence = (parent, ratio) =>
{
  const value = Math.floor(ratio * parent.getValue());

  return new Influence(parent.getType(), parent.getUser(), parent.getSource(), value);
}
