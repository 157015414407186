/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (type, parent)
  {
    this.type = type;
    this.parent = parent;
  }


  getID ()
  {
    return this.type.id;
  }


  getUser ()
  {
    return this.parent.getUser();
  }


  getPlanet ()
  {
    return this.parent.getPlanet();
  }


  getCoords ()
  {
    return this.type.coords;
  }


  getLocation ()
  {
    return this.type.location;
  }


  getEnergy (user)
  {
    const found = this.getEnergyCollection().find(value => value.user === user);

    return found ? found.value : 0;
  }


  getEnergyCollection ()
  {
    return this.type.energy;
  }


  getResistance (user)
  {
    const found = this.getResistanceCollection().find(value => value.user === user);

    return found ? found.value : 0;
  }


  getResistanceCollection ()
  {
    return this.type.resistance;
  }


  getResource ()
  {
    return this.type.resource;
  }


  isOverheat ()
  {
    return this.type.overheat;
  }


  isCritical ()
  {
    return this.type.critical;
  }


  getUpdateDate ()
  {
    return this.type.update_date;
  }


  isCenter ()
  {
    return this.getCoords().isEqual(this.parent.getCenter());
  }


}
