/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { React, useFlux } from 'fierry/react';

import AccessEnum from 'galax/enum/access';

import Auth from 'fierry/client/auth';
import Url from 'fierry/client/url';

import Galaxy from 'galax/server';
import CurrentUser from 'galax/query/current.user';
import CurrentLocation from 'galax/query/current.location';


const getCurrentAccess = ({ auth, galaxy, currentLocation }) =>
{
  let access = AccessEnum.None;

  if (auth.isLoggedOut())
  {
    access = access | AccessEnum.Unauthorized;
  }

  if (galaxy == null || !galaxy.isReady())
  {
    return access;
  }

  access = access | AccessEnum.Galaxy;

  if (currentLocation == null)
  {
    return access;
  }

  access = access | AccessEnum.Location;

  if (currentLocation.getDock())
  {
    access = access | AccessEnum.Dock;
  }

  if (currentLocation.getPlatform())
  {
    access = access | AccessEnum.Platform;
  }

  if (currentLocation.getZone())
  {
    access = access | AccessEnum.Zone;
  }

  if (currentLocation.getSpaceship())
  {
    access = access | AccessEnum.Spaceship;
  }

  return access;
}


const getRouteProps = (access, { url, galaxy, currentUser, currentLocation }) =>
{
  let props = { currentArgs: url.getRoute().getArgs() };

  if (access & AccessEnum.Spaceship)
  {
    props.currentSpaceship = currentLocation.getSpaceship();
  }

  if (access & AccessEnum.Zone)
  {
    props.currentZone = currentLocation.getZone();
  }

  if (access & AccessEnum.Dock)
  {
    props.currentDock = currentLocation.getDock();
  }

  if (access & AccessEnum.Platform)
  {
    props.currentPlatform = currentLocation.getPlatform();
  }

  if (access & AccessEnum.Location)
  {
    props.key = currentLocation.getID();

    props.currentLocation = currentLocation;
  }

  if (access & AccessEnum.Galaxy)
  {
    props.galaxy = galaxy;
    props.currentUser = currentUser;
  }

  return props;
}


const Component = ({ component: Component, minAccess, ...props }) =>
{
  const access = getCurrentAccess(props);

  if (minAccess > access || Component == null)
  {
    return null;
  }

  return <Component {...props} {...getRouteProps(access, props)} access = {access} />;
}


export default ({ ...props }) =>
{
  const [ auth, url, galaxy, currentUser, currentLocation ] = useFlux(Auth, Url, Galaxy, CurrentUser, CurrentLocation);

  return <Component {...props} auth = {auth} url = {url} galaxy = {galaxy} currentUser = {currentUser} currentLocation = {currentLocation} />;
}
