/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux } from 'fierry/react';

import View from 'fierry/client/view';

import Item from 'fierry/react/skeleton/drawer/item';


const OnClick = (event, view) =>
{
  view.pushForeground('/privacy');
}


export default (props) =>
{
  const [ view ] = useFlux(View);

  const onClick = useCallback(OnClick, [ view ] );

  return <Item text = 'Privacy Statement' icon = 'bookmark' onClick = {onClick} />
};
