/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Fortified from 'galax/react/map/util/fortified';
import Dashed from 'galax/react/map/util/dashed';
import Invert from 'galax/react/map/util/invert';

import Spaceship from 'galax/react/icon/spaceship';


const getDimension = () =>
{
  return 2 * Math.sqrt(Math.pow(13, 2) + Math.pow(28, 2)) + 28;
}


const Component = ({ spaceship, grid }) =>
{
  const size = grid.getGeneralHex().getEntitySize();

  return <Spaceship center size = {size} spaceship = {spaceship} />;
};


export default (props) =>
{
  const { spaceship } = props;

  if (spaceship.getStatus().isProduction())
  {
    return <Dashed dimension = {getDimension()}> <Component {...props} /> </Dashed>;
  }

  if (spaceship.getStatus().isDock())
  {
    return <Fortified> <Component {...props} /> </Fortified>;
  }

  if (spaceship.hasTarget())
  {
    return <Invert> <Component {...props} /> </Invert>;
  }

  return <Component {...props} />;
};
