/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { styled } from 'fierry/react';


// TODO this is a little broken - actual border length can be between internal & external size.
const getSectorBorderDasharray = ({ grid, diagonal }) =>
{
  const size = diagonal ? grid.getGeneralHex().getDiagonalBorderSize() : grid.getGeneralHex().getVerticalBorderSize();

  return size / 3;
}


const getZoneBorderDasharray = ({ grid, diagonal }) =>
{
  return 0;
}


const UserBorderSide = styled.line`

  stroke: var(--svg-always-black);

  stroke-width: ${props => props.grid.getGeneralHex().getUserBorderWidth()};

  stroke-linecap: round;
`;


const SectorBorderSide = styled.line`

  stroke: var(--svg-always-black);

  stroke-width: ${props => props.grid.getGeneralHex().getSectorBorderWidth()};
  stroke-dasharray: ${props => getSectorBorderDasharray(props)};

  stroke-linecap: round;
`;


const ZoneBorderSide = styled.line`

  stroke: var(--svg-always-black);

  stroke-width: ${props => props.grid.getGeneralHex().getZoneBorderWidth()};
  stroke-dasharray: ${props => getZoneBorderDasharray(props)};

  stroke-linecap: round;
`;


const getLineProps = ({ x: x1, y: y1 }, { x: x2, y: y2 }) =>
{
  return { x1, y1, x2, y2 };
}


const isDiagonal = (index) =>
{
  return index != 1 && index != 4;
}



const isEmptyBorder = (location, neighbor) =>
{
  return false;
}


const isUserBorder = (location, neighbor) =>
{
  return !location.getZone() || !neighbor.getZone() || location.getZone().getUserID() !== neighbor.getZone().getUserID();
}


const isSectorBorder = (location, neighbor) =>
{
  return neighbor.getZone() && location.getZone().getSector().getID() !== neighbor.getZone().getSector().getID();
}


export default ({ location, grid }) =>
{
  const zoom = grid.getZoom();
  const hex = grid.getLocalHex(location);

  return hex.getZonePoints().map( (_, index, points) =>
  {
    const neighbors = hex.getNeighbors();

    const props = getLineProps(points[index], points[(index + 1) % points.length]);

    if (isEmptyBorder(location, neighbors[index]))
    {
      return null;
    }

    if (isUserBorder(location, neighbors[index]))
    {
      return <UserBorderSide {...props} key = {index} grid = {grid} />;
    }

    if (index > 2)
    {
      return null;
    }

    const diagonal = isDiagonal(index);

    if (zoom.isSectorVisible() && isSectorBorder(location, neighbors[index]))
    {
      return <SectorBorderSide {...props} key = {index} grid = {grid} diagonal = {diagonal} />;
    }

    if (zoom.isZoneVisible())
    {
      return <ZoneBorderSide {...props} key = {index} grid = {grid} diagonal = {diagonal} />;
    }

    return null;
  });
}
