/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { useCallback, useFlux } from 'fierry/react';

import Server from 'galax/server';

import Progress from 'galax/react/group/progress';

import ConstructionIcon from 'svg/shared/modern/construction-fill';
import ProductionIcon from 'svg/shared/modern/production-stroke';
import DisbandIcon from 'svg/shared/modern/delete-stroke';


const getHeader = ({ status }) =>
{
  if (status.isConstruction()) { return 'Construction'; }
  if (status.isUpgrade())      { return 'Upgrade'; }
  if (status.isDisband())      { return 'Abandon'; }

  if (status.isSetup())        { return 'Setup'; }
  if (status.isActive())       { return 'Active'; }
  if (status.isCooldown())     { return 'Cooldown'; }

  return null;
};


const getIcon = ({ status }) =>
{
  if (status.isConstruction()) { return <ConstructionIcon />; }
  if (status.isUpgrade())      { return <ConstructionIcon />; }
  if (status.isDisband())      { return <DisbandIcon />; }

  if (status.isSetup())        { return <ProductionIcon />; }
  if (status.isActive())       { return <ProductionIcon />; }
  if (status.isCooldown())     { return <ProductionIcon />; }

  return null;
};


const OnCancel = (event, zone, server) =>
{
  server.cancelEntityAction(zone.getPlanetID(), zone.getID());
};


export default ({ building, ...props }) =>
{
  const [ server ] = useFlux(Server);

  const onCancel = useCallback(OnCancel, [ building, server ]);

  if (building.getStatus().isDefault())
  {
    return null;
  }

  return <Progress {...props} icon = {getIcon} header = {getHeader} status = {building.getStatus()} onCancel = {onCancel} />;
}
