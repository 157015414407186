/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';


export default (projectiles) =>
{
  let state = new Immutable.Map();

  for (const projectile of projectiles)
  {
    state = state.set(projectile.type, projectile);
  }

  return state;

}
