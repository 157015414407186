/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern } from 'fierry/react';

import DefenceElement from 'galax/react/element/defence';
import EnergyElement from 'galax/react/element/energy';

import UserIcon from 'svg/shared/modern/user';


const getUserValue = ({ user }) =>
{
  return user.getName();
}


const getResistanceValue = ({ targetZone, user, now }) =>
{
  return 1 - targetZone.getResistance().getEfficiency(user.getID());
}


const getEnergyValue = ({ targetZone, user, now }) =>
{
  return targetZone.getEnergy().getEnergy(user.getID(), now);
}


const getInfluenceValue = ({ targetZone, user }) =>
{
  return targetZone.getEnergy().getFlow(user.getID());
}


const Item = (props) =>
(
  <Modern.Group>

    <Modern.Text param width = '8.5'
      icon = {<UserIcon />}
      text = {getUserValue(props)}
    />

    <DefenceElement {...props}
      value = {getResistanceValue}
    />

    <EnergyElement {...props}
      energy = {getEnergyValue(props)}
      influence = {getInfluenceValue(props)}
    />

  </Modern.Group>
);

export default ({ galaxy, targetZone, ...props }) =>
{
  let results = [];

  for (const userID of targetZone.getEnergy().getCurrentUsers())
  {
    const user = galaxy.getUser(userID);

    results.push(<Item {...props} key = {userID} user = {user} targetZone = {targetZone} />);
  }

  return results;
}
