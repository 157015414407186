/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { css, styled, withRef } from 'fierry/react';

import Container from 'fierry/react/skeleton/sidebar/container';


// TODO expand only config?
const normalConfig = ({ expand }) => expand && !expand.active && css`
  border-right: 1px solid var(--fierry-divider-color);
`;


const Component = styled(Container)`

  // Scrollbar apperance doesn't take up content space.
  overflow: overlay;

  ::-webkit-scrollbar {
    width: 0.25rem;
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--fierry-divider-color);
  }

  :hover, :active
  {
    ::-webkit-scrollbar {
      display: block;
    }
  }

  // TODO switch later.
  //${normalConfig}
  border-right: 1px solid var(--fierry-divider-color);
`;


export default withRef( (props, ref) =>
{
  return <Component ref = {ref} {...props} />;
});
