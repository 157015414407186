/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Dependency from 'fierry/flux/dependency'

import Action from 'fierry/flux/entity/action';


export default class Collection
{
  constructor (collection)
  {
    this.collection = collection;
  }


  push (entities)
  {
    return new Collection([...this.collection, this.__createDependences__(entities)]);
  }


  __createDependences__ (entities)
  {
    let collection = new Map();

    for (const entity of entities)
    {
      this.__createDependency__(entity, collection);
    }

    for (const dependency of collection.values())
    {
      dependency.install(collection.values());
    }

    return collection;
  }


  __createDependency__ (entity, collection)
  {
    const children = entity.getEntities();

    if (children.length)
    {
      for (const entity of children)
      {
        this.__createDependency__(entity, collection);
      }
    }
    else
    {
      collection.set(entity, new Dependency(entity));
    }
  }


  getDependency (entity)
  {
    entity = this.__getEntityRepresentative__(entity);

    for (let i = this.collection.length - 1; i >= 0; --i)
    {
      // TODO Action is an entity that is not exported directly - it should point to Store entity first (or dispatcher).
      if (this.collection[i].has(entity))
      {
        return this.collection[i].get(entity);
      }
    }

    return null;
  }


  isFrontDependency (entity)
  {
    return this.getDependencyLevel(entity) === this.collection.length - 1;
  }


  isBackDependency (entity)
  {
    return this.getDependencyLevel(entity) < this.collection.length - 1;
  }


  getDependencyLevel (entity)
  {
    entity = this.__getEntityRepresentative__(entity);

    for (let i = this.collection.length - 1; i >= 0; --i)
    {
      // TODO Action is an entity that is not exported directly - it should point to Store entity first (or dispatcher).
      if (this.collection[i].has(entity))
      {
        return i;
      }
    }

    return this.collection.length;
  }


  __getEntityRepresentative__ (entity)
  {
    if (entity instanceof Function)
    {
      return entity;
    }

    return entity.getEntities().length ? entity.getDefaultEntity() : entity;
  }


  getFrontDependences ()
  {
    let collection = [];

    for (const dependency of this.__getFrontDependencesValues__())
    {
      dependency.markEmpty();
    }

    for (const dependency of this.__getFrontDependencesValues__())
    {
      if (dependency.isEmptyMarker())
      {
        this.__sortDependency__(dependency, collection);
      }
    }

    return collection;
  }


  __getFrontDependencesValues__ ()
  {
    return this.collection[this.collection.length - 1].values();
  }


  // TODO Action entity returns incorrect dependency and therefore fierry/event/auth.refresh couldn't be correctly sorted!!
  __sortDependency__ (dependency, collection)
  {
    if (dependency.isTemporaryMarker())
    {
      throw new Error('Dependency cycle found.');
    }

    if (dependency.isPermanentMarker())
    {
      return;
    }

    dependency.markTemporary();

    for (const entity of dependency.getDependences())
    {
      if (this.isFrontDependency(entity))
      {
        this.__sortDependency__(this.getDependency(entity), collection);
      }

      else if (!this.isBackDependency(entity) && !entity instanceof Action)
      {
        throw new Error('Dependency not found but should be exported');
      }
    }

    dependency.markPermament();

    collection.push(dependency);
  }
}
