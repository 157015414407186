/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 30 30" {...props}><defs/><rect width="30" height="30" className="none"/><g><circle cx="15" cy="15" r="12" className="white-shape"/><g data-name="no-invert line-dash-a"><circle cx="15" cy="15" r="12" className="black-line-20"/></g></g><g><polyline points="12 10 9 15 12 20" className="black-line-10"/><polyline points="18 20 21 15 18 10" className="black-line-10"/></g></Svg>
  );
}
