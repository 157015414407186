/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Element, Modern, useFlux } from 'fierry/react';

import CoordsElement from 'galax/react/element/coords';

import UserIcon from 'svg/shared/modern/user';


const getUserName = ({ currentLocation }) =>
{
  return currentLocation.getUser().getName();
}


const Location = ({ currentLocation }) =>
(
  <CoordsElement location = {currentLocation} />
);


export default (props) =>
(
  <Card {...props} headline = 'Dock' subline = {Location} >
    <Modern.Group>

      <Modern.Text param width = '6'
        icon = {<UserIcon />}
        text = {getUserName(props)}
      />

    </Modern.Group>
  </Card>
);
