/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Modern from 'fierry/react/modern';

import { isString } from 'fierry/util/lang'


const Component = ({ subline : Subline, ...props }) =>
{
  return isString(Subline) ? <Modern.Text body text = {Subline} /> : <Subline {...props} />;
}


export default (props) =>
{
  return props.subline ? <Component {...props} /> : null;
}
