/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ParentEnum from 'galax/enum/parent';
import InteractionEnum from 'galax/enum/interaction';

import User from 'galax/fake/builder/user';
import Constants from 'galax/fake/builder/constants';

import Planet from 'galax/fake/builder/planet';
import Spaceship from 'galax/fake/builder/spaceship';

import Interaction from 'galax/fake/builder/interaction';
import Projectile from 'galax/fake/builder/projectile';

import * as Coords from 'galax/geo/coords';


export default class
{
  constructor (id)
  {
    this.id = id;

    this.currentTime = { id, global: true, realtime: false };
    this.currentGalaxy = { id, turn: 0, last_planet_id: 0 };

    this.users = [];
    this.planets = [];
    this.spaceships = [];

    this.constants = new Constants();
  }


  createUser(id, theme, name)
  {
    const user = new User(id, theme, name);

    this.users.push(user);

    return user;
  }


  createPlanet (id, [x, y] = [0, 0])
  {
    const planet = new Planet(id, Coords.fromOffset(x, y), this);

    this.planets.push(planet);

    return planet;
  }


  createSpaceship ([x, y], type, user)
  {
    const spaceship = new Spaceship(type, Coords.fromOffset(x, y), user);

    this.spaceships.push(spaceship);

    return spaceship;
  }


  createInteraction (type, source, target, shape, args)
  {
    const interaction = new Interaction(type, source, target, shape, args);

    this.getParent(source).insertInteractionFrom(interaction);
    this.getParent(target).insertInteractionTo(interaction);
  }


  createTransferInteraction (source, target, shape)
  {
    const args = { point_efficiency: 0.95, transfer_ratio: 0.05, transfer_strength: 1, transfer_distance: 0, strength_constant: 1, distance_constant: 1 };

    return this.createInteraction(InteractionEnum.TransferEnergy, source, target, shape, args);
  }


  createProjectile (type, source, target, shape, currentFlightSeconds, totalFlightSeconds)
  {
    const projectile = new Projectile(type, source, target, shape, currentFlightSeconds * 1000, totalFlightSeconds * 1000);

    this.getParent(source).insertProjectileFrom(projectile);
    this.getParent(target).insertProjectileTo(projectile);
  }


  finalize ()
  {
    this.date = new Date();

    this.finalizeUsers();
    this.finalizePlanets();
    this.finalizeCurrentTime();
    this.finalizeCurrentGalaxy();
  }


  finalizeUsers ()
  {
    for (const user of this.users)
    {
      user.setGalaxy(this.id);
      user.setUpdateDate(this.date);
    }
  }


  finalizePlanets ()
  {
    for (const planet of this.planets)
    {
      planet.setGalaxy(this.id);
      planet.setUpdateDate(this.date);
    }

    for (const planet of this.planets)
    {
      planet.finalize();
      //planet.printSectorsAndResources();
    }
  }


  finalizeCurrentTime ()
  {
    this.currentTime.server_date = this.date;
    this.currentTime.client_date = this.date;

    this.currentTime.client_delay = 0;
    this.currentTime.server_delay = 0;
  }


  finalizeCurrentGalaxy ()
  {
    this.currentGalaxy.update_date = this.date;
  }


  getCurrentGalaxy ()
  {
    return this.currentGalaxy;
  }


  getUsers ()
  {
    return this.users;
  }


  getUser (index)
  {
    return this.users[index];
  }


  getAncientUser ()
  {
    return this.users[0];
  }


  getParent (entity)
  {
    return entity instanceof Spaceship ? entity : entity.getPlanet();
  }


  getPlanets ()
  {
    return this.planets;
  }


  getPlanet (index)
  {
    return this.planets[index];
  }


  getPlanetByID (id)
  {
    for (const planet of this.planets)
    {
      if (planet.getID() === id)
      {
        return planet;
      }
    }

    return null;
  }


  getSpaceships ()
  {
    return this.spaceships;
  }


  /*getSpaceships (x, y)
  {
    for (const spaceship of this.spaceships)
    {
      const coords = spaceship.getCoords();

      if (coords.getX() === x && coords.getY() === y)
      {
        return spaceship;
      }
    }

    return null;
  }*/

  getSpaceship (index)
  {
    return this.spaceships[index];
  }


  getSpaceshipByID (id)
  {
    for (const spaceship of this.spaceships)
    {
      if (spaceship.getID() === id)
      {
        return spaceship;
      }
    }

    return null;
  }


  getConstants ()
  {
    return this.constants;
  }


  getCurrentTime ()
  {
    return this.currentTime;
  }


  getCurrentGalaxy ()
  {
    return this.currentGalaxy;
  }

}
