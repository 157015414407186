/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Building from 'galax/server/building';


const CREATE_EFFICIENCY_BASE = Math.sqrt(1.5);


export default class extends Building
{
  installPassiveTarget (zone)
  {
    const attribute = this.getFriendlyAttribute(this.getSpaceshipCreateEfficiency());

    zone.getAttributes().installSpaceshipCreateEfficiency(attribute);
  }



  getSpaceshipCreateEfficiency ()
  {
    return 1 / Math.pow(CREATE_EFFICIENCY_BASE, this.getLevel() - 1);
  }


};
