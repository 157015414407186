/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (scope)
  {
    this.scope = scope;
  }


  attackResistance (user, value)
  {
    return this.scope.getUsers().getResistanceUser(user).increaseResistance(value);
  }


  refreshResistance (duration)
  {
    this.scope.getUsers().eachResistanceUser( (user, value) =>
    {
      if (value.updateResistance(duration))
      {
        // no-op.
      }
    });
  }


  getFlow (user)
  {
    const value = this.scope.getUsers().getResistanceUser(user);

    return value ? value.getFlow() : 0;
  }


  getDamage (user, date)
  {
    const value = this.scope.getUsers().getResistanceUser(user);

    return value ? value.getDamage(date) : 0;
  }


  getProgress (user, date)
  {
    const value = this.scope.getUsers().getResistanceUser(user);

    return value ? value.getBracketProgress(date) : 0;
  }


  getEfficiency (user, date)
  {
    const value = this.scope.getUsers().getResistanceUser(user);

    return value ? value.getEfficiency(date) : this.scope.getAttributes().getResistanceEfficiency(user);
  }


  getFutureEventTime ()
  {
    let time = -1;

    this.scope.getUsers().eachResistanceUser( (user, value) =>
    {
      const timeToBracket = value.getTimeToBracket();

      if (timeToBracket > 0 && (time < 0 || timeToBracket < time))
      {
        time = timeToBracket;
      }
    });

    return time;
  }


}
