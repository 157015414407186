/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Marker from 'fierry/flux/dependency/marker';


export default class
{
  constructor (entity)
  {
    this.entity = entity;

    this.marker = Marker.None;
  }


  install (dependences)
  {
    this.entity.install(dependences);
  }


  isExportable ()
  {
    return this.entity.isExportable();
  }


  getDependences ()
  {
    const entityDependences = this.entity.getDependences();

    return this.visibility ? [...entityDependences, this.visibility] : entityDependences;
  }


  getEntity ()
  {
    return this.entity;
  }


  // - - - - - - - - - Visibility - - - - - - - - -


  setVisibility (visibility)
  {
    this.visibility = visibility;
  }


  getVisibility ()
  {
    return this.visibility;
  }


  // Client dependency can run visibility definition and check if it returns the client entity to be constructed.
  isVisible ()
  {
    if (this.visibility)
    {
      const dependences = extract(this.visibility.getDependences(), {}); // TODO expects props!

      return this.visibility.getDefinition()(dependences) === this.entity;
    }

    return true;
  }


  // - - - - - - - - - Sort Marker - - - - - - - - -


  markEmpty ()
  {
    this.marker = Marker.None;
  }


  markTemporary ()
  {
    this.marker = Marker.Temporary;
  }


  markPermament ()
  {
    this.marker = Marker.Permanent;
  }


  isEmptyMarker ()
  {
    return this.marker === Marker.None;
  }


  isTemporaryMarker ()
  {
    return this.marker === Marker.Temporary;
  }


  isPermanentMarker ()
  {
    return this.marker === Marker.Permanent;
  }


}
