/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


const add = (lhs, rhs) =>
{
  const q = lhs.getQ() + rhs.getQ();
  const r = lhs.getR() + rhs.getR();

  return new Axial(q, r);
};


export default class Axial
{
  constructor (q, r)
  {
    this.q = q;
    this.r = r;
  }


  getQ ()
  {
    return this.q;
  }


  getR ()
  {
    return this.r;
  }


  getS ()
  {
    return - this.q - this.r;
  }


  getX ()
  {
    return this.q + (this.r - (this.r & 1)) / 2;
  }


  getY ()
  {
    return this.r;
  }


  getTopRight ()
  {
    return add(this, new Axial(1, -1))
  }


  getCenterRight ()
  {
    return add(this, new Axial(1, 0))
  }


  getBottomRight ()
  {
    return add(this, new Axial(0, 1))
  }


  getTopLeft ()
  {
    return add(this, new Axial(0, -1))
  }


  getCenterLeft ()
  {
    return add(this, new Axial(-1, 0))
  }


  getBottomLeft ()
  {
    return add(this, new Axial(-1, 1))
  }


  toString ()
  {
    return this.getX() + '.' + this.getY();
  }


  isEqual (other)
  {
    return this.getQ() === other.getQ() && this.getR() === other.getR();
  }


};
