/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Query from 'fierry/flux/entity/query'

import Url from 'fierry/client/url';


export default new Query([ Url ], (url) =>
{
  return url;
});
