/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Modern from 'fierry/react/modern';

import { styled } from 'fierry/react/styled';


export default styled(Modern.Group)`
  margin-top: -0.5rem;
  margin-left: -0.25rem;
  margin-bottom: 0.25rem;

  height: 3rem;
`;
