/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import GalaxyBasic from 'galax/fake/galaxy.basic';

import SpaceshipEnum from 'galax/enum/spaceship';
import BuildingEnum from 'galax/enum/building';
import UnitEnum from 'galax/enum/unit';

import InteractionEnum from 'galax/enum/interaction';
import ProjectileEnum from 'galax/enum/projectile';
import ShapeEnum from 'galax/enum/shape';

import * as Influence from 'galax/value/influence';


export default () =>
{
  const galaxy = GalaxyBasic();

  setupPlanetOne(galaxy);
  setupPlanetTwo(galaxy);
  setupPlanetThree(galaxy);
  setupPlanetFour(galaxy);
  setupPlanetFive(galaxy);

  galaxy.finalize();

  return galaxy;
}


const setupPlanetOne = (galaxy) =>
{
  const planet = galaxy.getPlanet(0);

  // 1
  planet.getSector(0).assignUser(galaxy.getUser(1));
  planet.getSector(1).assignUser(galaxy.getUser(1));
  planet.getSector(4).assignUser(galaxy.getUser(2));
  planet.getSector(7).assignUser(galaxy.getUser(2));

  // 2
  planet.getSector(2).assignUser(galaxy.getUser(1));

  // Energy
  planet.getZone(1, 0).assignEnergy([ [ galaxy.getUser(1), 85 ] ]);
  planet.getZone(2, 0).assignEnergy([ [ galaxy.getUser(1), 90 ] ]);
  planet.getZone(1, 1).assignEnergy([ [ galaxy.getUser(1), 59 ] ]);
  planet.getZone(2, 1).assignEnergy([ [ galaxy.getUser(1), 82 ] ]);
  planet.getZone(1, 2).assignEnergy([ [ galaxy.getUser(1), 75 ] ]);
  planet.getZone(2, 2).assignEnergy([ [ galaxy.getUser(1), 60 ] ]);
  planet.getZone(1, 3).assignEnergy([ [ galaxy.getUser(1), 58 ] ]);
  planet.getZone(2, 3).assignEnergy([ [ galaxy.getUser(1), 71 ] ]);
  planet.getZone(3, 3).assignEnergy([ [ galaxy.getUser(1), 58 ] ]);

  planet.getZone(4, 2).assignEnergy([ [ galaxy.getUser(1), 82 ] ]);
  planet.getZone(3, 2).assignEnergy([ [ galaxy.getUser(1), 84 ] ]);
  planet.getZone(5, 1).assignEnergy([ [ galaxy.getUser(1), 85 ] ]);
  planet.getZone(4, 1).assignEnergy([ [ galaxy.getUser(1), 81 ] ]);
  planet.getZone(3, 0).assignEnergy([ [ galaxy.getUser(1), 80 ] ]);
  planet.getZone(3, 1).assignEnergy([ [ galaxy.getUser(1), 85 ] ]);
  planet.getZone(4, 0).assignEnergy([ [ galaxy.getUser(1), 85 ] ]);

  planet.getZone(0, 4).assignEnergy([ [ galaxy.getUser(1), 65 ] ]);
  planet.getZone(1, 4).assignEnergy([ [ galaxy.getUser(1), 60 ] ]);
  planet.getZone(2, 4).assignEnergy([ [ galaxy.getUser(1), 55 ] ]);
  planet.getZone(1, 5).assignEnergy([ [ galaxy.getUser(1), 43 ] ]);
  planet.getZone(0, 6).assignEnergy([ [ galaxy.getUser(1), 49 ] ]);
  planet.getZone(1, 6).assignEnergy([ [ galaxy.getUser(1), 39 ] ]);
  planet.getZone(0, 5).assignEnergy([ [ galaxy.getUser(1), 10 ] ]);
  planet.getZone(2, 5).assignEnergy([ [ galaxy.getUser(1),  6 ] ]);
  planet.getZone(3, 5).assignEnergy([ [ galaxy.getUser(1), 10 ] ]);

  planet.getZone(1, 7).assignEnergy([ [ galaxy.getUser(0), 68 ], [ galaxy.getUser(1), 32 ] ]);
  planet.getZone(2, 7).assignEnergy([ [ galaxy.getUser(0), 68 ], [ galaxy.getUser(1), 32 ] ]);
  planet.getZone(2, 8).assignEnergy([ [ galaxy.getUser(0), 76 ], [ galaxy.getUser(1), 24 ] ]);
  planet.getZone(1, 8).assignEnergy([ [ galaxy.getUser(0), 80 ], [ galaxy.getUser(1), 20 ] ]);
  planet.getZone(0, 8).assignEnergy([ [ galaxy.getUser(0), 73 ], [ galaxy.getUser(1), 27 ] ]);
  planet.getZone(1, 9).assignEnergy([ [ galaxy.getUser(0), 80 ], [ galaxy.getUser(1), 20 ] ]);
  planet.getZone(2, 9).assignEnergy([ [ galaxy.getUser(0), 76 ], [ galaxy.getUser(1), 24 ] ]);

  planet.getZone(3, 4).assignEnergy([ [ galaxy.getUser(0), 65 ], [ galaxy.getUser(1), 35 ] ]);
  planet.getZone(5, 5).assignEnergy([ [ galaxy.getUser(0), 80 ], [ galaxy.getUser(2), 20 ] ]);
  planet.getZone(5, 4).assignEnergy([ [ galaxy.getUser(0), 65 ], [ galaxy.getUser(2), 35 ] ]);
  planet.getZone(5, 3).assignEnergy([ [ galaxy.getUser(0), 80 ], [ galaxy.getUser(2), 20 ] ]);

  planet.getZone(7, 4).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);
  planet.getZone(7, 3).assignEnergy([ [ galaxy.getUser(2), 70 ] ]);
  planet.getZone(6, 3).assignEnergy([ [ galaxy.getUser(2), 60 ] ]);
  planet.getZone(8, 3).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);
  planet.getZone(7, 2).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);
  planet.getZone(6, 2).assignEnergy([ [ galaxy.getUser(2), 70 ] ]);
  planet.getZone(5, 2).assignEnergy([ [ galaxy.getUser(2), 70 ] ]);
  planet.getZone(6, 1).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);
  planet.getZone(7, 1).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);

  planet.getZone(5, 7).assignEnergy([ [ galaxy.getUser(2), 45 ] ]);
  planet.getZone(6, 7).assignEnergy([ [ galaxy.getUser(2), 35 ] ]);
  planet.getZone(5, 6).assignEnergy([ [ galaxy.getUser(2), 36 ] ]);
  planet.getZone(6, 6).assignEnergy([ [ galaxy.getUser(2), 55 ] ]);
  planet.getZone(6, 5).assignEnergy([ [ galaxy.getUser(2), 43 ] ]);
  planet.getZone(7, 5).assignEnergy([ [ galaxy.getUser(2), 45 ] ]);
  planet.getZone(6, 4).assignEnergy([ [ galaxy.getUser(2), 51 ] ]);

  planet.getZone(3, 9).assignEnergy([ [ galaxy.getUser(0), 45 ], [ galaxy.getUser(2), 55 ] ]);
  planet.getZone(4, 9).assignEnergy([ [ galaxy.getUser(0), 35 ], [ galaxy.getUser(2), 65 ] ]);
  planet.getZone(3,10).assignEnergy([ [ galaxy.getUser(0), 48 ], [ galaxy.getUser(2), 52 ] ]);
  planet.getZone(4,10).assignEnergy([ [ galaxy.getUser(0), 52 ], [ galaxy.getUser(2), 48 ] ]);
  planet.getZone(3, 8).assignEnergy([ [ galaxy.getUser(0), 60 ], [ galaxy.getUser(2), 40 ] ]);
  planet.getZone(4, 8).assignEnergy([ [ galaxy.getUser(0), 60 ], [ galaxy.getUser(2), 40 ] ]);

  // Buildings
  planet.createBuilding([ 2, 2 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 3, 0 ], BuildingEnum.LaserBattery);
  planet.createBuilding([ 0, 4 ], BuildingEnum.Spaceport).setStatus('create');
  planet.createBuilding([ 6, 2 ], BuildingEnum.DistributionCenter).setStatus('upgrade');
  planet.createBuilding([ 7, 5 ], BuildingEnum.GaussCannon);

  // Units
  planet.createUnit([ 3, 3 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 1, 3 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 1, 6 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 2, 5 ], UnitEnum.RangeAttack).setStatus('attack');


  planet.createUnit([ 6, 3 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 6, 5 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 5, 6 ], UnitEnum.RangeAttack).setStatus('attack');
  planet.createUnit([ 6, 7 ], UnitEnum.RangeAttack).setStatus('attack');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(1, 3), planet.getZone(1, 8), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(3, 3), planet.getZone(1, 8), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(1, 6), planet.getZone(1, 7), { type: ShapeEnum.DoubleBasic, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(2, 5), planet.getZone(0, 8), { type: ShapeEnum.Single, variant: 0 }, { value: Influence.fromPercentage(6)});

  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(6, 3), planet.getZone(5, 3), { type: ShapeEnum.DoubleBasic, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(6, 5), planet.getZone(5, 4), { type: ShapeEnum.DoubleBasic, variant: 2 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(5, 6), planet.getZone(4, 8), { type: ShapeEnum.TripleArea, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(6, 7), planet.getZone(4, 8), { type: ShapeEnum.TripleArea, variant: 1 }, { value: Influence.fromPercentage(6)});

  //galaxy.createTransferInteraction(planet.getZone(3, 1), planet.getZone(1, 2), { type: ShapeEnum.Sector, variant: 0 });
  //galaxy.createTransferInteraction(planet.getZone(1, 2), planet.getZone(0, 4), { type: ShapeEnum.Sector, variant: 0 });
  //galaxy.createTransferInteraction(planet.getZone(6, 2), planet.getZone(6, 5), { type: ShapeEnum.Sector, variant: 0 });

  // Projectiles
  galaxy.createProjectile(ProjectileEnum.LaserBattery, planet.getZone(3, 0), planet.getZone(2, 8), { type: ShapeEnum.TripleArea, variant: 0 }, 300, 900);
  galaxy.createProjectile(ProjectileEnum.GaussCannon, planet.getZone(7, 5), planet.getZone(4, 4), { type: ShapeEnum.QuadrupleArea, variant: 2 }, 200, 900);

}


const setupPlanetTwo = (galaxy) =>
{
  const planet = galaxy.getPlanet(1);

  // 1
  planet.getSector(2).assignUser(galaxy.getUser(3));
  planet.getSector(8).assignUser(galaxy.getUser(3));

  // 2
  planet.getSector(5).assignUser(galaxy.getUser(3));

  // Energy
  planet.getZone( 7, 1).assignEnergy([ [ galaxy.getUser(3), 91 ] ]);
  planet.getZone( 8, 3).assignEnergy([ [ galaxy.getUser(3), 78 ] ]);
  planet.getZone( 9, 2).assignEnergy([ [ galaxy.getUser(3), 88 ] ]);
  planet.getZone( 8, 2).assignEnergy([ [ galaxy.getUser(3), 71 ] ]);
  planet.getZone( 9, 3).assignEnergy([ [ galaxy.getUser(3), 76 ] ]);
  planet.getZone( 9, 4).assignEnergy([ [ galaxy.getUser(3), 96 ] ]);
  planet.getZone(10, 2).assignEnergy([ [ galaxy.getUser(3), 79 ] ]);
  planet.getZone( 9, 1).assignEnergy([ [ galaxy.getUser(3), 93 ] ]);
  planet.getZone( 8, 1).assignEnergy([ [ galaxy.getUser(3), 92 ] ]);

  planet.getZone(10, 4).assignEnergy([ [ galaxy.getUser(3), 75 ] ]);
  planet.getZone(10, 5).assignEnergy([ [ galaxy.getUser(3), 89 ] ]);
  planet.getZone( 9, 5).assignEnergy([ [ galaxy.getUser(3), 88 ] ]);
  planet.getZone(11, 4).assignEnergy([ [ galaxy.getUser(3), 75 ] ]);
  planet.getZone(10, 3).assignEnergy([ [ galaxy.getUser(3), 88 ] ]);
  planet.getZone(10, 6).assignEnergy([ [ galaxy.getUser(3), 93 ] ]);
  planet.getZone(11, 5).assignEnergy([ [ galaxy.getUser(3), 90 ] ]);
  planet.getZone(11, 6).assignEnergy([ [ galaxy.getUser(3), 83 ] ]);
  planet.getZone(12, 6).assignEnergy([ [ galaxy.getUser(3), 94 ] ]);

  planet.getZone( 8, 4).assignEnergy([ [ galaxy.getUser(3), 55 ] ]);
  planet.getZone( 7, 3).assignEnergy([ [ galaxy.getUser(3), 55 ] ]);
  planet.getZone( 6, 4).assignEnergy([ [ galaxy.getUser(3), 70 ] ]);
  planet.getZone( 6, 5).assignEnergy([ [ galaxy.getUser(3), 67 ] ]);
  planet.getZone( 7, 4).assignEnergy([ [ galaxy.getUser(3), 65 ] ]);
  planet.getZone( 7, 5).assignEnergy([ [ galaxy.getUser(3), 10 ] ]);
  planet.getZone( 8, 5).assignEnergy([ [ galaxy.getUser(3), 15 ] ]);

  planet.getZone(11, 2).assignEnergy([ [ galaxy.getUser(0), 45 ], [ galaxy.getUser(3), 55 ] ]);
  planet.getZone(11, 3).assignEnergy([ [ galaxy.getUser(0), 40 ], [ galaxy.getUser(3), 60 ] ]);
  planet.getZone(10, 1).assignEnergy([ [ galaxy.getUser(0), 70 ], [ galaxy.getUser(3), 30 ] ]);
  planet.getZone(12, 4).assignEnergy([ [ galaxy.getUser(0), 70 ], [ galaxy.getUser(3), 30 ] ]);
  planet.getZone(12, 2).assignEnergy([ [ galaxy.getUser(0), 82 ], [ galaxy.getUser(3), 18 ] ]);
  planet.getZone(12, 3).assignEnergy([ [ galaxy.getUser(0), 82 ], [ galaxy.getUser(3), 18 ] ]);
  planet.getZone(12, 1).assignEnergy([ [ galaxy.getUser(0), 82 ], [ galaxy.getUser(3), 18 ] ]);
  planet.getZone(13, 2).assignEnergy([ [ galaxy.getUser(0), 82 ], [ galaxy.getUser(3), 18 ] ]);
  planet.getZone(11, 1).assignEnergy([ [ galaxy.getUser(0), 82 ], [ galaxy.getUser(3), 18 ] ]);

  // Buildings
  planet.createBuilding([ 9, 3 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 9, 5 ], BuildingEnum.LaserBattery);
  planet.createBuilding([ 7, 4 ], BuildingEnum.TransferStation).setStatus('create');

  // Units
  planet.createUnit([10, 2 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([11, 4 ], UnitEnum.BasicAttack).setStatus('attack');

  planet.createUnit([11, 6 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([10, 4 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 8, 3 ], UnitEnum.AreaAttack).setStatus('create');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(10, 2), planet.getZone(10, 1), { type: ShapeEnum.DoubleBasic, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(11, 4), planet.getZone(11, 3), { type: ShapeEnum.DoubleBasic, variant: 1 }, { value: Influence.fromPercentage(6)});

  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(11, 6), planet.getZone(12, 2), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(10, 4), planet.getZone(12, 2), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});

}


const setupPlanetThree = (galaxy) =>
{
  const planet = galaxy.getPlanet(2);

  // 2
  planet.getSector(2).assignUser(galaxy.getUser(4));
  planet.getSector(3).assignUser(galaxy.getUser(4));

  // Energy
  planet.getZone( 8, 5).assignEnergy([ [ galaxy.getUser(4), 86 ] ]);
  planet.getZone( 8, 4).assignEnergy([ [ galaxy.getUser(4), 93 ] ]);
  planet.getZone( 9, 4).assignEnergy([ [ galaxy.getUser(4), 93 ] ]);
  planet.getZone( 9, 5).assignEnergy([ [ galaxy.getUser(4), 93 ] ]);

  planet.getZone(11, 6).assignEnergy([ [ galaxy.getUser(4), 86 ] ]);
  planet.getZone(10, 5).assignEnergy([ [ galaxy.getUser(4), 93 ] ]);
  planet.getZone(10, 6).assignEnergy([ [ galaxy.getUser(4), 93 ] ]);
  planet.getZone(11, 5).assignEnergy([ [ galaxy.getUser(4), 93 ] ]);
  planet.getZone(11, 7).assignEnergy([ [ galaxy.getUser(4), 93 ] ]);
  planet.getZone(12, 6).assignEnergy([ [ galaxy.getUser(4), 93 ] ]);
  planet.getZone(10, 7).assignEnergy([ [ galaxy.getUser(4), 93 ] ]);

  planet.getZone( 7, 7).assignEnergy([ [ galaxy.getUser(0), 81 ], [ galaxy.getUser(4), 19 ] ]);
  planet.getZone( 8, 7).assignEnergy([ [ galaxy.getUser(0), 81 ], [ galaxy.getUser(4), 19 ] ]);
  planet.getZone( 8, 8).assignEnergy([ [ galaxy.getUser(0), 89 ], [ galaxy.getUser(4), 11 ] ]);
  planet.getZone( 7, 6).assignEnergy([ [ galaxy.getUser(0), 93 ], [ galaxy.getUser(4),  7 ] ]);
  planet.getZone( 8, 6).assignEnergy([ [ galaxy.getUser(0), 93 ], [ galaxy.getUser(4),  7 ] ]);

  // Buildings
  planet.createBuilding([ 9, 4 ], BuildingEnum.DistributionCenter).setStatus('upgrade');

  // Units
  planet.createUnit([ 8, 5 ], UnitEnum.ConeAttack).setStatus('attack');
  planet.createUnit([11, 6 ], UnitEnum.RangeAttack).setStatus('attack');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 8, 5), planet.getZone( 8, 6), { type: ShapeEnum.QuadrupleArea, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(11, 6), planet.getZone( 8, 7), { type: ShapeEnum.TripleArea, variant: 1 }, { value: Influence.fromPercentage(6)});
}


const setupPlanetFour = (galaxy) =>
{
  const planet = galaxy.getPlanet(3);

  // 1
  planet.getSector(0).assignUser(galaxy.getUser(5));
  planet.getSector(1).assignUser(galaxy.getUser(5));

  // 2
  planet.getSector(2).assignUser(galaxy.getUser(5));
  planet.getSector(3).assignUser(galaxy.getUser(5));

  // Energy
  planet.getZone( 2, 1).assignEnergy([ [ galaxy.getUser(5), 60 ] ]);
  planet.getZone( 3, 1).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 2, 2).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 3, 2).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 2, 3).assignEnergy([ [ galaxy.getUser(5), 90 ] ]);
  planet.getZone( 3, 3).assignEnergy([ [ galaxy.getUser(5), 90 ] ]);
  planet.getZone( 3, 4).assignEnergy([ [ galaxy.getUser(5), 90 ] ]);

  planet.getZone( 4, 0).assignEnergy([ [ galaxy.getUser(5), 60 ] ]);
  planet.getZone( 6, 0).assignEnergy([ [ galaxy.getUser(5), 60 ] ]);
  planet.getZone( 4, 1).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 5, 0).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 5, 1).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 6, 1).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 4, 2).assignEnergy([ [ galaxy.getUser(5), 90 ] ]);
  planet.getZone( 5, 2).assignEnergy([ [ galaxy.getUser(5), 90 ] ]);
  planet.getZone( 6, 2).assignEnergy([ [ galaxy.getUser(5), 90 ] ]);

  planet.getZone( 6, 3).assignEnergy([ [ galaxy.getUser(5), 60 ] ]);
  planet.getZone( 7, 1).assignEnergy([ [ galaxy.getUser(5), 51 ] ]);
  planet.getZone( 7, 2).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 8, 2).assignEnergy([ [ galaxy.getUser(5), 60 ] ]);
  planet.getZone( 7, 3).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);

  planet.getZone( 4, 3).assignEnergy([ [ galaxy.getUser(5), 51 ] ]);
  planet.getZone( 5, 3).assignEnergy([ [ galaxy.getUser(5), 51 ] ]);
  planet.getZone( 4, 4).assignEnergy([ [ galaxy.getUser(5), 51 ] ]);
  planet.getZone( 5, 4).assignEnergy([ [ galaxy.getUser(5), 51 ] ]);
  planet.getZone( 6, 4).assignEnergy([ [ galaxy.getUser(5), 51 ] ]);
  planet.getZone( 4, 5).assignEnergy([ [ galaxy.getUser(5), 51 ] ]);
  planet.getZone( 5, 5).assignEnergy([ [ galaxy.getUser(5), 51 ] ]);

  planet.getZone( 5, 6).assignEnergy([ [ galaxy.getUser(5),  1 ] ]);
  planet.getZone( 6, 6).assignEnergy([ [ galaxy.getUser(5),  1 ] ]);
  planet.getZone( 6, 5).assignEnergy([ [ galaxy.getUser(5),  1 ] ]);
  planet.getZone( 7, 4).assignEnergy([ [ galaxy.getUser(5),  1 ] ]);

  // Buildings
  planet.createBuilding([ 5, 2 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 3, 3 ], BuildingEnum.GaussCannon);
  planet.createBuilding([ 7, 1 ], BuildingEnum.Spaceport).setStatus('create');

  // Units
  planet.createUnit([ 6, 0 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 4, 0 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 2, 1 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 8, 2 ], UnitEnum.AreaAttack).setStatus('attack');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 6, 0), planet.getZone( 5, 4), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 4, 0), planet.getZone( 5, 4), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 2, 1), planet.getZone( 5, 4), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 8, 2), planet.getZone( 5, 4), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});

  // Projectiles
  galaxy.createProjectile(ProjectileEnum.GaussCannon, planet.getZone(3, 3), planet.getZone(7, 7), { type: ShapeEnum.QuadrupleArea, variant: 0 }, 400, 900);
}


const setupPlanetFive = (galaxy) =>
{
  const planet = galaxy.getPlanet(4);
}
