/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import View from 'fierry/react/skeleton/card/view';

import Unit from 'galax/react/card/unit/overview';
import Building from 'galax/react/card/building/overview';

import Zone from 'galax/react/card/zone.overview';
import Sector from 'galax/react/card/sector.overview';
import SectorStatus from 'galax/react/card/sector.status';

import ProjectileCard from 'galax/react/card/zone.projectile.list';


export default (props) =>
(
  <>
    <Zone {...props}/>

    <Unit {...props} />
    <Building {...props} />

    <Sector {...props} />
    <SectorStatus {...props} />

    <View {...props} card = {ProjectileCard} route = '/projectile.list' />
  </>
);
