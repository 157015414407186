/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react';

import MobileRoot from 'fierry/react/skeleton/pane/root/mobile';
import DesktopRoot from 'fierry/react/skeleton/pane/root/desktop';

import MobilePresentation from 'fierry/react/skeleton/pane/presentation/mobile';
import DesktopPresentation from 'fierry/react/skeleton/pane/presentation/desktop';

import isMobile from 'fierry/util/mobile';


const Root = (props) =>
{
  return isMobile() ? <MobileRoot {...props} /> : <DesktopRoot {...props} />
}


const Presentation = (props) =>
{
  return isMobile() ? <MobilePresentation {...props} /> : <DesktopPresentation {...props} />
}


export default (props) =>
(
  <Root>
    <Presentation {...props} />
  </Root>
);
