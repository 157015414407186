/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, Modern, useCallback, useFlux } from 'fierry/react';

import Zoom from 'galax/client/zoom';

import PlusIcon from 'svg/shared/modern/plus';
import MinusIcon from 'svg/shared/modern/minus';


const Container = styled(Modern.Group)`
  float: right;

  margin-top: 1rem;

  background: var(--fierry-theme-background);
  border: 1px solid var(--fierry-divider-color);
`;


const Component = ({ onZoomIn, onZoomOut, ...props }) =>
(
  <Container vertical>
    <Modern.Button {...props} icon = {<PlusIcon />} tooltip = 'Zoom In' onClick = {onZoomIn} />
    <Modern.Button {...props} icon = {<MinusIcon />} tooltip = 'Zoom Out' onClick = {onZoomOut} />
  </Container>
);


const OnZoomIn = (event, zoom) =>
{
  zoom.zoomIn();
}


const OnZoomOut = (event, zoom) =>
{
  zoom.zoomOut();
}


export default (props) =>
{
  const [ zoom ] = useFlux(Zoom);

  const onZoomIn = useCallback(OnZoomIn, [ zoom ]);
  const onZoomOut = useCallback(OnZoomOut, [ zoom ]);

  return <Component {...props} onZoomIn = {onZoomIn} onZoomOut = {onZoomOut} />;
}
