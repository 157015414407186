/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import '@material/mwc-linear-progress';

import { styled, css } from 'fierry/react/styled';


const defaultState = (props) => css`
  --fierry-progress-foreground: var(--fierry-theme-foreground);
  --fierry-progress-opacity: var(--fierry-high-opacity);
`;


const emphasisState = ({ emphasis }) => emphasis && css`
  --fierry-progress-opacity: var(--fierry-medium-opacity);
`;


const disabledState = ({ disabled }) => disabled && css`
  --fierry-progress-opacity: var(--fierry-disabled-opacity);
`;


const primaryState = ({ primary, disabled }) => (primary && !disabled) && css`
  --fierry-progress-foreground: var(--fierry-theme-primary-foreground);
  --fierry-progress-opacity: var(--fierry-full-opacity);
`;


const secondaryState = ({ secondary, disabled }) => (secondary && !disabled) && css`
  --fierry-progress-foreground: var(--fierry-theme-secondary-foreground);
  --fierry-progress-opacity: var(--fierry-full-opacity);
`;


const widthConfig = ({ width }) => width && css`
  width: ${width}rem;
  flex-grow: 0;
`;


const Component = ({ className, value, ...props }) =>
(
  <mwc-linear-progress {...props} class = {className} progress = {value} />
);


export default styled(Component)`

  flex-grow: 1;
  opacity: var(--fierry-progress-opacity);

  ${defaultState}
  ${disabledState}
  ${emphasisState}

  ${primaryState}
  ${secondaryState}

  ${widthConfig}

  --mdc-theme-primary: var(--fierry-progress-foreground);
  --mdc-linear-progress-buffer-color:  var(--fierry-theme-background-active);
`;
