/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useContext } from 'react';

import Contexts from 'fierry/flux/contexts'


export default (key) =>
{
  return useContext(Contexts.get(key));
}
