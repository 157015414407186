/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Client } from 'fierry/flux';

import ShapeEnum from 'galax/enum/shape';

import Galaxy from 'galax/server';
import Selection from 'galax/client/selection';
import CurrentLocation from 'galax/query/current.location';

import * as Coords from 'galax/geo/coords';
import * as Shapes from 'galax/geo/shapes';


export default new Client([ CurrentLocation, Selection, Galaxy ], class
{
  constructor (location, selection, galaxy)
  {
    this.galaxy = galaxy;
    this.spaceship = location.getSpaceship();
    this.target = selection.getLocation();
    this.count = selection.getCount();
  }


  onDone ()
  {
    const shape = this.__getTargetShape__();

    this.galaxy.attackSpaceship(this.spaceship, this.target, shape);
  }


  getSelectionTargets ()
  {
    if (this.target.getSpaceship())
    {
      return [ this.target ];
    }

    const mapCallback = (coords) =>
    {
      return this.galaxy.getLocation(coords);
    };

    return this.__getTargetShape__().data.map(mapCallback);
  }



  __getTargetShape__ ()
  {
    const constants = this.spaceship.getSpaceshipConstants();

    const targetVariants = Shapes.getMaxVariants(this.target.getCoords(), this.spaceship.getSourceCoords(), constants.getTargetShape());
    const targetShape = targetVariants[(this.count - 1) % targetVariants.length];

    if (this.target.getSpaceship())
    {
      return targetShape;
    }

    const downgradeCallback = (coords) =>
    {
      return this.galaxy.getLocation(coords).getZone() == null;
    };

    return Shapes.getDowngrade(this.target.getCoords(), targetShape, downgradeCallback);
  }



  getError ()
  {
    const targetSpaceship = this.target.getSpaceship();

    /*if (this.spaceship.isCriticalEnergy())
    {
      return 'Spaceship energy is too low for attack';
    }*/

    if (targetSpaceship)
    {
      if (this.spaceship.getUserID() === targetSpaceship.getUserID())
      {
        return 'Cannot attack your own spaceship.';
      }

      if (targetSpaceship.getStatus().isProduction())
      {
        return 'Cannot attack spaceship under construction.';
      }

      if (targetSpaceship.getStatus().isDock())
      {
        return 'Cannot attack docked spaceship.';
      }
    }

    if (!this.target.getZone() && !this.target.getSpaceship())
    {
      return 'Cannot attack empty space.';
    }

    const distance  = Coords.getDistance(this.spaceship.getSourceCoords(), this.target.getCoords());

    if (distance < this.spaceship.getMinRange())
    {
      return 'Target zone is too close. Please select more distant target.';
    }

    if (distance > this.spaceship.getMaxRange())
    {
      return 'Target zone is out of range. Please select a closer target.';
    }

    return null;
  }


  getInitial ()
  {
    if (this.target == null)
    {
      return 'Select target zone';
    }

    return null;
  }


  canSelect ()
  {
    return true;
  }


  canSelectGalaxy ()
  {
    return true;
  }


  canNavigate ()
  {
    return false;
  }


  isPaneCollapsed ()
  {
    return true;
  }


});
