/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern, Element, useFlux, withValue } from 'fierry/react';

import CenterButton from 'galax/react/button/map/center';

import PointsIcon from 'svg/shared/modern/points';
import UserIcon from 'svg/shared/modern/user';


const getName = ({ currentUser }) =>
{
  return currentUser.getName();
}


const getPoints = ({ currentUser }) =>
{
  return Math.round(currentUser.getPoints());
}


const Buttons = (props) =>
(
  <CenterButton x = {0} y = {0} />
);


const Headline = (props) =>
(
  <Modern.Text headline icon = {<UserIcon />} text = {getName(props)} tooltip = 'User Name' />
);


const PointsElement = (props) =>
(
  <Modern.Text param width = '3' icon = {<PointsIcon />} text = {getPoints(props)} tooltip = 'Points' />
);


export default (props) =>
(
  <Card {...props} headline = {Headline} subline = {PointsElement} buttons = {Buttons} >
  </Card>
);
