/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { css, styled } from 'fierry/react/styled';

import Group from 'fierry/react/modern/group';
import Text from 'fierry/react/modern/text';


const defaultConfig = (props) => css`
  --fierry-status-foreground: var(--fierry-theme-foreground);
  --fierry-status-opacity: var(--fierry-high-opacity);
`;


const emphasisConfig = ({ emphasis }) => emphasis && css`
  --fierry-status-opacity: var(--fierry-medium-opacity);
`;


const disabledConfig = ({ disabled }) => disabled && css`
  --fierry-status-opacity: var(--fierry-disabled-opacity);
`;


const primaryConfig = ({ primary, disabled }) => primary && !disabled && css`
  --fierry-status-foreground: var(--fierry-theme-primary-foreground);
  --fierry-status-opacity: var(--fierry-full-opacity);
`;


const secondaryConfig = ({ secondary, disabled }) => secondary && !disabled && css`
  --fierry-status-foreground: var(--fierry-theme-secondary-foreground);
  --fierry-status-opacity: var(--fierry-full-opacity);
`;


const widthConfig = ({ width }) => width && css`
  --fierry-status-width: ${width}rem;
`;


const heightConfig = ({ height }) => height && css`
  --fierry-status-height: ${height}rem;
`;


const Svg = styled.svg`

  --fierry-status-width: 0rem;
  --fierry-status-height: 2rem;

  --fierry-status-circuit: calc(var(--fierry-status-width) * 2 + var(--fierry-status-height) * 2 - 4px);

  position: absolute;

  width: var(--fierry-status-width);
  height: var(--fierry-status-height);

  fill: transparent;
  stroke-width: 1px;

  ${defaultConfig}
  ${emphasisConfig}
  ${disabledConfig}

  ${primaryConfig}
  ${secondaryConfig}

  ${widthConfig}
  ${heightConfig}
`;


const getViewBox = ({ width = 0, height = 2 }) =>
{
  return `0 0 ${width * 16} ${height * 16}`;
}


const valueConfig = ({ value }) => value && css`
  stroke-dasharray: calc(${value} * var(--fierry-status-circuit)) calc(${1 - value} * var(--fierry-status-circuit));
`;


const ActiveProgress = styled.rect`
  width: calc(var(--fierry-status-width) - 2px);
  height: calc(var(--fierry-status-height) - 2px);

  stroke: var(--fierry-status-foreground);
  opacity: var(--fierry-status-opacity);

  ${valueConfig}
`;


const PassiveProgress = styled.rect`
  width: calc(var(--fierry-status-width) - 2px);
  height: calc(var(--fierry-status-height) - 2px);

  stroke: var(--fierry-theme-foreground);
  opacity: var(--fierry-divider-opacity);
`;


// TODO Hide Svg with progress when value becomes 1 maybe ?
export default ({ width, height, text, ...props }) =>
(
  <Group centerAlign >

    <Svg {...props} width = {width} height = {height} viewBox = {getViewBox({ width, height })} >

      <PassiveProgress {...props} x = '1' y = '1' />
      <ActiveProgress  {...props} x = '1' y = '1' />

    </Svg>

    <Text {...props} overline text = {text} />

  </Group>
);
