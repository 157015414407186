/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import InfluenceEnum from 'galax/enum/influence';

import EnergyUser from 'galax/server/zone/energy.user';
import ResistanceUser from 'galax/server/zone/resistance.user';

import { getRelativeStrength } from 'galax/util/user.strength';


export default class
{
  constructor (scope)
  {
    this.scope = scope;

    this.energyUsers = new Map();
    this.resistanceUsers = new Map();
  }


  setup ()
  {
    for (const { user } of this.scope.getType().getEnergyCollection())
    {
      const userRef = this.scope.getPlanet().getUser(user).getReference();

      this.installEnergyUser(userRef);
    }

    for (const { user } of this.scope.getType().getResistanceCollection())
    {
      this.installResistanceUser(user);
    }
  }


  installInfluence (influence)
  {
    this.scope.getUsers().installEnergyUser(influence.getUser());

    if (influence.getType() == InfluenceEnum.Attack)
    {
      this.scope.getUsers().installResistanceUser(influence.getUserID());
    }

    this.scope.getAttributes().installInfluence(influence);
  }



  installEnergyUser (user)
  {
    if (!this.energyUsers.has(user.id))
    {
      this.energyUsers.set(user.id, new EnergyUser(user, this.scope));
    }
  }


  uninstallEnergyUser (userID)
  {
    this.energyUsers.delete(userID);
  }


  installResistanceUser (userID)
  {
    if (!this.resistanceUsers.has(userID))
    {
      this.resistanceUsers.set(userID, new ResistanceUser(userID, this.scope));
    }
  }


  uninstallResistanceUser (userID)
  {
    this.resistanceUsers.delete(userID);

  }


  eachEnergyUser (callback)
  {
    for (const [userID, value] of this.energyUsers)
    {
      callback(userID, value);
    }
  }


  eachResistanceUser (callback)
  {
    for (const [userID, value] of this.resistanceUsers)
    {
      callback(userID, value);
    }
  }


  getRelativeStrength (lhs, rhs)
  {
    const lhsUser = this.getEnergyUser(lhs).getUser();
    const rhsUser = this.getEnergyUser(rhs).getUser();

    return getRelativeStrength(lhsUser, rhsUser);
  }


  getEnergyUserCount ()
  {
    let count = 0;

    this.eachEnergyUser( (userID, value) =>
    {
      ++count;
    });

    return count;
  }


  getResistanceUserCount ()
  {
    let count = 0;

    this.eachResistanceUser( (userID, value) =>
    {
      ++count;
    });

    return count;
  }


  getEnergyUser (userID)
  {
    if (this.energyUsers.has(userID))
    {
      return this.energyUsers.get(userID);
    }

    return null;
  }


  getEnergyUsers ()
  {
    return this.energyUsers.keys();
  }


  getResistanceUser (userID)
  {
    if (this.resistanceUsers.has(userID))
    {
      return this.resistanceUsers.get(userID);
    }

    return null;
  }


  getResistanceUsers ()
  {
    return this.resistanceUsers.keys();
  }


  getEnemyCount (userID)
  {
    let count = 0;

    this.eachEnergyUser(otherID =>
    {
      if (this.isEnemy(userID, otherID))
      {
        ++count;
      }
    });

    return count;
  }


  isEnemy (lhs, rhs)
  {
    if (lhs === rhs)
    {
      return false;
    }

    if (!this.getEnergyUser(lhs) || !this.getEnergyUser(rhs))
    {
      return false;
    }

    if (lhs === this.getCurrentOwner() || rhs === this.getCurrentOwner())
    {
      return true;
    }

    if (!lhs || !rhs)
    {
      return !this.getEnergyUser(this.getCurrentOwner());
    }

    return true;
  }


  getCurrentOwner ()
  {
    return this.scope.getUserID();
  }


}
