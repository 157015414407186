/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Element, Modern, useFlux, withValue } from 'fierry/react';

import Server from 'galax/server';

import CurrencyIcon from 'svg/galax/resource/currency';


const getProjectileCost = ({ constants }) =>
{
  return 1;
}


export default (props) =>
(
  <Modern.Card>
    <Modern.Group>

      <Modern.Text text = 'Projectile Cost'/>
      <Modern.Param icon = {<CurrencyIcon />} text = {getProjectileCost(props)} />

    </Modern.Group>
  </Modern.Card>
);
