/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import Pane from 'fierry/state/pane';
import Url from 'fierry/client/url';

import HistoryBackButton from 'shared/react/button/history.back';
import SearchClearButton from 'shared/react/button/search.clear';
import CollapseButton from 'shared/react/button/pane.collapse';
import DrawerButton from 'shared/react/button/drawer.expand';


export default ({ forceDrawer, ...props }) =>
{
  const [ pane, url ] = useFlux(Pane, Url);

  if (pane.getCollapsed() !== pane.getDefault())
  {
    return <CollapseButton />;
  }

  if (url.getRoute().getValue() !== '/')
  {
    return <HistoryBackButton />;
  }

  if (!forceDrawer && (url.getArg('current') || url.getArg('target')))
  {
    return <SearchClearButton />;
  }

  return <DrawerButton />;
};
