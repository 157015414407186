/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Header from 'fierry/react/header';

import SpaceshipAttackButton from 'galax/react/button/spaceship/attack';
import SpaceshipDockButton from 'galax/react/button/spaceship/dock';
import SpaceshipMoveButton from 'galax/react/button/spaceship/move';
import SpaceshipCancelCreateButton from 'galax/react/button/spaceship/cancel.create';


const getEntityHeadline = ({ currentSpaceship }) =>
{
  return currentSpaceship.getName();
}


const SpaceshipHeader = (props) =>
(
  <Header headline = {getEntityHeadline(props)} >

    <SpaceshipAttackButton {...props} />
    <SpaceshipMoveButton {...props} />
    {/*<SpaceshipDockButton {...props} />*/}

  </Header>
);


const ConstructionSpaceshipHeader = (props) =>
(
  <Header headline = {getEntityHeadline(props)} >

    <SpaceshipCancelCreateButton {...props} />

  </Header>
)



export default (props) =>
{
  const { currentSpaceship } = props;

  return currentSpaceship.getStatus().isProduction() ? <ConstructionSpaceshipHeader {...props} /> : <SpaceshipHeader {...props} />;
}

