/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><line x1="3.75" y1="17.75" x2="20.25" y2="17.75" className="black-line-15"/><polyline points="6.24 20.23 3.76 17.75 6.24 15.28" className="black-line-15"/><line x1="4.5" y1="7" x2="19.5" y2="7" className="black-line-30"/><polyline points="17.01 4.53 19.49 7 17.01 9.47" className="black-line-30"/></Svg>
  );
}
