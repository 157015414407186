/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import View from 'fierry/react/skeleton/card/view';

import UnitEnum from 'galax/enum/unit';
import UnitInfoCard from 'galax/react/card/unit/info'


const getRoute = ({ type }) =>
{
  return '/unit.create/' + type;
}


const UnitCard = (props) =>
(
  <View {...props} item card = {UnitInfoCard} route = {getRoute(props)} />
);


export default (props) =>
(
  <>
    <UnitCard {...props} type = {UnitEnum.BasicAttack} />
    <UnitCard {...props} type = {UnitEnum.RangeAttack} />
    <UnitCard {...props} type = {UnitEnum.ConeAttack} />
    <UnitCard {...props} type = {UnitEnum.AreaAttack} />
  </>
);

