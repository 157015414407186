/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Model from 'fierry/flux/model';


export default class extends Model
{
  static construct ({ queries })
  {
    return { galaxy: queries.getCurrentGalaxy(), planets: queries.getPlanets(), spaceships: queries.getSpaceships() };
  }


  getPlanets ()
  {
    return this.props.planets;
  }


  getPlanetsInRange ({ start, end })
  {
    let collection = [];

    for (const { id, x, y, width, height } of this.props.planets)
    {
      if (x > end.x || start.x > x + width)
      {
        continue;
      }

      if (y > end.y || start.y > y + height)
      {
        continue;
      }

      collection.push(id);
    }

    return collection;
  }


  getSpaceships ()
  {
    return this.props.spaceships;
  }


  getTurn ()
  {
    return this.props.galaxy.turn;
  }


  getID ()
  {
    return this.props.galaxy.id;
  }


};
