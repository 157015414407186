/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux }  from 'fierry/react';

import Flight from 'galax/react/map/flight';


const getInteractions = (currentLocation) =>
{
  let collection = [];

  for (const interaction of currentLocation.getInteractionsTo())
  {
    const object = interaction.getObject();

    if (object.isAggressive() || object.isFutureTarget() || object.isProjectPlatform())
    {
      collection.push(interaction);
    }
  }

  for (const interaction of currentLocation.getInteractionsFrom())
  {
    const object = interaction.getObject();

    if (object.isAggressive() || object.isFutureTarget() || object.isProjectPlatform())
    {
      collection.push(interaction);
    }
  }

  return collection;
}


const getResolvedTarget = (interaction) =>
{
  const target = interaction.getTarget();

  return target.getSpaceship() ? target.getSpaceship().getLocation() : target;
}

export default ({ currentLocation }) =>
{
  return getInteractions(currentLocation).map(interaction =>
  {
    const key = interaction.getObject().getKey();

    const user = interaction.getUser();
    const source = interaction.getSource();
    const target = interaction.getTarget(); // getResolvedTarget(interaction) will fix moved-spaceship for target but not for source drawing!

    return <Flight key = {key} user = {user} sourceLocation = {source} targetLocation = {target} progress = {1} indeterminate />;
  });
}
