/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux, Modern } from 'fierry/react';

import View from 'fierry/client/view';

import Next from 'svg/shared/modern/next';


const OnClick = (event, view) =>
{
  const index = Number.parseInt(view.getRoute().getArgs().index);

  view.pushForeground('/manual/' + ((index || 0) + 1));
}


export default (props) =>
{
  const [ view ] = useFlux(View);

  const onClick = useCallback(OnClick, [ view ]);

  return <Modern.Button icon = {<Next />} tooltip = 'Next' {...props} onClick = {onClick} />;
}
