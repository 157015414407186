/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { useFlux } from 'fierry/react';

import Url from 'fierry/client/url';
import Trigger from 'fierry/flux/trigger';

import Galaxy from 'galax/server';

import * as State from 'fierry/client/url/state';


const canDispatch = ({ galaxy }) =>
{
  return galaxy && galaxy.isReady();
}


const executeDispatch = ({ url, galaxy }) =>
{
  const state = State.getStateFromURL();

  // History state exists - reconcile normal refresh.
  if (window.history.state != null)
  {
    url.reconcile({ history: window.history.state });

    return;
  }

  // URL state is same as default state - do nothing.
  if (State.areEqual(state, State.getDefaultState()))
  {
    return;
  }

  // Update HTML history to default url state.
  window.history.replaceState(url.getSessionState(), '', url.getSessionURL())

  // Push initial URL state if valid.
  if (url.isNavigationValid(state, galaxy))
  {
    url.pushForeground(state.route, state.args, state.context);
  }
}


export default (props) =>
{
  const [url, galaxy] = useFlux(Url, Galaxy);

  return <Trigger url = {url} galaxy = {galaxy} canDispatch = {canDispatch} executeDispatch = {executeDispatch} />
}
