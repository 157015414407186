/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Element, Modern, useFlux, withValue } from 'fierry/react';

import Server from 'galax/server';

import BenefitElement from 'galax/react/element/building/benefit';
import LauncherParams from 'galax/react/group/launcher/params';
import ResourcesElement from 'galax/react/element/building/resources';

import ClockElement from 'galax/react/element/clock';
import CostElement from 'galax/react/element/building/cost';

import BuildingIcon from 'galax/react/icon/building';
import DefenceIcon from 'svg/shared/modern/defence.js';
import PlusUnit from 'svg/shared/unit/plus.js';
import MinusUnit from 'svg/shared/unit/minus.js';
import PercentUnit from 'svg/shared/unit/percent.js';


const getIcon = ({ constants, ...props }) =>
{
  return <BuildingIcon type = {constants.getType()} />;
}


const getName = ({ constants }) =>
{
  return constants.getName();
}


const getTime = ({ currentZone, constants, level }) =>
{
  const efficiency = currentZone.getAttributes().getBuildingCreateEfficiency();

  return constants.getConstructionTime(level) * efficiency;
}


const Headline = (props) =>
(
  <Modern.Text headline icon = {getIcon(props)} text = {getName(props)} />
);


const Subline = (props) =>
(
  <ResourcesElement {...props} rightAlign spacing = '0.5' width = '5.5' />
);


const Component = (props) =>
(
  <Card {...props} headline = {Headline} subline = {Subline} >
    <Modern.Group>

      <LauncherParams {...props} />

      <BenefitElement {...props} />

      <ClockElement {...props} value = {getTime} tooltip = 'Construction Time' />

    </Modern.Group>
  </Card>
);


export default (props) =>
{
  let { galaxy, type, level, currentArgs } = props;

  if (level == null)
  {
    level = 1;
  }

  return <Component {...props} constants = {galaxy.getConstants().getBuilding(type || currentArgs.type)} level = {level} />;
}
