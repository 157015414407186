/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, useFlux } from 'fierry/react';

import Screen from 'galax/client/screen';


const Container = styled.div`
  position: absolute;
  pointer-events: none;

  right: 0;
  top: 0;

  margin-top: 1rem;
  margin-right: 1rem;

  > * { pointer-events: auto; }
`;


const Component = ({ content: Content, ...props }) =>
(
  <Container>
    <Content {...props} />
  </Container>
);


export default (props) =>
{
  const [ screen ] = useFlux(Screen);

  return screen.isMobile() ? null : <Component {...props}/>;
}
