/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Client } from 'fierry/flux';

import Now from 'galax/query/now';
import View from 'fierry/client/view';
import Galaxy from 'galax/server';
import CurrentLocation from 'galax/query/current.location';
import Selection from 'galax/client/selection';

import BuildingEnum from 'galax/enum/building';
import ShapeEnum from 'galax/enum/shape';

import * as Coords from 'galax/geo/coords';
import * as Shapes from 'galax/geo/shapes';


export default new Client([ CurrentLocation, Selection, Galaxy, View, Now ], class
{
  constructor (location, selection, galaxy, view, now)
  {
    this.galaxy = galaxy;
    this.now = now;

    this.source = location;
    this.target = selection.getLocation();
    this.count = selection.getCount();

    this.args = view.getRoute().getArgs();
  }


  onDone ()
  {
    if (this.args.type === BuildingEnum.Gate)
    {
      const shape = this.__getTargetShape__();

      this.galaxy.createBuildingWithTarget(this.source, this.args.type, this.target, shape);
    }
    else
    {
      this.galaxy.createBuilding(this.source, this.args.type);
    }
  }


  getSelectionTargets ()
  {
    const mapCallback = (coords) =>
    {
      return this.galaxy.getLocation(coords);
    };

    return this.__getTargetShape__().data.map(mapCallback);
  }


  __getTargetShape__ ()
  {
    const downgradeCallback = (coords) =>
    {
      return this.galaxy.getLocation(coords).getZone() == null;
    };

    const targetVariants = this.__getConstantsShape__(this.target.getCoords(), this.source.getCoords());
    return targetVariants[(this.count - 1) % targetVariants.length];
  }


  __getConstantsShape__ (target, source)
  {
    return Shapes.getMaxVariants(target, source, ShapeEnum.QuadrupleArea)
  }


  getError ()
  {
    const type = this.args.type;
    const zone = this.source.getZone();

    if (type == null)
    {
      return 'No building type found';
    }

    if (!zone.getCass().isCenter())
    {
      return 'Building has to be constructed in the sector\'s center';
    }

    if (zone.getSector().getBuilding() != null)
    {
      return 'Sector can only have one building';
    }

    const constants = this.galaxy.getConstants().getBuilding(type);

    const userResources = this.source.getUser().getResources(this.now); // TODO needs to use global user - this is tricky access...
    const buildingResources = constants.getConstructionResources();

    if (userResources.carbon < buildingResources.carbon || userResources.silicon < buildingResources.silicon || userResources.hydrogen < buildingResources.hydrogen)
    {
      return 'You don\'t have enough resources to construct';
    }

    if (zone.getPlanet().getEntities().getActiveConstruction(zone.getUserID()))
    {
      return 'One building construction / upgrade per planet';
    }

    if (type === 'spaceport' && zone.getSector().getNeighbors().getMaybeDocks().length === 0)
    {
      return 'Spaceport requires access to planet edge';
    }

    // TODO gate specific checks
    if (type === BuildingEnum.Gate)
    {
      if (this.source.isEqual(this.target))
      {
        return 'Unit cannot attack zone it occupies.';
      }

      const distance  = Coords.getDistance(this.source.getCoords(), this.target.getCoords());

      if (distance > 10)
      {
        return 'Target zone is out of range. Please select a closer target.';
      }

      for (const target of this.getSelectionTargets())
      {
        if (target.getPlatform() || target.getZone() || target.getSpaceship())
        {
          return 'Needs an empty zone to create platform.';
        }
      }
    }

    return null;
  }


  getInitial (target)
  {
    if (this.args.type === '')
    {
      return 'Select building type';
    }

    if (this.args.type === BuildingEnum.Gate)
    {
      if (this.target == null)
      {
        return 'Select target zone';
      }
    }

    return null;
  }


  canSelect ()
  {
    return this.args.type === BuildingEnum.Gate;
  }


  canNavigate ()
  {
    return false;
  }


  isPaneCollapsed ()
  {
    return false;
  }


});
