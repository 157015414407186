/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor ({ constants })
  {
    this.constants = constants;
  }


  getPrimaryLight ()
  {
    return this.constants.primary_light;
  }


  getSecondaryLight ()
  {
    return this.constants.secondary_light;
  }


  getPrimaryDark ()
  {
    return this.constants.primary_dark;
  }


  getSecondaryDark ()
  {
    return this.constants.secondary_dark;
  }


};
