/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { State } from 'fierry/flux';

import isMobile from 'fierry/util/mobile';


export default new State([], { collapsed: isMobile(), default: isMobile() });
