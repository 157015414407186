/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { CardButtons, Modern, styled, withValue } from 'fierry/react';

import ClockElement from 'galax/react/element/clock';
import CountdownElement from 'galax/react/element/countdown';
import ProgressElement from 'galax/react/element/progress';

import CancelIcon from 'svg/shared/modern/cancel-stroke';


const getProgressValue = ({ status }, now) =>
{
  return status.getProgressPercentage(now);
}


const getProgressPrimary = ({ status }) =>
{
  return !status.isDefault();
}


const getRemainingTime = ({ status }, now) =>
{
  return status.getRemainingTime(now);
}


const TimeElement = (props) =>
{
  if (props.status.getEndDate())
  {
    return <CountdownElement {...props} value = {getRemainingTime} />;
  }

  return <ClockElement {...props} value = {getRemainingTime} />;
}


const CancelButton = ({ status, onCancel, ...props }) =>
{
  /*if (status.getParent().getUser().isCurrentUser() && onCancel)
  {
    return <CardButtons> <Modern.Button primary icon = {<CancelIcon />} onClick = {onCancel} /> </CardButtons>;
  }

  return <Modern.Space width = '2' />;*/

  return null;
};


const WideProgressElement = styled(ProgressElement)`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`;


// Add withValue for header & icon support ? Then both can use props.status for resolution!
export default ({ header, icon, ...props }) =>
(
  <Modern.Group vertical>

    <Modern.Group height = '3'>

      <Modern.Text body
        icon = {withValue(icon, props)}
        text = {withValue(header, props)}
      />

      <Modern.Group>
        <TimeElement {...props} />
        <CancelButton {...props} />
      </Modern.Group>

    </Modern.Group>

    <WideProgressElement {...props}
      value = {getProgressValue}
      primary = {getProgressPrimary}
    />

  </Modern.Group>
);
