/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import InteractionEnum from 'galax/enum/interaction';

import AttackEnergyInteraction from 'galax/server/interaction/attack.energy';

import FutureTargetInteraction from 'galax/server/interaction/future.target';
import ProjectPlatformInteraction from 'galax/server/interaction/project.platform';


export const makeInteraction = (cass) =>
{
  switch (cass.type)
  {
    case InteractionEnum.AttackEnergy:    return new AttackEnergyInteraction(cass);

    case InteractionEnum.FutureTarget:    return new FutureTargetInteraction(cass);
    case InteractionEnum.ProjectPlatform: return new ProjectPlatformInteraction(cass);
  }

  throw new Error('Unsupported interaction type');
}
