/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Entity from 'fierry/flux/entity'

import Query from 'fierry/flux/entity/query'
import Visibility from 'fierry/flux/entity/visibility'


const queryDefinition = (...dependences) =>
{
  for (const dependency of dependences)
  {
    if (dependency !== null)
    {
      return dependency;
    }
  }

  return null;
}


export default class extends Entity
{
  constructor(dependences, targets, definition)
  {
    super([]);

    this.targets = targets;

    this.query = new Query(this.targets, queryDefinition);
    this.visibility = new Visibility(dependences, this.targets, definition);
  }


  getEntities ()
  {
    return [this.visibility, ...this.targets, this.query];
  }


  getDefaultEntity ()
  {
    return this.query;
  }


};
