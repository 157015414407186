/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Type from 'galax/enum/route';
import Access from 'galax/enum/access';

import Route from 'galax/react/skeleton/route';
import Switch from 'galax/react/skeleton/switch';

import SpaceshipInfo from 'galax/react/card/spaceship/info'
import BuildingInfo from 'galax/react/card/building/info'
import UnitInfo from 'galax/react/card/unit/info'

import ProjectileList from 'galax/react/card/zone.projectile.list';
import TransferList from 'galax/react/card/sector.transfer.list';


export default (props) =>
(
  <Switch {...props} >

    <Route type = {Type.SpaceshipCreateType} component = {SpaceshipInfo}   access = {Access.Dock} />
    <Route type = {Type.BuildingCreateType}  component = {BuildingInfo}    access = {Access.Zone} />
    <Route type = {Type.UnitCreateType}      component = {UnitInfo}        access = {Access.Zone} />
    <Route type = {Type.TransferList}        component = {TransferList}    access = {Access.Zone} />
    <Route type = {Type.ProjectileList}      component = {ProjectileList}  access = {Access.Location} />

  </Switch>
);
