/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (user, scope)
  {
    this.user = user;
    this.scope = scope;

    this.energy = this.scope.getType().getEnergy(this.user.id);

    this.activeInfluence = 0;
    this.passiveInfluence = 0;
    this.balanceInfluence = 0;

    this.clearFlow();
  }


  clearFlow ()
  {
    this.flow = 0;
  }


  increaseFlow (value)
  {
    this.flow += value;
  }


  decreaseFlow (value)
  {
    this.flow -= value;
  }


  increaseEnergy (value)
  {
    this.energy += value;
  }


  decreaseEnergy (value)
  {
    this.energy -= value;
  }


  updateEnergy (duration)
  {
    this.energy += duration * this.getFlow();
  }


  setActiveInfluence (value)
  {
    this.activeInfluence = value;
  }


  setPassiveInfluence (value)
  {
    this.passiveInfluence = value;
  }


  increaseBalanceInfluence (value)
  {
    this.balanceInfluence += value;
  }


  getUser ()
  {
    return this.user;
  }


  getFlow ()
  {
    return this.flow;
  }


  getEnergy ()
  {
    return this.energy;
  }


  getInfluence ()
  {
    return (this.getPassiveInfluence() + this.getActiveInfluence()) * this.scope.getResistance().getEfficiency(this.user.id);
  }


  getActiveInfluence ()
  {
    return this.activeInfluence;
  }


  getPassiveInfluence ()
  {
    return this.passiveInfluence - this.balanceInfluence;
  }


  // TODO is this getTimeToEnergy(0) ?
  getTimeToEmpty ()
  {
    const flow = this.getFlow();

    return flow ? Math.ceil(- this.getEnergy() / flow) : -1;
  }


  getTimeToEnergy (value)
  {
    const flow = this.getFlow();

    return flow ? Math.ceil((value - this.getEnergy()) / flow) : -1;
  }



  getTimeToWinner (winner)
  {
    if (!winner)
    {
      return -1;
    }

    const flowDifference = this.getFlow() - winner.getFlow();

    return flowDifference ? Math.ceil((winner.getEnergy() - this.getEnergy()) / flowDifference) : -1;
  }


};
