/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';


export default new class
{
  constructor ()
  {
    this.__contexts__ = new Map();
  }


  get (type)
  {
    if (!this.__contexts__.has(type))
    {
      this.__contexts__.set(type, React.createContext(null));
    }

    return this.__contexts__.get(type);
  }


  set (type, context)
  {
    if (!this.__contexts__.has(type))
    {
      this.__contexts__.set(type, context);
    }
  }


}();
