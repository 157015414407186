/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, styled, useEffect } from 'fierry/react';

import '@material/mwc-drawer';
import '@material/mwc-list';


const getDrawerOpen = (open) =>
{
  return open ? true : null;
}


const HeaderGroup = styled(Modern.Group)`
  padding: 0.25rem 1rem;
`;


const Header = ({ title, subtitle }) =>
(
  <HeaderGroup vertical>
    <Modern.Text headline text = {title}/>
    <Modern.Text body = '2' emphasis text = {subtitle} />
  </HeaderGroup>
);


const Component = ({ open, children, ...props }) =>
(
  <mwc-drawer type = 'modal' open = {getDrawerOpen(open)} >
    <mwc-list>

      <Header {...props} />
      {children}

    </mwc-list>
  </mwc-drawer>
);


export default ({ onOpenChange, ...props }) =>
{
  useEffect(() =>
  {
    const openedEventListener = (e) =>
    {
      onOpenChange(true);
    };


    const closedEventListener = (e) =>
    {
      onOpenChange(false);
    };

    window.addEventListener('MDCDrawer:opened', openedEventListener);
    window.addEventListener('MDCDrawer:closed', closedEventListener);

    return () =>
    {
      window.removeEventListener('MDCDrawer:opened', openedEventListener);
      window.removeEventListener('MDCDrawer:closed', closedEventListener);
    }
  }, []);

  return <Component {...props} />;
}
