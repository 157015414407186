/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern, useFlux } from 'fierry/react';

import Now from 'galax/query/now';

import SpaceshipInfo from 'galax/react/group/spaceship/info';
import SpaceshipParams from 'galax/react/group/spaceship/params';
import StatusProgress from 'galax/react/group/progress/spaceship'

import EnergyElement from 'galax/react/element/energy';

import SpaceshipIcon from 'galax/react/icon/spaceship';

import UserIcon from 'svg/shared/modern/user';
import PercentUnit from 'svg/shared/unit/percent';


const getIcon = ({ currentSpaceship }) =>
{
  return <SpaceshipIcon type = {currentSpaceship.getType()} />;
}


const getName = ({ currentSpaceship }) =>
{
  return currentSpaceship.getName();
}


const getUserName = ({ currentSpaceship }) =>
{
  return currentSpaceship.getUser().getName();
}


const getInfluence = ({ currentSpaceship }) =>
{
  return currentSpaceship.getInfluence();
}


const Headline = (props) =>
(
  <Modern.Text headline icon = {getIcon(props)} text = {getName(props)} />
);


const Subline = (props) =>
(
  <Modern.Param width = '6' tooltip = 'User Name'
    icon = {<UserIcon />}
    text = {getUserName(props)}
  />
);


const Component = (props) =>
(
  <Card {...props} headline = {Headline} subline = {Subline} >

    {/*<SpaceshipInfo {...props} />*/}
    <SpaceshipParams {...props} constants = {props.currentSpaceship.getSpaceshipConstants()} />

    <StatusProgress {...props} spaceship = {props.currentSpaceship} />

  </Card>
);


export default ({ currentSpaceship, ...props }) =>
{
  const [ now ] = useFlux(Now);

  if (currentSpaceship == null)
  {
    currentSpaceship = props.currentLocation.getSpaceship();
  }

  return currentSpaceship ? <Component {...props} currentSpaceship = {currentSpaceship} now = {now} /> : null;
}

