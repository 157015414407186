/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import extract from 'fierry/flux/dependency/extract';
import isVisible from 'fierry/flux/hook/is.visible';


const construct = (entity, dependences, visible) =>
{
  return visible ? new (entity.getDefinition())(...dependences) : null;
}


export default (dependency, props) =>
{
  const dependences = extract(dependency.getDependences(), props);
  const visible = isVisible(dependency);

  return React.useMemo(() => construct(dependency.getEntity(), dependences, visible), [ ...dependences, visible ]);
}
