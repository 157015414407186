/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Building from 'galax/server/building';


const CREATE_EFFICIENCY_BASE = Math.pow(2, 1/3);


export default class extends Building
{
  installPassiveTarget (zone)
  {
    const attribute = this.getFriendlyAttribute(this.getUnitCreateEfficiency());

    zone.getAttributes().installUnitCreateEfficiency(attribute);
  }


  installActiveTarget (zone)
  {
    // no-op.
  }


  getUnitCreateEfficiency ()
  {
    return 0.5;
  }


};
