/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 8 16" {...props}><defs/><rect width="8" height="16" className="none"/><line x1="7" y1="8" x2="1" y2="8" className="black-line-20"/></Svg>
  );
}
