/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Action from 'fierry/flux/entity/action';
import Store from 'fierry/flux/entity/store';


const State = new Date();


const Actions =
{
  Refresh: new Action(),
};


const refreshNow = (state, { now }) =>
{
  return now;
};


export default new Store(State, Actions,
[
  [Actions.Refresh, refreshNow],
]);

