/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Model from 'fierry/flux/model';
import Time from 'fierry/util/time';


const SERVER_DELAY_MAX = 5 * Time.getSecondTicks();


export default class extends Model
{
  static construct ({ queries })
  {
    return { time: queries.getCurrentTime() };
  }


  getClientDate (now)
  {
    const { time } = this.props;

    return now ? new Date(now - time.client_delay) : time.client_date;
  }


  getServerDate (now)
  {
    const { time } = this.props;

    return now ? new Date(now - time.server_delay) : time.server_date;
  }


  isRealTime ()
  {
    return this.props.time.realtime === true;
  }


  isServerDelayed ()
  {
    return this.getServerDelay() >= SERVER_DELAY_MAX;
  }


  getServerDelay ()
  {
    return this.getClientDate() - this.getServerDate();
  }



};
