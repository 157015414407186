/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react/styled';


export default styled.div`

  opacity: var(--fierry-divider-opacity);
  border-bottom: 1px var(--fierry-theme-foreground) solid;
`;
