/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><g><circle cx="16" cy="16" r="13" className="white-shape"/><g data-name="no-invert line-dash-a"><circle cx="16" cy="16" r="13" className="black-line-20"/></g></g><g><polyline points="13 16 17.5 20.5 24 16" className="black-line-10"/><polyline points="24 16 17.5 11.5 13 16" className="black-line-10"/><path d="M11,21" className="black-line-10"/></g><polyline points="10 10 13 16 10 22" className="black-line-10"/></Svg>
  );
}
