/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import '@material/mwc-icon-button';

import { styled, css } from 'fierry/react/styled';
import config from 'fierry/react/modern/button/config';

import Icon from 'fierry/react/modern/icon';


const iconConfig = ({ iconSize }) => css`
  --mdc-icon-size: ${iconSize}rem;
`;


const Container = styled.div`

  ${config}
  ${iconConfig}

  --mdc-icon-button-size: calc(var(--fierry-icon-button-size) - 2 * var(--fierry-button-border-width));

  display: inline-flex;
  vertical-align: top;

  mwc-icon-button
  {
    color: var(--fierry-button-foreground);
    background: var(--fierry-button-background);

    opacity: var(--fierry-button-opacity);

    border-style: solid;
    border-radius: 50%;

    border-width: var(--fierry-button-border-width);
    border-color: var(--fierry-button-foreground);

    box-shadow: var(--fierry-button-box-shadow);
  }

  mwc-icon-button:hover
  {
    box-shadow: var(--fierry-button-box-shadow-hover);
  }

  mwc-icon-button:focus
  {
    box-shadow: var(--fierry-button-box-shadow-focus);
  }

  mwc-icon-button:active
  {
    box-shadow: var(--fierry-button-box-shadow-active);
  }
`;


const getDisabled = ({ disabled }) =>
{
  return disabled ? true : null;
}


export default ({ icon, iconSize = '1.5', tooltip, onClick, ...props }) =>
(
  <Container {...props} iconSize = {iconSize} aria-describedby = {tooltip} >

    <mwc-icon-button disabled = {getDisabled(props)} onClick = {onClick} >
      <Icon icon = {icon} size = {iconSize} />
    </mwc-icon-button>

  </Container>
);
