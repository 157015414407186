/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback } from 'react';

import useContext from 'fierry/flux/hook/use.context';


export default (action) =>
{
  const dispatch = useContext(action.getDispatchContext());

  return useCallback((args) => dispatch.send(action, args), []);
}
