/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><g><rect x="3" y="6" width="26" height="20" className="white-shape"/><g data-name="no-invert line-dash-a"><rect x="3" y="6" width="26" height="20" className="black-line-20"/></g></g><g><line x1="22" y1="19.5" x2="10" y2="12.5" className="black-line-10"/><line x1="10" y1="19.5" x2="22" y2="12.5" className="black-line-10"/></g></Svg>
  );
}
