/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Coords from 'galax/geo/coords';
import * as Absolute from 'galax/geo/shapes/absolute';


export const getMinVariants = (target, source, type) =>
{
  const variants = getSortedShapeVariants(target, source, type);
  const distance = getShapeDistance(source, variants[0]);

  return variants.filter((shape) =>
  {
    return getShapeDistance(source, shape) === distance
  });
}


export const getMaxVariants = (target, source, type) =>
{
  const variants = getSortedShapeVariants(target, source, type);
  const distance = getShapeDistance(source, variants[variants.length - 1]);

  return variants.filter((shape) =>
  {
    return getShapeDistance(source, shape) === distance
  });
}


export const getSortedShapeVariants = (target, source, type) =>
{
  const variants = Absolute.getVariants(target, type);

  return getVariantsWithoutSource(source, variants).sort((lhs, rhs) =>
  {
    return getShapeDistance(source, lhs) - getShapeDistance(source, rhs);
  });
}


export const getShapeDistance = (source, shape) =>
{
  let distance = 0;

  for (const element of shape.data)
  {
    distance += Coords.getDistance(element, source);
  }

  return distance;
}


export const getVariantsWithoutSource = (source, variants) =>
{
  const equalPredicate = (element) =>
  {
    return element.isEqual(source);
  }

  const filterPredicate = (shape) =>
  {
    return !shape.data.find(equalPredicate);
  }

  return variants.filter(filterPredicate);
}
