/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { useCache, useFlux, withMemo } from 'fierry/react';

import Zoom from 'galax/client/zoom';

import Offset from 'galax/client/map/offset';
import PlanetCache from 'galax/client/map/planet.cache';

import Planet from 'galax/react/map/galaxy/planet';
import Spaceship from 'galax/react/map/galaxy/spaceship';


const Planets = ({ galaxy, cache,  ...props }) =>
{
  let planets = [];

  for (const id of cache.keys())
  {
    planets.push( <Planet {...props} key = {id} planet = {galaxy.getPlanet(id)} currentUser = {galaxy.getCurrentUser()} />);
  }

  return planets;
};


const Spaceships = ({ galaxyType, galaxy, ...props }) =>
{
  return galaxyType.getSpaceships().map(({ id }) => <Spaceship {...props} key = {id} spaceship = {galaxy.getSpaceship(id)} />);
};


const Component = (({ planets, spaceships, ...props }) =>
(
  <>
    <Planets {...props} planets = {planets} />
    <Spaceships {...props} spaceships  = {spaceships} />
  </>
));


export default (props) =>
{
  const [ zoom, offset ] = useFlux(Zoom, Offset);

  const galaxyType = props.galaxy.getCurrentGalaxy();

  const cache = useCache(() => new PlanetCache(), [ zoom ]);

  cache.merge(galaxyType.getPlanetsInRange(offset.getRange()));

  return <Component {...props} galaxyType = {galaxyType} cache = {cache} />;
};
