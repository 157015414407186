/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import InfluenceEnum from 'galax/enum/influence';

import AttributeSourceCollection from 'galax/server/adapter/attribute.source/collection';
import InfluenceCollection from 'galax/util/influence/user.collection';


export default class
{
  constructor (scope)
  {
    this.scope = scope;

    this.entityCollection = [];
    this.influenceCollection = new InfluenceCollection();

    this.defenceEfficiencyCollection = new AttributeSourceCollection();
    this.transferEfficiencyCollection = new AttributeSourceCollection();

    this.resistanceEfficiencyCollection = new AttributeSourceCollection();
    this.resistanceIncreaseCollection = new AttributeSourceCollection();
    this.resistanceDecreaseCollection = new AttributeSourceCollection();

    this.unitCreateEfficiencyCollection = new AttributeSourceCollection();
    this.buildingCreateEfficiencyCollection = new AttributeSourceCollection();
    this.spaceshipCreateEfficiencyCollection = new AttributeSourceCollection();

    this.yieldProductionCollection = new AttributeSourceCollection();

    this.zoneAttritionCollection = new AttributeSourceCollection();
    this.unitAttritionCollection = new AttributeSourceCollection();
  }


  installEntity (entity)
  {
    this.entityCollection.push(entity);
  }


  installInfluence (influence)
  {
    if (influence.getValue() < 0)
    {
      throw new Error('Influence cannot have a negative value.');
    }

    this.influenceCollection.push(influence);
  }


  installDefenceEfficiency (source)
  {
    this.defenceEfficiencyCollection.push(source);
  }


  installTransferEfficiency (source)
  {
    this.transferEfficiencyCollection.push(source);
  }


  installResistanceEfficiency (source)
  {
    this.resistanceEfficiencyCollection.push(source);
  }


  installResistanceIncrease (source)
  {
    this.resistanceIncreaseCollection.push(source);
  }


  installResistanceDecrease (source)
  {
    this.resistanceDecreaseCollection.push(source);
  }


  installUnitCreateEfficiency (source)
  {
    this.unitCreateEfficiencyCollection.push(source);
  }


  installBuildingCreateEfficiency (source)
  {
    this.buildingCreateEfficiencyCollection.push(source);
  }


  installSpaceshipCreateEfficiency (source)
  {
    this.spaceshipCreateEfficiencyCollection.push(source);
  }


  installYieldProduction (source)
  {
    this.yieldProductionCollection.push(source);
  }


  installZoneAttrition (source)
  {
    this.zoneAttritionCollection.push(source);
  }


  installUnitAttrition (source)
  {
    this.unitAttritionCollection.push(source);
  }


  onOverheatChange (value)
  {
    for (const entity of this.entityCollection)
    {
      entity.onZoneOverheatChange(value);
    }
  }


  onCriticalChange (value)
  {
    for (const entity of this.entityCollection)
    {
      entity.onZoneCriticalChange(value);
    }
  }


  onUserChange (user)
  {
    for (const entity of this.entityCollection)
    {
      entity.onZoneUserChange(user);
    }
  }


  eachInfluence (callback)
  {
    return this.influenceCollection.eachInfluence(callback);
  }


  getActiveInfluence (user)
  {
    return this.influenceCollection.getAggressiveInfluence(user);
  }


  getPassiveInfluence (user)
  {
    return this.influenceCollection.getPassiveInfluence(user);
  }


  getDefenceEfficiency ()
  {
    let value = 0.0;

    const garrison = this.defenceEfficiencyCollection.getMaxAttribute(this.scope.getUserID(), 'building/garrison');
    const roboticFactory = this.defenceEfficiencyCollection.getMaxAttribute(this.scope.getUserID(), 'building/robotic-factory');

    if (garrison)
    {
      value += garrison.getValue();
    }

    if (roboticFactory)
    {
      value += roboticFactory.getValue();
    }

    return value;
  }


  getTransferEfficiency ()
  {
    let value = 0.95;

    const transferStation = this.transferEfficiencyCollection.getMinAttribute(this.scope.getUserID(), 'building/transfer-station');

    if (transferStation)
    {
      value += transferStation.getValue();
    }

    return value;
  }

  getResistanceEfficiency (user)
  {
    let value = 1.0;

    const outpost = this.resistanceEfficiencyCollection.getMaxAttribute(user, 'building/outpost');
    const distributionCenter = this.resistanceEfficiencyCollection.getMaxAttribute(user, 'building/distribution-center');

    if (outpost)
    {
      value -= outpost.getValue();
    }

    if (distributionCenter)
    {
      value -= distributionCenter.getValue();
    }

    return value;
  }


  getResistanceIncrease (user)
  {
    return 1.0;
  }


  getResistanceDecrease (user)
  {
    if (user == this.scope.getUserID())
    {
      return 1.0 / 3.0;
    }

    return 1.0;
  }


  getUnitCreateEfficiency ()
  {
    let value = 1.0;

    const roboticFactory = this.unitCreateEfficiencyCollection.getMinAttribute(this.scope.getUserID(), 'building/robotic-factory');
    const outpost = this.unitCreateEfficiencyCollection.getMaxAttribute(this.scope.getUserID(), 'building/outpost');

    if (roboticFactory)
    {
      value *= roboticFactory.getValue();
    }

    if (outpost)
    {
      value *= outpost.getValue();
    }

    return value;
  }


  getBuildingCreateEfficiency ()
  {
    let value = 1.0;

    const slowEffect = this.buildingCreateEfficiencyCollection.getMaxAttribute(this.scope.getUserID(), 'effect/slow');
    const hasteEffect = this.buildingCreateEfficiencyCollection.getMinAttribute(this.scope.getUserID(), 'effect/haste');

    if (slowEffect)
    {
      value *= slowEffect.getValue();
    }

    if (hasteEffect)
    {
      value *= hasteEffect.getValue();
    }

    return value;
  }


  getSpaceshipCreateEfficiency ()
  {
    let value = 1.0;

    const spaceport = this.spaceshipCreateEfficiencyCollection.getMaxAttribute(this.scope.getUserID(), 'building/spaceport');

    if (spaceport)
    {
      value *= spaceport.getValue();
    }

    return value;
  }


  getYieldProduction ()
  {
    let value = 1.0;

    const distributionCenter = this.yieldProductionCollection.getMaxAttribute(this.scope.getUserID(), 'building/distribution-center');

    if (distributionCenter)
    {
      value *= distributionCenter.getValue();
    }

    return value;
  }


  getZoneAttrition ()
  {
    return 1.0;
  }


  getUnitAttrition ()
  {
    let value = 1.0;

    const garrison = this.unitAttritionCollection.getMaxAttribute(this.scope.getUserID(), 'building/garrison');

    if (garrison)
    {
      value *= garrison.getValue();
    }

    return value;
  }


};
