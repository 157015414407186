/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ParentEnum from 'galax/enum/parent';

import ServerDate from 'fierry/util/date';

import * as Coords from 'galax/geo/coords';

import * as EnergyValue from 'galax/value/energy';
import * as InfluenceValue from 'galax/value/influence';


export const CurrentTime = (object) =>
{
  const timestamp = new Date();

  object.server_date = ServerDate.getServerDate(object.server_date, timestamp);
  object.client_date = ServerDate.getServerDate(object.client_date, timestamp);

  object.client_delay = timestamp - object.client_date;
  object.server_delay = timestamp - object.server_date;
}


export const Interaction = (object) =>
{
  object.target.id = Coords.fromString(object.target.id);
  object.source.id = Coords.fromString(object.source.id);

  JsonArgs(object);
  CreateDate(object);
}


export const Projectile = (object) =>
{
  object.target.id = Coords.fromString(object.target.id);
  object.source.id = Coords.fromString(object.source.id);

  object.launch_date = ServerDate.getServerDate(object.launch_date);
  object.event.date = ServerDate.getServerDate(object.event.date);
}


export const User = (object) =>
{
  UpdateDate(object);
  CreateDate(object);
  LoginDate(object);
}


export const Planet = (object) =>
{
  object.coords = Coords.fromOffset(object.x, object.y);
};


export const Spaceship = (object) =>
{
  object.source = Coords.fromString(object.zone);

  object.location = { id: object.zone, parent: { id: object.id, type: ParentEnum.Spaceship }};

  if (object.target)
  {
    object.target.id = Coords.fromString(object.target.id);
  }
};


export const Sector = (object) =>
{
  for (const zone of object.zones)
  {
    UpdateDate(zone);

    zone.coords = Coords.fromString(zone.id);
    zone.location = { id: zone.id, parent: { id: object.planet, type: ParentEnum.Planet }};
  }

  object.center = Coords.fromString(object.center);
};


export const Unit = (object) =>
{
  object.source = Coords.fromString(object.zone);

  if (object.target)
  {
    object.target.id = Coords.fromString(object.target.id);
  }
}


export const Entity = (object) =>
{
  object.source = Coords.fromString(object.zone);

  ZoneCoords(object);
  CreateDate(object);
  UpdateDate(object);
  StatusDate(object);
}


export const Building = (object) =>
{
  CreateDate(object);
}


export const ProjectileConstants = (object) =>
{
  object.strength = EnergyValue.fromConstants(object.strength);
}


export const SpaceshipConstants = (object) =>
{
  object.min_energy = EnergyValue.fromConstants(object.min_energy);
  object.max_energy = EnergyValue.fromConstants(object.max_energy);

  object.attack = InfluenceValue.fromConstants(object.attack);
  object.attrition = InfluenceValue.fromConstants(object.attrition);
}


export const UnitConstants = (object) =>
{
  object.energy = EnergyValue.fromConstants(object.energy);

  object.attack = InfluenceValue.fromConstants(object.attack);
  object.attrition = InfluenceValue.fromConstants(object.attrition);
}


export const CreateDate = (object) =>
{
  object.create_date = ServerDate.getServerDate(object.create_date);
}


export const LoginDate = (object) =>
{
  object.login_date = ServerDate.getServerDate(object.login_date);
}


export const UpdateDate = (object) =>
{
  object.update_date = ServerDate.getServerDate(object.update_date);
}


export const ZoneCoords = (object) =>
{
  object.coords = Coords.fromString(object.zone);
}


export const JsonArgs = (object) =>
{
  object.args = object.args ? JSON.parse(object.args) : {};
}


export const StatusDate = ({ status }) =>
{
  if (!status)
  {
    return;
  }

  status.date = ServerDate.getServerDate(status.date);

  if (status.event)
  {
    status.event.date = ServerDate.getServerDate(status.event.date);
  }
}
