/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Client } from 'fierry/flux';

import ShapeEnum from 'galax/enum/shape';

import Now from 'galax/query/now';
import Galaxy from 'galax/server';
import Navigation from 'galax/client/navigation';
import Selection from 'galax/client/selection';

import * as Coords from 'galax/geo/coords';
import * as Shapes from 'galax/geo/shapes';


export default new Client([ Navigation, Selection, Galaxy, Now ], class
{
  constructor (navigation, selection, galaxy, now)
  {
    this.galaxy = galaxy;
    this.now = now;

    this.source = navigation.getLocation();
    this.target = selection.getLocation();
    this.count = selection.getCount();
  }


  onDone ()
  {
    this.galaxy.setupBuilding(this.source.getCass(), this.target.getCass(), this.__getTargetShape__());
  }


  getSelectionTargets ()
  {
    if (this.target.getSpaceship())
    {
      return [ this.target ];
    }

    const mapCallback = (coords) =>
    {
      return this.galaxy.getLocation(coords);
    };

    return this.__getTargetShape__().data.map(mapCallback);
  }


  __getTargetShape__ ()
  {
    if (this.target.getSpaceship())
    {
      return { type: ShapeEnum.Single, variant: 0, data: [ this.target.getCoords() ] };
    }

    const downgradeCallback = (coords) =>
    {
      const location = this.galaxy.getLocation(coords);

      return (location.getZone() == null && location.getPlatform() == null) || location.getParentID() != this.target.getParentID();
    };

    const constants = this.source.getZone().getBuilding().getProjectileConstants();

    const targetVariants = Shapes.getMaxVariants(this.target.getCoords(), this.source.getCoords(), constants.getShape());
    const targetShape = targetVariants[(this.count - 1) % targetVariants.length];

    return Shapes.getDowngrade(this.target.getCoords(), targetShape, downgradeCallback);
  }


  getError ()
  {
    const building = this.source.getZone().getBuilding();

    if (building == null)
    {
      return 'Zone requires a building to fire projectile.';
    }

    if (!building.canFireProjectiles())
    {
      return 'Building is not allowed to fire projectiles.';
    }

    if (this.source.isEqual(this.target))
    {
      return 'Building cannot attack zone it occupies.';
    }

    const targetDistance = this.__getDistance__();
    const maxDistance = building.getConstants().getWeaponRange();

    if (targetDistance > maxDistance)
    {
      return `Target is out of maximum range of ${maxDistance}.`;
    }

    const constants = building.getProjectileConstants();

    const currency = this.galaxy.getCurrentUser().getCurrency(this.now);

    if (currency < 1)
    {
      return 'Not enough currency to fire projectile';
    }

    /*if (!this.source.getZone().getEnergy().canSpend(constants.getSpaceshipStrength()))
    {
      return 'Not enough energy to fire projectile';
    }*/

    if (!this.target.getZone() && !this.target.getPlatform())
    {
      return 'Cannot attack empty space.';
    }

    return null;
  }


  __getDistance__ ()
  {
    return Coords.getDistance(this.source.getCoords(), this.target.getCoords());
  }


  getInitial ()
  {
    if (this.target == null)
    {
      return 'Select target zone';
    }

    return null;
  }


  canSelect ()
  {
    return true;
  }


  canSelectGalaxy ()
  {
    return true;
  }


  canNavigate ()
  {
    return false;
  }


  isPaneCollapsed ()
  {
    return true;
  }


});
