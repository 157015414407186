/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Firebase from 'firebase/app';
import * as FirebaseUI from 'firebaseui';

import 'firebase/auth';
import 'firebaseui/dist/firebaseui.css';


Firebase.initializeApp(
{
  apiKey:     "AIzaSyA8sGQhN-9n_dZ7Dcp6sRSCWSQ6op5XYO4",

  projectId:  "fierry-com",
  authDomain: "login.fierry.com",
});


const onSignInFailure = () =>
{
  console.log('Firebase sign-in error!', error);
}


const filterSearchParams = (search) =>
{
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.delete('current');
  searchParams.delete('target');

  return searchParams.toString();
}


export const getConfig = () =>
{
  const { host, /*hash,*/ search, protocol} = window.location;

  return (
  {
    // Default sign-in through popup - though mobile may prefer redirect?
    signInFlow: 'popup',

    // Popup can be blocked by browser; fallback to full page redirect.
    signInSuccessUrl: `${protocol}//${host}${filterSearchParams(search)}`,

    callbacks:
    {
      signInFailure: onSignInFailure,
    },

    signInOptions:
    [{
      provider: Firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters:
      {
        prompt: 'select_account',
      }
    },
    {
      provider: Firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      scopes: [
        'email',
      ],
      customParameters:
      {
        display: 'popup',
      }
    }],

    tosUrl: `${protocol}//${host}/terms${search}`,
    privacyPolicyUrl: `${protocol}//${host}/privacy${search}`,
  });
}


export const getInstance = () =>
{
  const instance = FirebaseUI.auth.AuthUI.getInstance();

  return instance ? instance : new FirebaseUI.auth.AuthUI(Firebase.auth())
}


// FirebaseUI needs FirebaseUI instance.start() to finish login if instance.isPendingRedirect().
if (getInstance().isPendingRedirect())
{
  getInstance().start('#firebase-auth-container-redirect', getConfig())
}
