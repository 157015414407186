/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><path d="M19,14.63A7.28,7.28,0,0,1,12.26,19,7.13,7.13,0,0,1,5,12a7.13,7.13,0,0,1,7.26-7,7.29,7.29,0,0,1,6.26,3.45" transform="translate(0 0)" className="black-line-20"/><polyline points="19 5 19 8.5 15.5 8.5" className="black-line-20"/></Svg>
  );
}
