/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Building from 'galax/server/building';


const UNIT_ATTRITION_BASE = Math.pow(Math.sqrt(2), 1/3);


export default class extends Building
{
  installPassiveTarget (zone)
  {
    const attribute = this.getFriendlyAttribute(this.getDefenceEfficiency());

    zone.getAttributes().installDefenceEfficiency(attribute);
  }


  installActiveTarget (zone)
  {
    const attribute = this.getFriendlyAttribute(this.getUnitAttrition());

    zone.getAttributes().installUnitAttrition(attribute);
  }


  getDefenceEfficiency ()
  {
    return this.getLevel() * 0.1;
  }


  getUnitAttrition ()
  {
    return 1 / Math.pow(UNIT_ATTRITION_BASE, this.getLevel());
  }


};
