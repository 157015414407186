/*
 * Copyright (C) 2019 Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (entity)
  {
    this.entity = entity;

    this.collection = [];
  }


  push (influence)
  {
    this.collection.push(influence);
  }


  eachInfluenceSource (callback)
  {
    for (const influence of this.collection)
    {
      callback(influence);
    }
  }


  getFriendlyInfluence ()
  {
    let value = 0;

    for (const influence of this.collection)
    {
      if (influence.getUserID() === this.entity.getUserID())
      {
        value += influence.getValue();
      }
    }

    return value;
  }


  getHostileInfluence ()
  {
    let value = 0;

    for (const influence of this.collection)
    {
      if (influence.getUserID() !== this.entity.getUserID())
      {
        value += influence.getValue();
      }
    }

    return value;
  }


}
