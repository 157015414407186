/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback } from 'fierry/react/native';

import TextButton from 'fierry/react/modern/button/text';
import IconButton from 'fierry/react/modern/button/icon';

import withTooltip from 'fierry/react/modern/with.tooltip';


const OnClickShallow = (event, onClick) =>
{
  if (onClick)
  {
    onClick(event);
  }

  event.stopPropagation();
}


export default withTooltip( ({ label, onClick, ...props }) =>
{
  const onClickShallow = useCallback(OnClickShallow, [ onClick ]);

  return label ? <TextButton {...props} label = {label} onClick = {onClickShallow} /> : <IconButton {...props} onClick = {onClickShallow} />;
})
