/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><g><rect x="6" y="6" width="12" height="15" className="black-line-20"/><polygon points="20 6 18.22 6 5.78 6 4 6 20 6" className="black-line-20"/><polygon points="9 3 11.8 3 15 3 15 5 9 5 9 3" className="black-line-20"/><rect x="9" y="9" width="2" height="9" className="black-shape"/><rect x="13" y="9" width="2" height="9" className="black-shape"/></g></Svg>
  );
}
