/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Projectile from 'galax/server/projectile';


export default class
{
  constructor (parent, state)
  {
    this.parent = parent;

    this.targetCollection = [];
    this.sourceCollection = [];

    for (const type of state.get('projectilesTo').values())
    {
      this.targetCollection.push(new Projectile(type, this.parent));
    }

    for (const type of state.get('projectilesFrom').values())
    {
      this.sourceCollection.push(new Projectile(type, this.parent));
    }
  }


  getProjectilesTo (coords)
  {
    return this.allTargetProjectiles(projectile =>
    {
      return projectile.isTargetOf(coords);
    });
  }


  getProjectilesFrom (coords)
  {
    return this.allSourceProjectiles(projectile =>
    {
      return projectile.isSourceOf(coords);
    });
  }


  oneTargetProjectile (predicate)
  {
    for (const projectile of this.targetCollection)
    {
      if (predicate(projectile))
      {
        return projectile;
      }
    }

    return null;
  }


  oneSourceProjectile (predicate)
  {
    for (const projectile of this.sourceCollection)
    {
      if (predicate(projectile))
      {
        return projectile;
      }
    }

    return null;
  }


  allTargetProjectiles (predicate)
  {
    let collection = [];

    for (const projectile of this.targetCollection)
    {
      if (predicate(projectile))
      {
        collection.push(projectile);
      }
    }

    return collection;
  }


  allSourceProjectiles (predicate)
  {
    let collection = [];

    for (const projectile of this.sourceCollection)
    {
      if (predicate(projectile))
      {
        collection.push(projectile);
      }
    }

    return collection;
  }


  eachTargetProjectile (callback)
  {
    for (const projectile of this.targetCollection)
    {
      callback(projectile);
    }
  }


  eachSourceProjectile (callback)
  {
    for (const projectile of this.sourceCollection)
    {
      callback(projectile);
    }
  }


}
