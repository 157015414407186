/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Client } from 'fierry/flux';

import Now from 'galax/query/now';
import Galaxy from 'galax/server';
import CurrentLocation from 'galax/query/current.location';


export default new Client([ CurrentLocation, Galaxy, Now ], class
{
  constructor (location, galaxy, now)
  {
    this.location = location;
    this.galaxy = galaxy;
    this.now = now;
  }


  onDone ()
  {
    this.galaxy.upgradeBuilding(this.location);
  }


  getError ()
  {
    const zone = this.location.getZone();

    if (!zone.getCass().isCenter())
    {
      return 'Building needs to be constructed in the sector\'s center';
    }

    const building = zone.getBuilding();

    if (zone.getPlanet().getEntities().getActiveConstruction(zone.getUserID()))
    {
      return 'One building construction / upgrade per planet';
    }

    if (building.getLevel() >= 3)
    {
      return 'Can\'t upgrade beyond building\'s maximum level';
    }

    const constants = building.getConstants();

    const userResources = this.location.getUser().getResources(this.now); // TODO needs to use global user - this is tricky access...
    const buildingResources = constants.getConstructionResources();

    if (userResources.carbon < buildingResources.carbon || userResources.silicon < buildingResources.silicon || userResources.hydrogen < buildingResources.hydrogen)
    {
      return 'You don\'t have enough resources to upgrade';
    }

    return null;
  }


  getInitial (target)
  {
    return null;
  }


  canSelect ()
  {
    return false;
  }


  canNavigate ()
  {
    return false;
  }


});
