/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as EnergyValue from 'galax/value/energy';


export const getHourlyBase = () =>
{
  return 60 * 60 * 1000;
};


export const fromConstants = (value) =>
{
  return Math.floor(EnergyValue.fromConstants(value) / getHourlyBase());
};


export const fromPercentage = (value) =>
{
  return Math.round(EnergyValue.fromPercentage(value) / getHourlyBase());
};


export const toConstants = (value) =>
{
  return Math.floor(value / EnergyValue.getConstantsBase() * getHourlyBase());
}


export const toPercentage = (value) =>
{
  return Math.round(value / EnergyValue.getPercentageBase() * getHourlyBase());
}
