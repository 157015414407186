/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, useFlux } from 'fierry/react';

import Behavior from 'galax/client/behavior';
import CurrentLocation from 'galax/query/current.location';

import BorderTranslate from 'galax/react/map/zone/border.translate';


const getAggressiveInteractions = (zone) =>
{
  let collection = [];

  for (const interaction of zone.getInteractionsTo())
  {
    if ((interaction.isAggressive()) && interaction.getUserID() !== zone.getUserID())
    {
      collection.push(interaction);
    }
  }

  for (const projectile of zone.getProjectilesTo())
  {
    if (projectile.getUserID() !== zone.getUserID())
    {
      collection.push(projectile);
    }
  }

  return collection;
}


const Circle = styled.circle`
  fill: var(--svg-always-other);
`;


// TODO Radius should increase with zoom level?
const getDotProps = ({ grid, zone, offset }) =>
{
  const size = grid.getGeneralHex().getExternalWidth() / 2;

  return { cx: -3/4 * size, cy: 0.375 * size, r: 1 };
}


const Indicator = ({ grid, zone, offset, ...props }) =>
{
  const width = grid.getGeneralHex().getExternalWidth();

  const cx = -0.375 * width;
  const cy = 0.375 * width / 2 - offset;

  return <Circle {...props} cx = {cx} cy = {cy} r = {1} />;
}


const Component = ({ interactions, ...props }) =>
{
  let children = [];

  const maxOffset = props.grid.getGeneralHex().getExternalWidth() * 0.375;
  const stepOffset = maxOffset / 3;

  let offset = 0;

  for (const interaction of interactions)
  {
    children.push(<Indicator key = {offset} {...props} offset = {offset} />);

    if (offset >= maxOffset)
    {
      break;
    }

    offset += stepOffset;
  }

  return children;
}


const ComponentOptional = (props) =>
{
  const [ behavior, currentLocation ] = useFlux(Behavior, CurrentLocation);

  const { zone } = props;

  // TODO should use target locations and findIndex.
  if (currentLocation && behavior.getZoneTargetIDs(currentLocation).includes(zone.getID()))
  {
    return null;
  }

  return <BorderTranslate {...props} Component = {Component} index = {1} />
}


export default (props) =>
{
  const { grid, zone } = props;

  if (!grid.getZoom().isEntityVisible())
  {
    return null;
  }

  const interactions = getAggressiveInteractions(zone);

  return interactions.length ? <ComponentOptional {...props} interactions = {interactions} /> : null;
}
