/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import '@material/mwc-icon';

import { Children, cloneElement } from 'fierry/react/native';
import { styled, css } from 'fierry/react/styled';

import { isString } from 'fierry/util/lang';

import withTooltip from 'fierry/react/modern/with.tooltip';


const sizeConfig = ({ size }) => size && css`
  --fierry-icon-size: ${size}rem;
`;


const heightConfig = ({ height }) => height && css`
  --fierry-icon-size: ${height}rem;
`;


const Container = styled.div`

  ${sizeConfig}
  ${heightConfig}

  --mdc-icon-size: var(--fierry-icon-size);

  --svg-always-black: var(--fierry-theme-foreground);
  --svg-always-other: var(--fierry-theme-foreground);
  --svg-always-white: var(--fierry-theme-background);

  color: var(--fierry-theme-foreground);

  /*height: var(--fierry-icon-size);*/
`;


const FontIcon = ({ icon, tooltip, ...props }) =>
(
  <Container {...props} aria-describedby = {tooltip} >
    <mwc-icon> {icon} </mwc-icon>
  </Container>
);


const SvgIcon = ({ icon, tooltip, ...props }) =>
{
  const size = props.size * 16;

  icon = Children.map(icon, element => cloneElement(element, { size }));

  return <Container {...props} aria-describedby = {tooltip} children = {icon} />
};


export default withTooltip( props =>
{
  const { icon } = props;

  return isString(icon) ? <FontIcon {...props} /> : <SvgIcon {...props} />;
});
