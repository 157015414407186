/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */



export default class
{
  constructor (type, coords)
  {
    this.projectiles = [];
    this.spaceships = [];
    this.buildings = [];
    this.units = [];

    this.themes = [];
  }


  createProjectile (type, shape, strength, flight_time)
  {
    this.projectiles.push({ type, shape, strength, flight_time: flight_time * 1000 });
  }


  createSpaceship (type, name, target_shape, target_min_range, target_max_range, min_energy, max_energy, attrition, attack, defence, movement)
  {
    this.spaceships.push({ type, name, target_shape, target_min_range, target_max_range, min_energy, max_energy, attrition, attack, defence, movement, construction_time: 0 });
  }


  createBuilding (type, name, range, resources)
  {
    this.buildings.push({ type, name, description: '', range, uses: 0, construction_resources: resources, construction_cost: 0, construction_time: 0, setup_time: 0, active_time: 0, cooldown_time: 0 });
  }


  createUnit (type, name, target_shape, target_min_range, target_max_range, energy, attrition, attack, defence,)
  {
    this.units.push({ type, name, target_shape, target_min_range, target_max_range, energy, attrition, attack, defence, movement: 0, construction_time: 0, disband_time: 0 });
  }


  createTheme (type, primary_light, secondary_light, primary_dark, secondary_dark)
  {
    this.themes.push({ type, primary_light, secondary_light, primary_dark, secondary_dark });
  }


  getProjectiles ()
  {
    return this.projectiles;
  }


  getSpaceships ()
  {
    return this.spaceships;
  }


  getBuildings ()
  {
    return this.buildings;
  }


  getUnits ()
  {
    return this.units;
  }


  getThemes ()
  {
    return this.themes;
  }

}
