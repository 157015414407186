/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import uuid from 'uuid/v4';

import Entity from 'fierry/flux/entity'


export default class extends Entity
{
  type = uuid()


  setStore (store)
  {
    if (this.store)
    {
      throw new Error('Action cannot belong to multiple stores.');
    }

    this.store = store;
  }


  getType ()
  {
    return this.type;
  }


  getStore ()
  {
    return this.store;
  }


  // TODO not sure that helps at all...
  /*getDependences ()
  {
    return [ this.store ];
  }


  getDefaultEntity ()
  {
    return this.store;
  }*/


  getDispatchContext ()
  {
    return this.store.getDispatchContext();
  }


  isExportable ()
  {
    return false;
  }


};
