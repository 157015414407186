/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, Modern, useFlux } from 'fierry/react';

import Server from 'galax/server';


const getDescription = ({ constants }) =>
{
  return constants.getDescription();
};


const Component = (props) =>
(
  <Modern.Card>
    <Modern.Text param text = {getDescription(props)} />
  </Modern.Card>
);


export default (props) =>
{
  const { type } = props;

  const [ server ] = useFlux(Server);

  return <Component {...props} constants = {server.getConstants().getUnit(type)} />;
}
