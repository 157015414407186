/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ProjectileCard from 'galax/react/card/projectile';


export default (props) =>
{
  const { currentLocation } = props;

  let projectiles = [];

  // TODO should sort or projectiles based on the ascending end date!

  for (const projectileDirection of currentLocation.getProjectilesTo())
  {
    const projectile = projectileDirection.getObject();

    projectiles.push(<ProjectileCard {...props} key = {projectile.getID()} projectile = {projectile} />)
  }

  for (const projectileDirection of currentLocation.getProjectilesFrom())
  {
    const projectile = projectileDirection.getObject();

    projectiles.push(<ProjectileCard {...props} key = {projectile.getID()} projectile = {projectile} reverse />)
  }

  return projectiles;
}
