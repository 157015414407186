/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><g><circle cx="16" cy="16" r="13" className="white-shape"/><g data-name="no-invert line-dash-a"><circle cx="16" cy="16" r="13" className="black-line-20"/></g></g><g><line x1="16" y1="19" x2="16" y2="24" className="black-line-10"/><line x1="20" y1="11" x2="20" y2="9" className="black-line-10"/><rect x="12" y="11" width="8" height="8" className="black-line-10"/></g></Svg>
  );
}
