/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Client } from 'fierry/flux';

import Galaxy from 'galax/server';

import Navigation from 'galax/client/navigation';
import Selection from 'galax/client/selection';

import State from 'galax/state/optional/sector.transfer';


export default new Client([ Navigation, Selection, Galaxy, State ], class
{
  constructor (navigation, selection, galaxy, state)
  {
    this.galaxy = galaxy;
    this.state = state;

    this.source = navigation.getLocation();
    this.target = selection.getLocation();
  }


  onDone ()
  {
    const source = this.getSource();
    const target = this.getTarget();

    const value = source.getTransfers().getTransferRatio(target, this.state) / 100;

    this.galaxy.createSectorTransfer(source, target, value);
  }


  getError ()
  {
    const source = this.getSource();
    const target = this.getTarget();

    if (source.getCass().getID() === target.getCass().getID())
    {
      return 'Source and target cannot be the same';
    }

    if (source.getCass().getUser() !== target.getCass().getUser())
    {
      return 'Cannot transfer between different users';
    }

    if (source.getStatus().isCritical())
    {
      return 'Critical sector cannot create transfers.';
    }

    const transferStrength = source.getTransfers().getTransferStrength(target);

    if (transferStrength === 0)
    {
      return 'Transfer station is required in source sector.';
    }

    return null;
  }


  getInfo ()
  {
    const source = this.getSource();
    const target = this.getTarget();

    if (source.getTransfers().getTransferFrom(target) != null)
    {
      return 'Transfer will override existing reverse transfer';
    }

    return null;
  }


  getInitial ()
  {
    if (this.getTarget() == null)
    {
      return 'Select target sector';
    }

    return null;
  }


  getSource ()
  {
    const source = this.state.getReverse() ? this.target : this.source;

    return source ? source.getZone().getSector() : null;
  }


  getTarget ()
  {
    const target = this.state.getReverse() ? this.source : this.target;

    return target ? target.getZone().getSector() : null;
  }


  canSelect ()
  {
    return true;
  }


  canNavigate ()
  {
    return false;
  }


  isPaneCollapsed ()
  {
    return true;
  }


});
