/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (args, galaxy, currentLocation, targetLocation)
  {
    this.args = args;
    this.galaxy = galaxy;

    this.currentLocation = currentLocation;
    this.targetLocation = targetLocation;
  }


  isRouteVisible ()
  {
    return true;
  }


  isRouteDisabled ()
  {
    return false;
  }


  isRouteActivated ()
  {
    return false;
  }


  isRouteValid ()
  {
    return this.isRouteVisible() && !this.isRouteDisabled() && !this.isRouteActivated();
  }


  isZoneLocation ()
  {
    return this.currentLocation.getZone() != null;
  }


  isDockLocation ()
  {
    const dock = this.currentLocation.getDock();

    return dock != null && dock.isActive();
  }


  isSpaceshipLocation ()
  {
    return this.currentLocation.getSpaceship() != null;
  }



  isUserOwner ()
  {
    return this.galaxy.getCurrentUser().getID() === this.currentLocation.getUser().getID();
  }


};
