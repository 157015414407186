/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Action from 'fierry/flux/entity/action';
import Store from 'fierry/flux/entity/store';


const State = { query: true, login: false, user: null, token: null, prevToken: null };


const Actions =
{
  Query: new Action(),
  Login: new Action(),
  Logout: new Action(),
  Finalize: new Action(),
};


const queryUser = (state, action) =>
{
  return { query: true, login: false, user: null, token: null };
}


const loginUser = (state, { user, token }) =>
{
  return { user, token, prevToken: state.token, query: false, login: true };
}


const finalizeUser = (state, action) =>
{
  if (state.token === state.prevToken)
  {
    return state;
  }

  return { ...state, prevToken: state.token };
}


const logoutUser = (state, action) =>
{
  return { query: false, login: false, user: null, token: null, prevToken: state.token };
}


export default new Store (State, Actions,
[
  [Actions.Query,    queryUser],
  [Actions.Login,    loginUser],
  [Actions.Logout,   logoutUser],
  [Actions.Finalize, finalizeUser],
]);
