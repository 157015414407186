/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { useCallback, useFlux } from 'fierry/react';

import Server from 'galax/server';

import Progress from 'galax/react/group/progress';

import IdleIcon from 'svg/shared/modern/efficiency';
import AttackIcon from 'svg/shared/modern/attack-stroke';
import DisbandIcon from 'svg/shared/modern/delete-stroke';
import ProductionIcon from 'svg/shared/modern/production-stroke';


const getHeader = ({ status }) =>
{
  if (status.isProduction()) { return 'Production'; }
  if (status.isDisband())    { return 'Disband'; }
  if (status.isAttack())     { return 'Attack'; }

  return null;
};


const getIcon = ({ status }) =>
{
  if (status.isProduction()) { return <ProductionIcon />; }
  if (status.isDisband())    { return <DisbandIcon />; }
  if (status.isAttack())     { return <AttackIcon />; }

  return null;
};


const OnCancel = (event, unit, server) =>
{
  server.cancelEntityAction(unit.getSource());
};


export default ({ unit, ...props }) =>
{
  const [ server ] = useFlux(Server);

  const onCancel = useCallback(OnCancel, [ unit, server ]);

  if (unit.getStatus().isDefault())
  {
    return null;
  }

  return <Progress {...props} icon = {getIcon} header = {getHeader} status = {unit.getStatus()} onCancel = {onCancel} />;
}
