/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { getShapeToCount, getCountToShape } from 'galax/enum/shape';

import * as Absolute from 'galax/geo/shapes/absolute';


export const getDowngrade = (target, shape, isDowngradeCoordPredicate) =>
{
  let downgradeArray = [];

  for (const element of shape.data)
  {
    if (isDowngradeCoordPredicate(element))
    {
      downgradeArray.push(element);
    }
  }

  for (const element of downgradeArray)
  {
    shape = getDowngradeByCoord(target, shape, element);
  }

  return shape;
}


export const getDowngradeByCoord = (target, shape, downgradeCoord) =>
{
  const downgradeData = Absolute.substractFromData(shape.data, downgradeCoord);

  if (shape.data.length === downgradeData.length)
  {
    throw new Error('Shape doesn\'t contain downgrade coord.');
  }

  for (const typeDowngrade of getCountToShape(getShapeToCount(shape.type) - 1))
  {
    for (const shapeDowngrade of Absolute.getVariants(target, typeDowngrade))
    {
      if (Absolute.isEqualData(downgradeData, shapeDowngrade.data))
      {
        return shapeDowngrade;
      }
    }
  }

  return null;
}
