/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import DynamicElement from 'fierry/rpc/dynamic-element';
import NativePlanet from 'galax/server/planet';

import * as Key from 'galax/rpc/key';
import * as Translator from 'galax/rpc/translator';

import Compare from 'fierry/util/compare';


export default class extends DynamicElement
{
  constructor (constants)
  {
    super(NativePlanet);

    this.createLink('constants', constants);

    this.createRecord('currentPlanet', [ Translator.Planet, Translator.UpdateDate ]);

    this.createTable('interactionsTo', Key.TargetSourceID, [ Translator.Interaction ]);
    this.createTable('interactionsFrom', Key.TargetSourceID, [ Translator.Interaction ]);

    this.createTable('projectilesTo', Key.ID, [ Translator.Projectile ]);
    this.createTable('projectilesFrom', Key.ID, [ Translator.Projectile ]);

    this.createTable('users', Key.ID, [ Translator.UpdateDate ]);

    this.createTable('sectors', Key.ID, [ Translator.Sector, Translator.UpdateDate ]);
    this.createTable('entities', Key.Zone, [ Translator.Entity ]);
  }


  isReady ()
  {
    return super.isReady();
  }


}
