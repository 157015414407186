/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useFlux } from 'fierry/react';

import ParentEnum from 'galax/enum/parent';

import Projectile from 'galax/react/card/zone.projectile.list/projectile';


export default ({ projectilesTo, projectilesFrom, ...props }) =>
{
  let children = [];

  for (const projectileDirection of projectilesTo)
  {
    const projectile = projectileDirection.getObject();

    children.push(<Projectile {...props} key = {projectile.getID()} projectile = {projectile} />);
  }

  for (const projectileDirection of projectilesFrom)
  {
    const projectile = projectileDirection.getObject();

    children.push(<Projectile {...props} key = {projectile.getID()} projectile = {projectile} reverse />);
  }

  return <Modern.Group vertical children = {children} />
}
