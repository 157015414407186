/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Firebase from 'firebase/app';

import Event from 'fierry/flux/entity/event';
import Auth from 'fierry/state/auth';

import { isOffline } from 'galax/util/offline';


const OFFLINE_TOKEN_A = "40nm4QI0wtOZqmdTWclfX2t0hgE3";
const OFFLINE_TOKEN_B = "td6eu21dzufClkhjRHATXpsLOmz1";


export default new Event ([ Auth.Login, Auth.Logout ], (loginUser, logoutUser) =>
{
  const unsubscribe = Firebase.auth().onAuthStateChanged( (user, error, completed) =>
  {
    if (isOffline())
    {
      loginUser({ user, token: OFFLINE_TOKEN_A });
    }
    else if (user)
    {
      Firebase.auth().currentUser.getIdToken(true).then((token) =>
      {
        loginUser({ user, token });
      });
    }
    else
    {
      logoutUser({});
    }
  });

  return () =>
  {
    unsubscribe();
  }
});
