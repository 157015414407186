/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, css } from 'fierry/react';

import { isLightOverride, isDarkOverride } from 'galax/util/theme';


const darkCss = css`

  --fierry-theme-foreground:           rgb(255, 255, 255);
  --fierry-theme-background:           rgb( 30,  31,  33);
  --fierry-theme-error-foreground:     rgb(234, 149, 155);

  --fierry-divider-color:              rgba(255, 255, 255, 0.12);

  --fierry-theme-background-hover:     rgba(255, 255, 255, 0.04);
  --fierry-theme-background-active:    rgba(255, 255, 255, 0.08);
`;


const lightCss = css`

  --fierry-theme-foreground:           rgb(  0,   0,   0);
  --fierry-theme-background:           rgb(245, 244, 243);
  --fierry-theme-error-foreground:     rgb(176,   0,  32);

  --fierry-divider-color:              rgba(0, 0, 0, 0.12);

  --fierry-theme-background-hover:     rgba(0, 0, 0, 0.04);
  --fierry-theme-background-active:    rgba(0, 0, 0, 0.08);
`;


const darkConfig = isDarkOverride() && css`${darkCss}`;

const lightConfig = isLightOverride() && css`${lightCss}`;


const defaultConfig = !isLightOverride() && !isDarkOverride() && css`

  @media (prefers-color-scheme: dark) {
    ${darkCss}
  }

  @media (prefers-color-scheme: light) {
    ${lightCss}
  }
`;


export default styled.div`

  ${defaultConfig}
  ${lightConfig}
  ${darkConfig}

  --mdc-theme-surface: var(--fierry-theme-background);
  --mdc-theme-on-surface: var(--fierry-theme-foreground);

  width: 100vw;
  height: 100vh;

  background: var(--fierry-theme-background);
`;
