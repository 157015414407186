/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, withValue } from 'fierry/react';

import * as EnergyValue from 'galax/value/energy';

import DamageIcon from 'svg/shared/modern/damage';
import MultiplyUnit from 'svg/shared/unit/multiply';


const getDamageValue = ({ damage }) =>
{
  return EnergyValue.toPercentage(damage);
}


const getDamageTooltip = ({ tooltip }) =>
{
  return tooltip || 'Damage x Targets'
}


export default (props) =>
(
    <Element.Group param {...props} tooltip = {getDamageTooltip(props)} >

      <Element.Icon
        value = {<DamageIcon />}
      />

      <Element.Text
        value = {getDamageValue(props)}
      />

      <Element.Unit
        value = {<MultiplyUnit />}
      />

      <Element.Text
        value = {props.count}
      />

    </Element.Group>
);
