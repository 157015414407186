/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import Server from 'galax/server';


import UnitInfo from 'galax/react/card/unit/info'
import UnitDescription from 'galax/react/card/unit/description'
import TargetCard from 'galax/react/card/target';

import Error from 'galax/react/card/shared/error';


const getType = ({ constants }) =>
{
  return constants.getType();
}


const Component = (props) =>
(
  <>
    <UnitInfo {...props} type = {getType(props)} />
    <UnitDescription type = {getType(props)} />
    <TargetCard {...props} />

    <Error {...props} />
  </>
);


export default (props) =>
{
  const [ server ] = useFlux(Server);

  const constants = server.getConstants().getUnit(props.currentArgs.type);

  return <Component {...props} constants = {constants} />;
}
