/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern, Element } from 'fierry/react';

import Influences from 'galax/react/card/sector.overview/influence.list';

import LocationIcon from 'svg/shared/modern/move-stroke';


const getName = ({ zone }) =>
{
  return zone.getSector().getCass().getName();
}


const Location = (props) =>
(
  <Modern.Text body width = '2.5' tooltip = 'Sector Name' icon = {<LocationIcon />} text = {getName(props)} />
);


// TODO remove margin between each top row and give bottom-margin to card header. This way content can better control its height!
const Component = (props) =>
(
  <Card {...props} headline = 'Sector' subline = {Location} >

    <Modern.Group vertical>
      <Influences {...props} />
    </Modern.Group>

  </Card>
);


export default (props) =>
{
  const { currentZone } = props;

  return <Component {...props} zone = {currentZone} />;
}
