/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ResourceEnum from 'galax/enum/resource';

import Cass from 'galax/server/zone/cass';
import Attributes from 'galax/server/zone/attributes';
import Resistance from 'galax/server/zone/resistance';
import Energy from 'galax/server/zone/energy';
import Users from 'galax/server/zone/users';

import * as Coords from 'galax/geo/coords';

import { getRelativeStrength } from 'galax/util/user.strength';


export default class
{
  constructor (cass, sector)
  {
    this.sector = sector;
    this.planet = sector.getPlanet();

    this.cass = new Cass(cass, sector.getCass());

    this.users = new Users(this);
    this.energy = new Energy(this);
    this.attributes = new Attributes(this);
    this.resistance = new Resistance(this);
  }


  setup ()
  {
    this.users.setup();
  }


  install ()
  {
    this.energy.refreshInfluence();
    this.energy.refreshFlows();
    this.energy.refreshUsers();
    this.energy.refreshWinner();
  }


  getUsers ()
  {
    return this.users;
  }


  getAttributes ()
  {
    return this.attributes;
  }


  getEnergy ()
  {
    return this.energy;
  }


  getResistance ()
  {
    return this.resistance;
  }


  getSector ()
  {
    return this.sector;
  }


  getPlanet ()
  {
    return this.planet;
  }


  getInteractionsTo ()
  {
    return this.getPlanet().getInteractions().getInteractionsTo(this.getCoords());
  }


  getInteractionsFrom ()
  {
    return this.getPlanet().getInteractions().getInteractionsFrom(this.getCoords());
  }


  getProjectilesTo ()
  {
    return this.getPlanet().getProjectiles().getProjectilesTo(this.getCoords());
  }


  getProjectilesFrom ()
  {
    return this.getPlanet().getProjectiles().getProjectilesFrom(this.getCoords());
  }


  getBuilding ()
  {
    return this.getPlanet().getBuilding(this.getID());
  }


  getResourceName ()
  {
    if (this.getCass().getResource() == null)
    {
      return '';
    }

    switch (this.getCass().getResource().type)
    {
      case ResourceEnum.Carbon:   return 'Carbon';
      case ResourceEnum.Silicon:  return 'Silicon';
      case ResourceEnum.Hydrogen: return 'Hydrogen';
    }
  }


  getUnit ()
  {
    return this.getPlanet().getUnit(this.getID());
  }


  getLocation (resolver = this.planet)
  {
    return resolver.getLocation(this.getCass().getCoords());
  }


  getUser (resolver = this.planet)
  {
    return resolver.getUser(this.getUserID());
  }


  getType ()
  {
    return this.cass;
  }


  getCass ()
  {
    return this.cass;
  }


  getCoords ()
  {
    return this.getCass().getCoords();
  }


  getRelativeCoords ()
  {
    return Coords.substract(this.getCoords(), this.planet.getCoords());
  }


  getID ()
  {
    return this.getCass().getID();
  }


  getUserID ()
  {
    return this.getCass().getUser();
  }


  isLocal ()
  {
    return true;
  }


  isOverheat ()
  {
    return this.getCass().isOverheat() || this.getSector().getStatus().isOverheat();
  }


  isCritical ()
  {
    return this.getCass().isCritical() || this.getSector().getStatus().isCritical();
  }


  // Unverified API


  getProjectedTimeToFlip (user, maxDamage)
  {
    const energyDifference = this.getEnergy().getEnergy(this.getUserID()) - this.getEnergy().getEnergy(user.getID());

    if (energyDifference < 0)
    {
      return 0;
    }

    const flowDifference = this.getProjectedFlowDifference(user, maxDamage);

    if (flowDifference <= 0)
    {
      return -1;
    }

    return Math.ceil(energyDifference / flowDifference);
  }


  getProjectedFlowDifference (user, maxDamage)
  {
    const currentDamage = this.getAttributes().getActiveInfluence(user.getID());

    if (maxDamage == null || maxDamage < currentDamage)
    {
      maxDamage = currentDamage;
    }

    const currentFlow = this.getEnergy().getFlow(user.getID()) ? this.getEnergy().getFlow(user.getID()) - this.getEnergy().getFlow(this.getUserID()) : 0;

    // TODO user may not exist on this planet!
    const userRef = user.getReference();
    const ownerRef = this.getPlanet().getUser(this.getUserID()).getReference();

    // TODO cant use this.scope.getUsers().getRelativeStrength() because that one crashes if user is not already present as zone users!
    const relativeStrength = getRelativeStrength(userRef, ownerRef);
    const resistanceEfficiency = this.getResistance().getEfficiency(user.getID());

    const projectedFlow = (maxDamage - currentDamage) * relativeStrength * resistanceEfficiency;

    return currentFlow + projectedFlow;
  }


}
