/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  Planet: 0,
  Spaceship: 1,
};
