/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Action from 'fierry/flux/entity/action';
import Store from 'fierry/flux/entity/store';

import Auth from 'fierry/state/auth';


const State = { planet: null, id: null };


const Actions =
{
  Set: new Action(),
  Clear: new Action(),
};


const setState = (state, { planet, id }) =>
{
  if (planet === state.planet && id === state.id)
  {
    return state;
  }

  return { planet, id };
};


const clearState = (state, action) =>
{
  if (state.planet === null && state.id === null)
  {
    return state;
  }

  return { planet: null, id: null };
}


export default new Store (State, Actions,
[
  [ Actions.Set,    setState ],
  [ Actions.Clear,  clearState ],

  [ Auth.Query,     clearState ],
  [ Auth.Login,     clearState ],
  [ Auth.Logout,    clearState ],
]);
