/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern } from 'fierry/react';

import Influence from 'galax/react/element/influence';


const getWidth = ({ body, param, ...props }) =>
{
  if (body)  { return 11.0; }
  if (param) { return 9.25; }

  return 8.75;
}

const Component = ({ value, ...props }) =>
(
  <Modern.Group leftAlign>

    <Modern.Text {...props} width = {getWidth(props)} />

    <Influence value = {value} />

  </Modern.Group>
);


export default (props) =>
{
  const { value } = props;

  return value ? <Component {...props} /> : null;
}
