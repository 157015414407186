/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Interaction from 'galax/server/interaction';


export default class extends Interaction
{
  installTargetPlanet (planet)
  {
    // no-op.
  }


  installTargetSpaceship (spaceship)
  {
    // no-op.
  }


  installSourcePlanet (planet)
  {
    // no-op.
  }


  installSourceSpaceship (spaceship)
  {
    // no-op.
  }


};
