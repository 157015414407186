/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator';


export default class extends Validator
{
  isRouteVisible ()
  {
    return this.isZoneLocation() && this.isUserOwner() && this.isZoneCenterAndBuildingExists() && this.isBuildingLauncher();
  }


  isRouteDisabled ()
  {
    return !this.isBuildingStatusDefault() && !this.isBuildingStatusSetupOrActive();
  }


  isRouteActivated ()
  {
    return this.isBuildingStatusSetupOrActive();
  }


  isZoneCenterAndBuildingExists ()
  {
    const currentZone = this.currentLocation.getZone();

    return currentZone.getCass().isCenter() && currentZone.getBuilding() != null;
  }


  isBuildingLauncher ()
  {
    const building = this.currentLocation.getZone().getBuilding();

    return building && building.canFireProjectiles();
  }


  isBuildingStatusSetupOrActive ()
  {
    const building = this.currentLocation.getZone().getBuilding();

    return building && (building.getStatus().isSetup() || building.getStatus().isActive());
  }


  isBuildingStatusDefault ()
  {
    const building = this.currentLocation.getZone().getBuilding();

    return building && building.getStatus().isDefault();
  }


};
