/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Action from 'fierry/flux/entity/action';
import Store from 'fierry/flux/entity/store';


const State = { x: 0, y: 0, dnd: false };


const Actions =
{
  SetDnD: new Action(),
  SetAbsolute: new Action(),
  SetRelative: new Action(),
};


const setDnD = (state, { value }) =>
{
  if (state.dnd === value)
  {
    return state;
  }

  return { ...state, dnd: value };
}


const setAbsolute = (state, { x, y }) =>
{
  return { ...state, x, y };
};


const setRelative = (state, { dx, dy }) =>
{
  const x = state.x + dx;
  const y = state.y + dy;

  return { ...state, x, y };
};


export default new Store(State, Actions,
[
  [ Actions.SetDnD,       setDnD ],
  [ Actions.SetAbsolute,  setAbsolute ],
  [ Actions.SetRelative,  setRelative ],
]);
