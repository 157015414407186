/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern, Element } from 'fierry/react';

import CountdownElement from 'galax/react/element/countdown';
import Progress from 'galax/react/group/progress';

import AttackIcon from 'svg/shared/modern/attack-stroke';


const getWonCount = (results) =>
{
  let count = 0;

  for (const { zone, time } of results)
  {
    if (time === 0)
    {
      ++count;
    }
  }

  return count;
}


const getAttackCount = (results) =>
{
  let count = 0;

  for (const { zone, time } of results)
  {
    if (time > 0)
    {
      ++count;
    }
  }

  return count;
}


const getLostCount = (results) =>
{
  let count = 0;

  for (const { zone, time } of results)
  {
    if (time === -1)
    {
      ++count;
    }
  }

  return count;
}


const getFlipTime = (results) =>
{
  let requiredAttackCount = Math.ceil(results.length / 2);

  for (const { zone, time } of results)
  {
    if (time !== -1)
    {
      --requiredAttackCount;
    }

    if (requiredAttackCount === 0)
    {
      return time;
    }
  }

  return -1;
}


const getRemainingTime = ({ flipTime, currentZone }, now) =>
{
  const endDate = new Date(currentZone.getType().getUpdateDate().getTime() + flipTime);

  return new Date(endDate.getTime() - now.getTime())
}


const ProgressOrFailure = (props) =>
{
  const { flipTime } = props;

  return flipTime === -1 ? <FlipFailure {...props} /> : <TimeToFlip {...props} />;
}


const FlipFailure = (props) =>
(
  <Modern.Text param
    text = "You need more firepower to conquer this sector. Consider producing additional units and targetting majority of the sector's zones." />
);


const TimeToFlip = (props) =>
(
  <Modern.Group>
    <Modern.Text param
      icon = {<AttackIcon />}
      text = 'Time To Flip'
    />

    <CountdownElement param {...props} value = {getRemainingTime} />

  </Modern.Group>
)


// getEndDate, getRemainingTime, isDefault, getProgressPercentage
const Component = ({ flipResults, ...props }) =>
(
  <Card {...props} headline = 'Enemy Sector Status' >

    <ProgressOrFailure {...props} />
    <Modern.Space height = '0.5' />

    <Modern.Group>
      <Element.Group param tooltip = 'Zones conquered' >
        <Element.Text value = 'Done:' />
        <Element.Text value = {getWonCount(flipResults)} width = '1' rightAlign />
      </Element.Group>

      <Element.Group param tooltip = 'Zones under attack' >
        <Element.Text value = 'Ongoing:' />
        <Element.Text value = {getAttackCount(flipResults)} width = '1' rightAlign />
      </Element.Group>

      <Element.Group param tooltip = 'Zones under enemy control' >
        <Element.Text value = 'Lost:' />
        <Element.Text value = {getLostCount(flipResults)} width = '1' rightAlign />
      </Element.Group>
    </Modern.Group>

  </Card>
);


export default (props) =>
{
  const { currentZone, currentUser } = props;

  if (currentZone.getUserID() == currentUser.getID())
  {
    return null;
  }

  const results = currentZone.getSector().getZones().getProjectedTimeToFlip(currentUser);

  if (getWonCount(results) === 0 && getAttackCount(results) === 0)
  {
    return null;
  }

  return <Component {...props} flipResults = {results} flipTime = {getFlipTime(results)} />;
}
