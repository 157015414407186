/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Model from 'fierry/flux/model';


export default class extends Model
{
  static construct ({ queries })
  {
    return { users: queries.getUsers() };
  }


  constructor (props)
  {
    super(props);

    this.users = this.props.users.map(user => props.parent.getUser(user.id));
    this.users = this.users.filter(user => !user.isAncientUser());
    this.users = this.users.sort((lhs, rhs) => rhs.getPoints() - lhs.getPoints());
  }


  getAll ()
  {
    return this.users;
  }


  getUser (index)
  {
    return this.users[index];
  }


  getLength ()
  {
    return this.users.length;
  }


};

