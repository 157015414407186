/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { State, Optional } from 'fierry/flux';

import View from 'fierry/client/view';


const SectorTransferState = new State([ View ], { value: null, reverse: false });


export default new Optional([ View ], SectorTransferState, (view) =>
{
  return view.getRoute().getValue() === '/sector.transfer.edit';
});
