/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux, Modern } from 'fierry/react';

import View from 'fierry/client/view';
import Level from 'galax/enum/route.level';

import Back from 'svg/shared/modern/back';


const OnClick = (event, view) =>
{
  window.history.go(- view.getPopCountBefore(Level.Background));
}


export default (props) =>
{
  const [ view ] = useFlux(View);

  const onClick = useCallback(OnClick, [ view ]);

  return <Modern.Button icon = {<Back />} tooltip = 'Back' onClick = {onClick} />;
}
