/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><circle cx="16" cy="16" r="13" className="white-shape"/><circle cx="16" cy="16" r="13" className="black-line-20"/><path d="M15.5,22.5a6,6,0,0,1-6-6" className="black-line-10"/><path d="M16.5,9.5a6,6,0,0,1,6,6" className="black-line-10"/></Svg>
  );
}
