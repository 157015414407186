/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import AttackStatus from 'galax/server/spaceship/status/attack';
import CreateStatus from 'galax/server/spaceship/status/create';
import DefaultStatus from 'galax/server/spaceship/status/default';
import MoveStatus from 'galax/server/spaceship/status/move';
import DockStatus from 'galax/server/spaceship/status/dock';


export default (status, parent) =>
{
  if (status != null)
  {
    switch (status.type)
    {
      case 'attack':  return new AttackStatus(status, parent);
      case 'create':  return new CreateStatus(status, parent);
      case 'move':    return new MoveStatus(status, parent);
      case 'dock':    return new DockStatus(status, parent);
    }
  }

  return new DefaultStatus(status, parent);
}
