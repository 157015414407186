/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react';

import Theme from 'fierry/react/skeleton/theme';
import ThemeConstants from 'galax/server/constants/theme';


const Component = styled(Theme.Group)`
  --svg-always-black: var(--fierry-theme-primary-foreground);
  --svg-always-other: var(--fierry-theme-secondary-foreground);
  --svg-always-white: transparent;
`;


const DEFAULT_CONSTANTS = new ThemeConstants({ constants: { type: 0, primary_light: '#757575', secondary_light: '#616161', primary_dark: '#BDBDBD', secondary_dark: '#E0E0E0' } });


export default ({ user, ...props }) =>
{
  return <Component {...props} theme = {user.getTheme() || DEFAULT_CONSTANTS} />
}
