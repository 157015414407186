/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import View from 'fierry/react/skeleton/card/view';

import Spaceship from 'galax/react/card/spaceship/overview';

import ProjectileCard from 'galax/react/card/zone.projectile.list';


export default (props) =>
(
  <>
    <Spaceship {...props} />

    <View {...props} card = {ProjectileCard} route = '/projectile.list' />
  </>
);
