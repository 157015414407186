/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import BuildingEnum from 'galax/enum/building';

import Building from 'galax/server/building';

import DistributionCenter from 'galax/server/building/distribution.center';
import Garrison from 'galax/server/building/garrison';
import Outpost from 'galax/server/building/outpost';
import RoboticFactory from 'galax/server/building/robotic.factory';
import Spaceport from 'galax/server/building/spaceport';
import TransferStation from 'galax/server/building/transfer.station';
import Gate from 'galax/server/building/gate';

import LaserBattery from 'galax/server/building/laser.battery';
import GaussCannon from 'galax/server/building/gauss.cannon';
import GaussArtillery from 'galax/server/building/gauss.artillery';


export default (entity, constants, planet) =>
{
  switch (entity.variant)
  {
    case BuildingEnum.DistributionCenter: return new DistributionCenter(entity, constants, planet);
    case BuildingEnum.Garrison:           return new Garrison(entity, constants, planet);
    case BuildingEnum.Outpost:            return new Outpost(entity, constants, planet);
    case BuildingEnum.RoboticFactory:     return new RoboticFactory(entity, constants, planet);
    case BuildingEnum.Spaceport:          return new Spaceport(entity, constants, planet);
    case BuildingEnum.TransferStation:    return new TransferStation(entity, constants, planet);
    case BuildingEnum.Gate:               return new Gate(entity, constants, planet);

    case BuildingEnum.LaserBattery:       return new LaserBattery(entity, constants, planet);
    case BuildingEnum.GaussCannon:        return new GaussCannon(entity, constants, planet);
    case BuildingEnum.GaussArtillery:     return new GaussArtillery(entity, constants, planet);
  }

  return new Building(entity, constants, planet);
}
