/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import uuid from 'uuid/v4';


export default class
{
  constructor (type, source, target, shape, currentFlightTime, totalFlightTime)
  {
    this.currentFlightTime = currentFlightTime;
    this.totalFlightTime = totalFlightTime;

    this.projectile = { id: uuid(), type, user: { id: source.getUserID(), strength: 0 }, source: source.getLocation(), target: target.getLocation(), shape, event: { id: null } };
  }


  setGalaxy (galaxy)
  {
    this.projectile.galaxy = galaxy;
  }


  setUpdateDate (date)
  {
    this.projectile.launch_date = new Date(date.getTime() - this.currentFlightTime);
    this.projectile.event.date = new Date(date.getTime() - this.currentFlightTime + this.totalFlightTime);
  }


  getType ()
  {
    return this.projectile;
  }


  getKey ()
  {

    return this.projectile.id;
  }


}
