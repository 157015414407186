/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><polygon points="4.5 4.5 8.5 11 4 16 9 15.5 8 20 13 17 18 19.5 15.5 14 20 11 15.5 9.5 16.5 4 11.5 8 4.5 4.5" className="black-line-20"/></Svg>
  );
}
