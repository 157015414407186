/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import View from 'fierry/react/skeleton/card/view';

import BuildingEnum from 'galax/enum/building';
import BuildingInfoCard from 'galax/react/card/building/info'


const getRoute = ({ type }) =>
{
  return '/building.create/' + type;
}


const BuildingCard = (props) =>
(
  <View {...props} item card = {BuildingInfoCard} route = {getRoute(props)} />
);


export default (props) =>
(
  <>
    <BuildingCard {...props} type = {BuildingEnum.DistributionCenter} />
    <BuildingCard {...props} type = {BuildingEnum.RoboticFactory} />
    <BuildingCard {...props} type = {BuildingEnum.Outpost} />
    <BuildingCard {...props} type = {BuildingEnum.Gate} />

    <BuildingCard {...props} type = {BuildingEnum.LaserBattery} />
    <BuildingCard {...props} type = {BuildingEnum.GaussCannon} />
    <BuildingCard {...props} type = {BuildingEnum.GaussArtillery} />
  </>
);
