/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { styled, useCallback, useFlux } from 'fierry/react';

import Offset from 'galax/client/map/offset';

import drag from 'fierry/util/drag';


const Svg = styled.svg`
  float: left;
  height: 100%;
`;


const OnDragStart = (event, offset) =>
{
  offset.setDnD(true);
}


const OnDragEnd = (event, offset) =>
{
  offset.setDnD(false);
}


const OnDrag = ({ dx, dy }, offset) =>
{
  offset.setRelative(dx, dy);
}


const OnSvg = (svg, onDrag, onDragStart, onDragEnd) =>
{
  drag(svg, onDrag, onDragStart, onDragEnd);
}


const OnClick = ({ clientX, clientY }, offset, onZoneClick) =>
{
  if (onZoneClick)
  {
    onZoneClick(offset.getPixelToCoords(clientX, clientY));
  }
};


export default ({ onZoneClick, ...props }) =>
{
  const [ offset ] = useFlux(Offset);

  const onDrag = useCallback(OnDrag, [ offset ]);
  const onDragEnd = useCallback(OnDragEnd, [ offset ]);
  const onDragStart = useCallback(OnDragStart, [ offset ]);

  const onSvg = useCallback(OnSvg, [ onDrag, onDragStart, onDragEnd ]);
  const onClick = useCallback(OnClick, [ offset, onZoneClick ])

  return <Svg {...props} ref = {onSvg} onClick = {onClick} />;
}
