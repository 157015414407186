/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (scope)
  {
    this.scope = scope;

    this.critical = false;
    this.overheat = false;
  }


  install ()
  {
    const zones = this.scope.getZones();

    this.critical = zones.getCriticalCount() >= zones.getMajorityCount();
    this.overheat = zones.getOverheatCount() >= zones.getMajorityCount();
  }


  isOverheat ()
  {
    return this.overheat;
  }


  isCritical ()
  {
    return this.critical;
  }


};
