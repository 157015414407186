/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Action from 'fierry/flux/entity/action';

import useAction from 'fierry/flux/hook/use.action';
import useContext from 'fierry/flux/hook/use.context'


const extractDependency = (dependency, props) =>
{
  if (dependency instanceof Action)
  {
    return useAction(dependency);
  }

  if (dependency instanceof Function)
  {
    return dependency(props);
  }

  // TODO not all incomming 'dependency' objects inherit from Entity ??
  if (dependency.getDefaultEntity && dependency.getDefaultEntity())
  {
    return useContext(dependency.getDefaultEntity());
  }

  return useContext(dependency);
}


export default (dependences, props = {}) =>
{
  let objects = [];

  // Each client has static list of dependences; therefore useContext() can be used in a loop.
  for (const dependency of dependences)
  {
    objects.push(extractDependency(dependency, props));
  }

  return objects;
}
