/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Bezier } from "bezier-js";

import * as Points from 'galax/geo/points';


export const create = (p0, p1, p2) =>
{
  return new Bezier(p0, p1, p2);
}


export const split = (p0, p1, p2, t) =>
{
  return new Bezier(p0, p1, p2).split(t);
}


export const length = (p0, p1, p2) =>
{
  return new Bezier(p0, p1, p2).length();
}


export const getPerpendicularBisector = (lhs, rhs, offset) =>
{
  const mid = Points.scale(Points.add(lhs, rhs), 0.5);

  const theta = Math.atan2(rhs.y - lhs.y, rhs.x - lhs.x) - Math.PI / 2;

  return { x: mid.x + offset * Math.cos(theta), y: mid.y + offset * Math.sin(theta) };
}


export const getPoint = (p0, p1, p2, t) =>
{
  return new Bezier(p0, p1, p2).get(t);
}


export const getTime = (p0, p1, p2, p) =>
{
  const c10 = (p.x - p1.x) * (p.y - p0.y) - (p.x - p0.x) * (p.y - p1.y);
  const c20 = (p.x - p2.x) * (p.y - p0.y) - (p.x - p0.x) * (p.y - p2.y);

  return c10 / (c10 - 0.5 * c20);
}
