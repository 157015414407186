/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, styled } from 'fierry/react';
import { List, Item, Link, Bold } from 'galax/react/element/welcome';


const WelcomeContainer = styled.div`
  user-select: text;
`;


export default (props) =>
(
  <WelcomeContainer>

    <Modern.Card spacing = '1'>
      <Modern.Text param>We recognize that ensuring your personal privacy is an ongoing responsibility which we take seriously. This “<Bold>Privacy Statement</Bold>” describes how Fierry, LLC (“<Bold>Fierry</Bold>”) collects, uses and discloses your data when you use our services, including our websites, online games, applications, forums and email notifications (collectively, the “<Bold>Services</Bold>”).</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>INFORMATION WE COLLECT</Modern.Text>
      <Modern.Text param>We require certain information, including your personal data, to provide our Services to you. For example, you must have an account in order to participate in our online games. When you choose to share the information below with us, we collect and use it to operate our Services.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Account Information</Modern.Text>
      <Modern.Text param>You don’t have to create an account to use some of our Services, such as viewing our blog or forum. If you choose to create an account, you must supply us with some personal data, including your full name and email address, via our identity provider Google Firebase. You may also need to create a username which will be visible to others when using our Services. We will not share your email with other users, but your real name will be visible if you decide to post on our forum.</Modern.Text>
      <Modern.Text param>Each time you sign in your profile picture will be shared with us - we do not retain or use the image for any purpose.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Contact Information and Marketing</Modern.Text>
      <Modern.Text param>We collect and use your email address (“<Bold>Contact Information</Bold>”) to create your account, to keep it secure and to enable certain account features. To the extent permitted by applicable law, without your consent, we may directly market to you via your Contact Information to inform you about our Services, special offers, and conduct opinion polls and surveys. You can unsubscribe from all non-essential as well as marketing communications by following the instructions contained within each notification. If you contact us, we will keep the content of your message, email address and/or other contact information to respond to your request.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Location Information</Modern.Text>
      <Modern.Text param>When setting up your account and using our Services, we may collect personal data about your location. We automatically collect location data from your IP address and other device signals. We collect this data so we can maintain your account and provide you all of our Services.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Financial Information</Modern.Text>
      <Modern.Text param>As of the effective date of this Privacy Statement we do not enable financial transactions as part of our Services. If in the future we offer the ability for financial transactions we will update this Privacy Statement to reflect how we collect and process your personal data.</Modern.Text>
      </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>User Content</Modern.Text>
      <Modern.Text param>“<Bold>User Content</Bold>” is what you and other users create, generate, post, share or store when interacting and using the Services. This includes your posts on the forum, your comments in the blog and your actions, decisions, progress and communications when playing online games. We collect User Content so we can operate and improve our Services.</Modern.Text>
      <Modern.Text param>Most of your activity on Fierry is public meaning others using the Services can view your User Content. You are responsible for the information you provide through our Services, and you should think carefully about what you make public, especially if it is personal or sensitive information.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Logs</Modern.Text>
      <Modern.Text param>We automatically collect certain personal information about you when you interact with our Services (“<Bold>Logs</Bold>”), even if you have not created an account. Logs may include information such as your web request, referring website, IP address, access times, device, browser and operating system information, and cookie information. We use this data to improve and operate our Services and to ensure their secure, reliable, and robust performance.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Links</Modern.Text>
      <Modern.Text param>In order to operate and improve our Services, we keep track of how you interact with links across our Services, including links provided in emails we send you.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Cookies</Modern.Text>
      <Modern.Text param>A cookie is a small piece of data that is stored on your computer or mobile device. This section describes our usage and your control of cookies and similar technologies (“<Bold>Cookies</Bold>”) when accessing our Services.</Modern.Text>
      <Modern.Text param>When your browser or device allows it, we use both session Cookies and persistent Cookies to better understand how you interact with our Services, to improve your user experience, and to operate our Services. We do not use Cookies for third-party advertising or marketing purposes.</Modern.Text>
      <Modern.Text param>If you wish to disable the use of any Cookies, including cookies that are operationally necessary, you may use the settings in your browser or device, if this is supported by your browser or device. Certain parts of our Services may not function properly if you disable Cookies (e.g. you will not be able to sign in to your account or play online games).</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>INFORMATION WE SHARE AND DISCLOSE</Modern.Text>
      <Modern.Text param>In the limited circumstances where we disclose your personal data, we do so subject to your control, with your consent, because it’s necessary to support, operate and improve our Services, or because it’s required by law.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>We don’t sell your personal data</Modern.Text>
      <Modern.Text param>We do not share your personal data with third-parties for marketing or advertising purposes, nor will we sell or otherwise make your personal data available to a third-party for a fee.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Service Providers</Modern.Text>
      <Modern.Text param>We may provide access to or share your information, including your personal data, with select third-parties that use the information only to perform services on our behalf (consistent with this Privacy Statement and any other appropriate confidentiality and security measures). These third parties provide a variety of services to us including without limitation security, analytics, data storage, and other services.</Modern.Text>
      <Modern.Text param>We use Google Analytics for measuring our visitors. We anonymize the data in Google Analytics – so we don’t know who you are; just that somebody visited our site. If you wish to prevent Google Analytics from using your information for analytics, you may install the Google Analytics Opt-out Browser Add-on: <Link href = 'https://tools.google.com/dlpage/gaoptout' target = '_blank' title = 'Google Analytics Opt-out Browser Add-on'>https://tools.google.com/dlpage/gaoptout</Link>.</Modern.Text>
      <Modern.Text param>We use Google Firebase for authenticating and maintaining our user accounts. At this moment we allow users to sign in with their Google or Facebook credentials.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Forum and Blog</Modern.Text>
      <Modern.Text param>Our Services make it possible for you to share comments or feedback publicly with other users, such as on our blog, which is operated by medium.com, or through our forum. Any User Content you submit through such public features is not confidential, and we may use it for any purpose (including in testimonials or other marketing materials). Any information you post openly in these ways will be available to the public at large and potentially accessible through third-party search engines. Such information can be read, collected and/or used by other users, and it could be used to send you unsolicited messages. Accordingly, please take care when using these features.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Law, Harm, and the Public Interest</Modern.Text>
      <Modern.Text param>We may preserve, use, or disclose your personal data if we believe that it is reasonably necessary (i) to comply with a law, regulation, legal process, or governmental request; (ii) to protect the safety of any person; (iii) to protect the safety or integrity of our platform, including to help prevent spam, abuse, or malicious actors on our Services, or to explain why we have removed content or accounts from our Services; (iv) to defend against assertions, allegations of or against legal claims; (v) to address fraud, security, or technical issues; (vi) to protect our rights or property or the rights or property of those who use our Services; or (vii) to meet our legal obligations concerning the storage and retention of your data. However, nothing in this Privacy Statement is intended to limit any legal defenses or objections that you may have to a third-party’s, including a government’s, request to disclose your personal data.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Business Transfers</Modern.Text>
      <Modern.Text param>If the ownership of all or substantially all of our business changes, we may transfer your information to the new owner so that our Services can continue to operate. In such case, your information would remain subject to the promises and commitments contained in this Privacy Statement until such time as this Privacy Statement is updated or amended by the acquiring party upon notice to you. If such transfer is subject to additional mandatory restrictions under applicable laws, Fierry will comply with such restrictions.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>YOU CONTROL YOUR PERSONAL DATA</Modern.Text>
      <Modern.Text param>You control the personal data you share with us. You can access, correct or erase this data at any time. You can also object, restrict, or withdraw consent where applicable for the use of data you have provided to Fierry. For any requests please contact us at <Link href='mailto:privacy@fierry.com' target = '_blank' title = 'Privacy email'>privacy@fierry.com</Link>.</Modern.Text>
      <Modern.Text param>We will consider all requests and provide our response within the time period stated by applicable law. Please note that certain information may be exempt from such requests in some circumstances, which may include if we need to keep processing your information for our legitimate interests or to comply with a legal obligation. We may request you to provide us with information necessary to confirm your identity before responding to your request.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Right to withdraw your consent</Modern.Text>
      <Modern.Text param>You can withdraw your consent to our processing of your personal data at any time. As a result, we may no longer process your personal data based on consent in the future.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Right to access your personal data</Modern.Text>
      <Modern.Text param>You may access your personal data and request the following information:</Modern.Text>
      <Modern.Text param>
        <List>
          <Item>purposes for processing your personal data</Item>
          <Item>categories of your personal data processed</Item>
          <Item>categories of all third-parties we are (or will be) sharing or disclosing your personal data</Item>
          <Item>time-period for storing your personal data</Item>
        </List>
      </Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Right to correct</Modern.Text>
      <Modern.Text param>You may obtain from us without undue delay, the correction of inaccurate personal data about you, including the right to have incomplete personal data completed, and the ability to provide a supplementary statement.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Right to erase</Modern.Text>
      <Modern.Text param>You may obtain from us the erasure of personal data about you, unless processing is necessary for exercising the right of freedom of expression and information, for compliance with a legal obligation, for reasons of public interest or for the establishment, exercise or defense of legal claims. The right to erasure may be limited by law.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Right to restrict processing</Modern.Text>
      <Modern.Text param>You may obtain from us restriction of processing if:</Modern.Text>
      <Modern.Text param>
        <List>
          <Item>the accuracy of the data is disputed by you</Item>
          <Item>the processing is unlawful, but you oppose the erasure of the personal data</Item>
          <Item>we no longer need the data, but you need it to assert, exercise or defend legal claims</Item>
          <Item>you have objected to the processing</Item>
        </List>
      </Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Right to receive your personal data and data portability</Modern.Text>
      <Modern.Text param>You may receive personal data about you, which you have provided to us, in a structured, commonly used and machine-readable format and may transmit your personal data to another controller ("right to data portability").</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Right to lodge a complaint</Modern.Text>
      <Modern.Text param>You may lodge a complaint with a supervisory authority. You can contact the supervisory authority of your usual place of residence, your place of work or the registered office of the controller.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Right to object (legitimate interests, marketing and profiling)</Modern.Text>
      <Modern.Text param>If your personal data is processed based on legitimate interests, you may object to the processing of your personal data on grounds relating to your particular situation. This also applies to profiling. If your personal data is processed by us for direct marketing purposes, you may object to the processing of your personal data for such marketing, which includes profiling if it is related to such direct marketing.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Cookies and other Technology</Modern.Text>
      <Modern.Text param>As provided above in this Privacy Statement, you have control over the technology we use for monitoring and tracking. For example you can adjust your browser preferences to block cookies.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Fees</Modern.Text>
      <Modern.Text param>Your exercise of the above rights (e.g. right to access or erasure) is generally free. Where requests are manifestly unfounded or excessive, because of their repetitive character, we may charge an appropriate fee under applicable statutory regulations or refuse to process the application.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>DATA STORAGE, RETENTION AND DELETION</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>How we store your personal data</Modern.Text>
      <Modern.Text param>The personal data we collect may be stored and processed in your country or region, or in any other country where we or our affiliates, subsidiaries, or service providers maintain facilities. We primarily use data centers in the United States chosen to operate efficiently, to improve performance, and to create redundancies to protect the data if an outage or other problem occurs. We store your data as directed under applicable data protection laws to the extent necessary for the stated processing purposes.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>How long we retain your personal data</Modern.Text>
      <Modern.Text param>We retain your personal data for as long as necessary to provide your our Services, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements. Because these needs can vary for different data types in the context of our Services, actual retention periods can vary significantly based on criteria such as user expectations or consent, the sensitivity of the data, the availability of automated controls that enable users to delete data, and our legal or contractual obligations.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Deletion of personal data</Modern.Text>
      <Modern.Text param>We will delete your personal data upon your request or within 90 days of account termination unless we are legally obliged to keep your personal data longer (e.g. for tax, accounting or auditing purposes). Deleted personal data is not recoverable however we do retain copies of data as part of our data retention policy (e.g. by keeping data backups).</Modern.Text>
      <Modern.Text param>We will not delete your User Content needed for continued consistency and operation of our Services. This includes any data generated during your participation in our online games, your posts on the forum and comments on the blog. We will try to properly render the data anonymous by removing your real name, email and assigning a random username to your terminated account.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>INTERNATIONAL DATA PROCESSING AND TRANSFERS</Modern.Text>
      <Modern.Text param>For users in the European Economic Area (EEA) we transfer and process your personal data, when using our Services, in accordance with the EU-U.S. Privacy Shield, ensuring the appropriate safeguards for an international data transfer exist. To learn more about the European Commission’s decisions on the adequacy of personal data protection, please <Link href = 'https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en' target = '_blank' title = 'EU Adequacy Decisions'>click here</Link>.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>AGGREGATE DE-IDENTIFIED DATA</Modern.Text>
      <Modern.Text param>We may aggregate and/or de-identify information collected through our Services so that such information can no longer be linked to you or your device. We may then use this information for any purpose, including without limitation for research and marketing purposes, and may also share such data with any third parties, including advertisers, promotional partners, and/or others.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>AUTOMATED DECISION-MAKING</Modern.Text>
      <Modern.Text param>We do not use technology for automated decision-making purposes and will make no automated decisions about you unless we have obtained your consent, or we are required by law to use such technology.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>HOW WE PROTECT YOUR INFORMATION</Modern.Text>
      <Modern.Text param>We take reasonable and appropriate steps to help protect personal data from unauthorized access, use, disclosure, alteration, and destruction. We protect your personal data through technical and organizational security measures to minimize risks associated with data loss, misuse, unauthorized access and unauthorized disclosure and alteration. We use firewalls and data encryption, for example, as well as physical access restrictions for our data centers and authorization controls for data access.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>YOUR CALIFORNIA PRIVACY RIGHTS</Modern.Text>
      <Modern.Text param>California law requires us to let you know how we respond to web browser Do Not Track (“<Bold>DNT</Bold>”) signals. Because there currently isn't an industry or legal standard for recognizing or honoring DNT signals, we don't respond to them at this time. We await the result of work by the privacy community and industry to determine when such a response is appropriate at which point we will adopt the response.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>NOTICE TO CALIFORNIA RESIDENTS</Modern.Text>
      <Modern.Text param>A California resident who has provided personal data to a business with whom they have established a business relationship for personal, family, or household purposes (a “<Bold>California Customer</Bold>”) may request information about whether the business has disclosed personal information to any third parties for the third parties’ direct marketing purposes. California Customers may request further information about our compliance with this law by emailing <Link href='mailto:privacy@fierry.com' target = '_blank' title = 'Privacy email'>privacy@fierry.com</Link>. Please note that we are required to respond to one request per California Customer each year, and we are not required to respond to requests made by means other than through this email address.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>CHILDREN’S ONLINE PRIVACY PROTECTION ACT</Modern.Text>
      <Modern.Text param>Our services are not intended for use by children. We do not knowingly target or collect personal data from users who are considered children under applicable national laws. We prohibit any person younger than thirteen (13) years of age, or sixteen (16) years of age if the person lives in the European Union, from using the Services.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>CHANGES AND REVISIONS</Modern.Text>
      <Modern.Text param>We may revise this Privacy Statement from time to time. The changes will not be retroactive, and the most current version of the document will govern our relationship with you. We will try to notify you of material changes to the statement (e.g. via a service notification or through an email associated with your account) or obtain your consent regarding such changes as required by law.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>HOW TO CONTACT US</Modern.Text>
      <Modern.Text param>If you have a privacy concern, complaint, or a question for the us or our Data Protection Officer, please contact us at <Link href='mailto:privacy@fierry.com' target = '_blank' title = 'Privacy email'>privacy@fierry.com</Link>. Our address is 5555 14th Ave NW Unit 226 Seattle, WA 98107.</Modern.Text>
      <Modern.Text param><Bold>Effective</Bold> Oct 21, 2019.</Modern.Text>
      <Modern.Text param><Bold>Copyright ©</Bold> 2019 Fierry, LLC. All rights reserved.</Modern.Text>
    </Modern.Card>

  </WelcomeContainer>
);
