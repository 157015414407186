/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, Modern } from 'fierry/react';

import * as InfluenceValue from 'galax/value/influence';
import FlowUnit from 'galax/react/unit/flow';

import PerUnit from 'svg/shared/unit/per';
import HourUnit from 'svg/shared/unit/hour';


const getInfluenceText = ({ value }) =>
{
  return Math.abs(InfluenceValue.toPercentage(value));
}


export default (props) =>
(
  <Element.Group param>

    <Element.Unit value = {<FlowUnit {...props} />} />
    <Element.Text value = {getInfluenceText(props)} />
    <Element.Unit value = {[ <PerUnit />, <HourUnit /> ]} />

  </Element.Group>
);
