/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, styled } from 'fierry/react';
import { List, Item, Link, Bold } from 'galax/react/element/welcome';


const WelcomeContainer = styled.div`
  user-select: text;
`;


export default (props) =>
(
  <WelcomeContainer>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>What is Fierry?</Modern.Text>
      <Modern.Text param>Fierry is a massively multiplayer strategy game set in space. You start with a small settlement on a single planet. Your goal is to build on these modest origins and expand into a truly interplanetary empire. You will meet hundreds of other players willing to fight you, conquer your territory and destroy your spaceships.</Modern.Text>
      <Modern.Text param>Every decision you make happens in real time. Constructing a building, producing a spaceship or attacking another player takes time and can finish minutes to hours later. It doesn’t matter if you are online or not - each action you make will happen and there is no going back.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Game Start</Modern.Text>
      <Modern.Text param>When you log in for the first time you are assigned name and color and a starting position. You are free to drag and drop the map to explore your surroundings. You will notice that the map consists of hexagonal shapes called zones. Your initial settlement should contain 21 zones divided into 3 groups called sectors.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Zone</Modern.Text>
      <Modern.Text param>Zone represents a unit of territory that you or another player controls. It allows you to construct buildings and produce units in order to enhance your offensive or defensive capabilities.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Sector</Modern.Text>
      <Modern.Text param>Sector is a group of zones that act as a cohesive, governable entity. You cannot control parts of a sector. You can only control a sector fully or not control it at all.</Modern.Text>
      <Modern.Text param>Sector introduces a couple of economic measures designed to improve cooperation between zones. It distributes newly produced / transferred energy into zones that need it the most and spreads the cost of the zone's activity to its neighbors - effectively reducing vulnerability of specific zones to enemy attack.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Territory Control</Modern.Text>
      <Modern.Text param>Multiple players may try to gain control over a single zone. These efforts are represented in energy resource allocated to each of these players. If enemy player manages to claim more energy that the current owner, the zone will be displayed as conquered. Once a majority of zones in a given sector are conquered the sector will flip and the winner will gain full control over all zones in that sector.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Energy</Modern.Text>
      <Modern.Text param>Energy has a dual purpose. On one side it describes how much control you have over your zone. On the other side it is a resource that you spend whenever you produce units, spaceships or fire projectiles. You will need to manage how much energy you can afford to use without weakening yourself excessively and not being able to defend what you already have.</Modern.Text>
      <Modern.Text param>Zone fully controlled by a single player has 100 energy allocated to that player. Energy spent on actions instantly disappears from the zone and is slowly replenished back to 100 over a period of time.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Influence</Modern.Text>
     <Modern.Text param>Energy increase / decrease over time is called an influence. Zones can receive many sources of influence belonging to different players. Enemy units or buildings can attack zones in order to gain control. Neighbor sectors can transfer some of their energy to support a sector under assault. Zones themselves generate a small amount of energy for the player who controls them. All of these different sources are summed up and a result is calculated that shows how much energy each player is projected to gain / lose over the next hour.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Resistance</Modern.Text>
      <Modern.Text param>Zone can develop resistance against your attacks if you try to conquer it with too much force at once. Increased resistance will translate into rising defense against your current and future attacks. Resistance is temporary - once you lower the intensity of your attack it will decrease over time back to zero. As a rule of thumb any damage over 15 energy per hour will result in increasing resistance.</Modern.Text>
    </Modern.Card>

  <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Relative Strength</Modern.Text>
      <Modern.Text param>When two players fight for control over the same territory, the bigger player will get a disadvantage if the gap between players’ points is big enough. If that is the case then all damage exchanged by both players will be adjusted by a calculated relative strength between the players.</Modern.Text>
      <Modern.Text param>Relative strength should make conquering much smaller players not cost effective and change into prohibitive if the bigger player engages multiple small enemies at once. You will lose your territory fighting one-on-one against an enemy five times your size. However, if that enemy is engaging two other players your size, then you will likely be able to defend successfully and probably get some new territory from the bigger player.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Energy Generation</Modern.Text>
      <Modern.Text param>Each zone generates new energy on a sector level that (along with active transfers) gets distributed to zones that need it the most.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Energy Transfer</Modern.Text>
      <Modern.Text param>You can transfer energy between neighboring sectors in order to distribute it better throughout your territory. The longer the border between both sectors the faster you can transfer your energy. Each transfer has an efficiency - how much energy you retain between source and target. You can increase the speed of transfer at the cost of lower efficiency.</Modern.Text>
      <Modern.Text param>Later on you can construct a Transfer Station building that will allow you to transfer energy to more distant sectors - even on different planets!</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Critical Energy</Modern.Text>
      <Modern.Text param>Zone that gets to zero energy goes critical. Such zone cannot produce units or activate buildings (e.g. fire projectiles, charge docked spaceships, etc). Once the majority of zones in a sector go critical then the whole sector becomes critical - including zones that would not be considered critical based on their energy levels. Zone leaves critical as soon as it reaches safe energy level (20 energy).</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Resource</Modern.Text>
      <Modern.Text param>There are three user resources in the game: Carbon, Silicon and Hydrogen. Their main purpose is to pay for building construction or upgrade. You start with 3 Carbon and 3 Silicon. To get more you will need to extract resources from its deposits over a period of time.</Modern.Text>
      <Modern.Text param>Resource deposits are sprinkled all over the map with Hydrogen being the rarest. Once you control a sector with a specific resource you can attempt to mine by spending a small amount of the zone's energy over a period of excavation. The smaller the deposit the longer it takes to extract a resource. Deposit will increase by one over a period of 24 hours - it’s up to you to manage your deposits so you don’t need to spend too much time and energy on resource excavation.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Unit</Modern.Text>
      <Modern.Text param>Unit is a temporary entity that can be produced in minutes by spending your sector’s energy. It can attack multiple zones at once by continuously projecting its influence in a defined shape and at a specific distance.</Modern.Text>
      <Modern.Text param>Here are currently available units:</Modern.Text>
      <Modern.Text param><Bold>Infantry</Bold> is a close quarters attack unit. It will target two adjacent zones dealing 9 damage / hour to each.</Modern.Text>
      <Modern.Text param><Bold>Rangers</Bold> is a range attack unit. It will target three zones at a range of 2 ~ 4 dealing 6 damage / hour to each.</Modern.Text>
      <Modern.Text param><Bold>Mechanized Infantry</Bold> is an short range attack unit. It will target four zones at a range of 1 ~ 2 dealing 4 damage / hour to each.</Modern.Text>
      <Modern.Text param><Bold>Artillery</Bold> is a long range attack unit with limitted precision. It will target seven zones at a range 3 ~ 5 dealing 3 damage / hour to each.</Modern.Text>
      <Modern.Text param>Unit starts with a specific amount of energy taken from your sector. It will continuously weaken over time due to attrition. Attacking enemy territory will cause the unit to lose its energy at a faster rate but in exchange for increased influence over the targeted zones. As a result most units will exist only for 2 ~ 3 hours before their energy drops to zero and they are destroyed.</Modern.Text>
      <Modern.Text param>Unit cannot move. If you decide at any moment that the unit is no longer needed you can disband it to transfer its remaining energy back onto your sector.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Building</Modern.Text>
      <Modern.Text param>Building is a permanent entity that provides you with special bonuses or new capabilities as long as you control its underlying sector. Most buildings can be upgraded to further increase their capabilities.</Modern.Text>
      <Modern.Text param>Constructing or upgrading a building requires user resources and takes an increasing amount of time. You can construct only one building per sector. If you find that the building is no longer required you can always abandon it to relinquish its occupied space and used resources. During construction you cannot create nor upgrade any other buildings on the same planet.</Modern.Text>
      <Modern.Text param>Here are currently available buildings:</Modern.Text>
      <Modern.Text param><Bold>Distribution Center</Bold> increases energy generation for all zones in the sector. When activated it instead provides additional resistance to enemy attacks.</Modern.Text>
      <Modern.Text param><Bold>Robotic Center</Bold> decreases unit production time for all zones in the sector. When activated it instead increases units defence.</Modern.Text>
      <Modern.Text param><Bold>Transfer Station</Bold> increases efficiency of all transfers originating from the sector. When activated it allows to create one transfer to non-adjacent sector - including sector on a different planet.</Modern.Text>
      <Modern.Text param><Bold>Outpost</Bold> provides additional resistance to enemy attacks for all zones in the sector. When activated it instead decreases unit production time.</Modern.Text>
      <Modern.Text param><Bold>Garrison</Bold> increases units defence in all zones of the sector. When activated it instead decreases units attrition.</Modern.Text>
      <Modern.Text param><Bold>Spaceport</Bold> enables spaceship production and docking. Each upgrade decreases spaceship production time. Spaceport cannot be constructed if the sector is not on the edge of the planet.</Modern.Text>
      <Modern.Text param><Bold>Laser Battery</Bold> can fire a projectile that will hit 3 target zones dealing 5 damage to each at distance of 15. Projectile can also target spaceships dealing 15 total damage.</Modern.Text>
      <Modern.Text param><Bold>Gauss Cannon</Bold> can fire a projectile that will hit 4 target zones dealing 4 damage to each at distance of 20. Projectile can also target spaceships dealing 16 total damage.</Modern.Text>
      <Modern.Text param><Bold>Gauss Artillery</Bold> can fire a projectile that will hit 7 target zones dealing 2 damage to each at distance of 30. Projectile can also target spaceships dealing 14 total damage.</Modern.Text>
      <Modern.Text param>Most buildings can be activated to provide a different bonus for a short time. Faced against enemy attack Distribution Center will help you defend your zones with increased resistance. Outpost will allow you to suddenly produce many units for a counterattack. Once the activation runs its course (or is cancelled prematurely) building enters cooldown where both its passive and active effects are disabled. Each activation starts with a quick setup where you can cancel the action without any penalty.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Spaceship</Modern.Text>
      <Modern.Text param>Spaceship is a semi-permanent entity that can fly in the space between planets and attack enemy spaceships or zones. Currently only one type of spaceship exists.</Modern.Text>
      <Modern.Text param>You need a Spaceport building to produce spaceships. Spaceship production is energy expensive and takes 3 hours or more. Once a spaceship is ready it will automatically transition into docking  action where it will receive energy over time (until it reaches its maximum). Docked spaceship is also protected from enemy attacks.</Modern.Text>
    </Modern.Card>

  </WelcomeContainer>
);
