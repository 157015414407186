/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Client } from 'fierry/flux';

import View from 'fierry/client/view';
import Galaxy from 'galax/server';
import Now from 'galax/query/now';
import CurrentLocation from 'galax/query/current.location';


export default new Client([ CurrentLocation, Galaxy, View, Now ], class
{
  constructor (location, galaxy, view, now)
  {
    this.location = location;
    this.galaxy = galaxy;
    this.now = now;

    this.args = view.getRoute().getArgs();
  }


  onDone ()
  {
    const type = this.args.type;

    this.galaxy.createSpaceship(this.location.getDock(), type);
  }


  getError ()
  {
    const type = this.args.type;
    const dock = this.location.getDock();

    if (type == null)
    {
      return 'No spaceship type found';
    }

    if (!dock || !dock.isActive())
    {
      return 'Spaceship has to be constructed on an actual dock';
    }

    if (this.location.getSpaceship())
    {
      return 'Dock cannot be occupied by another spaceship';
    }

    const constants = this.galaxy.getConstants().getSpaceship(type);

    const currency = this.galaxy.getCurrentUser().getCurrency(this.now);

    if (currency < constants.getConstructionCost())
    {
      return 'Not enough currency to produce spaceship';
    }

    /*if (!dock.getSpaceport().getEnergy().canSpend(constants.getMinEnergy()))
    {
      return 'Not enough energy to produce spaceship';
    }*/

    return null;
  }


  getInitial (target)
  {
    if (this.args.type === '')
    {
      return 'Select spaceship type';
    }

    return null;
  }


  canSelect ()
  {
    return false;
  }


  canNavigate ()
  {
    return false;
  }


  isPaneCollapsed ()
  {
    return false;
  }


});
