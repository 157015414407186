/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import EntityEnum from 'galax/enum/entity';


export default class
{
  constructor (variant, coords)
  {
    this.building = { type: EntityEnum.Building, variant, coords, zone: coords.toString(), args: { level: 1 }, status: null };
  }


  setStatus (type, date = null)
  {
    if (type === 'create')
    {
      this.building.args.level = 0;
    }

    this.building.status = { type, event: date ? { id: null, date } : null };
  }


  setGalaxy (galaxy)
  {
    this.building.galaxy = galaxy;
  }


  setPlanet (planet)
  {
    this.building.planet = planet;
  }


  setUpdateDate (date)
  {
    this.building.create_date = date;
    this.building.update_date = date;

    if (this.building.status)
    {
      this.building.status.date = date;
    }
  }


  getType ()
  {
    return this.building;
  }


  getID ()
  {
    return this.building.zone;
  }


}
