/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor ()
  {
    this.cache = new Set();
  }


  merge (collection)
  {
    for (const id of collection)
    {
      this.cache.add(id);
    }
  }


  keys ()
  {
    return this.cache.keys();
  }


}
