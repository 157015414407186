/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { Element, Modern, useFlux } from 'fierry/react';

import Behavior from 'galax/client/behavior';

import Info from 'svg/shared/modern/info';
import Error from 'svg/shared/modern/error';


const Component = ({ icon, text }) =>
(
  <Modern.Card>
    <Element.Group param >

      <Element.Icon value = {icon} />
      <Element.Text value = {text} />

    </Element.Group>
  </Modern.Card>
);


export default (props) =>
{
  const [ behavior ] = useFlux(Behavior);

  if (behavior.isInitial())
  {
    return <Component icon = {<Info />} text = {behavior.getInitial()} />;
  }

  if (behavior.isError())
  {
    return <Component icon = {<Error />} text = {behavior.getError()} />;
  }

  if (behavior.getInfo())
  {
    return <Component icon = {<Info />} text = {behavior.getInfo()} />;
  }

  return null;
}
