/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';

import ConstantsModel from 'galax/server/constants';

import ProjectilesState from 'galax/fake/state/constants/projectiles';
import SpaceshipsState from 'galax/fake/state/constants/spaceships';
import BuildingsState from 'galax/fake/state/constants/buildings';
import UnitsState from 'galax/fake/state/constants/units';

import ThemesState from 'galax/fake/state/constants/themes';


export default (constants) =>
{
  let state = new Immutable.Map();

  state = state.set('projectiles', ProjectilesState(constants.getProjectiles()));
  state = state.set('spaceships', SpaceshipsState(constants.getSpaceships()));
  state = state.set('buildings', BuildingsState(constants.getBuildings()));
  state = state.set('units', UnitsState(constants.getUnits()));

  state = state.set('themes', ThemesState(constants.getThemes()));

  return new ConstantsModel(state, null);
}

