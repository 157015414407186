/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern, Element, useFlux, withValue } from 'fierry/react';

import BuildingEnum from 'galax/enum/building';
import UnitEnum from 'galax/enum/unit';


const getSectorWithMostEnemies = (planet, userID) =>
{
  let maxEnemies = [];
  let maxNeighbor = null;

  for (const neighbor of planet.getSectorNeighbors(userID))
  {
    const enemies = neighbor.getInfluences().getEnemyUnits(userID);

    if (enemies.length > maxEnemies.length)
    {
      maxEnemies = enemies;
      maxNeighbor = neighbor
    }
  }

  return { neighbor: maxNeighbor, enemies: maxEnemies };
}


const areEnemiesAllInfantry = (units) =>
{
  for (const unit of units)
  {
    if (unit.getType() !== UnitEnum.BasicAttack)
    {
      return false;
    }
  }

  return true;
}


const getHint = ({ currentUser, planet }) =>
{
  const userID = currentUser.getID();
  const entities = planet.getEntities();

  if (entities.canConstructBuilding(userID) && entities.getActiveConstruction(userID) == null)
  {
    if (!entities.getBuildingByType(userID, BuildingEnum.DistributionCenter))
    {
      return 'Construct Distribution Center building in one of you empty sectors. This will increase your energy production.';
    }

    if (!entities.getBuildingByType(userID, BuildingEnum.LaserBattery))
    {
      return 'Construct Laser Battery building in one of you empty sectors. This will allow you to fire projectiles to attack enemy territory.';
    }
  }

  const { neighbor, enemies } = getSectorWithMostEnemies(planet, userID);

  if (enemies.length === 0)
  {
    return 'You advance in the game by expanding your territory. Produce an Infantry unit and attack one of your neighboring sectors to conquer it.';
  }

  if (areEnemiesAllInfantry(enemies))
  {
    return 'You need to win majority of sector\'s zones in order to conquer it. Consider using ranged units (Rangers / Artillery) to attack behind enemy lines.'
  }

  if (enemies.length < 3)
  {
    return 'Best way to conquer a sector is to attack it with 3~5 units all at once. Make sure you are targetting more than half of sector\'s zones.';
  }

  return null;
}


const Component = ({ hint, ...props }) =>
(
  <Card {...props} headline = 'First Steps' >
    <Modern.Text param text = {hint} />
  </Card>
);


export default (props) =>
{
  const { currentUser } = props;

  if (currentUser.getPlanetCount() !== 1 || currentUser.getZoneCount() > 34)
  {
    return null;
  }

  const planets = currentUser.getPlanets();

  if (planets.length === 0)
  {
    return null;
  }

  const hint = getHint({ ...props, planet: planets[0] });

  if (!hint)
  {
    return null
  }

  return <Component hint = {hint} />;
}
