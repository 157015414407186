/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import SpaceshipEnum from 'galax/enum/spaceship';

import FighterBasic from 'svg/galax/spaceship/fighter-basic';
import FighterButterfly from 'svg/galax/spaceship/fighter-butterfly';
import BomberMoth from 'svg/galax/spaceship/bomber-moth';
import CruiserDove from 'svg/galax/spaceship/cruiser-dove';


export default ({ spaceship, type, ...props }) =>
{
  if (spaceship && type === undefined)
  {
    type = spaceship.getType();
  }

  switch (type)
  {
    case SpaceshipEnum.FighterBasic:     return <FighterBasic {...props} />;
    case SpaceshipEnum.FighterButterfly: return <FighterButterfly {...props} />;
    case SpaceshipEnum.BomberMoth:       return <BomberMoth {...props} />;
    case SpaceshipEnum.CruiserDove:      return <CruiserDove {...props} />;
  }

  return null;
}
