/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const isLightOverride = () =>
{
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.has('light');
};


export const isDarkOverride = () =>
{
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.has('dark');
};
