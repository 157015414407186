/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Element, Modern, useFlux } from 'fierry/react';

import EnergyList from 'galax/react/group/energy.list';
import Resistance from 'galax/react/group/resistance';

import CoordsElement from 'galax/react/element/coords'


const getName = ({ header }) =>
{
  return header ? header : 'Zone';
}


const Location = ({ currentLocation }) =>
(
  <CoordsElement location = {currentLocation} />
);


const Component = (props) =>
(
  <Card {...props} headline = {getName(props)} subline = {Location} >
    <EnergyList {...props} />
    <Resistance {...props} />
  </Card>
);


export default (props) =>
{
  const { currentZone } = props;

  return <Component {...props} zone = {currentZone} />;
}
