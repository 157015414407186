/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Building from 'galax/server/building';


const CREATE_EFFICIENCY_BASE = Math.pow(2, 1/3);


export default class extends Building
{
  installPassiveTarget (zone)
  {
    const attribute = this.getFriendlyAttribute(this.getTransferEfficiency());

    zone.getAttributes().installTransferEfficiency(attribute);
  }


  getTransferEfficiency ()
  {
    return this.getLevel() * 0.01;
  }


};
