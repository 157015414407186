/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { makeInteraction } from 'galax/server/interaction/make';


export default class
{
  constructor (state)
  {
    this.targetCollection = [];
    this.sourceCollection = [];

    for (const type of state.get('interactionsTo').values())
    {
      this.targetCollection.push(makeInteraction(type));
    }

    for (const type of state.get('interactionsFrom').values())
    {
      this.sourceCollection.push(makeInteraction(type));
    }
  }


  setupInstaller (installer)
  {
    for (const interaction of this.targetCollection)
    {
      installer.pushTargetInteraction(interaction);
    }

    for (const interaction of this.sourceCollection)
    {
      installer.pushSourceInteraction(interaction);
    }
  }


  installSpaceship (spaceship)
  {
    for (const interaction of this.targetCollection)
    {
      interaction.installTargetSpaceship(spaceship);
    }

    for (const interaction of this.sourceCollection)
    {
      interaction.installSourceSpaceship(spaceship);
    }
  }


  getInteractionsTo (coords)
  {
    return this.allTargetInteractions(interaction =>
    {
      return interaction.isTargetOf(coords);
    });
  }


  getInteractionsFrom (coords)
  {
    return this.allSourceInteractions(interaction =>
    {
      return interaction.isSourceOf(coords);
    });
  }


  oneTargetInteraction (predicate)
  {
    for (const interaction of this.targetCollection)
    {
      if (predicate(interaction))
      {
        return interaction;
      }
    }

    return null;
  }


  oneSourceInteraction (predicate)
  {
    for (const interaction of this.sourceCollection)
    {
      if (predicate(interaction))
      {
        return interaction;
      }
    }

    return null;
  }


  allTargetInteractions (predicate)
  {
    let collection = [];

    for (const interaction of this.targetCollection)
    {
      if (predicate(interaction))
      {
        collection.push(interaction);
      }
    }

    return collection;
  }


  allSourceInteractions (predicate)
  {
    let collection = [];

    for (const interaction of this.sourceCollection)
    {
      if (predicate(interaction))
      {
        collection.push(interaction);
      }
    }

    return collection;
  }


  eachTargetInteraction (callback)
  {
    for (const interaction of this.targetCollection)
    {
      callback(interaction);
    }
  }


  eachSourceInteraction (callback)
  {
    for (const interaction of this.sourceCollection)
    {
      callback(interaction);
    }
  }


}
