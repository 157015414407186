/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Header from 'fierry/react/header';

import LogInButton from 'galax/react/button/general/login'


export default (props) =>
(
  <Header headline = 'How to Play?'>
    <LogInButton {...props} />
  </Header>
);
