/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import Behavior from 'galax/client/behavior';
import Selection from 'galax/client/selection';
import TransferState from 'galax/state/optional/sector.transfer';

import TransferEdit from 'galax/react/card/sector.transfer.edit';
import Error from 'galax/react/card/shared/error';


const Component = (props) =>
(
  <>
    <TransferEdit {...props} />
    <Error {...props} />
  </>
);


export default (props) =>
{
  const [ behavior, selection, state ] = useFlux(Behavior, Selection, TransferState);

  const target = state.getReverse() ? props.currentLocation : selection.getLocation();
  const source = state.getReverse() ? selection.getLocation() : props.currentLocation;

  // TODO not sure why but behavior is no longer invalid on transition back. Is it because we loose the behavior here ?
  const invalid = behavior.isInvalid() || !target || !source;

  return <Component {...props} state = {state} selection = {selection} source = {source} target = {target} invalid = {invalid} />;
}
