/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  LaserBattery : 0,
  GaussCannon : 1,
  GaussArtillery : 2,
};
