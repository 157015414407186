/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ParentEnum from 'galax/enum/parent';

import Client from 'fierry/flux/entity/client';

import Server from 'galax/server';
import View from 'fierry/client/view';
import Navigation from 'galax/state/navigation';

import * as Coords from 'galax/geo/coords';


export default new Client ([ Navigation, View, Server ], class
{
  constructor ([ state, dispatch ], view, server)
  {
    this.state = state;
    this.dispatch = dispatch;

    this.view = view;
    this.server = server;
  }


  setZone (zone)
  {
    this.dispatch(Navigation.Set, { planet: null, id: zone.getID() });

    this.view.pushArgs({ current: zone.getCoords().toString(), target: null });
  }


  clearZone ()
  {
    this.dispatch(Navigation.Clear, {});

    this.view.pushArgs({ current: null, target: null })
  }


  getLocation ()
  {
    return this.server ? this.server.getLocation(this.view.getArg('current')) : null;
  }


});
