/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux } from 'fierry/react';

import View from 'fierry/client/view';
import Server from 'galax/server/root';

import Item from 'fierry/react/skeleton/drawer/item';


const OnClick = (event, view, server) =>
{
  view.pushForeground('/manual/1');

  if (server.isReady())
  {
    server.log('/how.to.play drawer');
  }
}


export default (props) =>
{
  const [ view, server ] = useFlux(View, Server);

  const onClick = useCallback(OnClick, [ view, server ]);

  return <Item text = 'How To Play?' icon = 'play_circle_outline' onClick = {onClick} />
};
