/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Header from 'fierry/react/header';

import ResourceEnum from 'galax/enum/resource';

import CreateBuildingButton from 'galax/react/button/building/create';
import CreateUnitButton from 'galax/react/button/unit/create';

import BuildingActivateButton from 'galax/react/button/building/activate';
import BuildingAttackButton from 'galax/react/button/building/attack';
import BuildingDisbandButton from 'galax/react/button/building/disband';

import UnitAttackButton from 'galax/react/button/unit/attack';
import UnitDisbandButton from 'galax/react/button/unit/disband';


const getEntityHeadline = ({ currentZone }) =>
{
  if (currentZone.getBuilding())
  {
    return currentZone.getBuilding().getConstants().getName();
  }

  if (currentZone.getCass().getResource() != null)
  {
    return currentZone.getResourceName() + ' Resource ' + currentZone.getCass().getResource().deposit;
  }

  if (currentZone.getUnit())
  {
    return currentZone.getUnit().getConstants().getName();
  }

  return '';
}


const ResourceHeader = (props) =>
(
  <Header headline = {getEntityHeadline(props)} >
  </Header>
);


const BuildingHeader = (props) =>
(
  <Header headline = {getEntityHeadline(props)} >

    <BuildingActivateButton {...props} />
    <BuildingAttackButton {...props} />
    <BuildingDisbandButton {...props} />

  </Header>
);


const UnitHeader = (props) =>
(
  <Header headline = {getEntityHeadline(props)} >

    {/*<UnitAttackButton {...props} />*/}
    <UnitDisbandButton {...props} />

  </Header>
);


const ConstructionHeader = (props) =>
(
  <Header headline = {getEntityHeadline(props)} >

    <CreateBuildingButton {...props} />
    <CreateUnitButton {...props} />

  </Header>
)


const EmptyZoneHeader = (props) =>
(
  <Header headline = 'Empty' >

    <CreateBuildingButton {...props} />
    <CreateUnitButton {...props} />

  </Header>
);


export default (props) =>
{
  const { currentZone } = props;

  if (currentZone.getCass().getResource() != ResourceEnum.None)
  {
    return <ResourceHeader {...props} />;
  }

  if (currentZone.getBuilding() && !currentZone.getBuilding().getStatus().isConstruction())
  {
    return <BuildingHeader {...props} />;
  }

  if (currentZone.getUnit() && !currentZone.getUnit().getStatus().isProduction())
  {
    return <UnitHeader {...props} />;
  }

  if (currentZone.getBuilding() || currentZone.getUnit())
  {
    return <ConstructionHeader {...props} />;
  }

  return <EmptyZoneHeader {...props} />;
}
