/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import View from 'fierry/client/view';

import Icon from 'svg/shared/modern/production-stroke';


const OnClick = (event, view) =>
{
  view.pushForeground('/spaceship.create');
};


export default ({ currentDock, currentLocation, currentUser, ...props }) =>
{
  const [ view ] = useFlux(View);

  const onClick = useCallback(OnClick, [ view ]);

  if (!currentDock.isActive() || currentDock.getUser().getID() != currentUser.getID())
  {
    return null;
  }

  const spaceport = currentDock.getSpaceport();

  const disabled = !spaceport || !spaceport.getBuilding().getStatus().isDefault();
  const selected = currentLocation.getSpaceship();


  let tooltip = 'Create Spaceship';

  return <Modern.Button tooltip = {tooltip} icon = {<Icon />} contained = {selected} disabled = {disabled} onClick = {onClick} />;
}
