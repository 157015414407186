/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator/unit.create.list';


export default class extends Validator
{
  isRouteVisible ()
  {
    return super.isRouteVisible() && this.isUnitTypeFound();
  }


  isRouteDisabled ()
  {
    return super.isRouteDisabled();
  }


  isRouteActivated ()
  {
    return super.isRouteActivated();
  }


  isUnitTypeFound ()
  {
    return this.galaxy.getConstants().getUnit(this.args.type) != null;
  }


};
