/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';

import InteractionsToState from 'galax/fake/state/interactions.to';
import InteractionsFromState from 'galax/fake/state/interactions.from';

import ProjectilesToState from 'galax/fake/state/projectiles.to';
import ProjectilesFromState from 'galax/fake/state/projectiles.from';


export default (spaceship) =>
{
  let state = new Immutable.Map();

  state = state.set('currentSpaceship', spaceship.getCurrentSpaceship());

  state = state.set('interactionsTo', InteractionsToState(spaceship));
  state = state.set('interactionsFrom', InteractionsFromState(spaceship));

  state = state.set('projectilesTo', ProjectilesToState(spaceship));
  state = state.set('projectilesFrom', ProjectilesFromState(spaceship));

  return state;
}
