/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator';


export default class extends Validator
{
  isRouteVisible ()
  {
    return this.isZoneLocation() && this.isUserOwner() && this.isZoneNotCenterAndUnitExists();
  }


  isRouteDisabled ()
  {
    return !this.isUnitStatusDefault() && !this.isUnitStatusAttack();
  }


  isRouteActivated ()
  {
    return this.isUnitStatusAttack();
  }


  isZoneNotCenterAndUnitExists ()
  {
    const currentZone = this.currentLocation.getZone();

    return !currentZone.getCass().isCenter() && currentZone.getUnit() != null;
  }


  isUnitStatusAttack ()
  {
    const unit = this.currentLocation.getZone().getUnit();

    return unit && unit.getStatus().isAttack();
  }


  isUnitStatusDefault ()
  {
    const unit = this.currentLocation.getZone().getUnit();

    return unit && unit.getStatus().isDefault();
  }


};
