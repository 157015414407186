/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Header from 'fierry/react/header';

import DoneButton from 'shared/react/button/behavior.done';


export default (props) =>
(
  <Header headline = 'Create Transfer' >
    <DoneButton {...props} />
  </Header>
);
