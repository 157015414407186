/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


const Type =
{
  Sector : 0,
  Splash : 1,
  Single: 2,

  DoubleBasic: 3,

  TripleArea: 4,
  TripleLine: 5,
  TripleAngle: 6,

  QuadrupleArea: 7,
  QuadrupleStar: 8,
  QuadrupleLTail: 9,
  QuadrupleRTail: 10,

  QuintupleArea: 11,
  QuintupleStar: 12,
  QuintupleSplash: 13,

  SextupleArea: 14,
  SeptupleArea: 15,
};


export const getShapeToCount = (type) =>
{
  switch (type)
  {
    case Type.Single:          return 1;
    case Type.DoubleBasic:     return 2;

    case Type.TripleArea:      return 3;
    case Type.TripleLine:      return 3;
    case Type.TripleAngle:     return 3;

    case Type.QuadrupleArea:   return 4;
    case Type.QuadrupleStar:   return 4;
    case Type.QuadrupleLTail:  return 4;
    case Type.QuadrupleRTail:  return 4;

    case Type.QuintupleArea:   return 5;
    case Type.QuintupleStar:   return 5;
    case Type.QuintupleSplash: return 5;

    case Type.SextupleArea:    return 6;
    case Type.SeptupleArea:    return 7;
  }

  return 0;
};


export const getCountToShape = (count) =>
{
  if (count === 1)
  {
    return [ Type.Single ];
  }

  if (count === 2)
  {
    return [ Type.DoubleBasic ];
  }

  if (count === 3)
  {
    return [ Type.TripleArea, Type.TripleLine, Type.TripleAngle ];
  }

  if (count === 4)
  {
    return [ Type.QuadrupleArea, Type.QuadrupleStar, Type.QuadrupleLTail, Type.QuadrupleRTail ];
  }

  if (count === 5)
  {
    return [ Type.QuintupleArea, Type.QuintupleStar, Type.QuintupleSplash ];
  }

  if (count === 6)
  {
    return [ Type.SextupleArea ];
  }

  if (count === 7)
  {
    return [ Type.SeptupleArea ];
  }

  return [];
};


export default Type;
