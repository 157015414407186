/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Array from 'fierry/util/array';

import * as Coords from 'galax/geo/coords';
import * as Shapes from 'galax/geo/shapes';


// TODO maybe neighbors always operate with a local resolver? Because why would you need to calculate a neighbor to a spaceship?
// You may care if zone is a dock and has other zones but that is within single parent.
// But what if you start with spaceship location and then want to see if its a dock too?

export default class
{
  constructor (coords, resolver)
  {
    this.coords = coords;
    this.resolver = resolver;
  }


  getZones ()
  {
    return this.getLocations().map(location => location.getZone());
  }


  getDocks ()
  {
    return this.getLocations().map(location => location.getDock());
  }


  // TODO Theoretically could be benefitial here to also pass parent. But doing that will break getting spaceship as a neighbor!
  // Maybe pass a bool if we are supposed to search within a single parent? But then maybe you want to have a local resolver instead?!
  getLocations ()
  {
    return Coords.getNeighborsInOrder(this.coords).map(coords => this.resolver.getLocation(coords));
  }


  // TODO this is always supposed to work on a single parent! Want to keep parent when resolving?
  getShape (shape)
  {
    let collection = [];

    for (const coords of Shapes.getCoords(this.coords, shape))
    {
      collection.push(this.resolver.getLocation(coords));
    }

    return collection;
  }


  getSectors ()
  {
    let sectors = [];

    for (const neighbor of this.getZones())
    {
      if (neighbor != null)
      {
        sectors.push(neighbor.getSector());
      }
    }

    return Array.unique(sectors);
  }


}
