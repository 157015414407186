/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const getEmpty = () =>
{
  return '';
}


export const getAncient = () =>
{
  return '00000000-0000-0000-0000-000000000000';
}


export const getEmptyReference = () =>
{
  return { id: getEmpty(), strength: 0 };
}


export const getAncientReference = () =>
{
  return { id: getAncient(), strength: 100 };
}


export const getAncientTheme = () =>
{
  return 0;
}
