/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux }  from 'fierry/react';

import Behavior from 'galax/client/behavior';

import Target from 'galax/react/map/selection/target';


// TODO need to add observe interaction for weapon attack fire.
export default ({ currentLocation, ...props }) =>
{
  const [ behavior ] = useFlux(Behavior);

  if (currentLocation == null)
  {
    return null;
  }

  return behavior.getZoneTargets(currentLocation).map((location, _, targets) =>
  {
    return <Target {...props} key = {location.getID()} location = {location} targets = {targets} />
  });
}
