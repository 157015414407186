/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Invert from 'galax/react/map/util/invert';
import Fortified from 'galax/react/map/util/fortified';

import Resource from 'galax/react/icon/resource';


export default ({ zone, grid }) =>
{
  const type = zone.getCass().getResource().type;

  const size = grid.getGeneralHex().getEntitySize();

  return <Resource center size = {size} type = {type} />;
}
