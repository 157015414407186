/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 12 16" {...props}><defs/><rect width="12" height="16" className="none"/><line x1="8.5" y1="3" x2="3.5" y2="13" className="black-line-20"/><rect y="2" width="4" height="4" className="black-shape"/><rect x="8" y="10" width="4" height="4" className="black-shape"/></Svg>
  );
}
