/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react/styled';


export const List = styled.ul`
  list-style: disc;
  padding-left: 1rem;
`;


export const Item = styled.li`
  padding-left: 0.5rem;
`;


export const Link = styled.a`

  :link { color: var(--fierry-theme-foreground); }
  :visited { color: var(--fierry-theme-foreground); }
`;


export const Bold = styled.span`

  font-weight: bold;
`;
