/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><g><circle cx="16" cy="16" r="13" className="white-shape"/><g data-name="no-invert line-dash-a"><circle cx="16" cy="16" r="13" className="black-line-20"/></g></g><polyline points="9 17.5 17.5 9 23 14.5 14.5 23" className="black-line-10"/></Svg>
  );
}
