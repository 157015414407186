/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator/building.create.list';


export default class extends Validator
{
  isRouteVisible ()
  {
    return super.isRouteVisible() && this.isBuildingTypeFound();
  }


  isRouteDisabled ()
  {
    return super.isRouteDisabled();
  }


  isRouteActivated ()
  {
    return super.isRouteActivated();
  }


  isBuildingTypeFound ()
  {
    return this.galaxy.getConstants().getBuilding(this.args.type) != null;
  }


};
