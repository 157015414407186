/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Zone from 'galax/fake/builder/zone';

import * as User from 'galax/value/user';
import * as Energy from 'galax/value/energy';


export default class
{
  constructor (id, name, parent)
  {
    this.parent = parent;
    this.sector = { id, name };

    this.zones = [];
  }


  createZone (coords)
  {
    const zone = new Zone(coords, this);

    this.zones.push(zone);

    return zone;
  }


  assignUser (user)
  {
    this.sector.user = user.getID();

    for (const zone of this.zones)
    {
      zone.assignEnergy([ [ user, 100 ] ]);
    }

    this.parent.createLocalUser(user);
  }


  setGalaxy (galaxy)
  {
    this.sector.galaxy = galaxy;
  }


  setPlanet (planet)
  {
    this.sector.planet = planet;
  }


  setUpdateDate (date)
  {
    this.sector.update_date = date;

    for (const zone of this.zones)
    {
      zone.setUpdateDate(date);
    }
  }


  finalize ()
  {
    this.sector.zones = [];

    for (const zone of this.zones)
    {
      this.sector.zones.push(zone.getType());
    }

    this.sector.center = this.sector.zones[0].coords;
  }


  getZones ()
  {
    return this.zones;
  }


  getZone (index)
  {
    return this.zones[index];
  }


  getParent ()
  {
    return this.parent;
  }


  getType ()
  {
    return this.sector;
  }


  getID ()
  {
    return this.sector.id;
  }


  getUserID ()
  {
    return this.sector.user;
  }


  __getGalaxy__ ()
  {
    return this.parent.getParent();
  }


}
