/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (scope)
  {
    this.scope = scope;
  }


  eachZone (callback)
  {
    this.scope.getZones().eachZone(zone =>
    {
      callback(zone, this.getZone(zone));
    });
  }


  getTotal ()
  {
    let total = 0;

    this.scope.getZones().eachZone(zone =>
    {
      total += this.getZone(zone);
    });

    return total;
  }


  getZone (zone)
  {
    const helper = zone.getEnergy();

    return helper.getEnergy(helper.getCurrentOwner());
  }


};
