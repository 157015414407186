/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import BuildingInfo from 'galax/react/card/building/info'
import BuildingDescription from 'galax/react/card/building/description'

import Error from 'galax/react/card/shared/error';


const getBuildingType = ({ currentZone }) =>
{
  return currentZone.getBuilding().getType();
}


const getBuildingLevel = ({ currentZone }) =>
{
  return currentZone.getBuilding().getLevel() + 1;
}


const Component = (props) =>
(
  <>
    <BuildingInfo {...props} type = {getBuildingType(props)} level = {getBuildingLevel(props)} />
    <BuildingDescription type = {getBuildingType(props)} />

    <Error {...props} />
  </>
);


export default (props) =>
{
  const { currentZone } = props;

  return currentZone.getBuilding() ? <Component {...props} /> : null;
}
