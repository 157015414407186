/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import RouteEnum from 'galax/enum/route';

import { matchPath } from 'react-router';



export const parse = (value) =>
{
  let result = null;

  for (const type of Object.values(RouteEnum))
  {
    const match = matchPath(value, { path: type });

    if (match && (result == null || result.type.length < type.length))
    {
      result = { type, args: match.params };
    }
  }

  return result;
}


export default class
{
  constructor (value)
  {
    const { type, args } = parse(value);

    this.type = type;
    this.args = args;
    this.value = value;
  }


  getType ()
  {
    return this.type;
  }


  getArgs ()
  {
    return this.args;
  }


  getValue ()
  {
    return this.value;
  }


}



