/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import DynamicElement from 'fierry/rpc/dynamic-element';

import * as Key from 'galax/rpc/key';
import * as Translator from 'galax/rpc/translator';


export default class extends DynamicElement
{
  constructor ()
  {
    super();

    this.createRecord('currentUser', [ Translator.User ]);
    this.createTable('users', Key.ID, [ Translator.User ]);
  }
}
