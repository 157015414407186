/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Context from 'fierry/rpc'


export default (rpc) =>
{
  const location = rpc.getLocation();
  const hierarchy = rpc.getHierarchy();

  const context = React.useMemo(() => new Context({ location, hierarchy }), []);

  const [state, dispatch] = React.useReducer(context.onMessage, context.getHierarchy().getState());

  const effectCallback = () =>
  {
    context.setDispatch(dispatch);

    return () => context.dispose();
  };

  React.useEffect(effectCallback, []);

  return React.useMemo(() => [state, hierarchy], [state, hierarchy]);
}
