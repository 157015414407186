/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';

import Model from 'fierry/rpc/model';
import Notification from 'fierry/rpc/notification';

import Compare from 'fierry/util/compare';


export default class
{
  constructor (ctor)
  {
    this.__ctor__ = ctor;
    this.__attached__ = false;

    this.__elements__ = new Map();
    this.__notifications__ = new Map();

    this.__propagations__ = [];
  }


  dispose()
  {
    for (let element of this.__elements__.values())
    {
      element.dispose();
    }

    for (let notification of this.__notifications__.values())
    {
      notification.dispose();
    }

    this.__parent__ = null;
  }


  setParent (parent)
  {
    this.__parent__ = parent;
  }


  setRouting (routing)
  {
    this.__routing__ = routing;
  }


  setContext (context)
  {
    this.__context__ = context;
  }


  attach ()
  {
    if (this.__attached__)
    {
      return;
    }

    if (this.__parent__)
    {
      this.__parent__.onElementAttach(this.getName());
    }

    this.__attached__ = true;

    this.transitionToGlobal();
  }


  detach ()
  {
    if (!this.__attached__)
    {
      return;
    }

    for (let element of this.__elements__.values())
    {
      element.detach();

      element.setRouting(null);
      element.setContext(null);
    }

    for (let notification of this.__notifications__.values())
    {
      notification.detach();
    }

    this.__attached__ = false;

    this.transitionToLocal();

    if (this.__parent__)
    {
      this.__parent__.onElementDetach(this.getName());
    }
  }


  onElementAttach (name)
  {
    // no-op.
  }


  onElementDetach (name)
  {
    // no-op.
  }


  transitionToGlobal ()
  {
    if (this.isGlobal() || !this.isReady())
    {
      return;
    }

    if (this.__parent__)
    {
      this.__parent__.transitionToGlobal();

      if (!this.__parent__.isGlobal())
      {
        return;
      }
    }

    this.__global__ = true;

    if (this.__parent__)
    {
      this.setState(this.getLocalState());
      this.onTransitionToGlobal();
    }

    for (let element of this.__elements__.values())
    {
      element.transitionToGlobal();
    }
  }


  transitionToLocal ()
  {
    if (!this.isGlobal() || this.isReady())
    {
      return;
    }

    this.setState(null);
    this.onTransitionToLocal();

    this.__global__ = false;

    // It is illegal to detach table / record without detaching its parent first.
    // Otherwise sibling table / record would think it's global but its parent would not be...
  }


  onTransitionToGlobal ()
  {
    // no-op.
  }


  onTransitionToLocal ()
  {
    // no-op.
  }


  setState (state)
  {
    if (!this.isGlobal())
    {
      return;
    }

    this.__context__.getState().setIn(this.getRouting(), state);
  }


  getState ()
  {
    return this.isReady() ? this.__context__.getState().getIn(this.getRouting()) : null;
  }


  execute (action, args)
  {
    if (this.__context__ === undefined)
    {
      throw new Error('Cannot execute actions when context is not set.');
    }

    return this.__context__.getScheduler().schedule(this.__routing__, action, args);
  }


  createElement (name, element)
  {
    element.setParent(this);

    this.__elements__.set(name, element);

    return element;
  }


  getElement (name)
  {
    if (!this.__elements__.has(name))
    {
      throw new Error(`Element not found; routing: ${this.getRoutingString()}, name: ${name}.`);
    }

    return this.__elements__.get(name);
  }


  getElementCount ()
  {
    return this.__elements__.size;
  }


  createNotification (name, callback)
  {
    this.__notifications__.set(name, new Notification(callback));
  }


  getNotification (name)
  {
    if (!this.__notifications__.has(name))
    {
      throw new Error(`Notification not found; routing: ${this.getRoutingString()}, name: ${name}.`);
    }

    return this.__notifications__.get(name);
  }


  getNotificationCount ()
  {
    return this.__notifications__.size;
  }


  createPropagationTarget (routing)
  {
    if (this.__getPropagationIndex__(routing) === -1)
    {
      this.__propagations__.push(routing);
    }
  }


  destroyPropagationTarget (routing)
  {
    const index = this.__getPropagationIndex__(routing);

    if (index !== -1)
    {
      this.__propagations__.splice(index, 1);
    }
  }


  __getPropagationIndex__ (routing)
  {
    for (let i = 0; i < this.__propagations__.length; ++i)
    {
      if (Compare.shallow(routing, this.__propagations__[i]))
      {
        return i;
      }
    }

    return -1;
  }


  getPropagationTargets ()
  {
    return this.__propagations__;
  }


  getParent ()
  {
    return this.__parent__;
  }


  getName ()
  {
    return this.__routing__.length ? this.__routing__[this.__routing__.length - 1] : '';
  }


  getConstructor ()
  {
    return this.__ctor__;
  }


  getRouting ()
  {
    return this.__routing__;
  }


  getRoutingString ()
  {
    return '/' + this.__routing__.join('/');
  }


  getLocalState ()
  {
    return new Immutable.Map();
  }


  isReady ()
  {
    return this.__attached__;
  }


  isGlobal ()
  {
    return this.__global__;
  }


  isStateStable ()
  {
    return this.getConstructor() ? (this.getState() instanceof Model) : this.isReady();
  }


};
