/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import Server from 'galax/server';

import Icon from 'svg/shared/modern/delete-stroke';


const OnClick = (event, server) =>
{
  server.releaseUser();
};


export default ({ currentUser, ...props }) =>
{
  const [ server ] = useFlux(Server);

  const onClick = useCallback(OnClick, [ server ]);

  if (currentUser.getPlanetCount() === 0)
  {
    return null;
  }

  return <Modern.Button tooltip = {'Leave Game'} icon = {<Icon />} onClick = {onClick} />;
}
