/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */

import Client from 'fierry/flux/entity/client';

import State from 'galax/state/zoom';


export default new Client ([ State ], class
{
  constructor ([ state, dispatch ])
  {
    this.state = state;
    this.dispatch = dispatch;
  }


  zoomIn ()
  {
    this.dispatch(State.ZoomIn, { });
  }


  zoomOut ()
  {
    this.dispatch(State.ZoomOut, { });
  }


  getValue ()
  {
    return this.state;
  }


  isSectorVisible ()
  {
    return this.getValue() >= 1;
  }


  isEntityVisible ()
  {
    return this.getValue() >= 2;
  }


  isZoneVisible ()
  {
    return this.getValue() >= 3;
  }


});
