/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><path d="M5,3H19q0,6-4,9,4,3,4,9H5q0-6,4-9Q5,9,5,3" transform="translate(0 0)" className="black-line-20"/><path d="M8,18.5h8C15,15,13.5,14,12,14s-3,1-3.89,4.25" transform="translate(0 0)" className="black-shape"/></Svg>
  );
}
