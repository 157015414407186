/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { useFlux, withMemo } from 'fierry/react';

import Grid from 'galax/client/map/grid';

import * as Mask from 'galax/react/map/mask/stripe';


const Component = (props) =>
(
  <defs>
    <Mask.VerticalStripe {...props} />
    <Mask.HorizontalStripe {...props} />

    <Mask.DecreaseStripe {...props} />
    <Mask.IncreaseStripe {...props} />

    <Mask.IncreaseDashStripe {...props} />
  </defs>
);


export default withMemo((props) =>
{
  const [ grid ] = useFlux(Grid);

  return <Component {...props} grid = {grid} />;
});
