/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const isOffline = () =>
{
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.has('offline');
};
