/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import uuid from 'uuid/v4';

import GalaxySetup from 'galax/fake/galaxy.setup';

import InteractionEnum from 'galax/enum/interaction';
import ProjectileEnum from 'galax/enum/projectile';
import ShapeEnum from 'galax/enum/shape';

import SpaceshipEnum from 'galax/enum/spaceship';
import ResourceEnum from 'galax/enum/resource';
import BuildingEnum from 'galax/enum/building';
import UnitEnum from 'galax/enum/unit';

import * as User from 'galax/value/user';
import * as Energy from 'galax/value/energy';
import * as Influence from 'galax/value/influence';


export default () =>
{
  const galaxy = GalaxySetup();

  createPlanetOne(galaxy);
  createPlanetTwo(galaxy);
  createPlanetThree(galaxy);
  createPlanetFour(galaxy);
  createPlanetFive(galaxy);

  return galaxy;
}


const createPlanetOne = (galaxy) =>
{
  const planet = galaxy.createPlanet('ed2c6f02-eab5-4b5c-856e-c3029d89b5ce', [ -3,  -3 ]);

  planet.createSector([ [ 1, 2 ], [ 1, 0 ], [ 2, 0 ], [ 1, 1 ], [ 2, 1 ], [ 2, 2 ], [ 1, 3 ], [ 2, 3 ], [ 3, 3 ] ]);
  planet.createSector([ [ 3, 1 ], [ 3, 0 ], [ 4, 0 ], [ 4, 1 ], [ 5, 1 ], [ 3, 2 ], [ 4, 2 ] ]);
  planet.createSector([ [ 0, 4 ], [ 1, 4 ], [ 2, 4 ], [ 0, 5 ], [ 1, 5 ], [ 2, 5 ], [ 3, 5 ], [ 0, 6 ], [ 1, 6 ] ]);
  planet.createSector([ [ 4, 4 ], [ 4, 3 ], [ 5, 3 ], [ 3, 4 ], [ 5, 4 ], [ 4, 5 ], [ 5, 5 ], [ 3, 6 ], [ 4, 6 ] ]);
  planet.createSector([ [ 6, 2 ], [ 5, 2 ], [ 7, 2 ], [ 6, 3 ], [ 7, 3 ], [ 8, 3 ], [ 7, 4 ], [ 6, 1 ], [ 7, 1 ] ]);
  planet.createSector([ [ 1, 8 ], [ 2, 6 ], [ 1, 7 ], [ 2, 7 ], [ 3, 7 ], [ 0, 8 ], [ 2, 8 ], [ 1, 9 ], [ 2, 9 ] ]);
  planet.createSector([ [ 4, 9 ], [ 4, 7 ], [ 3, 8 ], [ 4, 8 ], [ 5, 8 ], [ 3, 9 ], [ 5, 9 ], [ 3,10 ], [ 4,10 ] ]);
  planet.createSector([ [ 6, 5 ], [ 6, 4 ], [ 7, 5 ], [ 5, 6 ], [ 6, 6 ], [ 5, 7 ], [ 6, 7 ] ]);
  planet.createSector([ [ 0,10 ], [ 1,10 ], [ 2,10 ], [ 1,11 ], [ 2,11 ], [ 0,12 ], [ 1,12 ] ]);

  planet.createResource([ 2, 1 ], ResourceEnum.Carbon);
  planet.createResource([ 4, 1 ], ResourceEnum.Silicon);
  planet.createResource([ 1, 5 ], ResourceEnum.Carbon);
  planet.createResource([ 6, 6 ], ResourceEnum.Silicon);
  planet.createResource([ 4, 8 ], ResourceEnum.Hydrogen);
  planet.createResource([ 7, 3 ], ResourceEnum.Carbon);
}


const createPlanetTwo = (galaxy) =>
{
  const planet = galaxy.createPlanet('28e8e917-dae6-4775-9a77-04305a2c7ec2', [ -10, -12]);

  planet.createSector([ [ 2, 3 ], [ 1, 3 ], [ 3, 3 ], [ 2, 4 ], [ 3, 4 ], [ 1, 5 ], [ 2, 5 ] ]);
  planet.createSector([ [ 5, 2 ], [ 5, 1 ], [ 6, 1 ], [ 6, 2 ], [ 7, 2 ], [ 4, 3 ], [ 5, 3 ], [ 6, 3 ], [ 5, 4 ] ]);
  planet.createSector([ [ 9, 3 ], [ 7, 1 ], [ 8, 1 ], [ 9, 1 ], [ 8, 2 ], [ 9, 2 ], [10, 2 ], [ 8, 3 ], [ 9, 4 ] ]);
  planet.createSector([ [ 3, 8 ], [ 1, 6 ], [ 2, 6 ], [ 1, 7 ], [ 2, 7 ], [ 1, 8 ], [ 2, 8 ] ]);
  planet.createSector([ [ 4, 6 ], [ 4, 4 ], [ 3, 5 ], [ 4, 5 ], [ 5, 5 ], [ 3, 6 ], [ 5, 6 ], [ 6, 6 ], [ 3, 7 ], [ 4, 7 ], [ 5, 7 ] ]);
  planet.createSector([ [ 8, 5 ], [ 7, 4 ], [ 6, 4 ], [ 8, 4 ], [ 6, 5 ], [ 7, 5 ], [ 7, 3 ] ]);
  planet.createSector([ [ 0, 9 ], [ 1, 9 ], [ 2, 9 ], [ 1,10 ], [ 2,10 ], [ 3,10 ], [ 1,11 ], [ 2,11 ], [ 2,12 ] ]);
  planet.createSector([ [ 4, 9 ], [ 4, 8 ], [ 5, 8 ], [ 3, 9 ], [ 5, 9 ], [ 4,10 ], [ 5,10 ] ]);
  planet.createSector([ [ 9, 5 ], [10, 6 ], [11, 6 ], [12, 6 ], [11, 5 ], [10, 5 ], [11, 4 ], [10, 4 ], [10, 3 ] ]);
  planet.createSector([ [12, 2 ], [12, 4 ], [11, 3 ], [12, 3 ], [13, 2 ], [11, 2 ], [10, 1 ], [11, 1 ], [12, 1 ] ]);
  planet.createSector([ [13, 7 ], [12, 5 ], [13, 6 ], [13, 4 ], [13, 5 ], [14, 6 ], [14, 4 ], [14, 5 ], [13, 3 ] ]);
  planet.createSector([ [ 3, 1 ], [ 2, 2 ], [ 2, 1 ], [ 4, 2 ], [ 4, 1 ], [ 3, 2 ], [ 4, 0 ], [ 5, 0 ], [ 3, 0 ] ]);

  planet.createResource([10, 5 ], ResourceEnum.Carbon);
  planet.createResource([ 8, 2 ], ResourceEnum.Silicon);
  planet.createResource([13, 4 ], ResourceEnum.Carbon);
  planet.createResource([ 4, 1 ], ResourceEnum.Silicon);
  planet.createResource([ 5, 6 ], ResourceEnum.Hydrogen);
  planet.createResource([ 2, 7 ], ResourceEnum.Carbon);
}


const createPlanetThree = (galaxy) =>
{
  const planet = galaxy.createPlanet('ee3cd912-069b-4b78-97b9-baecd79ddf92', [ 7, -4 ]);

  planet.createSector([ [ 7, 0 ], [ 8, 0 ], [ 6, 1 ], [ 7, 1 ], [ 8, 1 ], [ 6, 2 ], [ 7, 2 ], [ 8, 2 ], [ 7, 3 ] ]);
  planet.createSector([ [ 6, 4 ], [ 5, 2 ], [ 4, 3 ], [ 5, 3 ], [ 6, 3 ], [ 5, 4 ], [ 7, 4 ], [ 5, 5 ], [ 6, 5 ] ]);
  planet.createSector([ [ 9, 4 ], [ 8, 3 ], [ 9, 3 ], [10, 3 ], [ 8, 4 ], [10, 4 ], [11, 4 ], [ 8, 5 ], [ 9, 5 ] ]);
  planet.createSector([ [11, 6 ], [10, 5 ], [11, 5 ], [10, 6 ], [12, 6 ], [10, 7 ], [11, 7 ] ]);
  planet.createSector([ [ 3, 5 ], [ 4, 5 ], [ 2, 6 ], [ 3, 6 ], [ 4, 6 ], [ 2, 7 ], [ 3, 7 ], [ 3, 8 ], [ 4, 8 ] ]);
  planet.createSector([ [ 5, 6 ], [ 6, 6 ], [ 4, 7 ], [ 5, 7 ], [ 6, 7 ], [ 5, 8 ], [ 6, 8 ] ]);
  planet.createSector([ [ 7, 9 ], [ 7, 5 ], [ 7, 6 ], [ 8, 6 ], [ 9, 6 ], [ 7, 7 ], [ 8, 7 ], [ 7, 8 ], [ 8, 8 ] ]);
  planet.createSector([ [ 1, 9 ], [ 2, 9 ], [ 3, 9 ], [ 1,10 ], [ 2,10 ], [ 3,10 ], [ 0,11 ], [ 1,11 ], [ 2,11 ], [ 1,12 ], [ 2,12 ] ]);
  planet.createSector([ [ 5, 9 ], [ 4, 9 ], [ 6, 9 ], [ 4,10 ], [ 5,10 ], [ 6,10 ], [ 7,10 ], [ 5,11 ], [ 6,11 ] ]);

  planet.createResource([10, 5 ], ResourceEnum.Carbon);
  planet.createResource([ 8, 3 ], ResourceEnum.Silicon);
  planet.createResource([ 2, 7 ], ResourceEnum.Carbon);
  planet.createResource([ 5, 7 ], ResourceEnum.Silicon);
  planet.createResource([ 6,10 ], ResourceEnum.Hydrogen);
}


const createPlanetFour = (galaxy) =>
{
  const planet = galaxy.createPlanet('e74e58fe-381d-4fc5-ab58-14c3fdf3e12f', [ -15, 4 ]);

  planet.createSector([ [ 5, 2 ], [ 5, 0 ], [ 5, 1 ], [ 6, 0 ], [ 6, 1 ], [ 4, 0 ], [ 4, 1 ], [ 6, 2 ], [ 4, 2 ] ]);
  planet.createSector([ [ 3, 3 ], [ 3, 1 ], [ 3, 2 ], [ 2, 3 ], [ 2, 2 ], [ 3, 4 ], [ 2, 1 ] ]);
  planet.createSector([ [ 7, 1 ], [ 7, 2 ], [ 8, 2 ], [ 6, 3 ], [ 7, 3 ] ]);
  planet.createSector([ [ 5, 5 ], [ 4, 3 ], [ 5, 3 ], [ 4, 4 ], [ 5, 4 ], [ 6, 4 ], [ 4, 5 ], [ 7, 4 ], [ 6, 5 ], [ 6, 6 ], [ 5, 6 ] ]);
  planet.createSector([ [ 8, 3 ], [ 8, 4 ], [ 9, 4 ], [ 7, 5 ], [ 8, 5 ], [ 9, 5 ], [ 8, 6 ], [ 9, 6 ], [ 9, 3 ] ]);
  planet.createSector([ [ 8, 8 ], [ 7, 6 ], [ 6, 7 ], [ 7, 7 ], [ 7, 8 ], [ 8, 7 ], [ 7, 9 ] ]);
  planet.createSector([ [ 2, 4 ], [ 1, 4 ], [ 1, 5 ], [ 0, 5 ], [ 2, 5 ], [ 2, 6 ], [ 1, 6 ] ]);
  planet.createSector([ [ 3, 5 ], [ 3, 6 ], [ 4, 6 ], [ 2, 7 ], [ 3, 7 ], [ 4, 7 ], [ 3, 8 ] ]);
  planet.createSector([ [ 4, 9 ], [ 5, 7 ], [ 5, 8 ], [ 4, 8 ], [ 6, 8 ], [ 5, 9 ], [ 3, 9 ], [ 4, 10 ], [ 5, 10 ] ]);
  planet.createSector([ [ 7,10 ], [ 6, 9 ], [ 6,10 ], [ 8,10 ], [ 5,11 ], [ 6,11 ], [ 7,11 ] ]);

  planet.createResource([ 3, 2 ], ResourceEnum.Carbon);
  planet.createResource([ 5, 1 ], ResourceEnum.Silicon);
  planet.createResource([ 6,10 ], ResourceEnum.Carbon);
  planet.createResource([ 3, 9 ], ResourceEnum.Silicon);
  planet.createResource([ 2, 5 ], ResourceEnum.Silicon);
  planet.createResource([ 7, 7 ], ResourceEnum.Hydrogen);
}


const createPlanetFive = (galaxy) =>
{
  const planet = galaxy.createPlanet('77a451e7-1556-471a-a755-0ec020a39afb', [ 1, 10 ]);

  planet.createSector([ [ 2, 2 ], [ 1, 1 ], [ 2, 1 ], [ 1, 2 ], [ 3, 2 ], [ 2, 3 ], [ 1, 3 ], [ 3, 3 ], [ 2, 0 ] ]);
  planet.createSector([ [ 4, 3 ], [ 4, 4 ], [ 5, 4 ], [ 4, 5 ], [ 3, 5 ] ]);
  planet.createSector([ [ 3, 4 ], [ 2, 4 ], [ 1, 4 ], [ 0, 4 ], [ 1, 5 ], [ 2, 5 ], [ 0, 5 ], [ 1, 6 ], [ 2, 6 ], [ 3, 6 ], [ 1, 7 ] ]);
  planet.createSector([ [ 5, 5 ], [ 5, 6 ], [ 6, 6 ], [ 4, 6 ], [ 4, 7 ], [ 5, 7 ], [ 6, 7 ], [ 5, 8 ], [ 4, 8 ] ]);
  planet.createSector([ [ 3, 8 ], [ 2, 7 ], [ 3, 7 ], [ 2, 8 ], [ 3, 9 ], [ 2, 9 ], [ 1, 9 ] ]);

  planet.createResource([ 6, 6 ], ResourceEnum.Carbon);
  planet.createResource([ 2, 8 ], ResourceEnum.Hydrogen);
}
