/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ParentEnum from 'galax/enum/parent';

import makeStatus from 'galax/server/spaceship/status/make';

import * as Influence from 'galax/util/influence';

import InfluenceCollection from 'galax/util/influence/entity.collection';
import InteractionCollection from 'galax/server/interaction/collection';
import ProjectileCollection from 'galax/server/projectile/collection';

import Time from 'fierry/util/time';

import { getRelativeStrength } from 'galax/util/user.strength';


export default class
{
  static construct(id, { queries })
  {
    const spaceship = queries.getSpaceshipByID(id);

    if (!spaceship)
    {
      return null;
    }

    return { spaceship, queries };
  }


  static constructByZone (zone, { queries })
  {
    const spaceship = queries.getSpaceshipByZone(zone);

    if (!spaceship)
    {
      return null;
    }

    return { spaceship, queries };
  }
i

  constructor ({ queries, parent, ...props })
  {
    this.props = props;
    this.parent = parent;
    this.state = queries.getSpaceshipRpc(this.getID())

    this.status = makeStatus(this.props.spaceship.status, this);

    this.influences = new InfluenceCollection(this);

    this.interactions = new InteractionCollection(this.state);
    this.projectiles = new ProjectileCollection(this, this.state);

    this.__installAttrition__();
    this.interactions.installSpaceship(this);
  }


  __installAttrition__ ()
  {
    const source = this.getSourceCoords();

    this.installInfluence(Influence.makeAttritionInfluence(this.getType().location, this.getAttritionInfluence()));
  }


  getInteractions ()
  {
    return this.interactions;
  }


  getProjectiles ()
  {
    return this.projectiles;
  }


  installInfluence (influence)
  {
    if (influence.getUserID() && influence.getUserID() !== this.getUserID())
    {
      const ratio = getRelativeStrength(influence.getUser(), this.getUser().getReference());

      influence = Influence.makeFragmentInfluence(influence, ratio);
    }

    this.influences.push(influence);
  }


  getMinRange ()
  {
    return this.getSpaceshipConstants().getTargetMinRange();
  }


  getMaxRange ()
  {
    return this.getSpaceshipConstants().getTargetMaxRange();
  }


  getInfluence ()
  {
    return this.influences.getFriendlyInfluence() - this.influences.getHostileInfluence();
  }


  getAttritionInfluence ()
  {
    if (this.status.isProduction())
    {
      return 0;
    }

    if (this.status.isDock())
    {
      return this.getSpaceshipConstants().getAttrition();
    }

    return this.getSpaceshipConstants().getAttrition();
  }


  getMovement (date)
  {
    const { movement, update_date } = this.props.spaceship;

    if (date == null)
    {
      date = update_date;
    }

    const milliseconds = date - update_date;
    const increase = milliseconds / Time.getHourTicks() * this.getSpaceshipConstants().getMovement();

    return Math.min(movement + increase, this.getSpaceshipConstants().getMovement());
  }


  getEnergy (date)
  {
    const { energy, update_date } = this.props.spaceship;

    if (date == null)
    {
      date = update_date;
    }

    return Math.min(energy + (date - update_date) * this.getInfluence(), this.getSpaceshipConstants().getMaxEnergy());
  }


  isCriticalEnergy (date)
  {
    return this.getEnergy(date) <= this.getSpaceshipConstants().getMinEnergy();
  }


  getTimeToEmpty (date)
  {
    if (this.getInfluence() >= 0)
    {
      return 0;
    }

    return Math.ceil(this.getEnergy(date) / -this.getInfluence());
  }


  getTimeToFull (date)
  {
    if (this.getInfluence() <= 0 || this.getEnergy() >= this.getSpaceshipConstants().getMaxEnergy())
    {
      return 0;
    }

    return Math.ceil((this.getSpaceshipConstants().getMaxEnergy() - this.getEnergy(date)) / this.getInfluence());
  }


  getName ()
  {
    return this.getSpaceshipConstants().getName();
  }


  getType ()
  {
    return this.props.spaceship.type;
  }


  getCass ()
  {
    return this.props.spaceship;
  }


  getStatus ()
  {
    return this.status;
  }


  getSource ()
  {
    return this.parent.getLocation(this.getSourceCoords());
  }


  getSourceCoords ()
  {
    return this.props.spaceship.source;
  }


  hasTarget ()
  {
    if (this.status.isAttack())
    {
      return this.getInteractions().getInteractionsFrom(this.props.spaceship.source).length !== 0;
    }

    return this.status.isMove() && this.getTargetCoords();
  }


  getTarget ()
  {
    if (this.getTargetID() && !this.getTargetCoords())
    {
      return this.parent.getSpaceship(this.getTargetID()).getLocation();
    }

    return this.parent.getLocation(this.getTargetCoords());
  }


  getInteractionsTo ()
  {
    return this.getInteractions().getInteractionsTo(this.getSourceCoords());
  }


  getInteractionsFrom ()
  {
    return this.getInteractions().getInteractionsFrom(this.getSourceCoords());
  }


  getProjectilesTo ()
  {
    return this.getProjectiles().getProjectilesTo(this.getSourceCoords());
  }


  getProjectilesFrom ()
  {
    return this.getProjectiles().getProjectilesFrom(this.getSourceCoords());
  }


  // TODO useInteractions
  getTargetID ()
  {
    const { spaceship } = this.props;

    return spaceship.target && spaceship.target.parent ? spaceship.target.parent.id : '';
  }


  // TODO useInteractions
  getTargetCoords ()
  {
    const { spaceship } = this.props;

    return spaceship.target ? spaceship.target.id : '';
  }


  getLocation (resolver = this.parent)
  {
    return resolver.getLocation(this.getSourceCoords());
  }


  getUserID ()
  {
    return this.props.spaceship.user;
  }


  getID ()
  {
    return this.props.spaceship.id;
  }


  getSourceCoords ()
  {
    return this.props.spaceship.source;
  }


  getUpdateDate ()
  {
    return this.props.spaceship.update_date;
  }


  getUser (resolver = this.parent)
  {
    return resolver.getUser(this.getUserID());
  }


  getConstants ()
  {
    return this.parent.getConstants();
  }


  getSpaceshipConstants ()
  {
    return this.getConstants().getSpaceship(this.getType());
  }


  getGalaxy ()
  {
    return this.parent;
  }


};
