/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, css } from 'fierry/react/styled';


const defaultState = (props) => css`
  --fierry-element-foreground: var(--fierry-theme-foreground);
  --fierry-element-opacity: var(--fierry-theme-opacity, var(--fierry-high-opacity));
`;


const emphasisState = ({ emphasis }) => emphasis && css`
  --fierry-element-opacity: var(--fierry-medium-opacity);
`;


const disabledState = ({ disabled }) => disabled && css`
  --fierry-element-opacity: var(--fierry-disabled-opacity);
`;


const errorState = ({ error, disabled }) => error && !disabled && css`
  --fierry-element-foreground: var(--fierry-theme-error-foreground);
  --fierry-element-opacity: var(--fierry-full-opacity);
`;


const primaryState = ({ primary, disabled }) => primary && !disabled && css`
  --fierry-element-foreground: var(--fierry-theme-primary-foreground);
  --fierry-element-opacity: var(--fierry-full-opacity);
`;


const secondaryState = ({ secondary, disabled }) => secondary && !disabled && css`
  --fierry-element-foreground: var(--fierry-theme-secondary-foreground);
  --fierry-element-opacity: var(--fierry-full-opacity);
`;


export default styled.div`

  ${defaultState}
  ${emphasisState}
  ${disabledState}

  ${errorState}
  ${primaryState}
  ${secondaryState}
`;
