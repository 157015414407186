/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Hex from 'galax/geo/hex';
import * as Points from 'galax/geo/points';


export default class
{
  constructor (location, parent)
  {
    this.location = location;
    this.parent = parent;

    this.neighbors = this.location.getNeighbors().getLocations();

    this.zonePoints = this.parent.getGeneralHex().getZonePoints(this.location, this.neighbors);
    this.dockPoints = this.parent.getGeneralHex().getDockPoints(this.location, this.neighbors)
    this.platformPoints = this.parent.getGeneralHex().getPlatformPoints(this.location, this.neighbors)
  }


  getCenterTransform (local)
  {
    const { x, y } = this.getAbsolute(this.getCenter(local));

    return `translate(${x},${y})`;
  }


  getTransform ()
  {
    const x = this.location.getCoords().getX();
    const y = this.location.getCoords().getY();

    const xDistance = this.parent.getGeneralHex().getHorizontalDistance();
    const yDistance = this.parent.getGeneralHex().getVerticalDistance();

    return { x: x * xDistance + (y % 2 ? xDistance / 2 : 0), y: y * yDistance };
  }


  getAbsolute (point = { x: 0, y: 0 })
  {
    const transform = this.getTransform();

    return { x: point.x + transform.x, y: point.y + transform.y };
  }


  getRelative (point)
  {
    const transform = this.getTransform();

    return { x: point.x - transform.x, y: point.y - transform.y };
  }


  getPointsFromBorder (offset)
  {
    return this.getBorders().map( ({ pointA, pointB }) =>
    {
      const point = Points.scale(Points.add(pointA, pointB), 0.5);

      return this.getAbsolute(Points.getPointWithOffset(this.getCenter(true), point, offset));
    });
  }


  getPointsFromVertex (offset)
  {
    return this.getZonePoints().map( (point) =>
    {
      return this.getAbsolute(Points.getPointWithOffset(this.getCenter(true), point, offset));
    });
  }


  getZonePoints ()
  {
    return this.zonePoints;
  }


  getDockPoints ()
  {
    return this.dockPoints;
  }


  getPlatformPoints ()
  {
    return this.platformPoints;
  }


  getNeighbors ()
  {
    return this.neighbors;
  }


  getBorders ()
  {
    return Hex.getBorders(this.zonePoints);
  }


  getCenter (local)
  {
    return local ? Hex.getCenter(this.zonePoints) : { x: 0, y: 0 };
  }


  getSize ()
  {
    return this.parent.getGeneralHex();
  }


  getGeneral ()
  {
    return this.parent.getGeneral();
  }


  getZoom ()
  {
    return this.parent.getZoom();
  }


};
