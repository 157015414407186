/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Platform from 'galax/react/card/platform.overview';
import Unit from 'galax/react/card/unit/overview';


export default (props) =>
(
  <>
    <Platform {...props}/>
    <Unit {...props} />
  </>
);
