/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, css } from 'fierry/react';

import Center from 'galax/react/map/zone/center';
import Background from 'galax/react/map/platform/background';
import Border from 'galax/react/map/platform/border';


export default (props) =>
(
  <Center {...props} >

    <Background {...props} />
    <Border {...props} />

  </Center>
);
