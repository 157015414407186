/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';


export default (buildings) =>
{
  let state = new Immutable.Map();

  for (const building of buildings)
  {
    state = state.set(building.type, building);
  }

  return state;

}
