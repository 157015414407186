/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import extract from 'fierry/flux/dependency/extract';


export default (entity, props) =>
{
  const dependences = extract(entity.getDependences(), props);

  return React.useMemo(() => entity.getDefinition()(...dependences), dependences);
}
