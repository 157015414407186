/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Firebase from 'firebase/app';

import Client from 'fierry/flux/entity/client';
import State from 'fierry/state/auth';


export default new Client ([ State ], class
{
  constructor ([ state, dispatch ])
  {
    this.state = state;
    this.dispatch = dispatch;
  }


  logout ()
  {
    this.dispatch(State.Query, { });

    Firebase.auth().signOut();
  }


  finalize ()
  {
    this.dispatch(State.Finalize, { });
  }


  isLoggedIn ()
  {
    const { login, query } = this.state;

    return login && !query;
  }


  isLoggedOut ()
  {
    const { login, query } = this.state;

    return !login && !query;
  }


  isQueryComplete ()
  {
    return !this.state.query;
  }


  getToken ()
  {
    return this.state.token;
  }


  getPrevToken ()
  {
    return this.state.prevToken;
  }


});
