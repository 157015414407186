/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import EmptyBuilding from 'svg/galax/building/empty';


export default ({ grid }) =>
{
  const size = grid.getGeneralHex().getEntitySize();

  return <EmptyBuilding center size = {size} />;
}
