/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Header from 'fierry/react/header';

import UserInitiate from 'galax/react/button/user/initiate';
import UserRelease from 'galax/react/button/user/release';


export default (props) =>
(
  <Header {...props} headline = 'Dashboard'>

    <UserInitiate {...props} />
    <UserRelease {...props} />

  </Header>
);
