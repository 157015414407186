/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><g><circle cx="16" cy="16" r="13" className="white-shape"/><g data-name="no-invert line-dash-a"><circle cx="16" cy="16" r="13" className="black-line-20"/></g></g><path d="M16,8c.78,2.95,3.87,8.56,8,8-4.13.56-7.13,5.06-8,8,.84-2.82-3.87-8.56-8-8C12.13,15.44,16.78,11,16,8Z" className="black-line-10"/></Svg>
  );
}
