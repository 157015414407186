/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import View from 'fierry/client/view';

import Server from 'galax/server';

import Icon from 'svg/shared/modern/production-stroke';


const OnClick = (event, spaceship, server) =>
{
  if (spaceship.getStatus().isProduction())
  {
    server.cancelSpaceshipAction(spaceship);
  }
};


export default ({ currentLocation, ...props }) =>
{
  const [ server, view ] = useFlux(Server, View);

  const spaceship = currentLocation.getSpaceship();

  const selected = spaceship.getStatus().isProduction();

  const onClick = useCallback(OnClick, [ spaceship, server ]);

  if (!spaceship.getUser().isCurrentUser() || !spaceship.getStatus().isProduction())
  {
    return null;
  }

  return <Modern.Button tooltip = 'Cancel Production' icon = {<Icon />} contained = {selected} onClick = {onClick} />;
}
