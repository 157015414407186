/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern } from 'fierry/react';

import Projectiles from 'galax/react/card/zone.projectile.list/projectiles';


const Component = (props) =>
(
  <Card {...props} headline = 'Projectiles' >
    <Projectiles {...props} />
  </Card>
);


export default (props) =>
{
  const { currentLocation } = props;

  const projectilesTo = currentLocation.getProjectilesTo();
  const projectilesFrom = currentLocation.getProjectilesFrom();

  if (!projectilesTo.length && !projectilesFrom.length)
  {
    return null;
  }

  return <Component {...props} projectilesTo = {projectilesTo} projectilesFrom = {projectilesFrom} />;
}
