/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


const getDiagonal = ({ index }) =>
{
  return index !== 1 && index !== 4;
}


const getNeighbors = ({ location, grid }) =>
{
  return grid.getLocalHex(location).getNeighbors();
}


const getTransform = (props) =>
{
  return getRotate(props) + ' ' + getTranslate(props);
}


const getRotate = ({ index }) =>
{
  const rotate = ((index + 2) % 6) * 60;

  return `rotate(${rotate})`;;
}


const getTranslate = ({ grid, ...props }) =>
{
  const hex = grid.getGeneralHex();

  const userOffset = (hex.getExternalWidth() - hex.getInternalWidth()) / 2 + hex.getUserBorderWidth() / 2;
  const sectorOffset = hex.getSectorBorderWidth() / 2

  return isExternalBorder(props) ? `translate(${userOffset}, 0)` : `translate(${sectorOffset}, 0)`;
}


const isExternalBorder = ({ location, index, neighbors }) =>
{
  return !location.getZone() || !neighbors[index].getZone() || location.getZone().getUserID() !== neighbors[index].getZone().getUserID();
}


const Component = ({ Component, ...props }) =>
(
  <g transform = {getTransform(props)} >
    <Component {...props} />
  </g>
);


export default (props) =>
{
  return <Component {...props} neighbors = {getNeighbors(props)} diagonal = {getDiagonal(props)} />;
}
