/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useState, useLayoutEffect, withMemo } from 'fierry/react';


export default withMemo( ({ canDispatch, executeDispatch, ...props }) =>
{
  const [ready, setReady] = useState(true);

  useLayoutEffect(() =>
  {
    if (ready && canDispatch(props))
    {
      executeDispatch(props);

      setReady(false);
    }
  });

  return null;
});
