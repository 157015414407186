/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Rpc from 'fierry/flux/entity/rpc';

import Session from 'galax/rpc/session';


export default new Rpc('galax', hierarchy =>
{
  hierarchy.createElement('galaxy', new Session());
});
