/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


let sequence = 0;


export default class Command
{
  constructor (routing, command, json, socket)
  {
    this.routing = routing;
    this.command = command;
    this.json = json;
    this.socket = socket;

    this.id = ++sequence;

    this.on_complete = () => {};
    this.dependency = undefined;
  }


  on_schedule (parent, rpc)
  {
    this.rpc = rpc;

    this.parent = parent;

    if (this.parent)
    {
      this.parent.set_dependency(this);
    }
  }


  execute ()
  {
    this.socket.executeCommand(this.id, this.routing, this.command, this.json);

    return false;
  }


  complete (success, data)
  {
    if (this.parent)
    {
      this.parent.remove_dependency();
    }

    this.on_complete(success, data);
  }


  set_dependency (dependency)
  {
    this.dependency = dependency;
  }


  remove_dependency ()
  {
    this.dependency = undefined;
  }


  has_dependency ()
  {
    return this.dependency !== undefined;
  }


  get_id ()
  {
    return this.id;
  }


};
