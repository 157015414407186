/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { styled, useFlux } from 'fierry/react';

import BorderGroup from 'galax/react/map/zone/border.group';
import Center from 'galax/react/map/zone/center';


const getTargetSelectionStroke = ({ grid }) =>
{
  return grid.getGeneralHex().getExternalSize(0.05);
}


const getTargetSelectionLength = ({ grid }) =>
{
  return grid.getGeneralHex().getExternalSize(0.075);
}


const getTargetSelectionOffset = ({ grid }) =>
{
  return grid.getGeneralHex().getExternalWidth(-0.45);
}


const Line = styled.line`
  stroke: var(--svg-always-other);

  stroke-linecap: round;
  stroke-width: ${props => getTargetSelectionStroke(props)};
`;


const getLineProps = (props) =>
{
  const length = getTargetSelectionLength(props);
  const offset = getTargetSelectionOffset(props);

  return { x1: offset, y1: 0, x2: offset + length, y2: 0 };
}


const SelectionLine = ({ index, neighbors, targets, ...props }) =>
{
  const predicate = (target) =>
  {
    return neighbors[index] && target.getCoords().toString() === neighbors[index].getID();
  };

  if (targets.findIndex(predicate) !== -1)
  {
    return null;
  }

  return <Line {...props} {...getLineProps(props)} />;
}


export default (props) =>
(
  <Center {...props} >
    <BorderGroup {...props} Component = {SelectionLine} />
  </Center>
);
