/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, Element, withValue } from 'fierry/react';

import ResourceEnum from 'galax/enum/resource';

import CarbonIcon from 'svg/galax/resource/carbon';
import SiliconIcon from 'svg/galax/resource/silicon';
import HydrogenIcon from 'svg/galax/resource/hydrogen';
import CurrencyIcon from 'svg/galax/resource/currency';



const getValue = ({ value, ...props }) =>
{
  return withValue(value, props);
}


const isSufficient = ({ isSufficientValue, ...props }) =>
{
  return isSufficientValue ? withValue(isSufficientValue, props) : true;
}


const isError = (props) =>
{
  return !isSufficient(props);
}


const getIcon = ({ type, icon, ...props }) =>
{
  if (icon)
  {
    return withValue(icon, props);
  }

  switch (type)
  {
    case ResourceEnum.Carbon:   return <CarbonIcon />;
    case ResourceEnum.Silicon:  return <SiliconIcon />;
    case ResourceEnum.Hydrogen: return <HydrogenIcon />;
    case ResourceEnum.Currency: return <CurrencyIcon />;
  }

  return null;
}


const getName = ({ type, name, ...props }) =>
{
  switch (type)
  {
    case ResourceEnum.Carbon:   return 'Carbon';
    case ResourceEnum.Silicon:  return 'Silicon';
    case ResourceEnum.Hydrogen: return 'Hydrogen';
    case ResourceEnum.Currency: return 'Currency';
  }

  return name;
}


const getTooltip = ({ tooltip, ...props }) =>
{
  if (tooltip)
  {
    return withValue(tooltip, props);
  }

  const name = getName(props);

  return isSufficient(props) ? `${name} Resource` : `Insufficient ${name}`;
}


export default (props) => // { type, value, isSufficientValue, icon, name, tooltip }
(
  <Element.Group param width = '3' {...props} error = {isError(props)} tooltip = {getTooltip(props)} >

    <Element.Icon {...props} value = {getIcon(props)} />
    <Element.Text {...props} value = {getValue(props)} />

  </Element.Group>
);
