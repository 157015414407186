/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { memo, useRef, useState } from 'react';

import Store from 'fierry/flux/entity/store'
import Contexts from 'fierry/flux/contexts'


const Export = ({ dependency, value, children }) =>
{
  const Context = Contexts.get(dependency);

  return <Context.Provider value = {value} children = {children} />;
}


const ExportStore = ({ dependency, value: [ state, dispatcher ], children }) =>
(
  <Export dependency = {dependency.getDispatchContext()} value = {dispatcher} >
    <Export dependency = {dependency} value = {state} children = {children} />
  </Export>
);


export default memo( ({ dependency, ...props }) =>
{
  const entity = dependency.getEntity();

  if (!entity.isExportable())
  {
    return props.children;
  }

  // TODO ideally we would have two entities from each store - Store.State (default) & Store.Dispatch.
  return entity instanceof Store ? <ExportStore {...props} dependency = {entity} /> : <Export {...props} dependency = {entity} />;
});
