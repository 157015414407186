/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import makeStatus from 'galax/server/unit/status/make';

import InfluenceCollection from 'galax/util/influence/entity.collection';

import { makeAttritionInfluence, makeFragmentInfluence } from 'galax/util/influence';
import { getRelativeStrength } from 'galax/util/user.strength';


export default class
{
  constructor (unit, planet)
  {
    this.unit = unit;
    this.planet = planet;

    this.status = makeStatus(this.unit.status, this);
  }


  install ()
  {
    this.status.install();
  }


  getEnergy ()
  {
    return this.unit.args.health;
  }


  getZone ()
  {
    return this.planet.getZoneCollection().getZone(this.unit.source);
  }


  getUser ()
  {
    return this.planet.getUser(this.getUserID());
  }


  getCass ()
  {
    return this.unit;
  }


  getType ()
  {
    return this.unit.variant;
  }


  getConstants ()
  {
    return this.planet.getConstants().getUnit(this.getCass().variant);
  }


  getLocation (resolver)
  {
    return this.getZone().getLocation(resolver);
  }


  getUserID ()
  {
    return this.getZone().getUserID();
  }


  getStatus ()
  {
    return this.status;
  }


  hasTarget ()
  {
    for (const interaction of this.planet.getInteractions().getInteractionsFrom(this.unit.source))
    {
      if (interaction.isAggressive())
      {
        return true;
      }
    }

    return false;
  }


  // Unverified API


  getUpdateDate ()
  {
    return this.unit.update_date;
  }

}
