/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Element, Modern, useFlux } from 'fierry/react';

import Server from 'galax/server';

import UnitParams from 'galax/react/group/unit/params';

import ClockElement from 'galax/react/element/clock';
import EnergyElement from 'galax/react/element/energy';

import CurrencyIcon from 'svg/galax/resource/currency';
import UnitIcon from 'galax/react/icon/unit';


const getIcon = ({ constants, ...props }) =>
{
  return <UnitIcon type = {constants.getType()} />;
}


const getName = ({ constants }) =>
{
  return constants.getName();
}


const getCurrencyCost = ({ constants }) =>
{
  return constants.getConstructionCost();
}


const getTime = ({ currentZone, constants }) =>
{
  const efficiency = currentZone ? currentZone.getAttributes().getUnitCreateEfficiency() : 1;

  return constants.getConstructionTime() * efficiency;
}


const Headline = (props) =>
(
  <Modern.Text headline icon = {getIcon(props)} text = {getName(props)} />
);


const Subline = (props) =>
(
  <Modern.Group width = '9' >
    <Modern.Param icon = {<CurrencyIcon />} text = {getCurrencyCost(props)} tooltip = 'Currency Cost' />
    <ClockElement {...props} value = {getTime} tooltip = 'Production Time' />
  </Modern.Group>
)

const Component = (props) =>
(
  <Card {...props} headline = {Headline} subline = {Subline} >
    <UnitParams {...props} />
  </Card>
);


export default (props) =>
{
  const { galaxy, type, currentArgs } = props;

  return <Component {...props} constants = {galaxy.getConstants().getUnit(type || currentArgs.type)} />;
}
