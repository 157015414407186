/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ParentEnum from 'galax/enum/parent';

import Direction from 'galax/server/location/direction';
import Neighbors from 'galax/server/location/neighbors';

import * as Coords from 'galax/geo/coords';


export const makeLocation = (maybeLocation, resolver) =>
{
  const location = (maybeLocation instanceof Coords.Axial) ? { id: maybeLocation, parent: null } : maybeLocation;

  return new Location(location, resolver);
}


export default class Location
{
  constructor (location, resolver)
  {
    this.location = location;
    this.resolver = resolver;

    this.neighbors = new Neighbors(this.getCoords(), resolver);
  }


  getZone ()
  {
    return this.resolver.getZone(this.getCoords(), this.location.parent);
  }


  getDock ()
  {
    if (this.__isSpaceshipParent__())
    {
      return this.resolver.getDock(this.getCoords(), null);
    }

    return this.resolver.getDock(this.getCoords(), this.location.parent);
  }


  getPlatform ()
  {
    if (this.__isSpaceshipParent__())
    {
      return this.resolver.getPlatform(this.getCoords(), null);
    }

    return this.resolver.getPlatform(this.getCoords(), this.location.parent);
  }


  getSpaceship ()
  {
    if (this.__isPlanetParent__())
    {
      return this.resolver.getSpaceshipByLocation(this.getCoords(), null);
    }

    return this.resolver.getSpaceshipByLocation(this.getCoords(), this.location.parent);
  }


  __isPlanetParent__ ()
  {
    const parent = this.location.parent;

    return parent && parent.type === ParentEnum.Planet;
  }


  __isSpaceshipParent__ ()
  {
    const parent = this.location.parent;

    return parent && parent.type === ParentEnum.Spaceship;
  }


  getObject ()
  {
    const spaceship = this.getSpaceship();

    if (spaceship)
    {
      return spaceship;
    }

    const zone = this.getZone();

    if (zone)
    {
      return zone;
    }

    const platform = this.getPlatform();

    if (platform)
    {
      return platform;
    }

    const dock = this.getDock();

    if (dock)
    {
      return dock;
    }

    return null;
  }


  getUser ()
  {
    const object = this.getObject();

    // TODO should we return '' as empty user or maybe null is better here?
    return object ? object.getUser(this.resolver) : this.resolver.getUser('');
  }


  getInteractionsTo ()
  {
    const object = this.getObject();

    return object ? this.__upcastVectorToDirection__(object.getInteractionsTo()) : [];
  }


  getInteractionsFrom ()
  {
    const object = this.getObject();

    return object ? this.__upcastVectorToDirection__(object.getInteractionsFrom()) : [];
  }


  getProjectilesTo ()
  {
    const object = this.getObject();

    return object ? this.__upcastVectorToDirection__(object.getProjectilesTo()) : [];
  }


  getProjectilesFrom ()
  {
    const object = this.getObject();

    return object ? this.__upcastVectorToDirection__(object.getProjectilesFrom()) : [];
  }


  __upcastVectorToDirection__ (objects)
  {
    let collection = [];

    for (const object of objects)
    {
      collection.push(new Direction(object, this.resolver));
    }

    return collection;
  }


  getCass ()
  {
    if (this.getSpaceship())
    {
      return this.getSpaceship().getCass().location;
    }

    if (this.getZone())
    {
      return this.getZone().getCass().getLocation();
    }

    if (this.getPlatform())
    {
      return this.getPlatform().getCass().location;
    }

    return { id: this.getCoords().toString(), parent: null };
  }


  isEmpty ()
  {
    return this.getObject() == null;
  }


  isLocal ()
  {
    return this.resolver.isLocal();
  }


  getNeighbors ()
  {
    return this.neighbors;
  }


  getCoords ()
  {
    return this.location.id;
  }


  getResolver ()
  {
    return this.resolver;
  }


  valueOf ()
  {
    return this.getCoords().toString();
  }


  getID ()
  {
    return this.getCoords().toString();
  }


  getParentID ()
  {
    return this.getCass().parent.id;
  }


  isEqual (other)
  {
    return this.getCoords().isEqual(other.getCoords());
  }


}
