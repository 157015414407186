/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Action, Store } from 'fierry/flux';

import Auth from 'fierry/state/auth';
import Navigation from 'galax/state/navigation';


const State = { planet: null, id: null, count: 1 };


const Actions =
{
  SetSelection:   new Action(),
  ClearSelection: new Action(),
};


const setSelection = (state, { planet, id }) =>
{
  if (planet === state.planet && id === state.id)
  {
    return { ...state, count: state.count + 1 };
  }

  return { planet, id, count: 1 };
};


const clearSelection = (state, action) =>
{
  if (state.planet == null && state.id == null)
  {
    return state;
  }

  return { planet: null, id: null, count: 0 };
};


export default new Store (State, Actions,
[
  [ Actions.SetSelection,   setSelection ],
  [ Actions.ClearSelection, clearSelection ],

  [ Auth.Query,             clearSelection ],
  [ Auth.Login,             clearSelection ],
  [ Auth.Logout,            clearSelection ],

  [ Navigation.Set,         clearSelection ],
  [ Navigation.Clear,       clearSelection ],
]);
