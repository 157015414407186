/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Client } from 'fierry/flux';

import Now from 'galax/query/now';
import Galaxy from 'galax/server';
import Selection from 'galax/client/selection';
import Navigation from 'galax/client/navigation';
import CurrentLocation from 'galax/query/current.location';

import * as Coords from 'galax/geo/coords';


export default new Client([ Navigation, Selection, Galaxy, Now ], class
{
  constructor (navigation, selection, galaxy, now)
  {
    this.galaxy = galaxy;
    this.now = now;

    this.source = navigation.getLocation();
    this.target = selection.getLocation();
  }


  onDone ()
  {
    this.galaxy.moveSpaceship(this.source.getSpaceship(), this.target);
  }


  getError ()
  {
    if (this.source.isEqual(this.target))
    {
      return 'Spaceship cannot move to zone it already occupies.';
    }

    if (this.target.getZone())
    {
      return 'Spaceship cannot move above the planet.';
    }

    if (this.target.getSpaceship())
    {
      return 'Target is already occupied.';
    }

    const distance  = Coords.getDistance(this.source.getCoords(), this.target.getCoords());

    if (distance > this.source.getSpaceship().getMovement(this.now))
    {
      return 'Target is out of range.';
    }

    /* // Query to find a spaceship that has zone as target_zone and has move action. ?
    if (this.target.getResolver().getSpaceshipByMoveTarget())
    {
      return 'Target is blocked by another spaceship.';
    }*/

    return null;
  }


  getInitial ()
  {
    if (this.target == null)
    {
      return 'Select target zone';
    }

    return null;
  }


  canSelect ()
  {
    return true;
  }


  canSelectGalaxy ()
  {
    return true;
  }


  canNavigate ()
  {
    return false;
  }


  isPaneCollapsed ()
  {
    return true;
  }


});
