/*
 * Copyright (C) 2019 Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor ()
  {
    this.collection = [];
  }


  push (source)
  {
    this.collection.push(source);
  }


  getMaxAttribute (user, type)
  {
    let maxAttribute = null;

    for (const attribute of this.collection)
    {
      if (!attribute.isSatisfiedBy(user, type))
      {
        continue;
      }

      if (maxAttribute === null || attribute.getValue() > maxAttribute.getValue())
      {
        maxAttribute = attribute;
      }
    }

    return maxAttribute;
  }


  getMinAttribute (user, type)
  {
    let minAttribute = null;

    for (const attribute of this.collection)
    {
      if (!attribute.isSatisfiedBy(user, type))
      {
        continue;
      }

      if (minAttribute === null || attribute.getValue() < minAttribute.getValue())
      {
        minAttribute = attribute;
      }
    }

    return minAttribute;
  }


};
