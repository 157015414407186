/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import Behavior from 'galax/client/behavior';
import Selection from 'galax/client/selection';

import TargetZone from 'galax/react/card/target/zone';
import TargetSpaceship from 'galax/react/card/target/spaceship';


const Component = (props) =>
{
  const { targetLocation } = props;

  if (targetLocation.getZone())
  {
    return <TargetZone {...props} targetZone = {targetLocation.getZone()} />;
  }

  if (targetLocation.getSpaceship())
  {
    return <TargetSpaceship {...props} targetSpaceship = {targetLocation.getSpaceship()} />;
  }

  return null;
}


export default (props) =>
{
  const [ behavior, selection ] = useFlux(Behavior, Selection);

  if (behavior.isInvalid() || selection.getLocation() == null)
  {
    return null;
  }

  return <Component {...props} targetLocation = {selection.getLocation()} />;
}
