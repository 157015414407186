/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { memo, createContext, useContext } from 'react';

import DependencyCollection from 'fierry/flux/dependency/collection'

import Export from 'fierry/flux/dependency/export'
import useDependency from 'fierry/flux/hook/use.dependency';


const Children = memo( props =>
{
  return props.children;
});


const Dependency = ({ __dependency__, children, ...props }) =>
{
  const value = useDependency(__dependency__, props);

  return <Export dependency = {__dependency__} value = {value} children = {children} />;
};


const RecursiveFlux = ({ dependences : [dependency, ...dependences], children, ...props }) =>
(
  <Dependency {...props} __dependency__ = {dependency} >
    <Dependences {...props} dependences = {dependences} children = {children} />
  </Dependency>
);


const Dependences = (props) =>
{
  const { dependences, children } = props;

  return dependences.length ? <RecursiveFlux {...props} /> : <Children children = {children} />;
};


const FluxDependences = createContext(new DependencyCollection([]));


const Flux = ({ dependences, ...props }) =>
(
  <FluxDependences.Provider value = {dependences}>
    <Dependences {...props} dependences = {dependences.getFrontDependences()} />
  </FluxDependences.Provider>
);


export default ({ dependences : entities, ...props }) =>
{
  const dependences = useContext(FluxDependences);

  return <Flux {...props} dependences = {dependences.push(entities)} />;
}
