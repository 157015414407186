/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ShapeEnum, { getShapeToCount } from 'galax/enum/shape';


export default class
{
  constructor (parent)
  {
    this.parent = parent;
  }


  installInfluence (target, shape, influence)
  {
    switch(shape.type)
    {
      case ShapeEnum.Sector: this.installSectorInfluence(target, influence); return;
      case ShapeEnum.Splash: this.installSplashInfluence(target, influence); return;
      case ShapeEnum.Single: this.installSingleInfluence(target, influence); return;
      default:               this.installDirectInfluence(target, shape, influence); return;
    }
  }


  installSectorInfluence (target, influence)
  {
    const zone = this.parent.getZone(target.id);

    zone.getSector().getInfluences().installSectorInfluence(influence)
  }


  installSplashInfluence (target, influence)
  {
    const zone = this.parent.getZone(target.id);

    zone.getSector().getInfluences().installSplashInfluence(influence, zone)
  }


  installSingleInfluence (target, influence)
  {
    const zone = this.parent.getZone(target.id);

    zone.getUsers().installInfluence(influence);
  }


  installDirectInfluence (target, shape, influence)
  {
    this.parent.eachZone(target, shape, (zone) =>
    {
      zone.getUsers().installInfluence(influence);
    });
  }


};
