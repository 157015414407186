/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';


export default (parent) =>
{
  let state = new Immutable.Map();

  for (const projectile of parent.getProjectilesTo())
  {
    state = state.set(projectile.getKey(), projectile.getType());
  }

  return state;
}
