/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useMemo } from 'react';


export default (callback, dependences) =>
{
  if (callback === undefined)
  {
    callback = () => {}
  }

  if (dependences === undefined)
  {
    dependences = [];
  }

  return useMemo(() => callback(...dependences), dependences);
}
