/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */



export default ({ sidebar : Sidebar }) =>
{
  return <Sidebar />;
}
