/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, css } from 'fierry/react';

import * as Props from 'svg/props';
import * as Config from 'svg/config';


const Component = styled.svg`

  /* Always colors have basic default values. */
  --svg-always-black-default: var(--svg-always-black, black);
  --svg-always-other-default: var(--svg-always-other, black);
  --svg-always-white-default: var(--svg-always-white, white);
  --svg-always-transparent-default: var(--svg-always-transparent, transparent);

  /* Actual colors correspond to always colors by default. */
  --svg-black-default: var(--svg-black, var(--svg-always-black-default));
  --svg-other-default: var(--svg-other, var(--svg-always-other-default));
  --svg-white-default: var(--svg-white, var(--svg-always-white-default));
  --svg-transparent-default: var(--svg-transparent, var(--svg-always-transparent-default));

  /* Stroke widths */
  --svg-line-stroke-width-05: var(--svg-line-stroke-width-default-05, 0.5px);
  --svg-line-stroke-width-10: var(--svg-line-stroke-width-default-10, 1.0px);
  --svg-line-stroke-width-15: var(--svg-line-stroke-width-default-15, 1.5px);
  --svg-line-stroke-width-20: var(--svg-line-stroke-width-default-20, 2.0px);
  --svg-line-stroke-width-30: var(--svg-line-stroke-width-default-30, 3.0px);
  --svg-line-stroke-width-40: var(--svg-line-stroke-width-default-40, 4.0px);

  ${Config.SvgSize}

  ${Config.Svg24per32Ratio}
  ${Config.Svg18per32Ratio}

  & g[data-name*="no-invert"]
  {
    --svg-black-default: var(--svg-always-black-default);
    --svg-other-default: var(--svg-always-other-default);
    --svg-white-default: var(--svg-always-white-default);
    --svg-transparent-default: var(--svg-always-transparent-default);
  }


  // TODO this could be done much better... Need to support other no-invert lines?
  & g[data-name*="no-invert"] .black-line-20
  {
    stroke-width: calc(var(--svg-line-stroke-width-20) + var(--svg-line-stroke-increase, 0px) / ${props => Props.getRatio(props)});
  }


  & g[data-name*="display-a"]
  {
    display: var(--svg-group-a, none);
  }


  & g[data-name*="display-b"]
  {
    display: var(--svg-group-b, none);
  }


  & g[data-name*="display-c"]
  {
    display: var(--svg-group-c, none);
  }


  & g[data-name*="line-dash-a"]
  {
    stroke-dasharray: var(--svg-dash-array-a, none);
    stroke-dashoffset: var(--svg-dash-offset-a, 0);
  }


  & g[data-name*="line-dash-b"]
  {
    stroke-dasharray: var(--svg-dash-array-b, none);
    stroke-dashoffset: var(--svg-dash-offset-b, 0);
  }


  & g[data-name*="line-dash-c"]
  {
    stroke-dasharray: var(--svg-dash-array-c, none);
    stroke-dashoffset: var(--svg-dash-offset-c, 0);
  }


  & .none
  {
    display: none;
  }



  & .black-shape
  {
    ${Config.noneShape}

    fill: var(--svg-black-default);
  }


  & .other-shape
  {
    ${Config.noneShape}

    fill: var(--svg-other-default);
  }


  & .white-shape
  {
    ${Config.noneShape}

    fill: var(--svg-white-default);
  }


  & .black-line-05
  {
    ${Config.blackLine}

    stroke-width: var(--svg-line-stroke-width-05);
  }


  & .black-line-10
  {
    ${Config.blackLine}

    stroke-width: var(--svg-line-stroke-width-10);
  }


  & .black-line-15
  {
    ${Config.blackLine}

    stroke-width: var(--svg-line-stroke-width-15);
  }


  & .black-line-20
  {
    ${Config.blackLine}

    stroke-width: var(--svg-line-stroke-width-20);
  }


  & .black-line-30
  {
    ${Config.blackLine}

    stroke-width: var(--svg-line-stroke-width-30);
  }


  & .black-line-40
  {
    ${Config.blackLine}

    stroke-width: var(--svg-line-stroke-width-40);
  }


  & .other-line-05
  {
    ${Config.otherLine}

    stroke-width: var(--svg-line-stroke-width-05);
  }


  & .other-line-10
  {
    ${Config.otherLine}

    stroke-width: var(--svg-line-stroke-width-10);
  }


  & .other-line-15
  {
    ${Config.otherLine}

    stroke-width: var(--svg-line-stroke-width-15);
  }


  & .other-line-20
  {
    ${Config.otherLine}

    stroke-width: var(--svg-line-stroke-width-20);
  }


  & .other-line-30
  {
    ${Config.otherLine}

    stroke-width: var(--svg-line-stroke-width-30);
  }


  & .other-line-40
  {
    ${Config.otherLine}

    stroke-width: var(--svg-line-stroke-width-40);
  }


  & .white-line-05
  {
    ${Config.whiteLine}

    stroke-width: var(--svg-line-stroke-width-05);
  }


  & .white-line-10
  {
    ${Config.whiteLine}

    stroke-width: var(--svg-line-stroke-width-10);
  }


  & .white-line-15
  {
    ${Config.whiteLine}

    stroke-width: var(--svg-line-stroke-width-15);
  }


  & .white-line-20
  {
    ${Config.whiteLine}

    stroke-width: var(--svg-line-stroke-width-20);
  }


  & .white-line-30
  {
    ${Config.whiteLine}

    stroke-width: var(--svg-line-stroke-width-30);
  }


  & .white-line-40
  {
    ${Config.whiteLine}

    stroke-width: var(--svg-line-stroke-width-40);
  }
`;


export default (props) =>
{
  const centerPositionProps = props.center ? Props.getCenterPosition(props) : {};

  return <Component {...props} {...centerPositionProps} />;
}
