/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux } from 'fierry/react/native';

import Url from 'fierry/client/url';

import Group from 'fierry/react/modern/group';

import Context from 'fierry/react/skeleton/card/context';
import Buttons from 'fierry/react/skeleton/card/buttons';
import Container from 'fierry/react/skeleton/card/container'
import Header from 'fierry/react/skeleton/card/header';
import Headline from 'fierry/react/skeleton/card/headline';
import Subline from 'fierry/react/skeleton/card/subline';


const getFullScreen = ({ context }) =>
{
  return context && context.fullScreen;
}


const Component = ({ children, headline, subline, ...props }) =>
(
  <Container {...props} fullScreen = {getFullScreen(props)} >

    <Header>

      <Headline {...props} headline = {headline} />

      <Group>
        <Subline {...props} subline = {subline} />
        <Buttons {...props} />
      </Group>

    </Header>

    {children}

  </Container>
);


const OnExpand = (event, url, context) =>
{
  url.pushBackground(context.route, { }, { variant: context.variant });

  event.stopPropagation();
}


export default (props) =>
{
  const [ context, url ] = useFlux(Context, Url);

  const onExpand = useCallback(OnExpand, [ url, context ]);

  return <Component {...props} context = {context} onExpand = {onExpand} />;
}
