/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, styled, useCallback } from 'fierry/react';

import '@material/mwc-slider';


const Container = styled(Modern.Group)`

  --mdc-theme-secondary: var(--fierry-theme-primary-foreground);
  --mdc-theme-text-primary-on-dark: var(--fierry-theme-background);

  mwc-slider
  {
    width: 100%;
  }
`;


const getValue = ({ source, target, state }) =>
{
  return source.getZone().getSector().getTransfers().getTransferRatio(target.getZone().getSector(), state);
}


// TODO using old slider implementation - upgrading mwc from 0.22.1 will crash this!
const Component = ({ onChange, ...props }) =>
(
  <Container>
    <mwc-slider pin step = '1' min = '1' max = '10' value = {getValue(props)} onInput = {onChange} />
  </Container>
);


const OnChange = (event, state) =>
{
  state.setValue(event.target.value);
}

export default (props) =>
{
  const { state, invalid } = props;

  const onChange = useCallback(OnChange, [ state ]);

  return !invalid ? <Component {...props} onChange = {onChange} /> : null;
}
