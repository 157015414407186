/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const isBoolean = (o) =>
{
  return typeof o === 'boolean' || o instanceof Boolean;
};


export const isNumber = (o) =>
{
  return typeof o === 'number' || o instanceof Number;
};


export const isString = (o) =>
{
  return typeof o === 'string' || o instanceof String;
};


export const isFunction = (o) =>
{
  return typeof o === 'function' || o instanceof Function;
};


export const isArray = (o) =>
{
  return o instanceof Array;
};


export default new class Lang
{
  isBoolean (o)
  {
    return typeof o === 'boolean' || o instanceof Boolean;
  }


  isNumber (o)
  {
    return typeof o === 'number' || o instanceof Number;
  }


  isString (o)
  {
    return typeof o === 'string' || o instanceof String;
  }


  isFunction (o)
  {
    return typeof o === 'function' || o instanceof Function;
  }


  isArray (o)
  {
    return o instanceof Array;
  }


}();
