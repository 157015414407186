/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Cache from 'fierry/flux/cache';
import Model from 'fierry/rpc/model';

import ParentEnum from 'galax/enum/parent';
import ShapeEnum from 'galax/enum/shape';

import Queries from 'galax/rpc/queries';

import Time from 'galax/server/time';
import Galaxy from 'galax/server/galaxy';
import Spaceship from 'galax/server/spaceship';
import User from 'galax/server/user';
import Users from 'galax/server/users';

import * as Coords from 'galax/geo/coords';
import * as Lang from 'fierry/util/lang';

import { makeLocation } from 'galax/server/location';

import { isDebug } from 'galax/util/debug';
import { isOffline } from 'galax/util/offline';


export default class extends Model
{
  constructor (state, hierarchy)
  {
    super(state, hierarchy);

    this.props = { queries: new Queries(state), parent: this };
    this.hierarchy = hierarchy;

    this.currentUser = this.__createCache__(User, User.constructByCurrent);

    this.currentTime = this.__createCache__(Time);
    this.currentGalaxy = this.__createCache__(Galaxy);

    this.user = this.__createCache__(User);
    this.spaceshipByZone = this.__createCache__(Spaceship, Spaceship.constructByZone);
    this.spaceship = this.__createCache__(Spaceship);
    this.users = this.__createCache__(Users);
  }


  initiateUser ()
  {
    this.__getHierarchy__().execute('user/initiate', { });
  }


  releaseUser ()
  {
    this.__getHierarchy__().execute('user/release', { });
  }


  cancelEntityAction (zone)
  {
    this.__getHierarchy__().execute('entity/cancel', { location: zone.getLocation().getCass() });
  }


  createSpaceship (dock, type)
  {
    const spaceport = dock.getSpaceport();

    const user = spaceport.getUserID();
    const planet = spaceport.getPlanet().getID();

    this.__getHierarchy__().execute('spaceship/create', { type, planet, user, spaceship_zone: dock.getID(), spaceport_zone: spaceport.getID() });
  }


  cancelSpaceshipAction (spaceship)
  {
    this.__getHierarchy__().execute('entity/cancel', { location: spaceship.getLocation().getCass() });
  }


  dockSpaceship (spaceship, spaceport)
  {
    throw new Error('No longer supported!');
  }


  moveSpaceship (spaceship, target)
  {
    this.__getHierarchy__().execute('spaceship/move', { spaceship: spaceship.getID(), target_zone: target.getID() });
  }


  attackSpaceship (spaceship, target, shape)
  {
    this.__getHierarchy__().execute('spaceship/attack', { spaceship: spaceship.getID(), target: target.getCass(), shape });
  }


  createSectorTransfer (source, target, transfer_ratio)
  {
    const source_planet = source.getCass().getPlanet();
    const target_planet = target.getCass().getPlanet();

    const source_sector = source.getCass().getID();
    const target_sector = target.getCass().getID();

    this.__getHierarchy__().execute('sector/transfer/create', { source_planet, target_planet, source_sector, target_sector, transfer_ratio });
  }


  cancelSectorTransfer (source, target)
  {
    this.__getHierarchy__().execute('sector/transfer/cancel', { source: source.getCass(), target: target.getCass() });
  }


  beginProjectileAttack (source /*location*/, target /*location*/)
  {
    this.__getHierarchy__().execute('projectile/attack/begin', { source, target });
  }


  cancelProjectileAttack (id)
  {
    this.__getHierarchy__().execute('projectile/attack/cancel', { id });
  }


  createBuilding (source, variant)
  {
    this.__getHierarchy__().execute('building/create', { source: source.getCass(), variant, args: { } });
  }


  createBuildingWithTarget (sourceLoc, variant, targetLoc, shape)
  {
    const source = sourceLoc.getCass();
    const target = { id: targetLoc.getCass().id, parent: source.parent };

    this.__getHierarchy__().execute('building/create', { source: source, variant, args: { target, shape } });
  }


  upgradeBuilding (location)
  {
    this.__getHierarchy__().execute('building/upgrade', { source: location.getCass() });
  }


  activateBuilding (source)
  {
    this.__getHierarchy__().execute('building/setup', { source: source.getCass() });
  }


  disbandBuilding (zone)
  {
    this.__getHierarchy__().execute('building/disband', { source: zone.getLocation().getCass() /*planet: zone.getPlanet().getID(), zone: zone.getID()*/ });
  }


  setupBuilding (source /*location*/, target /*location*/, shape)
  {
    this.__getHierarchy__().execute('building/setup', { source, args: { target, shape } });
  }


  createUnit (source, target, variant, shape)
  {
    this.__getHierarchy__().execute('unit/create', { source: source.getCass(), variant, args: { target: target.getCass(), shape } });
  }


  attackUnit (source, target, shape)
  {
    //this.__getHierarchy__().execute('unit/attack', { source: source.getCass(), target: target.getCass(), shape });
  }


  disbandUnit (zone)
  {
    this.__getHierarchy__().execute('unit/disband', { source: zone.getLocation().getCass() });
  }


  __getHierarchy__ ()
  {
    return this.hierarchy;
  }


  getCurrentUser ()
  {
    return this.currentUser.get();
  }


  getCurrentTime()
  {
    return this.currentTime.get();
  }


  getCurrentGalaxy ()
  {
    return this.currentGalaxy.get();
  }


  getConstants ()
  {
    return this.state.get('constants');
  }


  getPlanet (id)
  {
    return this.getPlanets().get(id);
  }


  getPlanets ()
  {
    return this.state.get('planets');
  }


  getUserPlanets (userID)
  {
    let planets = [];

    for (const planet of this.getPlanets().values())
    {
      planets.push(planet);
    }

    return planets;
  }


  getSpaceship (id)
  {
    return this.spaceship.get(id);
  }


  getSpaceshipByZone (coords)
  {
    return this.spaceshipByZone.get(coords.toString());
  }


  getUser (id)
  {
    return this.user.get(id);
  }


  getUsers ()
  {
    return this.users.get();
  }


  getZone (coords, parent)
  {
    if (parent && parent.type === ParentEnum.Planet)
    {
      return this.getPlanet(parent.id).getZone(coords);
    }

    for (const planet of this.getPlanets().values())
    {
      if (planet.isWithinBounds(coords) && planet.getZone(coords))
      {
        return planet.getZone(coords);
      }
    }

    return null;
  }


  getDock (coords, parent)
  {
    if (parent && parent.type === ParentEnum.Planet)
    {
      return this.getPlanet(parent.id).getDock(coords);
    }

    for (const planet of this.getPlanets().values())
    {
      if (planet.isWithinBounds(coords, 1) && planet.getDock(coords))
      {
        return planet.getDock(coords);
      }
    }

    return null;
  }


  getPlatform (coords, parent)
  {
    if (parent && parent.type === ParentEnum.Planet)
    {
      return this.getPlanet(parent.id).getPlatform(coords);
    }

    for (const planet of this.getPlanets().values())
    {
      if (planet.getPlatform(coords))
      {
        return planet.getPlatform(coords);
      }
    }

    return null;
  }


  getSpaceshipByLocation (coords, parent)
  {
    // TODO coords may be incorrect - spaceship may have already moved and the interaction /absolute/ location may be outdated.
    if (parent && parent.type === ParentEnum.Spaceship)
    {
      return this.getSpaceship(parent.id);
    }

    return this.getSpaceshipByZone(coords);
  }


  getLocation (coordsOrLocation)
  {
    if (coordsOrLocation == null)
    {
      return null;
    }

    if (Lang.isString(coordsOrLocation))
    {
      coordsOrLocation = Coords.fromString(coordsOrLocation);
    }

    return makeLocation(coordsOrLocation, this);
  }


  isLocal ()
  {
    return false;
  }


  isReady ()
  {
    return this.getPlanets().size !== 0;
  }


  __createCache__ (type, construct = type.construct)
  {
    const factory = (...key) =>
    {
      const props = construct(...key, this.props);

      return props ? new type ({ ...props, parent: this }) : null;
    }

    return new Cache(factory);
  }


};
