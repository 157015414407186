/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Building from 'galax/server/building';


const CREATE_EFFICIENCY_BASE = Math.pow(Math.sqrt(2), 1/3);


export default class extends Building
{
  installPassiveTarget (zone)
  {
    const attribute = this.getHostileAttribute(this.getResistanceEfficiency());

    zone.getAttributes().installResistanceEfficiency(attribute);
  }


  installActiveTarget (zone)
  {
    // no-op.
  }


  getResistanceEfficiency ()
  {
    return 0.25;
  }


};
