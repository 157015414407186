/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Status from 'galax/server/status';


export default class extends Status
{
  isProduction ()
  {
    return this.isType('create');
  }


  isAttack ()
  {
    return this.isType('attack');
  }


  isDisband ()
  {
    return this.isType('disband');
  }


  isFortify ()
  {
    return this.isType('fortify');
  }


}
