/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Theme from 'galax/react/map/util/theme';


const getUser = ({ location }) =>
{
  return location.getUser();
}


const getTransform = ({ grid, location, local, ...props }) =>
{
  return grid.getLocalHex(location).getCenterTransform(local);
}


export default ({ children, ...props }) =>
(
  <Theme user = {getUser(props)} transform = {getTransform(props)} >
    {children}
  </Theme>
);
