/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import SpaceshipInfo from 'galax/react/card/spaceship/info'

import Error from 'galax/react/card/shared/error';


const getType = ({ constants }) =>
{
  return constants.getType();
}


const Component = (props) =>
(
  <>
    <SpaceshipInfo {...props} type = {getType(props)} />

    <Error {...props} />
  </>
);


export default (props) =>
{
  const constants = props.galaxy.getConstants().getSpaceship(props.currentArgs.type);

  return <Component {...props} constants = {constants} />;
}
