/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 8 16" {...props}><defs/><rect width="8" height="16" className="none"/><line x1="6.12" y1="5.88" x2="1.88" y2="10.12" className="black-line-20"/><line x1="1.88" y1="5.88" x2="6.12" y2="10.12" className="black-line-20"/></Svg>
  );
}
