/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useLayoutEffect, withMemo } from 'fierry/react';


export default withMemo( ({ canDispatch, executeDispatch, ...props }) =>
{
  useLayoutEffect(() =>
  {
    if (canDispatch(props))
    {
      executeDispatch(props);
    }
  });

  return null;
});
