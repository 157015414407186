/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, useFlux } from 'fierry/react';

import Server from 'galax/server';
import Screen from 'galax/client/screen';


const Scrim = styled.div`

  position: absolute;
  z-index: 5;

  width: 100%;
  height: 100%;

  opacity: var(--fierry-highligh-opacity);
  background: var(--fierry-theme-foreground);
`;


export default (props) =>
{
  const [ server, screen ] = useFlux(Server, Screen);

  if (server && server.getCurrentTime().isServerDelayed())
  {
    return <Scrim />;
  }

  if (!screen.isOnline())
  {
    return <Scrim />
  }

  return null;
}
