/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import View from 'fierry/client/view';

import Header from 'fierry/react/header';

import NextButton from 'galax/react/button/general/next'
import AboutButton from 'galax/react/button/general/about'
import LogInButton from 'galax/react/button/general/login'

import * as Manual from 'galax/fake/manual';


const StartHeader = (props) =>
(
  <Header headline = 'Welcome to Fierry!' >
    <NextButton {...props} />
  </Header>
);


const MoreHeader = ({ index, ...props }) =>
(
  <Header headline = {Manual.getHeader(index)} >
    <NextButton {...props} />
  </Header>
);


const PlayHeader = ({ index, ...props }) =>
(
  <Header headline = {Manual.getHeader(index)} >
    <LogInButton {...props} />
  </Header>
);


export default (props) =>
{
  const [ view ] = useFlux(View);

  const index = Number.parseInt(view.getRoute().getArgs().index);

  if (index === 0)
  {
    return <StartHeader {...props} />;
  }

  return index === 4 ? <PlayHeader {...props} index = {index} /> : <MoreHeader {...props} index = {index} />;
}
