/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Select } from 'fierry/flux';

import { matchPath } from "react-router";

import View from 'fierry/client/view';

import Galaxy from 'galax/server';

import BuildingAttackBehavior from 'galax/behavior/building.attack';
import BuildingCreateBehavior from 'galax/behavior/building.create';
import BuildingUpgradeBehavior from 'galax/behavior/building.upgrade';

import SpaceshipAttackBehavior from 'galax/behavior/spaceship.attack';
import SpaceshipCreateBehavior from 'galax/behavior/spaceship.create';
import SpaceshipMoveBehavior from 'galax/behavior/spaceship.move';

import UnitAttackBehavior from 'galax/behavior/unit.attack';
import UnitCreateBehavior from 'galax/behavior/unit.create';

import SectorTransferBehavior from 'galax/behavior/sector.transfer';

import WelcomeBehavior from 'galax/behavior/welcome';
import WelcomeManualBehavior from 'galax/behavior/welcome.manual';


const BuildingBehavior =
[
  BuildingAttackBehavior,
  BuildingCreateBehavior,
  BuildingUpgradeBehavior,
];


const UnitBehavior =
[
  UnitAttackBehavior,
  UnitCreateBehavior,
];


const SpaceshipBehavior =
[
  SpaceshipAttackBehavior,
  SpaceshipCreateBehavior,
  SpaceshipMoveBehavior,
];


const SectorBehavior =
[
  SectorTransferBehavior,
];



const getMatchingBehavior = (route, collection) =>
{
  for (let i = 0; i < collection.length; ++i)
  {
    const [ path, behavior ] = collection[i];

    if (matchPath(route, { path }) != null)
    {
      return behavior;
    }
  }

  return null;
}


export default new Select ([ View, Galaxy ], [ ...BuildingBehavior, ...UnitBehavior, ...SpaceshipBehavior, ...SectorBehavior, WelcomeBehavior, WelcomeManualBehavior ], (view, galaxy) =>
{
  if (galaxy == null || !galaxy.isReady())
  {
    return null;
  }

  return getMatchingBehavior(view.getRoute().getValue(),
  [
    [ '/building.attack',      BuildingAttackBehavior ],
    [ '/building.create',      BuildingCreateBehavior ],
    [ '/building.upgrade',     BuildingUpgradeBehavior ],

    [ '/sector.transfer.edit', SectorTransferBehavior ],

    [ '/spaceship.attack',     SpaceshipAttackBehavior ],
    [ '/spaceship.create',     SpaceshipCreateBehavior ],
    [ '/spaceship.move',       SpaceshipMoveBehavior ],

    [ '/unit.attack',          UnitAttackBehavior ],
    [ '/unit.create',          UnitCreateBehavior ],

    [ '/manual/:index',        WelcomeManualBehavior ],

    [ '/login',                WelcomeManualBehavior ],
    [ '/about',                WelcomeBehavior ],
    [ '/terms',                WelcomeBehavior ],
    [ '/privacy',              WelcomeBehavior ],
  ]);
});
