/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux }  from 'fierry/react';

import Grid from 'galax/client/map/grid';
import CurrentLocation from 'galax/query/current.location';

import CurrentSelection from 'galax/react/map/selection/current';
import TargetsSelection from 'galax/react/map/selection/targets';
import FutureEntitySelection from 'galax/react/map/selection/future.entity';


const Component = (props) =>
(
  <>
    <CurrentSelection {...props} />
    <TargetsSelection {...props} />
    <FutureEntitySelection {...props} />
  </>
);


export default (props) =>
{
  const [ currentLocation, grid ] = useFlux(CurrentLocation, Grid);

  if (currentLocation == null || !grid.getZoom().isEntityVisible())
  {
    return null;
  }

  return <Component {...props} currentLocation = {currentLocation} grid = {grid} />;
}
