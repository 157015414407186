/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, styled } from 'fierry/react';

import ParamWithDetails from 'galax/react/group/param.with.details';
import InfluenceEntry from 'galax/react/card/sector.overview/influence.entry';

import EnergyIcon from 'svg/shared/modern/energy.js';


const Header = (props) =>
(
  <InfluenceEntry {...props} body
    text = 'Influence'
    icon = {<EnergyIcon />}
    value = {props.zone.getSector().getInfluences().getTotalInfluence()}
  />
);


const Details = (props) =>
(
  <Modern.Group>

    <InfluenceEntry {...props} param
      text = 'Yield'
      value = {props.zone.getSector().getInfluences().getTotalYield()}
    />

    <InfluenceEntry {...props} param
      text = 'Transfers'
      value = {props.zone.getSector().getInfluences().getTotalTransfers()}
    />

    <InfluenceEntry {...props} param
      text = 'Spending'
      value = {props.zone.getSector().getInfluences().getTotalSpending()}
    />

    <InfluenceEntry {...props} param
      text = 'Mining'
      value = {props.zone.getSector().getInfluences().getTotalMining()}
    />

    <InfluenceEntry {...props} param
      text = 'Enemy Attacks'
      value = {props.zone.getSector().getInfluences().getTotalAggressive()}
    />

  </Modern.Group>
);


export default (props) =>
(
  <ParamWithDetails
    header = {<Header {...props} />}
    details = {<Details {...props} />}
  />
);
