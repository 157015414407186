/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import withTooltip from 'fierry/react/modern/with.tooltip';

import Children from 'fierry/react/element/children';
import Container from 'fierry/react/element/container';
import Context from 'fierry/react/element/context';


const getVariantNumber = (variant, defaultValue) =>
{
  return variant === true ? defaultValue : variant;
}


const getVariant = ({ headline, subline, body, param, overline, caption, button, variant }) =>
{
  if (headline) { return 'headline' + getVariantNumber(headline, 6); }
  if (subline)  { return 'subtitle' + getVariantNumber(subline, 1); }
  if (body)     { return 'body'     + getVariantNumber(body, 1); }

  if (param)    { return 'body2'; }

  if (button)   { return 'button'; }
  if (caption)  { return 'caption'; }
  if (overline) { return 'overline'; }

  return variant;
}


export default withTooltip( ({ tooltip, children, ...props }) =>
(
  <Context variant = {getVariant(props)} >
    <Container {...props} aria-describedby = {tooltip} >
      <Children {...props} children = {children} />
    </Container>
  </Context>
));
