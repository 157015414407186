/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern, useFlux } from 'fierry/react';

import SpaceshipGroup from 'galax/react/group/target/spaceship';

import CoordsElement from 'galax/react/element/coords'


const Location = ({ targetLocation }) =>
(
  <CoordsElement location = {targetLocation} />
);


const Component = (props) =>
(
  <Card {...props} headline = 'Target' subline = {Location} >

    <SpaceshipGroup {...props} />

  </Card>
);


export default ({ ...props }) =>
{
  return <Component {...props} />;
}
