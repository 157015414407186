/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import '@material/mwc-button';

import { styled } from 'fierry/react/styled';
import config from 'fierry/react/modern/button/config';

import Icon from 'fierry/react/modern/icon';


const Container = styled.div`

  ${config}

  --mdc-shape-small: 0px;
  --mdc-button-horizontal-padding: 16px;

  --mdc-theme-primary: var(--fierry-button-default-foreground);
  --mdc-theme-on-primary: var(--fierry-button-default-background);

  --mdc-button-outline-color: var(--fierry-button-foreground);
  --mdc-button-outline-width: var(--fierry-button-border-width);

  --mdc-button-disabled-ink-color: var(--fierry-button-foreground);
  --mdc-button-disabled-fill-color: var(--fierry-button-background);
  --mdc-button-disabled-outline-color: var(--fierry-button-foreground);

  display: inline-flex;
  vertical-align: top;

  mwc-button
  {
    opacity: var(--fierry-button-opacity);
  }
`;


const IconOptional = ({ icon, iconSize = '1.5', trailing }) =>
{
  return icon ? <Icon icon = {icon} size = {iconSize} slot = {getSlot(trailing)} /> : null
}


const getMaterialButtonProps = ({ disabled, outlined, contained, unelevated, raised, ...props }) =>
{
  disabled = disabled ? true : null;
  outlined = contained || raised ? null : outlined;
  unelevated = contained ? true : null;

  return { ...props, disabled, outlined, unelevated, raised };
}


const getSlot = (isTrailing) =>
{
  return isTrailing ? 'trailingIcon' : 'icon';
}


export default ({ tooltip, onClick, ...props }) =>
(
  <Container {...props} aria-describedby = {tooltip} >

    <mwc-button {...getMaterialButtonProps(props)} onClick = {onClick} >
      <IconOptional {...props} />
    </mwc-button>

  </Container>
);
