/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


const setFavicon = (theme) =>
{
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');

  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = `/favicon-${theme}.ico`

  document.getElementsByTagName('head')[0].appendChild(link);
};


const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
const lightModeQuery = window.matchMedia("(prefers-color-scheme: light)");


const onThemeUpdate = () =>
{
  if (darkModeQuery.matches)
  {
    setFavicon('dark');
  }

  if (lightModeQuery.matches)
  {
    setFavicon('light');
  }
}

onThemeUpdate();


darkModeQuery.addListener(onThemeUpdate);

lightModeQuery.addListener(onThemeUpdate);
