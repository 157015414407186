/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import BuildingEnum from 'galax/enum/building';

import DistributionCenter from 'svg/galax/building/distribution-center';
import Garrison from 'svg/galax/building/garrison';
import Outpost from 'svg/galax/building/outpost';
import RoboticFactory from 'svg/galax/building/robotic.factory';
import Spaceport from 'svg/galax/building/spaceport';
import TransferStation from 'svg/galax/building/transfer-station';

import LaserBattery from 'svg/galax/building/laser-battery';
import GaussCannon from 'svg/galax/building/gauss-cannon';
import GaussArtillery from 'svg/galax/building/gauss-artillery';

import Shield from 'svg/galax/building/shield';
import Gate from 'svg/galax/building/gate';

import Zone from 'svg/shared/modern/zone';


export default ({ type, ...props }) =>
{
  switch (type)
  {
    case BuildingEnum.DistributionCenter: return <DistributionCenter {...props} />;
    case BuildingEnum.Garrison:           return <Garrison {...props} />;
    case BuildingEnum.Outpost:            return <Outpost {...props} />;
    case BuildingEnum.RoboticFactory:     return <RoboticFactory {...props} />;
    case BuildingEnum.Spaceport:          return <Spaceport {...props} />;
    case BuildingEnum.TransferStation:    return <TransferStation {...props} />;

    case BuildingEnum.LaserBattery:       return <LaserBattery {...props} />;
    case BuildingEnum.GaussCannon:        return <GaussCannon {...props} />;
    case BuildingEnum.GaussArtillery:     return <GaussArtillery {...props} />;

    case BuildingEnum.Shield:             return <Shield {...props} />;
    case BuildingEnum.Gate:               return <Gate {...props} />;

    case BuildingEnum.Empty:              return <Zone {...props} />;
  }

  return null;
}
