/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { withMemo } from 'fierry/react';

import ResourceEnum from 'galax/enum/resource';

import Center from 'galax/react/map/zone/center';

import Building from 'galax/react/map/entity/building';
import Resource from 'galax/react/map/entity/resource';
import SectorCenter from 'galax/react/map/entity/sector.center';
import Unit from 'galax/react/map/entity/unit';


const Component = (props) =>
{
  const { zone, currentUser } = props;

  if (zone.getCass().getResource() != null)
  {
    return <Center local {...props} > <Resource {...props} /> </Center>;
  }

  if (zone.getBuilding())
  {
    return <Center local {...props} > <Building {...props} /> </Center>;
  }

  if (zone.getUnit())
  {
    return <Center local {...props} > <Unit {...props} /> </Center>;
  }

  // TODO would be easier if sector could return Empty building (for currentUser only ?).
  if (zone.getCass().isCenter() && zone.getUserID() === currentUser.getID() && !currentUser.isAncientUser())
  {
    return <Center local {...props} > <SectorCenter {...props} /> </Center>;
  }

  return null;
};


export default withMemo((props) =>
{
  const { zone } = props;

  return <Component {...props} location = {zone.getLocation()} />;
})
