/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Status from 'galax/server/building/status';


export default class extends Status
{
  install ()
  {
    this.parent.installActiveSource();

    this.parent.getSector().getZones().eachZone(zone =>
    {
      this.parent.installActiveTarget(zone)
    });
  }


}
