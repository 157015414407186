/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import DynamicElement from 'fierry/rpc/dynamic-element';
import NativeConstants from 'galax/server/constants';

import * as Key from 'galax/rpc/key';
import * as Translator from 'galax/rpc/translator';


export default class extends DynamicElement
{
  constructor ()
  {
    super(NativeConstants);

    this.createTable('projectiles', Key.Type, [ Translator.ProjectileConstants ]);
    this.createTable('spaceships', Key.Type, [ Translator.SpaceshipConstants ]);
    this.createTable('buildings', Key.Type);
    this.createTable('entities', Key.Variant);
    this.createTable('units', Key.Type, [ Translator.UnitConstants ]);

    this.createTable('themes', Key.Type);
  }
}
