/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern } from 'fierry/react';

import PermanentContent from 'fierry/react/skeleton/header/content/permanent'
import TemporaryContent from 'fierry/react/skeleton/header/content/temporary'

import FadeInAnimation from 'fierry/react/skeleton/animation/fade.in';
import FadeOutAnimation from 'fierry/react/skeleton/animation/fade.out';


export default ({ transitionProps, ...props }) =>
(
  <>
    <FadeOutAnimation {...transitionProps}>
      <PermanentContent {...props} />
    </FadeOutAnimation>

    <FadeInAnimation {...transitionProps}>
      <TemporaryContent {...props} />
    </FadeInAnimation>
  </>
);
