/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback } from 'fierry/react';

import Icon from 'svg/shared/modern/transfer-from.js';


const OnClick = (event, state) =>
{
  state.setReverse(!state.getReverse());
};


export default ({ state, currentZone, currentUser, ...props }) =>
{
  const onClick = useCallback(OnClick, [ state ]);

  if (!currentZone || currentZone.getUser().getID() != currentUser.getID())
  {
    return null;
  }

  return <Modern.Button tooltip = 'Reverse Transfer' icon = {<Icon />} contained = {state.getReverse()} onClick = {onClick} />;
}
