/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Building from 'galax/server/building';


const YIELD_INCREASE_BASE = Math.pow(2, 1/3);


export default class extends Building
{
  installPassiveTarget (zone)
  {
    const attribute = this.getFriendlyAttribute(this.getYieldProduction());

    zone.getAttributes().installYieldProduction(attribute);
  }


  installActiveTarget (zone)
  {
    // no-op.
  }


  getYieldProduction ()
  {
    return 2.0;
  }


};
