/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import EntityEnum from 'galax/enum/entity';
import ParentEnum from 'galax/enum/parent';

import Cache from 'fierry/flux/cache';
import Model from 'fierry/rpc/model';

import PlanetAttributes from 'galax/server/planet/attributes';
import PlanetInstaller from 'galax/server/planet/installer';

import Sector from 'galax/server/sector';
import User from 'galax/server/user/local';

import EntityCollection from 'galax/server/entity/collection';
import ZoneCollection from 'galax/server/zone/collection';

import InteractionCollection from 'galax/server/interaction/collection';
import ProjectileCollection from 'galax/server/projectile/collection';
import LocalUserCollection from 'galax/server/user/local.collection';

import Dock from 'galax/server/zone/dock';

import { makeLocation } from 'galax/server/location';

import * as Coords from 'galax/geo/coords';
import * as Shapes from 'galax/geo/shapes';
import * as Array from 'fierry/util/array';


export default class extends Model
{
  constructor (state, hierarchy)
  {
    super(state, hierarchy);

    this.attributes = new PlanetAttributes(this);

    this.interactions = new InteractionCollection(state);
    this.projectiles = new ProjectileCollection(this, state);

    this.zoneCollection = new ZoneCollection();
    this.users = new LocalUserCollection(this, state.get('users'));
    this.entities = new EntityCollection(this, state.get('entities'));

    this.__createSectors__();
    this.__createZones__();
    this.__createDocks__();

    this.install();
  }


  install ()
  {
    const installer = new PlanetInstaller(this);

    this.entities.setupInstaller(installer);
    this.interactions.setupInstaller(installer);

    installer.execute();

    this.__installSectors__();
  }


  __createSectors__ ()
  {
    this.sectors = new Map();

    for (const sector of this.state.get('sectors').values())
    {
      this.sectors.set(sector.id, new Sector(sector, this));
    }
  }


  __createZones__ ()
  {
    this.zones = new Map();

    for (const sector of this.sectors.values())
    {
      sector.getZones().eachZone(zone =>
      {
        this.zones.set(zone.getID(), zone);
      });
    }
  }


  __createDocks__ ()
  {
    this.docks = new Map();

    for (const zone of this.zones.values())
    {
      for (const coords of Coords.getNeighborsInRange(zone.getCoords()))
      {
        if (this.getZone(coords) == null && !this.docks.has(coords.toString()))
        {
          this.docks.set(coords.toString(), new Dock(coords, this))
        }
      }
    }
  }


  __installSectors__ ()
  {
    for (const sector of this.sectors.values())
    {
      sector.install();
    }
  }


  getAttributes ()
  {
    return this.attributes;
  }


  getInteractions ()
  {
    return this.interactions;
  }


  getProjectiles ()
  {
    return this.projectiles;
  }


  getEntities ()
  {
    return this.entities;
  }


  getZoneCollection ()
  {
    return this.zoneCollection;
  }


  getBuilding (zoneID)
  {
    return this.entities.getBuilding(zoneID);
  }


  getUnit (coords)
  {
    return this.entities.getUnit(coords.toString());
  }


  getUser (id)
  {
    return this.users.getUser(id);
  }


  getZone (coords, parent)
  {
    if (parent && (parent.type !== ParentEnum.Planet || parent.id !== this.getID()))
    {
      return null;
    }

    return this.zones.get(coords.toString());
  }


  getDock (coords, parent)
  {
    if (parent && (parent.type !== ParentEnum.Planet || parent.id !== this.getID()))
    {
      return null;
    }

    return this.docks.get(coords.toString());
  }


  getPlatform (coords, parent)
  {
    if (parent && (parent.type !== ParentEnum.Planet || parent.id !== this.getID()))
    {
      return null;
    }

    return this.getZoneCollection().getPlatform(coords);
  }


  getSpaceshipByLocation (coords, parent)
  {
    return null;
  }


  getLocation (coordsOrLocation)
  {
    return makeLocation(coordsOrLocation, this);
  }


  getZones ()
  {
    return this.zones.values();
  }


  eachZone (center, shape, callback)
  {
    for (const coordinate of Shapes.getCoords(center.id, shape))
    {
      callback(this.getZone(coordinate));
    }
  }


  isWithinBounds (coords, delta = 0)
  {
    const { id, x, y, width, height } = this.getCass();

    return (x - delta) <= coords.getX() && coords.getX() <= (x + width + delta) && (y - delta) <= coords.getY() && coords.getY() <= (y + height + delta);
  }


  getSector (id)
  {
    return this.sectors.get(id);
  }

  getSectors ()
  {
    return this.sectors.values();
  }


  getConstants ()
  {
    return this.state.get('constants');
  }


  getCass ()
  {
    return this.state.get('currentPlanet');
  }


  getID ()
  {
    return this.getCass().id;
  }


  getCoords ()
  {
    return this.getCass().coords;
  }


  isLocal ()
  {
    return true;
  }


  // Unverified API


  getSectorNeighbors (userID)
  {
    let neighbors = [];

    for (const lhs of this.sectors.values())
    {
      if (lhs.getUser().getID() !== userID)
      {
        continue;
      }

      for (const rhs of this.sectors.values())
      {
        if (rhs.getUser().getID() === userID)
        {
          continue;
        }

        if (lhs.getNeighbors().isNeighbor(rhs))
        {
          neighbors.push(rhs);
        }
      }
    }

    return Array.unique(neighbors);
  }


}
