/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator/spaceship.create.list';


export default class extends Validator
{
  isRouteVisible ()
  {
    return super.isRouteVisible() && this.isSpaceshipTypeFound();
  }


  isRouteDisabled ()
  {
    return super.isRouteDisabled();
  }


  isRouteActivated ()
  {
    return super.isRouteActivated();
  }


  isSpaceshipTypeFound ()
  {
    return this.galaxy.getConstants().getSpaceship(this.args.type) != null;
  }


};
