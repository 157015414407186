/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default new class
{
  capitalize (string)
  {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
  }


}();
