/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Status from 'galax/server/spaceship/status';


export default class extends Status
{
  getEndDate ()
  {
    const date = this.parent.getUpdateDate();
    const time = this.parent.getTimeToFull(date)

    return time !== 0 ? new Date(date.getTime() + time) : null;
  }


};
