/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Query from 'fierry/flux/entity/query'

import Time from 'galax/client/time';


export default new Query([ Time ], (time) =>
{
  return time.getClientDate();
});
