/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useFlux } from 'fierry/react';

import Now from 'galax/query/now';

import EnergyElement from 'galax/react/element/energy';

import UserIcon from 'svg/shared/modern/user';


const getUserName = ({ user }) =>
{
  return user.getName();
}


const getEnergy = ({ zone, user, now }) =>
{
  return zone.getEnergy().getEnergy(user.getID(), now);
}


const getInfluence = ({ zone, user }) =>
{
  return zone.getEnergy().getFlow(user.getID());
}


const EnergyItem = (props) =>
(
  <Modern.Group>

    <Modern.Text param width = '6'
      icon = {<UserIcon />}
      text = {getUserName(props)}
    />

    <EnergyElement {...props}
      energy = {getEnergy(props)}
      influence = {getInfluence(props)}
    />

  </Modern.Group>
);


export default (props) =>
{
  const { galaxy, currentZone } = props;

  const [ now ] = useFlux(Now);

  let results = [];

  for (const userID of currentZone.getEnergy().getCurrentUsers())
  {
    const user = galaxy.getUser(userID);

    results.push(<EnergyItem {...props} key = {userID} now = {now} user = {user} zone = {currentZone} />);
  }

  return <Modern.Group vertical> {results} </Modern.Group>;
}
