/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import uuid from 'uuid/v4';

import ParentEnum from 'galax/enum/parent';
import * as Energy from 'galax/value/energy';


export default class
{
  constructor (type, coords, user)
  {
    const id = uuid();

    this.currentSpaceship = { id, type, zone: coords.toString(), location: { id: coords.toString(), parent: { id, type: ParentEnum.Spaceship }}, source: coords, user: user.getID(), energy: Energy.fromPercentage(180), status: null, event: null };

    this.interactionsTo = [];
    this.interactionsFrom = [];

    this.projectilesTo = [];
    this.projectilesFrom = [];
  }


  setStatus (type, date = null)
  {
    this.currentSpaceship.status = { type, event: date ? { id: null, date } : null };
  }


  setTarget (target /*zone or spaceship*/)
  {
    this.currentSpaceship.target = target.getLocation();
  }


  insertInteractionTo (interaction)
  {
    this.interactionsTo.push(interaction);
  }


  insertInteractionFrom (interaction)
  {
    this.interactionsFrom.push(interaction);
  }


  insertProjectileTo (interaction)
  {
    this.projectilesTo.push(interaction);
  }


  insertProjectileFrom (interaction)
  {
    this.projectilesFrom.push(interaction);
  }


  setGalaxy (galaxy)
  {
    this.currentSpaceship.galaxy = galaxy;

    for (const interaction of this.interactionsTo)
    {
      interaction.setGalaxy(galaxy);
    }

    for (const interaction of this.interactionsFrom)
    {
      interaction.setGalaxy(galaxy);
    }

    for (const projectile of this.projectilesTo)
    {
      projectile.setGalaxy(galaxy);
    }

    for (const projectile of this.projectilesFrom)
    {
      projectile.setGalaxy(galaxy);
    }
  }


  setUpdateDate (date)
  {
    this.currentSpaceship.update_date = date;

    for (const projectile of this.projectilesTo)
    {
      projectile.setUpdateDate(date);
    }

    for (const projectile of this.projectilesFrom)
    {
      projectile.setUpdateDate(date);
    }
  }


  getInteractionsTo ()
  {
    return this.interactionsTo;
  }


  getInteractionsFrom ()
  {
    return this.interactionsFrom;
  }


  getProjectilesTo ()
  {
    return this.projectilesTo;
  }


  getProjectilesFrom ()
  {
    return this.projectilesFrom;
  }


  getCurrentSpaceship ()
  {
    return this.currentSpaceship;
  }


  getID ()
  {
    return this.currentSpaceship.id;
  }


  getUserID ()
  {
    return this.currentSpaceship.user;
  }


  getCoords ()
  {
    return this.currentSpaceship.source;
  }


  getLocation ()
  {
    return { id: this.getCoords(), parent: { id: this.getID(), type: ParentEnum.Spaceship }};
  }


}
