/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (dispatch)
  {
    this.dispatchers = [ dispatch ];
  }


  registerDispatch (dispatch)
  {
    if (!this.dispatchers.includes(dispatch))
    {
      this.dispatchers.push(dispatch);
    }
  }


  unregisterDispatch (dispatch)
  {
    const index = this.dispatchers.findIndex(dispatch);

    if (index !== -1)
    {
      this.dispatchers.splice(dispatch, 1);
    }
  }


  // TODO better way would be to pass a 'delayed' dispatch that will actually dispatch only after this send finishes!
  // This way user can dispatch sync & async and not worry about losing current method state! Now sync dispatch will ignore changes with method...
  send (action, args)
  {
    for (const dispatch of this.dispatchers)
    {
      dispatch({ type: action.getType(), payload: { ...args }, dispatch });
    }
  }


  getDispatch ()
  {
    return this.dispatchers[0];
  }


}
