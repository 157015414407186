/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import {drag as d3Drag} from 'd3-drag';
import {select as d3Select, event as d3Event} from 'd3-selection';


export default function drag (element, onDrag, onDragStart, onDragEnd)
{
  let dragCallback = d3Drag().on('drag', () => onDrag(d3Event));

  if (onDragStart != null)
  {
    dragCallback = dragCallback.on('start', () => onDragStart(d3Event));
  }

  if (onDragEnd != null)
  {
    dragCallback = dragCallback.on('end', () => onDragEnd(d3Event));
  }

  return d3Select(element).call(dragCallback);
}
