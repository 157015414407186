/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux } from 'fierry/react';

import Server from 'galax/server';

import Galaxy from 'galax/react/map/galaxy';
import Window from 'galax/react/map/window';

import SelectionCollection from 'galax/react/map/selection/collection';
import FlightCollection from 'galax/react/map/flight/collection';

import Scrim from 'galax/react/map/other/scrim';


const getKey = ({ galaxy }) =>
{
  return galaxy.getCurrentGalaxy().getID();
}


const Component = (props) =>
(
  <>
    <Window {...props} key = {getKey(props)} >

      <Galaxy {...props} />

      <SelectionCollection {...props} />
      <FlightCollection {...props} />

    </Window>

    <Scrim {...props} />
  </>
);


export default (props) =>
{
  const [ galaxy ] = useFlux(Server);

  return galaxy ? <Component {...props} galaxy = {galaxy} /> : null;
}
