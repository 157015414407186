/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Action from 'fierry/flux/entity/action';
import Store from 'fierry/flux/entity/store';


const MIN_ZOOM_LEVEL = 0;
const MAX_ZOOM_LEVEL = 5;


const State = 3;


const Actions =
{
  ZoomIn: new Action(),
  ZoomOut: new Action(),
};


const zoomIn = (zoom, action) =>
{
  return (zoom < MAX_ZOOM_LEVEL) ? ++zoom : zoom;
}


const zoomOut = (zoom) =>
{
  return (zoom > MIN_ZOOM_LEVEL) ? --zoom : zoom;
}


export default new Store(State, Actions,
[
  [Actions.ZoomIn,  zoomIn],
  [Actions.ZoomOut, zoomOut],
]);
