/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import UnitOverview from 'galax/react/card/unit/overview'
import SourceCard from 'galax/react/card/zone.overview';
import TargetCard from 'galax/react/card/target';

import Error from 'galax/react/card/shared/error';


const getType = ({ currentZone }) =>
{
  return currentZone.getUnit().getConstants().getType();
}


export default (props) =>
(
  <>
    <UnitOverview {...props} />
    <TargetCard {...props} />

    <Error {...props} />
  </>
);
