/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Client } from 'fierry/flux';

import ShapeEnum, { getShapeToCount } from 'galax/enum/shape';

import Now from 'galax/query/now';
import View from 'fierry/client/view';
import Galaxy from 'galax/server';
import Navigation from 'galax/client/navigation';
import Selection from 'galax/client/selection';

import * as Coords from 'galax/geo/coords';
import * as Shapes from 'galax/geo/shapes';


export default new Client([ Navigation, Selection, Galaxy, View, Now ], class
{
  constructor (navigation, selection, galaxy, view, now)
  {
    this.galaxy = galaxy;
    this.now = now;

    this.source = navigation.getLocation();
    this.target = selection.getLocation();
    this.count = selection.getCount();

    this.args = view.getRoute().getArgs();
  }


  onDone ()
  {
    const shape = this.__getTargetShape__();

    this.galaxy.createUnit(this.source, this.target, this.args.type, shape);
  }


  getSelectionTargets ()
  {
    if (this.target.getSpaceship())
    {
      return [ this.target ];
    }

    const mapCallback = (coords) =>
    {
      return this.galaxy.getLocation(coords);
    };

    return this.__getTargetShape__().data.map(mapCallback);
  }


  __getTargetShape__ ()
  {
    const downgradeCallback = (coords) =>
    {
      return this.galaxy.getLocation(coords).getZone() == null;
    };

    const targetVariants = this.__getConstantsShape__(this.target.getCoords(), this.source.getCoords());
    const targetShape = targetVariants[(this.count - 1) % targetVariants.length];

    if (this.target.getSpaceship())
    {
      return targetShape;
    }

    return Shapes.getDowngrade(this.target.getCoords(), targetShape, downgradeCallback);
  }


  __getConstantsShape__ (target, source)
  {
    const type = this.getUnitConstants().getTargetShape();

    return this.getUnitConstants().isRangeAttack() ? Shapes.getMaxVariants(target, source, type) : Shapes.getMinVariants(target, source, type)
  }


  getError ()
  {
    if (this.args.type == null)
    {
      return 'No unit type found';
    }

    const currency = this.galaxy.getCurrentUser().getCurrency(this.now);

    if (currency < this.getUnitConstants().getConstructionCost())
    {
      return 'Not enough currency to produce unit';
    }

    /*if (!this.source.getZone().getEnergy().canSpend(this.getUnitConstants().getEnergy()))
    {
      return 'Not enough energy to produce unit';
    }*/

    if (this.source.isEqual(this.target))
    {
      return 'Unit cannot attack zone it occupies.';
    }

    const distance  = Coords.getDistance(this.source.getCoords(), this.target.getCoords());

    if (distance < this.getUnitConstants().getTargetMinRange())
    {
      return 'Target zone is too close. Please select more distant target.';
    }

    if (distance > this.getUnitConstants().getTargetMaxRange())
    {
      return 'Target zone is out of range. Please select a closer target.';
    }

    const targetSpaceship = this.target.getSpaceship();

    if (targetSpaceship)
    {
      if (this.source.getUser().getID() === targetSpaceship.getUserID())
      {
        return 'Cannot attack your own spaceship.';
      }

      if (targetSpaceship.getStatus().isProduction())
      {
        return 'Cannot attack spaceship under construction.';
      }

      if (targetSpaceship.getStatus().isDock())
      {
        return 'Cannot attack docked spaceship.';
      }
    }

    if (!this.target.getZone() && !this.target.getSpaceship())
    {
      return 'Cannot attack empty space.';
    }

    return null;
  }


  getInitial ()
  {
    if (this.args.type === '')
    {
      return 'Select unit type';
    }

    if (this.target == null)
    {
      return 'Select target zone';
    }

    return null;
  }


  getUnitConstants ()
  {
    return this.galaxy.getConstants().getUnit(this.args.type);
  }


  canSelect ()
  {
    return this.args.type != null;
  }


  canSelectGalaxy ()
  {
    return true;
  }


  canNavigate ()
  {
    return false;
  }


  isPaneCollapsed ()
  {
    return this.args.type != null;
  }


});
