/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Header from 'fierry/react/header';


export default (props) =>
(
  <Header headline = 'Projectiles' >
  </Header>
);
