/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator';


export default class extends Validator
{
  isRouteVisible ()
  {
    return this.isZoneLocation() && this.isUserOwner() && this.isZoneCenterAndBuildingEmpty();
  }


  isRouteDisabled ()
  {
    return false;
  }


  isRouteActivated ()
  {
    return this.isBuildingStatusConstruction();
  }


  isZoneCenterAndBuildingEmpty ()
  {
    const currentZone = this.currentLocation.getZone();

    return currentZone.getCass().isCenter() && currentZone.getBuilding() == null;
  }


  isBuildingStatusConstruction ()
  {
    const building = this.currentLocation.getZone().getBuilding();

    return building && building.getStatus().isConstruction();
  }


};
