/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import Server from 'galax/server';

import Icon from 'svg/shared/modern/delete-stroke';


const isGateDisabled = (gate) =>
{
  for (const zone of gate.getPlatform())
  {
    if (zone.getUnit())
    {
      return true;
    }
  }

  return false;
}

const OnClick = (event, currentZone, server) =>
{
  const building = currentZone.getBuilding();

  if (building.getStatus().isDisband())
  {
    server.cancelEntityAction(currentZone);
  }
  else
  {
    server.disbandBuilding(currentZone);
  }
};


export default ({ currentZone, currentUser, ...props }) =>
{
  const [ server ] = useFlux(Server);

  const selected = currentZone.getBuilding().getStatus().isDisband();
  let disabled = !selected && !currentZone.getBuilding().getStatus().isDefault();

  if (disabled === false && currentZone.getBuilding().getType() === 'gate')
  {
    disabled = isGateDisabled(currentZone.getBuilding());
  }

  const onClick = useCallback(OnClick, [ currentZone, server ]);

  if (currentZone.getUser().getID() !== currentUser.getID())
  {
    return null;
  }

  const tooltip = selected ? 'Cancel Abandon' : 'Abandon';

  return <Modern.Button tooltip = {tooltip} icon = {<Icon />} contained = {selected} disabled = {disabled} onClick = {onClick} />;
}
