/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import UpgradeStatus from 'galax/server/building/status/upgrade';
import DisbandStatus from 'galax/server/building/status/disband';
import DefaultStatus from 'galax/server/building/status/default';

import SetupStatus from 'galax/server/building/status/setup';
import ActiveStatus from 'galax/server/building/status/active';
import CooldownStatus from 'galax/server/building/status/cooldown';


export default (status, parent) =>
{
  if (status != null)
  {
    switch (status.type)
    {
      case 'create':   return new UpgradeStatus(status, parent);
      case 'upgrade':  return new UpgradeStatus(status, parent);
      case 'disband':  return new DisbandStatus(status, parent);

      case 'setup':    return new SetupStatus(status, parent);
      case 'active':   return new ActiveStatus(status, parent);
      case 'cooldown': return new CooldownStatus(status, parent);
    }
  }

  return new DefaultStatus(status, parent);
}
