/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ParentEnum from 'galax/enum/parent';

import Client from 'fierry/flux/entity/client';

import Server from 'galax/server';
import Selection from 'galax/state/selection';
import View from 'fierry/client/view';

import * as Coords from 'galax/geo/coords';


export default new Client ([ Selection, View, Server ], class
{
  constructor ([ state, dispatch ], view, server)
  {
    this.state = state;
    this.dispatch = dispatch;

    this.view = view;
    this.server = server;
  }


  setSelection (zone)
  {
    this.dispatch(Selection.SetSelection, { planet: null, id: zone.getID() });

    this.view.pushArgs({ target: zone.getCoords().toString() });
  }


  clearSelection ()
  {
    this.dispatch(Selection.ClearSelection, { });

    this.view.pushArgs({ target: null });
  }


  getLocation ()
  {
    const id = this.view.getArg('target');

    if (id && this.server && this.server.isReady())
    {
      return this.server.getLocation(Coords.fromString(id));
    }

    return null;
  }


  getCount ()
  {
    return this.state.count;
  }


});
