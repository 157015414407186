/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { useFlux }  from 'fierry/react';

import Now from 'galax/query/now';

import Flight from 'galax/react/map/flight';


const getProjectiles = (currentLocation) =>
{
  return [].concat(currentLocation.getProjectilesTo(), currentLocation.getProjectilesFrom());
}


export default ({ galaxy, currentLocation, ...props }) =>
{
  const [ now ] = useFlux(Now);

  return getProjectiles(currentLocation).map(projectile =>
  {
    const user = projectile.getUser();
    const source = projectile.getSource();
    const target = projectile.getTarget();

    const key = projectile.getObject().getID();
    const progress = projectile.getObject().getFlightProgress(now);

    return <Flight key = {key} user = {user} sourceLocation = {source} targetLocation = {target} progress = {progress} />;
  });
}
