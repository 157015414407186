/*
 * Copyright (C) 2019 Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (user, type, value)
  {
    this.user = user;
    this.type = type;
    this.value = value;
  }


  isSatisfiedBy (user, type)
  {
    return this.user !== user && this.type === type;
  }


  getValue ()
  {
    return this.value;
  }


};
