/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import BuildingEnum from 'galax/enum/building';


export default class
{
  constructor ({ constants })
  {
    this.constants = constants;
  }


  getType ()
  {
    return this.constants.type;
  }


  getName ()
  {
    return this.constants.name;
  }


  getDescription ()
  {
    return this.constants.description;
  }


  getRange ()
  {
    return this.constants.range;
  }


  getUses ()
  {
    return this.constants.uses;
  }


  getConstructionCost ()
  {
    return this.constants.construction_cost;
  }


  getConstructionResources ()
  {
    return this.constants.construction_resources;
  }


  getConstructionResource (type)
  {
    const resources = this.getConstructionResources();

    switch (type)
    {
      case ResourceType.Carbon:   return resources.carbon;
      case ResourceType.Silicon:  return resources.silicon;
      case ResourceType.Hydrogen: return resources.hydrogen;
    }
  }


  getConstructionTime (level = 1)
  {
    // TODO hours(...);
    return Math.floor(level * this.constants.construction_time);
  }


  canFireProjectiles ()
  {
    const type = this.getType();

    return type === BuildingEnum.LaserBattery || type === BuildingEnum.GaussCannon || type === BuildingEnum.GaussArtillery;
  }


  // TODO maybe move all that to projectile constants and provide a way to access these from here ?

  getWeaponRange ()
  {
    switch (this.getType())
    {
      case BuildingEnum.LaserBattery:   return 15;
      case BuildingEnum.GaussCannon:    return 20;
      case BuildingEnum.GaussArtillery: return 25;
    }

    return 0;
  }


  getWeaponTargetCount ()
  {
    switch (this.getType())
    {
      case BuildingEnum.LaserBattery:   return 3;
      case BuildingEnum.GaussCannon:    return 4;
      case BuildingEnum.GaussArtillery: return 7;
    }

    return 0;
  }


  getWeaponTargetDamage ()
  {
    switch (this.getType())
    {
      case BuildingEnum.LaserBattery:   return 6;
      case BuildingEnum.GaussCannon:    return 4;
      case BuildingEnum.GaussArtillery: return 3;
    }

    return 0;
  }


};
