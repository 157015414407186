/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Building from 'galax/server/building';


const YIELD_INCREASE_BASE = Math.pow(2, 1/3);


export default class extends Building
{
  constructor (building, planet)
  {
    super(building, planet);

    this.platform = [];
  }


  installPlatformCoords (coords)
  {
    this.platform.push(coords);
  }


  getPlatform ()
  {
    let collection = [];

    for (const coords of this.platform)
    {
      collection.push(this.getPlanet().getPlatform(coords));
    }

    return collection;
  }

};
