/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Attributes from 'galax/server/zone/attributes';

import * as Coords from 'galax/geo/coords';


export default class
{
  constructor (location, user, planet)
  {
    this.location = location;
    this.planet = planet;
    this.user = user;

    this.attributes = new Attributes(this);
  }


  getAttributes ()
  {
    return this.attributes;
  }


  getCass ()
  {
    return { location: this.location, user: this.user };
  }


  getLocation (resolver = this.planet)
  {
    return resolver.getLocation(this.getCoords());
  }


  getUser (resolver = this.planet)
  {
    return resolver.getUser(this.getUserID());
  }


  getUnit ()
  {
    return this.getPlanet().getUnit(this.getID());
  }


  getInteractionsTo ()
  {
    return this.getPlanet().getInteractions().getInteractionsTo(this.getCoords());
  }


  getInteractionsFrom ()
  {
    return this.getPlanet().getInteractions().getInteractionsFrom(this.getCoords());
  }


  getProjectilesTo ()
  {
    return this.getPlanet().getProjectiles().getProjectilesTo(this.getCoords());
  }


  getProjectilesFrom ()
  {
    return this.getPlanet().getProjectiles().getProjectilesFrom(this.getCoords());
  }


  getPlanet ()
  {
    return this.planet;
  }


  isLocal ()
  {
    return true;
  }


  getCoords ()
  {
    return Coords.fromString(this.location.id);
  }


  getRelativeCoords ()
  {
    return Coords.substract(this.getCoords(), this.planet.getCoords());
  }


  getUserID ()
  {
    return this.user.id;
  }


  getID ()
  {
    return this.location.id;
  }


  valueOf ()
  {
    return this.getID();
  }


};
