/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Type from 'galax/enum/route';
import Access from 'galax/enum/access';

import Route from 'galax/react/skeleton/route';
import Switch from 'galax/react/skeleton/switch';

import UserOverview from 'galax/react/sidebar/user.overview';
import DockOverview from 'galax/react/sidebar/dock.overview';
import ZoneOverview from 'galax/react/sidebar/zone.overview';
import PlatformOverview from 'galax/react/sidebar/platform.overview';
import SpaceshipOverview from 'galax/react/sidebar/spaceship.overview';

import BuildingAttack from 'galax/react/sidebar/building.attack';
import BuildingCreateList from 'galax/react/sidebar/building.create.list';
import BuildingCreateType from 'galax/react/sidebar/building.create.type';
import BuildingUpgrade from 'galax/react/sidebar/building.upgrade';

import UnitAttack from 'galax/react/sidebar/unit.attack';
import UnitCreateList from 'galax/react/sidebar/unit.create.list';
import UnitCreateType from 'galax/react/sidebar/unit.create.type';

import ProjectileList from 'galax/react/sidebar/projectile.list';

import TransferList from 'galax/react/sidebar/transfer.list';
import TransferEdit from 'galax/react/sidebar/transfer.edit';

import SpaceshipAttack from 'galax/react/sidebar/spaceship.attack';
import SpaceshipCreateList from 'galax/react/sidebar/spaceship.create.list';
import SpaceshipCreateType from 'galax/react/sidebar/spaceship.create.type';
import SpaceshipMove from 'galax/react/sidebar/spaceship.attack';

import WelcomeHome from 'galax/react/sidebar/welcome/home';
import WelcomeLogin from 'galax/react/sidebar/welcome/login';
import WelcomeAbout from 'galax/react/sidebar/welcome/about';
import WelcomeManual from 'galax/react/sidebar/welcome/manual';

import WelcomeTerms from 'galax/react/sidebar/welcome/terms';
import WelcomePrivacy from 'galax/react/sidebar/welcome/privacy';


export default (props) =>
(
  <Switch {...props} >

    <Route type = {Type.Overview}            component = {WelcomeHome}         access = {Access.Unauthorized} />

    <Route type = {Type.Overview}            component = {UserOverview}        access = {Access.Galaxy} />
    <Route type = {Type.Overview}            component = {DockOverview}        access = {Access.Dock} />
    <Route type = {Type.Overview}            component = {ZoneOverview}        access = {Access.Zone} />
    <Route type = {Type.Overview}            component = {PlatformOverview}    access = {Access.Platform} />
    <Route type = {Type.Overview}            component = {SpaceshipOverview}   access = {Access.Spaceship} />

    <Route type = {Type.BuildingAttack}      component = {BuildingAttack}      access = {Access.Zone} />
    <Route type = {Type.BuildingCreateList}  component = {BuildingCreateList}  access = {Access.Zone} />
    <Route type = {Type.BuildingCreateType}  component = {BuildingCreateType}  access = {Access.Zone} />
    <Route type = {Type.BuildingUpgrade}     component = {BuildingUpgrade}     access = {Access.Zone} />

    <Route type = {Type.UnitAttack}          component = {UnitAttack}          access = {Access.Zone} />
    <Route type = {Type.UnitCreateList}      component = {UnitCreateList}      access = {Access.Zone} />
    <Route type = {Type.UnitCreateType}      component = {UnitCreateType}      access = {Access.Zone} />

    <Route type = {Type.UnitCreateList}      component = {UnitCreateList}      access = {Access.Platform} />
    <Route type = {Type.UnitCreateType}      component = {UnitCreateType}      access = {Access.Platform} />

    <Route type = {Type.SpaceshipAttack}     component = {SpaceshipAttack}     access = {Access.Spaceship} />
    <Route type = {Type.SpaceshipCreateList} component = {SpaceshipCreateList} access = {Access.Dock} />
    <Route type = {Type.SpaceshipCreateType} component = {SpaceshipCreateType} access = {Access.Dock} />
    <Route type = {Type.SpaceshipMove}       component = {SpaceshipMove}       access = {Access.Spaceship} />

    <Route type = {Type.ProjectileList}      component = {ProjectileList}      access = {Access.Location} />

    <Route type = {Type.TransferEdit}        component = {TransferEdit}        access = {Access.Zone} />
    <Route type = {Type.TransferList}        component = {TransferList}        access = {Access.Zone} />

    <Route type = {Type.WelcomeLogin}        component = {WelcomeLogin}        access = {Access.None} />
    <Route type = {Type.WelcomeAbout}        component = {WelcomeAbout}        access = {Access.None} />
    <Route type = {Type.WelcomeManual}       component = {WelcomeManual}       access = {Access.None} />

    <Route type = {Type.WelcomeTerms}        component = {WelcomeTerms}        access = {Access.None} />
    <Route type = {Type.WelcomePrivacy}      component = {WelcomePrivacy}      access = {Access.None} />

  </Switch>
);
