/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Cass from 'galax/server/sector/cass';
import Influences from 'galax/server/sector/influences';
import Neighbors from 'galax/server/sector/neighbors';
import Transfers from 'galax/server/sector/transfers';
import Status from 'galax/server/sector/status';
import Zones from 'galax/server/sector/zones';


export default class
{
  constructor (cass, planet)
  {
    this.planet = planet;

    this.cass = new Cass(cass);
    this.influences = new Influences(this);

    this.neighbors = new Neighbors(this);
    this.transfers = new Transfers(this);
    this.status = new Status(this);
    this.zones = new Zones(this);
  }


  install ()
  {
    this.zones.setup();

    this.influences.install();
    this.zones.install();
    this.status.install();
  }


  getInfluences ()
  {
    return this.influences;
  }


  getNeighbors ()
  {
    return this.neighbors;
  }


  getTransfers ()
  {
    return this.transfers;
  }


  getStatus ()
  {
    return this.status;
  }


  getZones ()
  {
    return this.zones;
  }


  getPlanet ()
  {
    return this.planet;
  }


  getUser ()
  {
    return this.planet.getUser(this.getCass().getUser());
  }


  getCenterZone ()
  {
    return this.getZones().getZone(this.getCass().getCenter());
  }


  getBuilding ()
  {
    return this.planet.getBuilding(this.getCass().getCenter().toString());
  }


  getConstants ()
  {
    return this.planet.getConstants().getGeneral();
  }


  getID ()
  {
    return this.cass.getID();
  }


  getCass ()
  {
    return this.cass;
  }


}
