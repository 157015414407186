/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Header from 'fierry/react/header';

import UnitCreateButton from 'galax/react/button/unit/create';
import SpaceshipCreateButton from 'galax/react/button/spaceship/create';


export default (props) =>
(
  <Header headline = 'Dock' >

    <SpaceshipCreateButton {...props} />

  </Header>
);
