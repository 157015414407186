/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import IncreaseUnit from 'svg/shared/unit/increase.js';
import DecreaseUnit from 'svg/shared/unit/decrease.js';


export default ({ value, ...props }) =>
{
  return (value < 0) ? <DecreaseUnit {...props} /> : <IncreaseUnit {...props} />;
};
