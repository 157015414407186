/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator';


export default class extends Validator
{
  isRouteVisible ()
  {
    return this.isSpaceshipLocation() && this.isUserOwner();
  }


  isRouteDisabled ()
  {
    return !this.isSpaceshipStatusDefault() && !this.isSpaceshipStatusMove();
  }


  isRouteActivated ()
  {
    return this.isSpaceshipStatusMove();
  }


  isSpaceshipStatusMove ()
  {
    const spaceship = this.currentLocation.getSpaceship();

    return spaceship && spaceship.getStatus().isMove();
  }


  isSpaceshipStatusDefault ()
  {
    const spaceship = this.currentLocation.getSpaceship();

    return spaceship && spaceship.getStatus().isDefault();
  }


};
