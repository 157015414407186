/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Tree from 'avl'

import compare from 'fierry/flux/cache-compare';


export default class Cache
{
  constructor (factory)
  {
    this.factory = factory;

    this.cache = new Tree(compare);
  }


  get (...key)
  {
    const found = this.cache.find(key);

    if (found !== null)
    {
      return found.data;
    }

    const object = (0, this.factory)(...key);

    this.cache.insert(key, object);

    return object;
  }


  has (...key)
  {
    return this.cache.find(key) !== null;
  }


};
