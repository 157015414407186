/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as EnergyValue from 'galax/value/energy';
import * as InfluenceValue from 'galax/value/influence';


export default class
{
  getSectorBaseWeight ()
  {
    return 0.5;
  }


  getSectorTransferConstant ()
  {
    return InfluenceValue.fromConstants(1000);
  }


  getSectorTransferDistanceConstant ()
  {
    return 400;
  }


  getProjectileStrength ()
  {
    return EnergyValue.fromConstants(100);
  }


  getZoneFullEnergy ()
  {
    return EnergyValue.fromConstants(1000);
  }


  getZoneEmptyEnergy ()
  {
    return EnergyValue.fromConstants(0);
  }


  getZoneOverheatEnergy ()
  {
    return EnergyValue.fromConstants(800);

  }


  getZoneCriticalEnergy ()
  {
    return EnergyValue.fromConstants(200);
  }


  getOutpostBuildingInfluence ()
  {
    return InfluenceValue.fromConstants(10);
  }


  getUserPointsModifier ()
  {
    return 2.0 / 3.0;
  }


  getUserPointsExponent()
  {
    return 0.7;
  }


  getUserPlanetsExponent ()
  {
    return 0.5;
  }


  getAvailableBuildingsBase ()
  {
    return 1.04;
  }


  getAvailableBuildingsConstant ()
  {
    return 2.25 * 60 * 60 * 1000 * 16; // 2.25 hours * initial_zone_count
  }


  getZoneYieldExponent ()
  {
    return 0.9;
  }


  getZoneYieldConstant ()
  {
    return InfluenceValue.fromConstants(20);
  }


  getAncientZoneInfluence()
  {
    return InfluenceValue.fromConstants(30);
  }


};
