/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { css, styled } from 'fierry/react/styled';


const getAlign = ({ leftAlign, rightAlign, centerAlign, justifyAlign, align = 'justify' }) =>
{
  if (leftAlign)    { return 'flex-start'; }
  if (rightAlign)   { return 'flex-end'; }
  if (centerAlign)  { return 'center'; }
  if (justifyAlign) { return 'space-between'; }

  switch (align)
  {
    case 'left':    return 'flex-start';
    case 'right':   return 'flex-end';
    case 'justify': return 'space-between';
  }

  return align;
}


const getDirection = ({ horizontal, vertical, direction = 'horizontal' }) =>
{
  if (horizontal) { return 'horizontal'; }
  if (vertical)   { return 'vertical'; }

  return direction;
}


const getGridColumns = (grid) =>
{
  let columns = grid.split('-');

  for (let i = 0; i < columns.length; ++i)
  {
    columns[i] = columns[i] + 'fr';
  }

  return columns.join(' ');
}


const Attrs = (props) =>
({
  align: getAlign(props),
  direction: getDirection(props),
});


const gridConfig = ({ grid }) => grid && css`
  display: grid;
  grid-template-columns: ${getGridColumns(grid)};

  flex-grow: 1;
  justify-items: start;
`;


const horizontalConfig = ({ direction }) => (direction === 'horizontal') && css`
  flex-direction: row;
  align-items: center;
`;


const verticalConfig = ({ direction }) => (direction === 'vertical') && css`
  flex-direction: column;
  align-items: stretch;
`;


const alignConfig = ({ align }) => align && css`
  justify-content: ${align};
`;


const stretchConfig = ({ stretch }) => stretch && css`
  flex-grow: 1;
`;


const widthConfig = ({ width }) => width && css`
  width: ${width}rem;
`;


const heightConfig = ({ height }) => height && css`
  height: ${height}rem;
`;


const horizontalSpacingConfig = ({ spacing, direction }) => (spacing && direction === 'horizontal') && css`
  > :not(:last-child)
  {
    margin-right: ${spacing}rem;
  }
`;


const verticalSpacingConfig = ({ spacing, direction }) => (spacing && direction === 'vertical') && css`
  > :not(:last-child)
  {
    margin-bottom: ${spacing}rem;
  }
`;


export default styled.div.attrs(Attrs)`

  display: flex;
  flex-wrap: wrap;

  ${gridConfig}
  ${horizontalConfig}
  ${verticalConfig}

  ${alignConfig}
  ${stretchConfig}

  ${widthConfig}
  ${heightConfig}

  ${horizontalSpacingConfig}
  ${verticalSpacingConfig}
`;
