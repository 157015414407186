/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';


export default (users) =>
{
  let state = new Immutable.Map();

  for (const user of users)
  {
    state = state.set(user.getID(), user.getType());
  }

  return state;
}
