/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (props)
  {
    this.props = props;
  }


};
