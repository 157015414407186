/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback } from 'fierry/react';

import Item from 'fierry/react/skeleton/drawer/item';

import Icon from 'svg/shared/modern/mastodon';


const OnClick = (event) =>
{
  window.open('https://masto.nyc/@fierry', '_blank');
}


export default (props) =>
{
  const onClick = useCallback(OnClick, [ ] );

  return <Item text = 'Mastodon' icon = {<Icon />} onClick = {onClick} />
};
