/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';

import GalaxyModel from 'galax/server/session';

import UsersState from 'galax/fake/state/users';
import ConstantsState from 'galax/fake/state/constants';

import PlanetsState from 'galax/fake/state/planets';
import SpaceshipsState from 'galax/fake/state/spaceships';


export default (galaxy) =>
{
  let state = new Immutable.Map();

  state = state.set('currentTime', galaxy.getCurrentTime());
  state = state.set('currentGalaxy', galaxy.getCurrentGalaxy());

  state = state.set('users', UsersState(galaxy.getUsers()));
  state = state.set('constants', ConstantsState(galaxy.getConstants()));

  state = state.set('planets', PlanetsState(galaxy.getPlanets()));
  state = state.set('spaceships', SpaceshipsState(galaxy.getSpaceships()));

  return new GalaxyModel(state, null);
}
