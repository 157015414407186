/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><polyline points="5 20 15 12 5 4" className="black-line-20"/></Svg>
  );
}
