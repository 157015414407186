/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import View from 'fierry/client/view';

import Server from 'galax/server';

import Icon from 'svg/shared/modern/attack-stroke';


const OnClick = (event, view, server, building) =>
{
  if (building.getStatus().isSetup() || building.getStatus().isActive())
  {
    server.cancelEntityAction(building.getZone());
  }
  else
  {
    view.pushForeground('/building.attack');
  }
};


export default ({ currentZone, currentUser, ...props }) =>
{
  const [ server, view ] = useFlux(Server, View);

  const building = currentZone.getBuilding();

  const selected = building.getStatus().isSetup() || building.getStatus().isActive();
  const enabled = building.getStatus().isDefault() || selected;

  const onClick = useCallback(OnClick, [ view, server, building ]);

  if (currentZone.getUser().getID() !== currentUser.getID() || !currentZone.getBuilding().canFireProjectiles())
  {
    return null;
  }

  const tooltip = selected ? 'Cancel Attack' : 'Attack';

  return <Modern.Button tooltip = {tooltip} icon = {<Icon />} contained = {selected}  disabled = {!enabled} onClick = {onClick} />;
}
