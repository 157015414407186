/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { withRef } from 'fierry/react/native';
import { styled, css } from 'fierry/react/styled';

import Group from 'fierry/react/modern/group';
import Separator from 'fierry/react/modern/separator';


const Attrs = (props) =>
({
  direction: 'vertical',
});


const FullScreenDiv = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;


const Card = styled(Group).attrs(Attrs)`
  padding: 1rem 1.5rem 1rem 1.5rem; // 3rem right

  --native-foreground-color: var(--fierry-theme-foreground, black);
  --native-background-color: var(--fierry-theme-background, white);
`;


const Space = styled.div`
  flex-grow: 1;
`;


const FullScreenCard = withRef( ({ children, ...props }, ref) =>
(
  <FullScreenDiv ref = {ref} >
    <Separator />

    <Card {...props} fullScreen = {true} children = {children} />

    <Space />
    <Separator />

  </FullScreenDiv>
));


const NormalCard = withRef( ({ children, ...props }, ref) =>
(
  <div ref = {ref}>
    <Card {...props} children = {children} />
    <Separator />
  </div>
));


export default withRef( ({ fullScreen, ...props }, ref) =>
{
  return fullScreen ? <FullScreenCard {...props} ref = {ref} /> : <NormalCard {...props} ref = {ref} />;
});
