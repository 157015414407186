/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (cass)
  {
    this.cass = cass;
  }


  getID ()
  {
    return this.cass.id;
  }


  getUser ()
  {
    return this.cass.user;
  }


  getPlanet ()
  {
    return this.cass.planet;
  }


  getName ()
  {
    return this.cass.name;
  }


  getCenter ()
  {
    return this.cass.center;
  }


  getZoneCollection ()
  {
    return this.cass.zones;
  }


}
