/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Transition } from 'react-transition-group';


export default ({ lazy, active, callback, timeout = 350, ...props }) =>
{
  const lazyProps = lazy ? { mountOnEnter: true, unmountOnExit: true } : {};

  const transitionEndListener = (node, onDone) =>
  {
    node.addEventListener('transitionend', onDone);
  }

  const children = (state) =>
  {
    const childrenProps =
    {
      isExit:      state === 'exiting',
      isExitDone:  state === 'exited',

      isEnter:     state === 'entering',
      isEnterDone: state === 'entered',
    }

    return callback(childrenProps);
  }

  return <Transition {...props} {...lazyProps} in = {active} addEndListener = {transitionEndListener} timeout = {timeout} children = {children} />
}
