/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Modern from 'fierry/react/modern';

import { styled } from 'fierry/react/styled';
import { withRef } from 'fierry/react/native';


const ItemCard = styled(Modern.Card)`

  cursor: pointer;

  :hover
  {
    background: var(--fierry-theme-background-hover);
  }

  :active
  {
    background: var(--fierry-theme-background-active);
  }
`;


export default withRef( ({ context, onExpand, ...props }, ref) =>
{
  if (context && context.variant === 'item')
  {
    return <ItemCard {...props} ref = {ref} onClick = {onExpand} />;
  }

  return <Modern.Card {...props} ref = {ref} />;
})
