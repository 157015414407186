/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, Card, Modern, Element, useFlux, withValue } from 'fierry/react';

import Now from 'galax/query/now';

import ResourcesGroup from 'galax/react/group/resources';
import CountdownElement from 'galax/react/element/countdown';
import ProgressElement from 'galax/react/element/progress';

import ProductionIcon from 'svg/shared/modern/production-stroke';


const getProgressValue = ({ currentUser }, now) =>
{
  return currentUser.getCurrency(now) % 1;
}


const getProgressPrimary = ({ currentUser }) =>
{
  return true;
}


const getRemainingTime = ({ currentUser }, now) =>
{
  const progress = 1 - getProgressValue({ currentUser }, now);

  return progress * currentUser.getTotalTimeToNextCurrency(now);
}


const ResourcesGroupWithMargin = styled(ResourcesGroup)`
  margin-top: 1rem;
`;


const WideProgressElement = styled(ProgressElement)`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`;


const Component = (props) =>
(
  <Card {...props} headline = 'Resources' >

    <Modern.Group vertical spacing = '0.25'>

      <Modern.Group>

        <Modern.Text body
          icon = {<ProductionIcon />}
          text = {'Currency'}
        />

        <CountdownElement {...props} value = {getRemainingTime} />

      </Modern.Group>

      <WideProgressElement primary {...props}
        value = {getProgressValue}
      />

    </Modern.Group>

    <ResourcesGroupWithMargin {...props} />

  </Card>
);


export default (props) =>
{
  const [ now ] = useFlux(Now);

  return <Component {...props} now = {now} />
}
