/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, css } from 'fierry/react/styled';


const widthConfig = ({ width }) => width && css`
  width: ${width}rem;
`;


export default styled.div`
  ${widthConfig}
`;
