/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux, Modern } from 'fierry/react';

import Auth from 'fierry/client/auth';
import View from 'fierry/client/view';


const OnClick = (event, auth, view) =>
{
  if (auth.isLoggedIn())
  {
    view.pushForeground('/');
  }
  else
  {
    view.pushForeground('/login');
  }
}


export default (props) =>
{
  const [ auth, view ] = useFlux(Auth, View);

  const onClick = useCallback(OnClick, [ auth, view ])

  return <Modern.Button {...props} icon = 'login' tooltip = 'Log In' onClick = {onClick} />;
}
