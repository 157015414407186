/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, Element } from 'fierry/react';

import Points from 'svg/shared/modern/points';


const getUserPlace = ({ index }) =>
{
  return (index + 1) + '.';
}


const getUserName = ({ user }) =>
{
  return user.getName();
}


const getUserPoints = ({ user }) =>
{
  const value = Math.round(user.getPoints());

  return value < 10 ? '0' + value : value;
}


export default (props) =>
(
  <Modern.Group>

    <Element.Group param>
      <Element.Text value = {getUserPlace(props)} />
      <Element.Text value = {getUserName(props)} />
    </Element.Group>

    <Modern.Text param width = '3'
      icon = {<Points />}
      text = {getUserPoints(props)}
    />

  </Modern.Group>
);
