/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><circle cx="12" cy="12" r="7" className="black-line-20"/><line x1="2.5" y1="12" x2="21.5" y2="12" className="black-line-20"/><line x1="12" y1="2.5" x2="12" y2="21.5" className="black-line-20"/></Svg>
  );
}
