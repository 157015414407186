/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><polyline points="21 15 21 19 3 19 3 15" className="black-line-20"/><polygon points="7 14 17 14 12 5 7 14" className="black-line-20"/></Svg>
  );
}
