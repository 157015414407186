/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import 'cookieconsent/build/cookieconsent.min.js';
import 'cookieconsent/build/cookieconsent.min.css';


window.cookieconsent.initialise(
{
  palette:
  {
    popup:  { background: "#000" },
    button: { background: "#f1d600" },
  },

  content:
  {
    message: "We use cookies to operate this website.",
    dismiss: "Dismiss",
    link:    "Learn more",
    href:    "https://www.fierry.com/#privacy",
  },
});
