/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern } from 'fierry/react';

import TransferBetween from 'galax/react/element/transfer/between';
import TransferEfficiency from 'galax/react/element/efficiency';
import TransferValue from 'galax/react/element/transfer/value';


const getReverse = ({ state }) =>
{
  return state.getReverse();
}


const getSourceName = ({ source }) =>
{
  return source.getZone().getSector().getCass().getName();
}


const getTargetName = ({ target }) =>
{
  return target.getZone().getSector().getCass().getName();
}


const getTransferValue = ({ source, target, state, selection }) =>
{
  const strength = source.getZone().getSector().getTransfers().getTransferStrength(target.getZone().getSector());
  const ratio = source.getZone().getSector().getTransfers().getTransferRatio(target.getZone().getSector(), state);

  return strength * ratio;
}


const getTransferEfficiency = ({ source, target, state, selection }) =>
{
  const constants = source.getZone().getPlanet().getConstants().getGeneral();

  const zoneAttrition = source.getZone().getAttributes().getZoneAttrition();
  const transferRatio = source.getZone().getSector().getTransfers().getTransferRatio(target.getZone().getSector(), state);
  const pointEfficiency = (1 - source.getZone().getAttributes().getTransferEfficiency()) * transferRatio;

  const transferDistance = source.getZone().getSector().getTransfers().getTransferDistance(target.getZone().getSector()) / constants.getSectorTransferDistanceConstant();

  return Math.max(Math.round((1 - (pointEfficiency + transferDistance) * zoneAttrition) * 100), 1);
}


export default (props) =>
(
  <Modern.Group>

    <TransferBetween {...props}
      reverse = {getReverse}
      sourceValue = {getSourceName}
      targetValue = {getTargetName}
    />

    <TransferValue {...props}
      value = {getTransferValue}
    />

    <TransferEfficiency {...props} tooltip = 'Transfer Efficiency'
      value = {getTransferEfficiency}
    />

  </Modern.Group>
);
