/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { getShapeToCount } from 'galax/enum/shape'


export default class
{
  constructor ({ constants })
  {
    this.constants = constants;
  }


  getType ()
  {
    return this.constants.type;
  }


  getShape ()
  {
    return this.constants.shape;
  }


  getPlanetStrength ()
  {
    return this.constants.strength;
  }


  getSpaceshipStrength ()
  {
    return getShapeToCount(this.constants.shape) * this.constants.strength;
  }


  getFlightTime ()
  {
    return this.constants.flight_time;
  }


};
