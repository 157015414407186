/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react';


export default styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;

  width: var(--fierry-pane-width);
  height: var(--fierry-pane-height);
`;
