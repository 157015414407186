/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import View from 'fierry/client/view';
import Server from 'galax/server';

import Icon from 'svg/shared/modern/production-stroke';

import ShapeEnum from 'galax/enum/shape';
import ParentEnum from 'galax/enum/parent';
import { makeLocation } from 'galax/server/location';
import * as Coords from 'galax/geo/coords';


const OnClick = (event, currentLocation, view, server) =>
{
  if (currentLocation && currentLocation.getObject().getUnit())
  {
    const unit = currentLocation.getObject().getUnit();

    server.cancelEntityAction(unit.getZone());
  }
  else
  {
    view.pushForeground('/unit.create');
  }
}


export default ({ currentZone, currentLocation, currentUser, ...props }) =>
{
  const [ view, server ] = useFlux(View, Server);

  const onClick = useCallback(OnClick, [ currentLocation, view, server ]);

  const unit = currentLocation.getObject().getUnit();

  const selected = unit && unit.getStatus().isProduction();
  const enabled = currentZone ? !currentZone.getBuilding() : true;

  if (currentLocation.getUser().getID() !== currentUser.getID() || (currentZone && currentZone.getCass().isCenter()))
  {
    return null;
  }

  const tooltip = selected ? 'Cancel Production' : 'Create Unit'

  return <Modern.Button tooltip = {tooltip} icon = {<Icon />} contained = {selected} disabled = {!enabled} onClick = {onClick} />;
}
