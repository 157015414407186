/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const unique = (array, isEqual) =>
{
  const uniqueOnlyCallback = (lhs, index, array) =>
  {
    const found = isEqual ? array.findIndex(rhs => isEqual(lhs, rhs)) : array.indexOf(lhs);

    return found === index;
  }

  return array.filter(uniqueOnlyCallback);
}
