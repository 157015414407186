/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import extract from 'fierry/flux/dependency/extract';
import isVisible from 'fierry/flux/hook/is.visible';

import String from 'fierry/util/string';


const onChange = (state, payload) =>
{
  return { ...state, ...payload };
}


const getGetterName = (name) =>
{
  return 'get' + String.capitalize(name);
}


const getSetterName = (name) =>
{
  return 'set' + String.capitalize(name);
}


const areDependencesEqual = (nextDependences, prevDependences) =>
{
  for (let i = 0; i < nextDependences.length; ++i)
  {
    if (nextDependences[i] !== prevDependences[i])
    {
      return false;
    }
  }

  return true;
}


const construct = (state, dispatch, visible) =>
{
  if (!visible)
  {
    return null;
  }

  let client = {};

  for (const name in state)
  {
    client[getGetterName(name)] = () =>
    {
      return state[name];
    }

    client[getSetterName(name)] = (value) =>
    {
      dispatch({ [name]: value });
    }
  }

  return client;
}


export default (dependency, props) =>
{
  const nextDependences = extract(dependency.getDependences(), props);

  const [state, dispatch] = React.useReducer(onChange, dependency.getEntity().getState());
  const [prevDependences, setPrevDependences] = React.useState(nextDependences);

  const visible = isVisible(dependency);

  // Clear state whenever dependency changes.
  if (!areDependencesEqual(nextDependences, prevDependences))
  {
    dispatch(dependency.getEntity().getState());

    setPrevDependences(nextDependences);
  }

  return React.useMemo(() => construct(state, dispatch, visible), [state, dispatch, visible]);
}
