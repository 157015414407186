/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  BasicAttack:      'basic-attack',
  BasicDefence:     'basic-defence',
  RangeAttack:      'range-attack',
  ConeAttack:       'cone-attack',
  AreaAttack:       'area-attack',
};
