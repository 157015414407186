/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { React } from 'fierry/react/native';
import { styled } from 'fierry/react/styled';

import * as Element from 'fierry/react/element';


const ParamGroup = styled(Element.Group)`
  padding: 0.25rem 0;
`;


// TODO should have top-bottom 0.25rem padding at all? Technically it's not its place to add it!
export default ({ text, icon, unit, ...props }) =>
(
  <Element.Group {...props} param >

    <Element.Icon value = {icon} />
    <Element.Text value = {text} />
    <Element.Unit value = {unit} />

  </Element.Group>
);
