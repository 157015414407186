/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import { canActivate } from 'galax/enum/building';

import Icon from 'galax/react/icon/building';


const OnClick = (event, galaxy, building) =>
{
  if (building.getStatus().isSetup() || building.getStatus().isActive())
  {
    galaxy.cancelEntityAction(building.getZone());
  }
  else
  {
    galaxy.activateBuilding(building.getZone().getLocation())
  }
};


export default ({ currentZone, currentUser, galaxy, ...props }) =>
{
  const building = currentZone.getBuilding();

  const selected = building.getStatus().isSetup() || building.getStatus().isActive();
  const enabled = building.getStatus().isDefault() || selected;

  const onClick = useCallback(OnClick, [ galaxy, building ]);

  if (currentZone.getUser().getID() !== currentUser.getID() || !canActivate(building.getType()))
  {
    return null;
  }

  const tooltip = selected ? 'Cancel Activate' : 'Activate';

  return <Modern.Button tooltip = {tooltip} icon = {<Icon type = {building.getType()} />} contained = {selected}  disabled = {!enabled} onClick = {onClick} />;
}
