/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Status from 'galax/server/spaceship/status';


export default class extends Status
{
  getEndDate ()
  {
    const date = this.parent.getUpdateDate();

    return new Date(date.getTime() + this.parent.getTimeToEmpty(date));
  }


  getStartDate ()
  {
    return this.parent.getCass().create_date;
  }


};
