/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, styled, useCallback, useFlux } from 'fierry/react';

import Behavior from 'galax/client/behavior';
import State from 'fierry/state/pane';
import Url from 'fierry/client/url';

import Container from 'fierry/react/skeleton/pane/container/mobile';

import Header from 'fierry/react/skeleton/header'
import Sidebar from 'fierry/react/skeleton/sidebar'


const Space = styled.div`

  pointer-events: none;
  flex-grow: 1;
`;


const Component = ({ collapsed, header, sidebar, onSidebarClick, ...props }) =>
(
  <Container>

    <Header
      header = {header}
    />

    <Space />

    <Sidebar
      sidebar = {sidebar}
      collapsed = {collapsed}
      onClick = {onSidebarClick}
    />

  </Container>
);


const OnSidebarClick = (event, state, url) =>
{
  if (state.getCollapsed() && url.getRoute().getValue() === '/')
  {
    state.setCollapsed(false);
  }
}


export default (props) =>
{
  const [ behavior, state, url ] = useFlux(Behavior, State, Url);

  const onSidebarClick = useCallback(OnSidebarClick, [ state, url ]);

  return <Component {...props} collapsed = {behavior.isPaneCollapsed()} onSidebarClick = {onSidebarClick} />;
}
