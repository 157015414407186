/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern } from 'fierry/react';

import Transfers from 'galax/react/card/sector.transfer.list/transfers';

import CreateButton from 'galax/react/button/sector.transfer/create';


const Buttons = (props) =>
(
  <CreateButton {...props} />
);


export default (props) =>
(
  <Card {...props} headline = 'Transfers' buttons = {Buttons} >
    <Transfers {...props} />
  </Card>
);
