  /*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as User from 'galax/value/user';


const DEFAULT_USER_STRENGTH = 1.0;
const MINIMUM_USER_STRENGTH = 0.1;

const DEFAULT_USER_DEFENCE = 0.05;
const ANCIENT_USER_DEFENCE = 0.25;

const RELATIVE_STRENGTH_BRACKET_SIZE = 1.0 / 3.0;


const getRelativeStrengthIndex = (lhs, rhs) =>
{
  return Math.floor((lhs.strength / rhs.strength - 1) / RELATIVE_STRENGTH_BRACKET_SIZE);
}


const getUserDefence = (lhs, rhs) =>
{
  if (lhs.strength > rhs.strength)
  {
    return DEFAULT_USER_DEFENCE;
  }

  if (rhs.id === User.getAncient())
  {
    return ANCIENT_USER_DEFENCE;
  }

  return 0;
}


// TODO would need last rhs login date (local / global user update_date) and the current date (currentTime or currentUser update_date).
// How to provide these ?
export const getRelativeStrength = (lhs, rhs) =>
{
  return 1.0;

  /*if (lhs.id === User.getAncient() || lhs.id === User.getEmpty() || rhs.id === User.getEmpty())
  {
    return DEFAULT_USER_STRENGTH;
  }

  return Math.max(1.0 - getRelativeStrengthIndex(lhs, rhs) * getUserDefence(lhs, rhs), MINIMUM_USER_STRENGTH);*/
}
