/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import Container from 'fierry/react/skeleton/pane/container/desktop';

import Header from 'fierry/react/skeleton/header'
import Sidebar from 'fierry/react/skeleton/sidebar'


export default ({ className, header, sidebar, ...props }) =>
(
  <Container className = {className} >

    <Header header = {header} />
    <Sidebar sidebar = {sidebar} />

  </Container>
);
