/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';
import styled from 'styled-components';

import filter from 'fierry/react/styled/filter';


export default (tag) =>
{
  return styled(React.forwardRef( ({ children, ...props }, ref) =>
  {
    return React.createElement(tag, { ref: ref, ...filter[tag](props) }, children);
  }));
}
