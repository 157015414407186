/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const ALL = '*';

export const DIV = 'div';

export const A = 'a';
export const SPAN = 'span';

export const OL = 'ol';
export const UL = 'ul';
export const LI = 'li';

export const SVG = 'svg';
export const G = 'g';

export const PATH = 'path';
export const POLYGON = 'polygon';
export const POLYLINE = 'polyline';

export const LINE = 'line';
export const RECT = 'rect';
export const CIRCLE = 'circle';
export const ELLIPSE = 'ellipse';


const ATTRIBUTES =
{
  'aria-describedby': [ALL], // TODO probably needs way more aria attributes pass-through! 'aria-*' should be used to match ?

  id: [ALL],
  slot: [ALL],
  tabindex: [ALL],

  style: [ALL],
  className: [ALL],

  href: [A],

  viewBox: [SVG],
  preserveAspectRatio: [SVG],

  d: [PATH],
  points: [POLYGON, POLYLINE],

  x: [SVG, RECT],
  y: [SVG, RECT],
  width: [SVG, RECT],
  height: [SVG, RECT],

  x1: [LINE],
  x2: [LINE],
  y1: [LINE],
  y2: [LINE],

  cx: [CIRCLE, ELLIPSE],
  cy: [CIRCLE, ELLIPSE],

  r: [CIRCLE],
  rx: [RECT, ELLIPSE],
  ry: [RECT, ELLIPSE],

  mask: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  filter: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  transform: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],

  clipPath: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  clipRule: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],

  color: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  colorInterpolation: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  colorRendering: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],

  fill: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  fillOpacity: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  fillRule: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],

  stroke: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  strokeDasharray: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  strokeDashoffset: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  strokeLinecap: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  strokeLinejoin: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  strokeMiterlimit: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  strokeOpacity: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  strokeWidth: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],

  opacity: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],

  shapeRendering: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  vectorEffect: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],

  display: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],
  visibility: [SVG, G, PATH, POLYGON, POLYLINE, LINE, RECT, CIRCLE, ELLIPSE],

  onFocus: [ALL],
  onBlur: [ALL],

  onKeyDown: [ALL],
  onKeyPress: [ALL],
  onKeyUp: [ALL],

  onClick: [ALL],
  onDoubleClick: [ALL],

  onMouseDown: [ALL],
  onMouseUp: [ALL],

  onMouseEnter: [ALL],
  onMouseMove: [ALL],
  onMouseLeave: [ALL],

  onTouchStart: [ALL],
  onTouchEnd: [ALL],

  onPointerDown: [ALL],
  onPointerUp: [ALL],

  onPointerEnter: [ALL],
  onPointerMove: [ALL],
  onPointerLeave: [ALL],

  onAnimationStart: [ALL],
  onAnimationCancel: [ALL],
  onAnimationEnd: [ALL],

  onTransitionStart: [ALL],
  onTransitionCancel: [ALL],
  onTransitionEnd: [ALL],
};


export default (type) =>
{
  let attributes = {};

  for (let name in ATTRIBUTES)
  {
    if (ATTRIBUTES[name].includes('*') || ATTRIBUTES[name].includes(type))
    {
      attributes[name] = true;
    }
  }

  return attributes;
}
