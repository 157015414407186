/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Model from 'fierry/flux/model';
import String from 'fierry/util/string';

import ResourceEnum from 'galax/enum/resource';

import * as Coords from 'galax/geo/coords';


const ANCIENT_ID = "00000000-0000-0000-0000-000000000000";

const HOUR_IN_MILLISECONDS = 3600 * 1000;

const CURRENCY_DEFAULT_YIELD = 15;
const CURRENCY_PLANET_YIELD = 5;
const CURRENCY_ZONE_YIELD = 0;

const CURRENCY_COMMON_RATIO = Math.pow(2.0, 0.2);


export default class extends Model
{
  static construct (id, { queries })
  {
    return { user: queries.getUser(id) };
  }


  static constructByCurrent ({ queries })
  {
    return { user: queries.getCurrentUser() };
  }


  constructor (props)
  {
    super(props);
  }


  getID ()
  {
    const { user } = this.props;

    return user ? user.id : '';
  }


  getTheme ()
  {
    const { user } = this.props;

    return this.props.parent.getConstants().getTheme(user ? user.theme : 0);
  }


  getName ()
  {
    if (this.isAncientUser())
    {
      return '- - - - -';
    }

    const { user } = this.props;

    return user ? String.capitalize(this.props.user.name) : '';
  }


  getPlanetCount ()
  {
    const { user } = this.props;

    return user ? user.planets : 0;
  }


  getZoneCount ()
  {
    const { user } = this.props;

    return user ? user.zones : 0;
  }


  getPoints ()
  {
    return Math.pow(this.getZoneCount(), this.getConstants().getUserPointsExponent());
  }


  getZoneYield ()
  {
    const zoneCount = this.getZoneCount();

    return Math.round(Math.pow(zoneCount, this.getConstants().getZoneYieldExponent()) / zoneCount * this.getConstants().getZoneYieldConstant());
  }


  getCurrency (date)
  {
    const { user } = this.props;

    return user ? this.getCurrencyInternal(date) - user.resources.currency : this.getCurrencyInternal(date);
  }


  getCurrencyInternal (date)
  {
    const { user } = this.props;

    if (user == null)
    {
      return 0;
    }

    if (date == null)
    {
      date = user.update_date;
    }

    const milliseconds = date - user.update_date;

    return this.__getCurrencyFromTime__(milliseconds + this.__getTimeFromCurrency__(user.deposit.currency));
  }


  getTotalTimeToNextCurrency (date)
  {
    const currency = Math.floor(this.getCurrencyInternal(date) + 1);

    return this.__getCurrencyScaleFactor__() * Math.pow(CURRENCY_COMMON_RATIO, currency);
  }


  __getTimeFromCurrency__ (currency)
  {
    return this.__getCurrencyScaleFactor__() * (1.0 - Math.pow(CURRENCY_COMMON_RATIO, currency)) / (1.0 - CURRENCY_COMMON_RATIO);
  }


  __getCurrencyFromTime__ (milliseconds)
  {
    const result = 1.0 + ((CURRENCY_COMMON_RATIO - 1.0) * milliseconds) / this.__getCurrencyScaleFactor__();

    return Math.log(result) / Math.log(CURRENCY_COMMON_RATIO);
  }


  __getCurrencyScaleFactor__ ()
  {
    const zonesYield = this.getZoneCount() * CURRENCY_ZONE_YIELD;
    const planetsYield = this.getPlanetCount() * CURRENCY_PLANET_YIELD;

    return Math.floor(HOUR_IN_MILLISECONDS / Math.floor(CURRENCY_DEFAULT_YIELD + zonesYield + planetsYield));
  }


  getResources (date)
  {
    const { user } = this.props;

    if (user == null)
    {
      return { carbon: 0, silicon: 0, hydrogen: 0 };
    }

    /*if (date == null)
    {
      return user.resources;
    }*/

    const { resources, deposit } = user;

    return { carbon: deposit.carbon - resources.carbon, silicon: deposit.silicon - resources.silicon, hydrogen: deposit.hydrogen - resources.hydrogen }

    /*const milliseconds = date - user.update_date;

    let { carbon, silicon, hydrogen } = user.resources;

    carbon += this.__getResourceYield__(user.deposit.carbon) * milliseconds;
    silicon += this.__getResourceYield__(user.deposit.silicon) * milliseconds;
    hydrogen += this.__getResourceYield__(user.deposit.hydrogen) * milliseconds;

    return { carbon, silicon, hydrogen };*/
  }


  getResource (type, date)
  {
    const resources = this.getResources(date);

    switch (type)
    {
      case ResourceEnum.Carbon:   return resources.carbon;
      case ResourceEnum.Silicon:  return resources.silicon;
      case ResourceEnum.Hydrogen: return resources.hydrogen;
    }

    throw new Error('Unexpected resource type');
  }


  __getResourceYield__ (deposit)
  {
    const fullDay = HOUR_IN_MILLISECONDS * 24;

    return deposit / fullDay;
  }


  getCenterCoords ()
  {
    const { user } = this.props;

    return user && user.center ? Coords.fromString(user.center) : Coords.fromEmpty();
  }


  getUpdateDate ()
  {
    const { user } = this.props;

    return user ? user.update_date : 0;
  }


  getTurn ()
  {
    const { user } = this.props;

    return user ? user.turn : 0;
  }


  isTurnCommited ()
  {
    return this.getTurn() > this.props.parent.getCurrentGalaxy().getTurn();
  }


  isEmpty ()
  {
    return this.props.user == null;
  }


  isCurrentUser ()
  {
    return this.getID() === this.props.parent.getCurrentUser().getID() && !this.isAncientUser();
  }


  isAncientUser ()
  {
    return this.getID() === ANCIENT_ID;
  }


  getConstants ()
  {
    return this.props.parent.getConstants().getGeneral();
  }


  getPlanets ()
  {
    return this.props.parent.getUserPlanets(this.getID());
  }


  getReference ()
  {
    return { id: this.getID(), strength: this.getZoneCount() };
  }


};
