/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  Anchor: 0,
  Attack: 1,
  Attrition: 2,
  Disband: 3,
  Excavation: 4,
  Sector: 5,
  Transfer: 6,
};
