/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, Element, useFlux, withValue } from 'fierry/react';

import Now from 'galax/query/now';

import ResourceEnum from 'galax/enum/resource';
import ResourceElement from 'galax/react/element/resource';

import CurrencyIcon from 'svg/galax/resource/currency';


const getCurrency = ({ currentUser, now }) =>
{
  return Math.floor(currentUser.getCurrency(now));
}


const getCarbon = ({ currentUser, now }) =>
{
  return Math.floor(currentUser.getResources(now).carbon);
}


const getSilicon = ({ currentUser, now }) =>
{
  return Math.floor(currentUser.getResources(now).silicon);
}


const getHydrogen = ({ currentUser, now }) =>
{
  return Math.floor(currentUser.getResources(now).hydrogen);
}


const Component = ({ className, ...props }) =>
(
  <Modern.Group className = {className} >

    <ResourceElement {...props} type = {ResourceEnum.Currency} value = {getCurrency} />
    <ResourceElement {...props} type = {ResourceEnum.Carbon} value = {getCarbon} />
    <ResourceElement {...props} type = {ResourceEnum.Silicon} value = {getSilicon} />
    <ResourceElement {...props} type = {ResourceEnum.Hydrogen} value = {getHydrogen} />

  </Modern.Group>
);


export default (props) =>
{
  const [ now ] = useFlux(Now);

  return <Component {...props} now = {now} />
}
