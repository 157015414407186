/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux }  from 'fierry/react';

import Behavior from 'galax/client/behavior';

import Center from 'galax/react/map/zone/center';

import Building from 'galax/react/map/entity/building.native';
import Unit from 'galax/react/map/entity/unit.native';


const FutureBuilding = ({ type, ...props }) =>
{
  if (type == null)
  {
    return null;
  }

  return <Center local {...props} > <Building {...props} dashed type = {type} /> </Center>;
}


const FutureUnit = ({ type, ...props }) =>
{
  if (type == null)
  {
    return null;
  }

  return <Center local {...props} > <Unit {...props} dashed type = {type} /> </Center>;
}


export default ({ currentLocation, ...props }) =>
{
  const [ behavior ] = useFlux(Behavior);

  if (behavior.getType().startsWith('/building.create'))
  {
    return <FutureBuilding {...props} location = {currentLocation} type = {behavior.getArgs().type} />;
  }

  if (behavior.getType().startsWith('/unit.create'))
  {
    return <FutureUnit {...props} location = {currentLocation} type = {behavior.getArgs().type} />;
  }

  return null;
}
