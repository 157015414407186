/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Cache from 'fierry/flux/cache';

import Zoom from 'galax/client/zoom';

import HexGeneral from 'galax/client/map/hex.general';
import HexLocal from 'galax/client/map/hex.local';


export default class
{
  constructor (zoom)
  {
    this.zoom = zoom;
    this.hexGeneral = new HexGeneral(this.zoom);

    this.hexLocalCache = new Cache( (location) =>
    {
      return new HexLocal(location, this);
    });
  }


  getZoom ()
  {
    return this.zoom;
  }


  getGeneralHex ()
  {
    return this.hexGeneral;
  }


  getLocalHex (location)
  {
    return this.hexLocalCache.get(location);
  }


};
