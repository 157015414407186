/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { css, styled, useFlux, useCallback } from 'fierry/react';

import Screen from 'galax/client/screen';
import Offset from 'galax/client/map/offset';


import Behavior from 'galax/client/behavior';
import Navigation from 'galax/client/navigation';
import Selection from 'galax/client/selection';

import Svg from 'galax/react/map/window/svg';
import Definitions from 'galax/react/map/window/definitions';

import { OnZoneClick } from 'galax/react/map/window/callbacks';


const getTransformStyle = (offset) =>
{
  const { x, y } = offset.getOffset();

  return { transform: `translate(${x}px, ${y}px)` };
};


const transitionConfig = ({ offset }) => !offset.isDnD() && css`
  transition-property:        transform;
  transition-duration:        var(--fierry-transition-duration);
  transition-timing-function: var(--fierry-transition-function);
`;


const Group = styled.g`
  ${transitionConfig}
`;


const Component = ({ screen, offset, children, onZoneClick }) =>
(
  <Svg width = {screen.getWindowWidth()} height = {screen.getWindowHeight()} onZoneClick = {onZoneClick} >

    <Definitions />

    {/* Transform changes too fast for styled - needs to be as separate style property */}
    <Group offset = {offset} style = {getTransformStyle(offset)}>
      {children}
    </Group>

  </Svg>
);


export default ({ galaxy, ...props }) =>
{
  const [ screen, offset, behavior, navigation, selection ] = useFlux(Screen, Offset, Behavior, Navigation, Selection);

  const onZoneClick = useCallback(OnZoneClick, [ galaxy, behavior, navigation, selection ]);

  return <Component {...props} screen = {screen} offset = {offset} onZoneClick = {onZoneClick} />
};
