/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, withValue } from 'fierry/react';

import TransferToIcon from 'svg/shared/modern/transfer-to.js';
import TransferFromIcon from 'svg/shared/modern/transfer-from.js';


const getSourceValue = ({ sourceValue, targetValue, reverse, ...props }) =>
{
  return withValue(reverse, props) ? withValue(targetValue, props) : withValue(sourceValue, props);
}


const getTargetValue = ({ sourceValue, targetValue, reverse, ...props }) =>
{
  return withValue(reverse, props) ? withValue(sourceValue, props) : withValue(targetValue, props);
}


const getTooltipValue = ({ reverse, ...props }) =>
{
  return withValue(reverse, props) ? 'Transfer From' : 'Transfer To';
}


const TransferIcon = ({ reverse, ...props }) =>
{
  return withValue(reverse, props) ? <TransferFromIcon {...props} /> : <TransferToIcon {...props} />;
}


const ShortComponent = ({ value, ...props }) =>
(
    <Element.Group param width = '2.5' tooltip = {getTooltipValue(props)} {...props}>

      <Element.Icon
        value = {<TransferIcon {...props} />}
      />

      <Element.Text leftAlign width = '0.625'
        value = {withValue(value, props)}
      />

    </Element.Group>
);


const LongComponent = (props) =>
(
    <Element.Group param width = '3.5' tooltip = {getTooltipValue(props)} {...props}>

      <Element.Text rightAlign width = '0.625'
        value = {getSourceValue(props)}
      />

      <Element.Icon
        value = {<TransferIcon {...props} />}
      />

      <Element.Text leftAlign width = '0.625'
        value = {getTargetValue(props)}
      />

    </Element.Group>
);


const ShortInvalid = (props) =>
(
  <Element.Group param width = '2.5' tooltip = {getTooltipValue(props)} {...props}>

    <Element.Icon value = {<TransferIcon {...props} />} />
    <Element.Space width = '0.625' />

  </Element.Group>
);


const LongInvalid = (props) =>
(
  <Element.Group param width = '3.5' tooltip = {getTooltipValue(props)} {...props}>

    <Element.Space width = '0.625' />
    <Element.Icon value = {<TransferIcon {...props} />} />
    <Element.Space width = '0.625' />

  </Element.Group>
);


export default (props) =>
{
  const { invalid, value } = props;

  if (invalid)
  {
    return value ? <ShortInvalid {...props} /> : <LongInvalid {...props} />;
  }

  return value ? <ShortComponent {...props} /> : <LongComponent {...props} />;
}
