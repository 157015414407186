/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';


export default (parent) =>
{
  let state = new Immutable.Map();

  for (const interaction of parent.getInteractionsFrom())
  {
    state = state.set(interaction.getKey(), interaction.getType());
  }

  return state;
}
