/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import uuid from 'uuid/v4';

import Galaxy from 'galax/fake/builder/galaxy';

import ProjectileEnum from 'galax/enum/projectile';
import ShapeEnum from 'galax/enum/shape';

import SpaceshipEnum from 'galax/enum/spaceship';
import BuildingEnum from 'galax/enum/building';
import UnitEnum from 'galax/enum/unit';

import * as User from 'galax/value/user';


export default () =>
{
  const galaxy = new Galaxy('1f98bde1-8f99-4731-be8c-2bfa11abe829');

  createBuildingConstants(galaxy);
  createSpaceshipConstants(galaxy);
  createUnitConstants(galaxy);
  createThemeConstants(galaxy);

  createUsers(galaxy);

  return galaxy;
}


const createProjectileConstants = (galaxy) =>
{
  const constants = galaxy.getConstants();

  constants.createProjectile(ProjectileEnum.LaserBattery, ShapeEnum.TripleArea, 50, 120);
  constants.createProjectile(ProjectileEnum.GaussCannon, ShapeEnum.QuadrupleArea, 40, 120);
  constants.createProjectile(ProjectileEnum.GaussArtillery, ShapeEnum.SeptupleArea, 20, 120);
}


const createSpaceshipConstants = (galaxy) =>
{
  const constants = galaxy.getConstants();

  constants.createSpaceship(SpaceshipEnum.FighterBasic, 'Fighter Sparrow', 0, 1, 4, 600, 1800, 50, 150, 0.2, 10);
}


const createBuildingConstants = (galaxy) =>
{
  const constants = galaxy.getConstants();

  constants.createBuilding(BuildingEnum.DistributionCenter, 'Distribution Center', 2, { carbon: 1, silicon: 0, hydrogen: 0 });
  constants.createBuilding(BuildingEnum.Outpost, 'Outpost', 1, { carbon: 1, silicon: 0, hydrogen: 0 });
  constants.createBuilding(BuildingEnum.Spaceport, 'Spaceport', 0, { carbon: 0, silicon: 1, hydrogen: 0 });
  constants.createBuilding(BuildingEnum.TransferStation, 'Transfer Station', 0, { carbon: 1, silicon: 0, hydrogen: 0 });

  constants.createBuilding(BuildingEnum.LaserBattery, 'Laser Battery', 0, { carbon: 0, silicon: 1, hydrogen: 0 });
  constants.createBuilding(BuildingEnum.GaussCannon, 'Gauss Cannon', 0, { carbon: 0, silicon: 1, hydrogen: 0 });
  constants.createBuilding(BuildingEnum.GaussArtillery, 'Gauss Artillery', 0, { carbon: 0, silicon: 1, hydrogen: 1 });
}


const createUnitConstants = (galaxy) =>
{
  const constants = galaxy.getConstants();

  constants.createUnit(UnitEnum.BasicAttack, 'Infantry', 3, 1, 1, 350, 25, 90, 0.15);
  constants.createUnit(UnitEnum.RangeAttack, 'Rangers', 4, 2, 4, 400, 25, 60, 0.15);
  constants.createUnit(UnitEnum.ConeAttack, 'Mech. Infantry', 7, 1, 2, 450, 25, 40, 0.25);
  constants.createUnit(UnitEnum.AreaAttack, 'Artillery', 15, 3, 5, 500, 25, 30, 0.25);
}


const createThemeConstants = (galaxy) =>
{
  const constants = galaxy.getConstants();

  constants.createTheme( 0, '#757575', '#616161', '#BDBDBD', '#E0E0E0'); // Ancient User
  constants.createTheme( 1,  '#68361F', '#E6605C', '#E66864', '#68361F'); // Brown - Red
  constants.createTheme( 2,  '#B71C1C', '#FF8A80', '#FF8A80', '#B71C1C'); // Dark Red - Orange
  constants.createTheme( 3,  '#E41321', '#F990D6', '#F990D6', '#E41321'); // Red - Violet
  constants.createTheme( 3,  '#880E4F', '#F48FB1', '#F48FB1', '#C51162'); // Purple - Pink
  constants.createTheme( 4,  '#BD03FB', '#522DA8', '#9575CD', '#D61FFF'); // Pink - Violet
  constants.createTheme( 5,  '#6A1B9A', '#EA80FC', '#CF87DC', '#AA00FF'); // Violet - Purple
  constants.createTheme( 6,  '#0D47A1', '#0EA7BB', '#0EA7BB', '#2962FF'); // Dark Blue - Cyan
  constants.createTheme( 7,  '#0579BD', '#0010B3', '#4FC3F7', '#1C52F3'); // Light Blue - Purple
  constants.createTheme( 8,  '#006064', '#29B76D', '#76CC9A', '#007465'); // Cyan - Green
  constants.createTheme( 9,  '#00968A', '#004b79', '#2BA4DC', '#01695F'); // Teal - Blue
  constants.createTheme(10,  '#1A237E', '#DE6981', '#7986CB', '#BD3A4F'); // Indigo - Pink
  constants.createTheme(11,  '#87A2AF', '#1AB4AE', '#90A4AE', '#01564E'); // Blue Gray - Teal
  constants.createTheme(12,  '#1B5E20', '#00C853', '#75B678', '#1B5E20'); // Dark Green - Light Green
  constants.createTheme(13,  '#008A00', '#FF9900', '#5CCC8D', '#AC4C02'); // Green - Orange
  constants.createTheme(14,  '#278005', '#B3CB49', '#9AAF3E', '#278005'); // Light Green - Lime
  constants.createTheme(15,  '#706613', '#F2933A', '#F2933A', '#706613'); // Dark Lime - Orange
  constants.createTheme(16,  '#E65100', '#00772C', '#FFAB40', '#00772C'); // Dark Orange - Green
  constants.createTheme(17,  '#EC7E1E', '#00695D', '#80CBC5', '#B03E00'); // Light Orange - Teal
  constants.createTheme(18,  '#AC4C02', '#FFAB00', '#FFD54F', '#AC4C02'); // Light Brown - Amber
}


const createUsers = (galaxy) =>
{
  galaxy.createUser(User.getAncient(), 0, '');

  galaxy.createUser(uuid(),  2, 'Coe');
  galaxy.createUser(uuid(),  5, 'Vuko');
  galaxy.createUser(uuid(),  8, 'Toqeb');
  galaxy.createUser(uuid(), 15, 'Vuxhxucu');
  galaxy.createUser(uuid(), 17, 'Niipo');
}
