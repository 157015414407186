/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import uuid from 'uuid/v4';


export default class
{
  constructor (type, source, target, shape, args)
  {
    this.interaction = { id: uuid(), type, user: { id: source.getUserID(), strength: 0 }, source: source.getLocation(), target: target.getLocation(), shape, args, active: true };
  }


  setGalaxy (galaxy)
  {
    this.interaction.galaxy = galaxy;
  }


  getType ()
  {
    return this.interaction;
  }


  getKey ()
  {
    const { target, source, id } = this.interaction;

    return target.parent.id + source.parent.id + id + target.id.toString() + source.id.toString();
  }


}
