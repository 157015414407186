  /*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import NativeBuilding from 'galax/react/map/entity/building.native';


export default ({ zone, ...props }) =>
{
  const building = zone.getBuilding();
  const type = building.getType();

  if (building.getStatus().isConstruction() || building.getStatus().isDisband())
  {
    return <NativeBuilding {...props} dashed type = {type} />;
  }

  if (building.getStatus().isUpgrade() || building.getStatus().isSetup() || building.getStatus().isActive())
  {
    return <NativeBuilding {...props} inverted type = {type} />;
  }

  return <NativeBuilding {...props} type = {type} />;
}
