/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern, useFlux } from 'fierry/react';

import Now from 'galax/query/now';

import UnitGroup from 'galax/react/group/target/unit';
import UsersGroup from 'galax/react/group/target/users';

import CoordsElement from 'galax/react/element/coords'


const Location = ({ targetLocation }) =>
(
  <CoordsElement location = {targetLocation} />
);


const Component = (props) =>
(
  <Card {...props} headline = 'Target' subline = {Location} >

    <UnitGroup {...props} targetUnit = {props.targetZone.getUnit()} />
    <UsersGroup {...props} />

  </Card>
);


export default ({ ...props }) =>
{
  const [ now ] = useFlux(Now);

  return <Component {...props} now = {now} />;
}
