/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Entity from 'fierry/flux/entity'
import Element from 'fierry/rpc/dynamic-element';


const getDefinition = (callback) =>
{
  return class extends Element
  {
    constructor ()
    {
      super();

      callback(this);
    }
  };
}


export default class extends Entity
{
  constructor(location, callback)
  {
    super([]);

    this.location = location;

    this.hierarchy = new (getDefinition(callback))();
  }


  getLocation ()
  {
    return this.location;
  }


  getHierarchy ()
  {
    return this.hierarchy;
  }


}
