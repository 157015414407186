/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, Modern } from 'fierry/react';


export default styled(Modern.Stack.Parent)`
  padding: 0.5rem 0.75rem;

  --fierry-header-foreground: var(--fierry-theme-background);
  --fierry-header-background: var(--fierry-theme-primary-foreground);

  > *
  {
    --fierry-theme-foreground: var(--fierry-header-foreground);
    --fierry-theme-background: var(--fierry-header-background);
  }

  background: var(--fierry-header-background);
`;
