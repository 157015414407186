/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Client from 'fierry/flux/entity/client';

import Behavior from 'galax/behavior';
import Location from 'galax/server/location';

import Pane from 'fierry/state/pane';
import View from 'fierry/client/view';

import Selection from 'galax/client/selection';

import * as Array from 'fierry/util/array';


export default new Client ([ Behavior, Pane, View, Selection ], class
{
  constructor (behavior, pane, view, selection)
  {
    this.behavior = behavior;
    this.pane = pane;
    this.view = view;
    this.selection = selection;
  }


  onDone ()
  {
    if (this.behavior && this.behavior.onDone)
    {
      this.behavior.onDone();
    }

    this.onCancel();
  }


  onCancel ()
  {
    if (this.behavior && this.behavior.onCancel)
    {
      this.behavior.onCancel()
    }

    this.view.popForeground();
  }


  // TODO getTargets () rename afterwards ?
  // used only here
  getSelectionTargets ()
  {
    const target = this.selection.getLocation();

    if (this.behavior && this.behavior.getSelectionTargets)
    {
      return this.behavior.getSelectionTargets();
    }

    return [ target ];
  }


  // selection/targets
  getZoneTargets (location)
  {
    if (this.canSelect())
    {
      return !this.isInvalid() ? this.getSelectionTargets() : [];
    }

    let collection = [];

    for (const interaction of location.getInteractionsFrom())
    {
      const object = interaction.getObject();

      if (object.isAggressive() || object.isFutureTarget())
      {
        collection = collection.concat(interaction.getFullTarget());
      }
    }

    for (const projectile of location.getProjectilesFrom())
    {
      collection = collection.concat(projectile.getFullTarget());
    }

    // TODO needed for spaceship move! And we should return locations as otherwise it is broken!
    const spaceship = location.getSpaceship();

    if (spaceship && spaceship.hasTarget())
    {
      collection.push(spaceship.getTarget());
    }

    return Array.unique(collection, (lhs, rhs) => lhs.isEqual(rhs));
  }


  // transfer.indicator, attack.indicator
  getZoneTargetIDs (location)
  {
    let collection = [];

    for (const location of this.getZoneTargets(location))
    {
      collection.push(location.getCoords().toString());
    }

    return collection;
  }


  // future.entity
  getType ()
  {
    return this.view.getRoute().getValue();
  }


  // future.entity
  getArgs ()
  {
    return this.view.getRoute().getArgs();
  }


  getError ()
  {
    if (this.behavior && this.behavior.getError)
    {
      return this.behavior.getError();
    }

    return null;
  }


  getInfo ()
  {
    if (this.behavior && this.behavior.getInfo)
    {
      return this.behavior.getInfo();
    }

    return null;
  }


  getInitial ()
  {
    if (this.behavior && this.behavior.getInitial)
    {
      return this.behavior.getInitial();
    }

    return null;
  }


  isError ()
  {
    return this.getError() !== null;
  }


  isInitial ()
  {
    return this.getInitial() !== null;
  }


  isInvalid ()
  {
    return this.isInitial() || this.isError();
  }


  canSelect ()
  {
    if (this.behavior && this.behavior.canSelect)
    {
      return this.behavior.canSelect();
    }

    return false;
  }


  canSelectGalaxy ()
  {
    if (this.behavior && this.behavior.canSelectGalaxy)
    {
      return this.behavior.canSelectGalaxy();
    }

    return false;

  }


  canNavigate ()
  {
    if (this.behavior && this.behavior.canNavigate)
    {
      return this.behavior.canNavigate();
    }

    return true;
  }


  isPaneCollapsed ()
  {
    if (this.behavior && this.behavior.isPaneCollapsed)
    {
      return this.behavior.isPaneCollapsed();
    }

    return this.pane.getCollapsed();
  }


});

