/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import AttackStatus from 'galax/server/unit/status/attack';
import CreateStatus from 'galax/server/unit/status/create';
import DefaultStatus from 'galax/server/unit/status/default';
import DisbandStatus from 'galax/server/unit/status/disband';


export default (status, parent) =>
{
  if (status != null)
  {
    switch (status.type)
    {
      case 'attack':  return new AttackStatus(status, parent);
      case 'create':  return new CreateStatus(status, parent);
      case 'disband': return new DisbandStatus(status, parent);
    }
  }

  return new DefaultStatus(status, parent);
}
