/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import useContext from 'fierry/flux/hook/use.context';


export default (dependency) =>
{
  const visibilityEntity = dependency.getVisibility();

  if (visibilityEntity == null)
  {
    return true;
  }

  return useContext(visibilityEntity) === dependency.getEntity();
}
