/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (context)
  {
    this.context = context;
  }


  onAttach (routing, notifications)
  {
    this.context.elements.attach(routing, notifications, this.context.commands);
  }


  onDetach (routing)
  {
    this.context.elements.detach(routing);
  }


  onNotify (routing, batch, data)
  {
    if (batch)
    {
      this.context.batches.notify(routing, batch, data);
    }
    else
    {
      this.context.elements.notify(routing, data);
    }
  }


  onCreateBatch (id)
  {
    this.context.batches.create(id);
  }


  onCommitBatch (id)
  {
    this.context.batches.commit(id);
    this.context.batches.execute();
  }


  onCommand (command)
  {
    this.context.commands.complete(command);
  }


  finalize ()
  {
    this.context.getStateTracker().execute();
  }


};
