/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Time from 'fierry/util/time';


const TWO_LETTERS = 10;


export default new class
{
  getServerDate (date, timestamp = new Date())
  {
    if (date == null)
    {
      return null;
    }

    return new Date(timestamp - (date ? date.diff : 0));
  }


  getTurnString (milliseconds)
  {
    return Math.round(Time.getHours(milliseconds));
  }


  getTimeString (milliseconds)
  {
    if (milliseconds < 0)
    {
      milliseconds = 0;
    }

    let hours = Time.getTotalHours(milliseconds);
    let minutes = Time.getTotalMinutes(Time.getHourRemainder(milliseconds));
    let seconds = Time.getTotalSeconds(Time.getMinuteRemainder(milliseconds));

    if (minutes < TWO_LETTERS)
    {
      minutes = `0${minutes}`;
    }

    if (seconds < TWO_LETTERS)
    {
      seconds = `0${seconds}`;
    }

    return `${hours}:${minutes}:${seconds}`;
  }


  getDateString (date)
  {
    return (date - new Date()) > Time.getDayTicks() ? this.getFutureDateString(date) : this.getTodayDateString(date);
  }


  getTodayDateString (date)
  {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    let period = hours < 12 ? 'am' : 'pm';

    if (hours > 12)
    {
      hours -= 12;
    }

    if (hours < TWO_LETTERS)
    {
      hours = `0${hours}`;
    }

    if (minutes < TWO_LETTERS)
    {
      minutes = `0${minutes}`;
    }

    return `${hours}:${minutes} ${period}`;
  }


  getFutureDateString (date)
  {
    let month = date.getMonth();
    let day = date.getDate();

    return `${this.getMonthString(month)} ${day}`;
  }


  getMonthString (month)
  {
    switch (month)
    {
      case  0: return 'Jan';
      case  1: return 'Feb';
      case  2: return 'Mar';
      case  3: return 'Apr';
      case  4: return 'May';
      case  5: return 'Jun';
      case  6: return 'Jul';
      case  7: return 'Aug';
      case  8: return 'Sep';
      case  9: return 'Oct';
      case 10: return 'Nov';
      case 11: return 'Dec';

      default: throw new Error('Unsupported value');
    }
  }


}();
