/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux, Modern } from 'fierry/react';

import Pane from 'fierry/state/pane';

import Collapse from 'svg/shared/modern/collapse-new';


const OnClick = (event, pane) =>
{
  pane.setCollapsed(true);
}


export default (props) =>
{
  const [ pane ] = useFlux(Pane);

  const onClick = useCallback(OnClick, [ pane ]);

  return <Modern.Button icon = {<Collapse />} tooltip = 'Collapse' onClick = {onClick} />;
}
