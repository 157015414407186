/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, Modern } from 'fierry/react';

import ResourceEnum from 'galax/enum/resource';

import ResourceElement from 'galax/react/element/resource';


const getResourceValue = ({ constants, type }) =>
{
  const resources = constants.getConstructionResources();

  switch (type)
  {
    case ResourceEnum.Carbon:   return resources.carbon;
    case ResourceEnum.Silicon:  return resources.silicon;
    case ResourceEnum.Hydrogen: return resources.hydrogen;
  }
}


const isSufficientValue = (props) =>
{
  const { currentUser, type } = props;

  return currentUser.getResource(type) >= getResourceValue(props);
}


const ResourceOptional = (props) =>
{
  if (getResourceValue(props) == 0)
  {
    return null;
  }

  return <ResourceElement {...props} width = '2.5' value = {getResourceValue} isSufficientValue = {isSufficientValue} />;
}


export default (props) =>
(
  <Modern.Group {...props} >
    <ResourceOptional {...props} type = {ResourceEnum.Carbon} />
    <ResourceOptional {...props} type = {ResourceEnum.Silicon} />
    <ResourceOptional {...props} type = {ResourceEnum.Hydrogen} />
  </Modern.Group>
);
