/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';
import ReactDOM from 'react-dom';

import { MDCTooltip } from '@material/tooltip';

import uuid from 'uuid/v4';


const TooltipComponent = React.forwardRef( ({ id, tooltip }, ref) =>
(
  <div id = {id} ref = {ref} className = 'mdc-tooltip' role = 'tooltip' aria-hidden = 'true'>
    <div className = 'mdc-tooltip__surface mdc-tooltip__surface-animation' children = {tooltip} />
  </div>
));


const Tooltip = (props) =>
{
  const ref = React.useRef(null);

  const  effectCallback = () =>
  {
    if (props.id == null)
    {
      return;
    }

    const mdcTooltip = new MDCTooltip(ref.current);

    return () => { mdcTooltip.destroy(); }
  };

  React.useEffect(effectCallback, [ props.id ]);

  return ReactDOM.createPortal(<TooltipComponent {...props} ref = {ref} />, document.getElementById('tooltips'));
};


const WithTooltipComponent = ({ Component, tooltip, tooltipID, ...props }) =>
(
  <>
    <Tooltip id = {tooltipID} tooltip = {tooltip} />
    <Component {...props} tooltip = {tooltipID} />
  </>
);


const WithTooltip = (props) =>
{
  const [tooltipID] = React.useState(uuid());

  return <WithTooltipComponent {...props} tooltipID = {tooltipID} />;
}


export default (Component) =>
{
  return (props) =>
  {
    const { tooltip } = props;

    return tooltip ? <WithTooltip {...props} Component = {Component} /> : <Component {...props} />;
  }
}
