/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import Header from 'fierry/react/header';

import DoneButton from 'shared/react/button/behavior.done';


const Component = (props) =>
(
  <Header headline = 'Upgrade' >
    <DoneButton {...props} />
  </Header>
);


export default (props) =>
{
  return <Component {...props} />;
}
