/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useFlux } from 'fierry/react';

import Url from 'fierry/client/url';

import Container from 'fierry/react/skeleton/header/container'

import TemporaryPresentation from 'fierry/react/skeleton/header/presentation/temporary';
import PermanentPresentation from 'fierry/react/skeleton/header/presentation/permanent';
import FadeThroughPresentation from 'fierry/react/skeleton/header/presentation/fade.through';

import isMobile from 'fierry/util/mobile';


const Presentation = ({ permanent, temporary, ...props }) =>
{
  const [ url ] = useFlux(Url);

  if (temporary)
  {
    return <TemporaryPresentation {...props} />;
  }

  if (permanent)
  {
    return <PermanentPresentation {...props} />;
  }

  if (url.isPaneToCardTransition())
  {
    return <TemporaryPresentation {...props} />;
  }

  if (url.isPaneToPaneTransition())
  {
    return <FadeThroughPresentation {...props} />;
  }

  return <PermanentPresentation {...props} />;
}


export default (props) =>
(
    <Container {...props} >
      <Presentation {...props} />
    </Container>
);
