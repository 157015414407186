/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';

import PlanetState from 'galax/fake/state/planet';


export default (planets) =>
{
  let state = new Immutable.Map();

  for (const planet of planets)
  {
    state = state.set(planet.getID(), PlanetState(planet));
  }

  return state;
}
