/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Element, Modern, styled, useFlux } from 'fierry/react';

import BenefitElement from 'galax/react/element/building/benefit';
import LauncherParams from 'galax/react/group/launcher/params';
import StatusProgress from 'galax/react/group/progress/building'

import BuildingIcon from 'galax/react/icon/building';


const getIcon = ({ building }) =>
{
  return <BuildingIcon type = {building.getType()} />;
}


const getName = ({ building }) =>
{
  return building.getConstants().getName();
}


const Headline = (props) =>
(
  <Modern.Text headline icon = {getIcon(props)} text = {getName(props)} />
);


const Subline = (props) =>
{
  const { building } = props;

  if (building.getStatus().isCooldown())
  {
    return null;
  }

  const active = building.getStatus().isActive();

  return <BenefitElement {...props} type = {building.getType()} level = {building.getLevel()} active = {active} constants = {building.getConstants()} />;
}


const Component = (props) =>
(
  <Card {...props} headline = {Headline} subline = {Subline} >
    <Modern.Group>
      <LauncherParams {...props} constants = {props.building.getConstants()} />
    </Modern.Group>
    <StatusProgress {...props} />
  </Card>
);


export default (props) =>
{
  const building = props.currentZone.getBuilding();

  return building ? <Component {...props} building = {building} /> : null;
}
