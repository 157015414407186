/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { useFlux } from 'fierry/react';

import Grid from 'galax/client/map/grid';

import Entity from 'galax/react/map/entity';
import Center from 'galax/react/map/zone/center';
import Spaceship from 'galax/react/map/entity/spaceship';


const Component = (props) =>
(
  <Center local {...props} location = {props.spaceship.getLocation()} >
    <Spaceship {...props} />
  </Center>
);


export default (props) =>
{
  const [ grid ] = useFlux(Grid);

  if (!grid.getZoom().isEntityVisible())
  {
    return null;
  }

  return <Component {...props} grid = {grid} />;
};
