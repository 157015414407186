/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ShapeEnum, { getShapeToCount } from 'galax/enum/shape';


class EntityInstallerEntry
{
  constructor (entity)
  {
    this.entity = entity;
  }


  install ()
  {
    this.entity.install();
  }


  getCreateDate ()
  {
    return this.entity.getCass().create_date;
  }
};


class SourceInteractionInstallerEntry
{
  constructor (interaction, parent)
  {
    this.interaction = interaction;
    this.parent = parent;
  }


  install ()
  {
    this.interaction.installSourcePlanet(this.parent);
  }


  getCreateDate ()
  {
    return this.interaction.getCass().create_date;
  }
};


class TargetInteractionInstallerEntry
{
  constructor (interaction, parent)
  {
    this.interaction = interaction;
    this.parent = parent;
  }


  install ()
  {
    this.interaction.installTargetPlanet(this.parent);
  }


  getCreateDate ()
  {
    return this.interaction.getCass().create_date;
  }
};


export default class
{
  constructor (parent)
  {
    this.parent = parent;

    this.collection = [];
  }


  pushEntity (entity)
  {
    this.collection.push(new EntityInstallerEntry(entity));
  }


  pushSourceInteraction (interaction)
  {
    this.collection.push(new SourceInteractionInstallerEntry(interaction, this.parent));
  }


  pushTargetInteraction (interaction)
  {
    this.collection.push(new TargetInteractionInstallerEntry(interaction, this.parent));
  }


  execute ()
  {
    this.collection.sort((lhs, rhs) =>
    {
      return lhs.getCreateDate() - rhs.getCreateDate();
    });

    for (const entry of this.collection)
    {
      entry.install();
    }
  }


};
