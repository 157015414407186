/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import EntityEnum from 'galax/enum/entity';

import makeBuilding from 'galax/server/building/make';
import Unit from 'galax/server/unit';


export default class
{
  constructor (parent, entities)
  {
    this.parent = parent;

    this.collection = new Map();

    for (const entity of entities.values())
    {
      this.collection.set(entity.zone, this.makeEntity(entity, parent));
    }
  }


  makeEntity (entity, parent)
  {
    switch (entity.type)
    {
      case EntityEnum.Building: return makeBuilding(entity, parent);
      case EntityEnum.Unit:     return new Unit(entity, parent);
    }
  }


  setupInstaller (installer)
  {
    for (const entity of this.collection.values())
    {
      installer.pushEntity(entity);
    }
  }


  getEntity (zoneID)
  {
    return this.collection.get(zoneID);
  }


  getBuilding (zoneID)
  {
    const entity = this.getEntity(zoneID);

    return entity && entity.getCass().type === EntityEnum.Building ? entity : null;
  }


  getUnit (zoneID)
  {
    const entity = this.getEntity(zoneID);

    return entity && entity.getCass().type === EntityEnum.Unit ? entity : null;
  }


  getBuildingByType (userID, type)
  {
    for (const entity of this.collection.values())
    {
      if (entity.getCass().type === EntityEnum.Building)
      {
        if (entity.getUserID() === userID && entity.getType() === type)
        {
          return entity;
        }
      }
    }

    return null;
  }


  getActiveConstruction (userID)
  {
    for (const entity of this.collection.values())
    {
      const status = entity.getStatus();

      if (entity.getCass().type === EntityEnum.Building)
      {
        if ((status.isConstruction() || status.isUpgrade()) && entity.getUserID() === userID)
        {
          return entity;
        }
      }
    }

    return null;
  }


  canConstructBuilding (userID)
  {
    for (const sector of this.parent.sectors.values())
    {
      if (sector.getUser().getID() === userID && sector.getBuilding() == null)
      {
        return true;
      }
    }

    return false;
  }


}
