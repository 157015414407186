/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, animateOnce, keyframes } from 'fierry/react';


export default animateOnce(Modern.Stack.Child, (props) => keyframes`

  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`);
