/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */

import Event from 'fierry/flux/entity/event';
import Screen from 'galax/state/screen';


export default new Event ([ Screen.Resize ], (setScreenSize) =>
{
  const eventListener = () =>
  {
    // Chrome for iOS provides incorrect innerWidth/Height after rotate - need to use clientWidth/Height here.
    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight;

    setScreenSize({ width, height });
  };

  window.addEventListener('resize', eventListener);

  return () =>
  {
    window.removeEventListener('resize', eventListener);
  }
});
