/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Client from 'fierry/flux/entity/client';

import Server from 'galax/server';

import State from 'galax/state/map/offset';

import Screen from 'galax/client/screen';
import Grid from 'galax/client/map/grid';

import * as Coords from 'galax/geo/coords';


export default new Client ([ State, Grid, Screen, Server ], class
{
  constructor ([ state, dispatch ], grid, screen, server)
  {
    this.state = state;
    this.dispatch = dispatch;

    this.grid = grid;
    this.screen = screen;
    this.server = server;
  }


  setDnD (value)
  {
    this.dispatch(State.SetDnD, { value });
  }


  setAbsolute (x, y)
  {
    this.dispatch(State.SetAbsolute, { x, y });
  }


  setRelative (dx, dy)
  {
    this.dispatch(State.SetRelative, { dx, dy });
  }


  isDnD ()
  {
    return this.state.dnd;
  }


  getOffset ()
  {
    const { x, y } = this.state;

    const center = this.__getCenterOffset__();

    return { x: x + center.x, y: y + center.y };
  }


  getRange ()
  {
    const { x, y } = this.getOffset();

    const width = this.__getZoneWidth__();
    const height = this.__getZoneHeight__();

    return (
    {
      start:
      {
        x: Math.floor((-x - width / 2) / width),
        y: Math.floor((-y - height / 2) / height),
      },
      end:
      {
        x: Math.ceil((-x + width / 2 + this.__getWindowWidth__()) / width),
        y: Math.ceil((-y + height / 2 + this.__getWindowHeight__()) / height),
      }
    });
  }


  getPixelToCoords (clientX, clientY)
  {
    const hex = this.grid.getGeneralHex();

    const xSize = hex.getExternalSize() * hex.getStretchX();
    const ySize = hex.getExternalSize() * hex.getStretchY();

    const { x, y } = this.getOffset();

    return Coords.fromPixel(clientX - x, clientY - y, xSize, ySize)
  }


  __getCenterOffset__ ()
  {
    const center = this.__getCenterCoords__();

    const width = this.__getZoneWidth__();
    const height = this.__getZoneHeight__();

    const x = Math.round((this.__getWindowWidth__() / 2) - (center.x + 1) * width);
    const y = Math.round((this.__getWindowHeight__() / 2) - (center.y + 1) * height);

    return { x, y };
  }


  __getCenterCoords__ ()
  {
    const coords = this.__getCenterUser__().getCenterCoords();

    return { x: coords.getX(), y: coords.getY() };
  }


  __getCenterUser__ ()
  {
    const currentUser = this.server.getCurrentUser();
    const otherUsers = this.server.getUsers().getAll();

    return currentUser;
  }


  __getZoneWidth__ ()
  {
    return this.grid.getGeneralHex().getHorizontalDistance();
  }


  __getZoneHeight__ ()
  {
    return this.grid.getGeneralHex().getVerticalDistance();
  }


  __getWindowWidth__ ()
  {
    return this.screen.getWindowWidth();
  }


  __getWindowHeight__ ()
  {
    return this.screen.getWindowHeight();
  }


});
