/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


const MILISECONDS = 1000;
const SECONDS = 60;
const MINUTES = 60;
const HOURS = 24;
const DAYS = 7;


export default new class
{
  getSecondTicks ()
  {
    return MILISECONDS;
  }


  getMinuteTicks ()
  {
    return MILISECONDS * SECONDS;
  }


  getHourTicks ()
  {
    return MILISECONDS * SECONDS * MINUTES;
  }


  getDayTicks ()
  {
    return MILISECONDS * SECONDS * MINUTES * HOURS;
  }


  getWeekTicks ()
  {
    return MILISECONDS * SECONDS * MINUTES * HOURS * DAYS;
  }


  getMiliseconds (date)
  {
    return date instanceof Date ? date.getTime() : date;
  }


  getSeconds (date)
  {
    return this.getMiliseconds(date) / MILISECONDS;
  }


  getMinutes (date)
  {
    return this.getSeconds(date) / SECONDS;
  }


  getHours (date)
  {
    return this.getMinutes(date) / MINUTES;
  }


  getDays (date)
  {
    return this.getHours(date) / HOURS;
  }


  getWeeks (date)
  {
    return this.getDays(date) / DAYS;
  }


  getTotalMiliseconds (date)
  {
    return Math.floor(this.getMiliseconds(date));
  }


  getTotalSeconds (date)
  {
    return Math.floor(this.getSeconds(date));
  }


  getTotalMinutes (date)
  {
    return Math.floor(this.getMinutes(date));
  }


  getTotalHours (date)
  {
    return Math.floor(this.getHours(date));
  }


  getTotalDays (date)
  {
    return Math.floor(this.getDays(date));
  }


  getTotalWeeks (date)
  {
    return Math.floor(this.getWeeks(date));
  }


  getSecondRemainder (date)
  {
    return this.getTotalMiliseconds(date) % this.getSecondTicks();
  }


  getMinuteRemainder (date)
  {
    return this.getTotalMiliseconds(date) % this.getMinuteTicks();
  }


  getHourRemainder (date)
  {
    return this.getTotalMiliseconds(date) % this.getHourTicks();
  }


  getDayRemainder (date)
  {
    return this.getTotalMiliseconds(date) % this.getDayTicks();
  }


  getWeekRemainder (date)
  {
    return this.getTotalMiliseconds(date) % this.getWeekTicks();
  }


}();
