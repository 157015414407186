/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import styled, { css } from 'styled-components';


export default (container, { transition, enter, enterDone, exit, exitDone }) =>
{
  enterDone = enterDone || enter;
  exitDone = exitDone || exit;

  const enterConfig =     ({ isEnter }) => isEnter && enter;
  const enterDoneConfig = ({ isEnterDone }) => isEnterDone && enterDone;

  const exitConfig =      ({ isExit }) => isExit && exit;
  const exitDoneConfig =  ({ isExitDone }) => isExitDone && exitDone;

  // TODO can technically change transitionDuration based on if isEnter or isExit triggered - so exit is a bit faster ?
  const transitionConfig = transition && css`
    transition-property:        ${transition.join(',')};
    transition-duration:        var(--fierry-transition-duration);
    transition-timing-function: var(--fierry-transition-function);
  `;

  return styled(container)`

    ${enterConfig}
    ${enterDoneConfig}

    ${exitConfig}
    ${exitDoneConfig}

    ${transitionConfig}
  `;
};
