/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */

import Client from 'fierry/flux/entity/client';

import State from 'galax/state/screen';


export default new Client ([ State ], class
{
  constructor ([ state, dispatch ])
  {
    this.state = state;
    this.dispatch = dispatch;
  }


  setMode (mode)
  {
    this.dispatch(State.SetMode, { mode });
  }


  setDrawer (drawer)
  {
    this.dispatch(State.SetDrawer, { drawer });
  }


  clearMode ()
  {
    this.dispatch(State.ClearMode, { });
  }


  getDrawer ()
  {
    return this.state.drawer;
  }


  getWindowWidth ()
  {
    return this.state.width;
  }


  getWindowHeight ()
  {
    return this.state.height;
  }


  isOnline ()
  {
    return this.state.online;
  }


  isMobile ()
  {
    return this.getWindowWidth() < 600 || this.getWindowHeight() < 550;
  }


  isTouch ()
  {
    return ('ontouchstart' in window) || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  }


});
