/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import extract from 'fierry/flux/dependency/extract';


export default (...dependences) =>
{
  return extract(dependences, {});
}
