/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import GalaxyBasic from 'galax/fake/galaxy.basic';

import SpaceshipEnum from 'galax/enum/spaceship';
import BuildingEnum from 'galax/enum/building';
import UnitEnum from 'galax/enum/unit';

import InteractionEnum from 'galax/enum/interaction';
import ProjectileEnum from 'galax/enum/projectile';
import ShapeEnum from 'galax/enum/shape';

import * as Influence from 'galax/value/influence';


export default () =>
{
  const galaxy = GalaxyBasic();

  setupPlanetOne(galaxy);
  setupPlanetTwo(galaxy);
  setupPlanetThree(galaxy);
  setupPlanetFour(galaxy);
  setupPlanetFive(galaxy);

  setupInterplanetaryInteractions(galaxy);

  galaxy.finalize();

  return galaxy;
}


const setupPlanetOne = (galaxy) =>
{
  const planet = galaxy.getPlanet(0);

  // 1
  planet.getSector(0).assignUser(galaxy.getUser(1));
  planet.getSector(1).assignUser(galaxy.getUser(1));
  planet.getSector(4).assignUser(galaxy.getUser(2));
  planet.getSector(7).assignUser(galaxy.getUser(2));

  // 2
  planet.getSector(2).assignUser(galaxy.getUser(1));

  // 3
  planet.getSector(5).assignUser(galaxy.getUser(1));
  planet.getSector(8).assignUser(galaxy.getUser(1));
  planet.getSector(3).assignUser(galaxy.getUser(2));
  planet.getSector(6).assignUser(galaxy.getUser(2));

  // Energy
  planet.getZone(1, 0).assignEnergy([ [ galaxy.getUser(1), 85 ] ]);
  planet.getZone(2, 0).assignEnergy([ [ galaxy.getUser(1), 90 ] ]);
  planet.getZone(1, 1).assignEnergy([ [ galaxy.getUser(1), 59 ] ]);
  planet.getZone(2, 1).assignEnergy([ [ galaxy.getUser(1), 82 ] ]);
  planet.getZone(1, 2).assignEnergy([ [ galaxy.getUser(1), 75 ] ]);
  planet.getZone(2, 2).assignEnergy([ [ galaxy.getUser(1), 60 ] ]);
  planet.getZone(1, 3).assignEnergy([ [ galaxy.getUser(1), 58 ] ]);
  planet.getZone(2, 3).assignEnergy([ [ galaxy.getUser(1), 71 ] ]);
  planet.getZone(3, 3).assignEnergy([ [ galaxy.getUser(1), 58 ] ]);

  planet.getZone(4, 2).assignEnergy([ [ galaxy.getUser(1), 56 ], [ galaxy.getUser(2),  5 ] ]);
  planet.getZone(3, 2).assignEnergy([ [ galaxy.getUser(1), 57 ], [ galaxy.getUser(2),  5 ] ]);
  planet.getZone(5, 1).assignEnergy([ [ galaxy.getUser(1), 65 ], [ galaxy.getUser(2),  5 ] ]);
  planet.getZone(4, 1).assignEnergy([ [ galaxy.getUser(1), 55 ], [ galaxy.getUser(2),  5 ] ]);
  planet.getZone(3, 0).assignEnergy([ [ galaxy.getUser(1), 49 ], [ galaxy.getUser(2),  5 ] ]);
  planet.getZone(3, 1).assignEnergy([ [ galaxy.getUser(1), 48 ], [ galaxy.getUser(2),  5 ] ]);
  planet.getZone(4, 0).assignEnergy([ [ galaxy.getUser(1), 57 ], [ galaxy.getUser(2),  5 ] ]);

  planet.getZone(0, 4).assignEnergy([ [ galaxy.getUser(1), 15 ] ]);
  planet.getZone(1, 4).assignEnergy([ [ galaxy.getUser(1), 40 ] ]);
  planet.getZone(2, 4).assignEnergy([ [ galaxy.getUser(1), 48 ] ]);
  planet.getZone(1, 5).assignEnergy([ [ galaxy.getUser(1), 33 ] ]);
  planet.getZone(0, 6).assignEnergy([ [ galaxy.getUser(1), 52 ] ]);
  planet.getZone(1, 6).assignEnergy([ [ galaxy.getUser(1), 42 ] ]);
  planet.getZone(0, 5).assignEnergy([ [ galaxy.getUser(1), 18 ] ]);
  planet.getZone(2, 5).assignEnergy([ [ galaxy.getUser(1), 35 ] ]);
  planet.getZone(3, 5).assignEnergy([ [ galaxy.getUser(1), 48 ] ]);

  planet.getZone(1, 7).assignEnergy([ [ galaxy.getUser(1), 41 ], [ galaxy.getUser(2), 28 ] ]);
  planet.getZone(2, 7).assignEnergy([ [ galaxy.getUser(1), 38 ], [ galaxy.getUser(2), 28 ] ]);
  planet.getZone(2, 8).assignEnergy([ [ galaxy.getUser(1), 18 ], [ galaxy.getUser(2), 26 ] ]);
  planet.getZone(1, 8).assignEnergy([ [ galaxy.getUser(1), 22 ], [ galaxy.getUser(2), 28 ] ]);
  planet.getZone(0, 8).assignEnergy([ [ galaxy.getUser(1), 46 ], [ galaxy.getUser(2),  4 ] ]);
  planet.getZone(1, 9).assignEnergy([ [ galaxy.getUser(1), 55 ] ]);
  planet.getZone(2, 9).assignEnergy([ [ galaxy.getUser(1), 33 ], [ galaxy.getUser(2), 18 ]]);
  planet.getZone(2, 6).assignEnergy([ [ galaxy.getUser(1),  8 ], [ galaxy.getUser(2), 26 ] ]);
  planet.getZone(3, 7).assignEnergy([ [ galaxy.getUser(1), 11 ], [ galaxy.getUser(2), 26 ] ]);

  planet.getZone(2,11).assignEnergy([ [ galaxy.getUser(1), 56 ] ]);
  planet.getZone(1,11).assignEnergy([ [ galaxy.getUser(1), 50 ] ]);
  planet.getZone(1,10).assignEnergy([ [ galaxy.getUser(1), 51 ] ]);
  planet.getZone(0,10).assignEnergy([ [ galaxy.getUser(1), 53 ] ]);
  planet.getZone(0,12).assignEnergy([ [ galaxy.getUser(1),  6 ] ]);
  planet.getZone(1,12).assignEnergy([ [ galaxy.getUser(1),  8 ] ]);
  planet.getZone(2,10).assignEnergy([ [ galaxy.getUser(1), 12 ] ]);

  planet.getZone(3, 4).assignEnergy([ [ galaxy.getUser(2), 44 ], [ galaxy.getUser(1), 13 ] ]);
  planet.getZone(5, 5).assignEnergy([ [ galaxy.getUser(2), 44 ], [ galaxy.getUser(1),  4 ] ]);
  planet.getZone(5, 4).assignEnergy([ [ galaxy.getUser(2), 56 ], [ galaxy.getUser(1),  4 ] ]);
  planet.getZone(5, 3).assignEnergy([ [ galaxy.getUser(2), 52 ], [ galaxy.getUser(1),  7 ] ]);
  planet.getZone(3, 6).assignEnergy([ [ galaxy.getUser(2), 41 ] ]);
  planet.getZone(4, 6).assignEnergy([ [ galaxy.getUser(2), 44 ] ]);
  planet.getZone(4, 5).assignEnergy([ [ galaxy.getUser(2), 50 ], [ galaxy.getUser(1),  4 ] ]);
  planet.getZone(4, 4).assignEnergy([ [ galaxy.getUser(2), 48 ], [ galaxy.getUser(1), 10 ] ]);
  planet.getZone(4, 3).assignEnergy([ [ galaxy.getUser(2), 49 ], [ galaxy.getUser(1), 16 ] ]);

  planet.getZone(7, 4).assignEnergy([ [ galaxy.getUser(2), 70 ] ]);
  planet.getZone(7, 3).assignEnergy([ [ galaxy.getUser(2), 65 ] ]);
  planet.getZone(6, 3).assignEnergy([ [ galaxy.getUser(2), 60 ] ]);
  planet.getZone(8, 3).assignEnergy([ [ galaxy.getUser(2), 71 ] ]);
  planet.getZone(7, 2).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);
  planet.getZone(6, 2).assignEnergy([ [ galaxy.getUser(2), 70 ] ]);
  planet.getZone(5, 2).assignEnergy([ [ galaxy.getUser(2), 70 ] ]);
  planet.getZone(6, 1).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);
  planet.getZone(7, 1).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);

  planet.getZone(5, 7).assignEnergy([ [ galaxy.getUser(2), 62 ] ]);
  planet.getZone(6, 7).assignEnergy([ [ galaxy.getUser(2), 49 ] ]);
  planet.getZone(5, 6).assignEnergy([ [ galaxy.getUser(2), 52 ] ]);
  planet.getZone(6, 6).assignEnergy([ [ galaxy.getUser(2), 54 ] ]);
  planet.getZone(6, 5).assignEnergy([ [ galaxy.getUser(2), 62 ] ]);
  planet.getZone(7, 5).assignEnergy([ [ galaxy.getUser(2), 65 ] ]);
  planet.getZone(6, 4).assignEnergy([ [ galaxy.getUser(2), 72 ] ]);

  planet.getZone(3, 9).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);
  planet.getZone(4, 9).assignEnergy([ [ galaxy.getUser(2), 92 ] ]);
  planet.getZone(3,10).assignEnergy([ [ galaxy.getUser(2), 86 ] ]);
  planet.getZone(4,10).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);
  planet.getZone(3, 8).assignEnergy([ [ galaxy.getUser(2), 88 ] ]);
  planet.getZone(4, 8).assignEnergy([ [ galaxy.getUser(2), 89 ] ]);
  planet.getZone(4, 7).assignEnergy([ [ galaxy.getUser(2), 69 ] ]);
  planet.getZone(5, 8).assignEnergy([ [ galaxy.getUser(2), 62 ] ]);
  planet.getZone(5, 9).assignEnergy([ [ galaxy.getUser(2), 65 ] ]);

  // Buildings
  planet.createBuilding([ 2, 2 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 3, 0 ], BuildingEnum.LaserBattery);
  planet.createBuilding([ 0, 4 ], BuildingEnum.Spaceport).setStatus('upgrade');
  planet.createBuilding([ 1, 8 ], BuildingEnum.GaussCannon);
  planet.createBuilding([ 0,10 ], BuildingEnum.Spaceport).setStatus('create');

  planet.createBuilding([ 6, 2 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 7, 5 ], BuildingEnum.GaussCannon).setStatus('active');
  planet.createBuilding([ 4, 9 ], BuildingEnum.GaussArtillery);
  planet.createBuilding([ 4, 4 ], BuildingEnum.Outpost).setStatus('upgrade');

  // Units
  planet.createUnit([ 4, 2 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 3, 3 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 1, 2 ], UnitEnum.RangeAttack).setStatus('attack');
  planet.createUnit([ 3, 1 ], UnitEnum.RangeAttack).setStatus('attack');

  planet.createUnit([ 3, 6 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 3, 9 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 3,10 ], UnitEnum.RangeAttack).setStatus('attack');

  planet.createUnit([ 7, 4 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 5, 5 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 6, 7 ], UnitEnum.AreaAttack).setStatus('attack');

  // Spaceships
  galaxy.createSpaceship([  4, -4 ], SpaceshipEnum.FighterBasic, galaxy.getUser(1)).setStatus('attack');
  galaxy.createSpaceship([ -2, -5 ], SpaceshipEnum.FighterBasic, galaxy.getUser(1)).setStatus('attack');
  galaxy.createSpaceship([ -3,  0 ], SpaceshipEnum.FighterBasic, galaxy.getUser(1)).setStatus('create');
  galaxy.createSpaceship([ -4,  1 ], SpaceshipEnum.FighterBasic, galaxy.getUser(1)).setStatus('create');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(4, 2), planet.getZone(4, 3), { type: ShapeEnum.DoubleBasic, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(3, 3), planet.getZone(4, 3), { type: ShapeEnum.DoubleBasic, variant: 2 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(1, 2), planet.getZone(3, 4), { type: ShapeEnum.TripleArea, variant: 5 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(3, 1), planet.getZone(4, 4), { type: ShapeEnum.TripleArea, variant: 5 }, { value: Influence.fromPercentage(6)});

  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(3, 6), planet.getZone(2, 6), { type: ShapeEnum.DoubleBasic, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(3, 9), planet.getZone(2, 9), { type: ShapeEnum.DoubleBasic, variant: 5 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(3,10), planet.getZone(1, 8), { type: ShapeEnum.TripleArea, variant: 3 }, { value: Influence.fromPercentage(6)});

  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(7, 4), planet.getZone(4, 1), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(5, 5), planet.getZone(2, 7), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(6, 7), planet.getZone(2, 7), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});

  //galaxy.createTransferInteraction(planet.getZone(1, 2), planet.getZone(3, 1), { type: ShapeEnum.Sector, variant: 0 });
  //galaxy.createTransferInteraction(planet.getZone(1, 2), planet.getZone(0, 4), { type: ShapeEnum.Sector, variant: 0 });

  // Projectiles
  galaxy.createProjectile(ProjectileEnum.GaussArtillery, planet.getZone(4, 9), planet.getZone(4, 1), { type: ShapeEnum.SeptupleArea, variant: 0 }, 200, 900);
}


const setupPlanetTwo = (galaxy) =>
{
  const planet = galaxy.getPlanet(1);

  // 1
  planet.getSector(2).assignUser(galaxy.getUser(3));
  planet.getSector(8).assignUser(galaxy.getUser(3));

  // 2
  planet.getSector(5).assignUser(galaxy.getUser(3));

  // 3
  planet.getSector(9).assignUser(galaxy.getUser(3));

  // Energy
  planet.getZone( 7, 1).assignEnergy([ [ galaxy.getUser(3), 71 ] ]);
  planet.getZone( 8, 3).assignEnergy([ [ galaxy.getUser(3), 78 ] ]);
  planet.getZone( 9, 2).assignEnergy([ [ galaxy.getUser(3), 82 ] ]);
  planet.getZone( 8, 2).assignEnergy([ [ galaxy.getUser(3), 74 ] ]);
  planet.getZone( 9, 3).assignEnergy([ [ galaxy.getUser(3), 83 ] ]);
  planet.getZone( 9, 4).assignEnergy([ [ galaxy.getUser(3), 90 ] ]);
  planet.getZone(10, 2).assignEnergy([ [ galaxy.getUser(3), 82 ] ]);
  planet.getZone( 9, 1).assignEnergy([ [ galaxy.getUser(3), 70 ] ]);
  planet.getZone( 8, 1).assignEnergy([ [ galaxy.getUser(3), 23 ] ]);

  planet.getZone(10, 4).assignEnergy([ [ galaxy.getUser(3), 80 ] ]);
  planet.getZone(10, 5).assignEnergy([ [ galaxy.getUser(3), 77 ] ]);
  planet.getZone( 9, 5).assignEnergy([ [ galaxy.getUser(3), 95 ] ]);
  planet.getZone(11, 4).assignEnergy([ [ galaxy.getUser(3), 81 ] ]);
  planet.getZone(10, 3).assignEnergy([ [ galaxy.getUser(3), 90 ] ]);
  planet.getZone(10, 6).assignEnergy([ [ galaxy.getUser(3), 83 ] ]);
  planet.getZone(11, 5).assignEnergy([ [ galaxy.getUser(3), 80 ] ]);
  planet.getZone(11, 6).assignEnergy([ [ galaxy.getUser(3), 80 ] ]);
  planet.getZone(12, 6).assignEnergy([ [ galaxy.getUser(3), 81 ] ]);

  planet.getZone( 8, 4).assignEnergy([ [ galaxy.getUser(3), 72 ] ]);
  planet.getZone( 7, 3).assignEnergy([ [ galaxy.getUser(3), 70 ] ]);
  planet.getZone( 6, 4).assignEnergy([ [ galaxy.getUser(3), 62 ] ]);
  planet.getZone( 6, 5).assignEnergy([ [ galaxy.getUser(3), 60 ] ]);
  planet.getZone( 7, 4).assignEnergy([ [ galaxy.getUser(3), 55 ] ]);
  planet.getZone( 7, 5).assignEnergy([ [ galaxy.getUser(3), 35 ] ]);
  planet.getZone( 8, 5).assignEnergy([ [ galaxy.getUser(3), 35 ] ]);

  planet.getZone(11, 2).assignEnergy([ [ galaxy.getUser(3), 95 ] ]);
  planet.getZone(11, 3).assignEnergy([ [ galaxy.getUser(3), 92 ] ]);
  planet.getZone(10, 1).assignEnergy([ [ galaxy.getUser(3), 70 ] ]);
  planet.getZone(12, 4).assignEnergy([ [ galaxy.getUser(3), 67 ] ]);
  planet.getZone(12, 2).assignEnergy([ [ galaxy.getUser(3), 56 ] ]);
  planet.getZone(12, 3).assignEnergy([ [ galaxy.getUser(3), 29 ] ]);
  planet.getZone(12, 1).assignEnergy([ [ galaxy.getUser(3), 25 ] ]);
  planet.getZone(13, 2).assignEnergy([ [ galaxy.getUser(3), 17 ] ]);
  planet.getZone(11, 1).assignEnergy([ [ galaxy.getUser(3), 10 ] ]);

  planet.getZone( 5, 3).assignEnergy([ [ galaxy.getUser(0), 94 ], [ galaxy.getUser(3), 6 ] ]);
  planet.getZone( 6, 3).assignEnergy([ [ galaxy.getUser(0), 94 ], [ galaxy.getUser(3), 6 ] ]);
  planet.getZone( 7, 2).assignEnergy([ [ galaxy.getUser(0), 94 ], [ galaxy.getUser(3), 6 ] ]);
  planet.getZone( 6, 1).assignEnergy([ [ galaxy.getUser(0), 94 ], [ galaxy.getUser(3), 6 ] ]);

  planet.getZone(5, 6).assignEnergy([ [ galaxy.getUser(0), 40 ], [ galaxy.getUser(1), 60 ] ]);
  planet.getZone(4, 6).assignEnergy([ [ galaxy.getUser(0), 85 ], [ galaxy.getUser(1), 15 ] ]);
  planet.getZone(4, 5).assignEnergy([ [ galaxy.getUser(0), 85 ], [ galaxy.getUser(1), 15 ] ]);
  planet.getZone(3, 5).assignEnergy([ [ galaxy.getUser(0), 85 ], [ galaxy.getUser(1), 15 ] ]);
  planet.getZone(3, 6).assignEnergy([ [ galaxy.getUser(0), 85 ], [ galaxy.getUser(1), 15 ] ]);
  planet.getZone(5, 7).assignEnergy([ [ galaxy.getUser(0), 45 ], [ galaxy.getUser(1), 55 ] ]);

  planet.getZone(13, 6).assignEnergy([ [ galaxy.getUser(0), 50 ], [ galaxy.getUser(1), 25 ], [ galaxy.getUser(3), 25 ] ]);
  planet.getZone(14, 6).assignEnergy([ [ galaxy.getUser(0), 85 ], [ galaxy.getUser(1), 15 ] ]);
  planet.getZone(13, 5).assignEnergy([ [ galaxy.getUser(0), 41 ], [ galaxy.getUser(1), 10 ], [ galaxy.getUser(3), 49 ] ]);
  planet.getZone(13, 3).assignEnergy([ [ galaxy.getUser(0), 64 ], [ galaxy.getUser(3), 36 ] ]);
  planet.getZone(13, 4).assignEnergy([ [ galaxy.getUser(0), 64 ], [ galaxy.getUser(3), 36 ] ]);
  planet.getZone(12, 5).assignEnergy([ [ galaxy.getUser(0), 64 ], [ galaxy.getUser(3), 36 ] ]);
  planet.getZone(14, 4).assignEnergy([ [ galaxy.getUser(0), 45 ], [ galaxy.getUser(3), 55 ] ]);
  planet.getZone(14, 5).assignEnergy([ [ galaxy.getUser(0), 47 ], [ galaxy.getUser(3), 53 ] ]);

  // Buildings
  planet.createBuilding([ 9, 3 ], BuildingEnum.DistributionCenter).setStatus('upgrade');
  planet.createBuilding([ 9, 5 ], BuildingEnum.LaserBattery);
  planet.createBuilding([ 7, 4 ], BuildingEnum.TransferStation);

  // Units
  planet.createUnit([ 7, 1 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 6, 4 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 8, 1 ], UnitEnum.RangeAttack).setStatus('create');
  planet.createUnit([ 9, 1 ], UnitEnum.RangeAttack).setStatus('create');

  planet.createUnit([12, 6], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([12, 3 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([10, 6 ], UnitEnum.RangeAttack).setStatus('attack');
  planet.createUnit([11, 5 ], UnitEnum.RangeAttack).setStatus('attack');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 7, 1), planet.getZone( 6, 1), { type: ShapeEnum.DoubleBasic, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 6, 4), planet.getZone( 5, 3), { type: ShapeEnum.DoubleBasic, variant: 0 }, { value: Influence.fromPercentage(6)});

  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(12, 6), planet.getZone(12, 5), { type: ShapeEnum.DoubleBasic, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(12, 3), planet.getZone(13, 3), { type: ShapeEnum.DoubleBasic, variant: 2 }, { value: Influence.fromPercentage(6)});

  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(10, 6), planet.getZone(13, 5), { type: ShapeEnum.TripleArea, variant: 4 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(11, 5), planet.getZone(13, 5), { type: ShapeEnum.TripleArea, variant: 4 }, { value: Influence.fromPercentage(6)});

}


const setupPlanetThree = (galaxy) =>
{
  const planet = galaxy.getPlanet(2);

  // 2
  planet.getSector(2).assignUser(galaxy.getUser(4));
  planet.getSector(3).assignUser(galaxy.getUser(4));

  // 3
  planet.getSector(6).assignUser(galaxy.getUser(4));

  // Energy
  planet.getZone( 8, 4).assignEnergy([ [ galaxy.getUser(4), 80 ] ]);
  planet.getZone( 8, 5).assignEnergy([ [ galaxy.getUser(4), 90 ] ]);
  planet.getZone( 9, 4).assignEnergy([ [ galaxy.getUser(4), 90 ] ]);
  planet.getZone( 8, 3).assignEnergy([ [ galaxy.getUser(4), 90 ] ]);

  planet.getZone(10, 6).assignEnergy([ [ galaxy.getUser(4), 80 ] ]);
  planet.getZone(10, 5).assignEnergy([ [ galaxy.getUser(4), 90 ] ]);
  planet.getZone(10, 7).assignEnergy([ [ galaxy.getUser(4), 90 ] ]);
  planet.getZone(11, 6).assignEnergy([ [ galaxy.getUser(4), 90 ] ]);

  planet.getZone( 7, 7).assignEnergy([ [ galaxy.getUser(4),100 ] ]);
  planet.getZone( 8, 7).assignEnergy([ [ galaxy.getUser(4),100 ] ]);
  planet.getZone( 8, 8).assignEnergy([ [ galaxy.getUser(4), 91 ] ]);
  planet.getZone( 7, 6).assignEnergy([ [ galaxy.getUser(4), 87 ] ]);
  planet.getZone( 8, 6).assignEnergy([ [ galaxy.getUser(4), 87 ] ]);
  planet.getZone( 7, 8).assignEnergy([ [ galaxy.getUser(4), 68 ] ]);
  planet.getZone( 7, 9).assignEnergy([ [ galaxy.getUser(4), 66 ] ]);
  planet.getZone( 7, 5).assignEnergy([ [ galaxy.getUser(4), 55 ] ]);
  planet.getZone( 9, 6).assignEnergy([ [ galaxy.getUser(4), 58 ] ]);

  planet.getZone( 5, 6).assignEnergy([ [ galaxy.getUser(0), 67 ], [ galaxy.getUser(4), 33 ] ]);
  planet.getZone( 6, 6).assignEnergy([ [ galaxy.getUser(0), 67 ], [ galaxy.getUser(4), 33 ] ]);
  planet.getZone( 4, 7).assignEnergy([ [ galaxy.getUser(0), 67 ], [ galaxy.getUser(4), 33 ] ]);
  planet.getZone( 5, 7).assignEnergy([ [ galaxy.getUser(0), 67 ], [ galaxy.getUser(4), 33 ] ]);
  planet.getZone( 6, 7).assignEnergy([ [ galaxy.getUser(0), 67 ], [ galaxy.getUser(4), 33 ] ]);
  planet.getZone( 5, 8).assignEnergy([ [ galaxy.getUser(0), 67 ], [ galaxy.getUser(4), 33 ] ]);
  planet.getZone( 6, 8).assignEnergy([ [ galaxy.getUser(0), 67 ], [ galaxy.getUser(4), 33 ] ]);

  // Buildings
  planet.createBuilding([ 9, 4 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([11, 6 ], BuildingEnum.DistributionCenter).setStatus('upgrade');
  planet.createBuilding([ 7, 9 ], BuildingEnum.Spaceport);

  // Units
  planet.createUnit([ 8, 4 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([10, 6 ], UnitEnum.AreaAttack).setStatus('attack');

  // Spaceships
  galaxy.createSpaceship([15, 5 ], SpaceshipEnum.FighterBasic, galaxy.getUser(4)).setStatus('create');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 8, 4), planet.getZone( 5, 7), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(10, 6), planet.getZone( 5, 7), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
}


const setupPlanetFour = (galaxy) =>
{
  const planet = galaxy.getPlanet(3);

  // 1
  planet.getSector(0).assignUser(galaxy.getUser(5));
  planet.getSector(1).assignUser(galaxy.getUser(5));

  // 2
  planet.getSector(2).assignUser(galaxy.getUser(5));
  planet.getSector(3).assignUser(galaxy.getUser(5));

  // 3
  planet.getSector(4).assignUser(galaxy.getUser(5));
  planet.getSector(5).assignUser(galaxy.getUser(5));

  // Energy
  planet.getZone( 2, 1).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 3, 1).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 2, 2).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 3, 2).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 2, 3).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 3, 3).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 3, 4).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);

  planet.getZone( 4, 0).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 6, 0).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 4, 1).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 5, 0).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 5, 1).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 6, 1).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 4, 2).assignEnergy([ [ galaxy.getUser(5), 85 ] ]);
  planet.getZone( 5, 2).assignEnergy([ [ galaxy.getUser(5), 85 ] ]);
  planet.getZone( 6, 2).assignEnergy([ [ galaxy.getUser(5), 85 ] ]);

  planet.getZone( 6, 3).assignEnergy([ [ galaxy.getUser(5), 45 ] ]);
  planet.getZone( 7, 1).assignEnergy([ [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 7, 2).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 8, 2).assignEnergy([ [ galaxy.getUser(5), 40 ] ]);
  planet.getZone( 7, 3).assignEnergy([ [ galaxy.getUser(5), 65 ] ]);

  planet.getZone( 4, 3).assignEnergy([ [ galaxy.getUser(5), 90 ] ]);
  planet.getZone( 5, 3).assignEnergy([ [ galaxy.getUser(5), 90 ] ]);
  planet.getZone( 4, 4).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 5, 4).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 6, 4).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 4, 5).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 5, 5).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 5, 6).assignEnergy([ [ galaxy.getUser(5), 45 ] ]);
  planet.getZone( 6, 6).assignEnergy([ [ galaxy.getUser(5), 45 ] ]);
  planet.getZone( 6, 5).assignEnergy([ [ galaxy.getUser(5), 40 ] ]);
  planet.getZone( 7, 4).assignEnergy([ [ galaxy.getUser(5), 45 ] ]);

  planet.getZone( 7, 6).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 6, 7).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);
  planet.getZone( 7, 7).assignEnergy([ [ galaxy.getUser(5), 50 ] ]);
  planet.getZone( 8, 7).assignEnergy([ [ galaxy.getUser(5), 50 ] ]);
  planet.getZone( 7, 8).assignEnergy([ [ galaxy.getUser(5), 45 ] ]);
  planet.getZone( 8, 8).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 7, 9).assignEnergy([ [ galaxy.getUser(5), 45 ] ]);

  planet.getZone( 8, 3).assignEnergy([ [ galaxy.getUser(5), 65 ] ]);
  planet.getZone( 9, 3).assignEnergy([ [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 8, 4).assignEnergy([ [ galaxy.getUser(5), 65 ] ]);
  planet.getZone( 9, 4).assignEnergy([ [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 7, 5).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 8, 5).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 9, 5).assignEnergy([ [ galaxy.getUser(5), 35 ] ]);
  planet.getZone( 8, 6).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 9, 6).assignEnergy([ [ galaxy.getUser(5), 35 ] ]);

  planet.getZone( 7,10).assignEnergy([ [ galaxy.getUser(0), 65 ], [ galaxy.getUser(5), 35 ] ]);
  planet.getZone( 6,11).assignEnergy([ [ galaxy.getUser(0), 65 ], [ galaxy.getUser(5), 35 ] ]);
  planet.getZone( 7,11).assignEnergy([ [ galaxy.getUser(0), 65 ], [ galaxy.getUser(5), 35 ] ]);
  planet.getZone( 6,10).assignEnergy([ [ galaxy.getUser(0), 77 ], [ galaxy.getUser(5), 23 ] ]);
  planet.getZone( 8,10).assignEnergy([ [ galaxy.getUser(0), 89 ], [ galaxy.getUser(5), 11 ] ]);

  planet.getZone( 4, 8).assignEnergy([ [ galaxy.getUser(0), 91 ], [ galaxy.getUser(5), 9 ] ]);
  planet.getZone( 5, 8).assignEnergy([ [ galaxy.getUser(0), 91 ], [ galaxy.getUser(5), 9 ] ]);
  planet.getZone( 3, 9).assignEnergy([ [ galaxy.getUser(0), 91 ], [ galaxy.getUser(5), 9 ] ]);
  planet.getZone( 4, 9).assignEnergy([ [ galaxy.getUser(0), 91 ], [ galaxy.getUser(5), 9 ] ]);
  planet.getZone( 5, 9).assignEnergy([ [ galaxy.getUser(0), 91 ], [ galaxy.getUser(5), 9 ] ]);
  planet.getZone( 4,10).assignEnergy([ [ galaxy.getUser(0), 91 ], [ galaxy.getUser(5), 9 ] ]);
  planet.getZone( 5,10).assignEnergy([ [ galaxy.getUser(0), 91 ], [ galaxy.getUser(5), 9 ] ]);

  // Buildings
  planet.createBuilding([ 5, 2 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 3, 3 ], BuildingEnum.GaussCannon);
  planet.createBuilding([ 5, 5 ], BuildingEnum.GaussCannon);
  planet.createBuilding([ 7, 1 ], BuildingEnum.Spaceport);
  planet.createBuilding([ 8, 8 ], BuildingEnum.Spaceport);
  planet.createBuilding([ 8, 5 ], BuildingEnum.GaussArtillery).setStatus('create');

  // Units
  planet.createUnit([ 7, 8 ], UnitEnum.ConeAttack).setStatus('attack');
  planet.createUnit([ 7, 9 ], UnitEnum.ConeAttack).setStatus('attack');

  planet.createUnit([ 4, 5 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 6, 5 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 3, 4 ], UnitEnum.AreaAttack).setStatus('attack');

  // Spaceships
  galaxy.createSpaceship([-7, 4 ], SpaceshipEnum.FighterBasic, galaxy.getUser(5)).setStatus('create');
  galaxy.createSpaceship([-7, 5 ], SpaceshipEnum.FighterBasic, galaxy.getUser(5)).setStatus('create');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 7, 8), planet.getZone( 7,10), { type: ShapeEnum.QuadrupleArea, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 7, 9), planet.getZone( 7,10), { type: ShapeEnum.QuadrupleArea, variant: 1 }, { value: Influence.fromPercentage(6)});

  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 4, 5), planet.getZone( 4, 9), { type: ShapeEnum.SeptupleArea, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 6, 5), planet.getZone( 4, 9), { type: ShapeEnum.SeptupleArea, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 3, 4), planet.getZone( 4, 9), { type: ShapeEnum.SeptupleArea, variant: 1 }, { value: Influence.fromPercentage(6)});

  //galaxy.createTransferInteraction(planet.getZone(3, 3), planet.getZone(5, 5), { type: ShapeEnum.Sector, variant: 0 });
  //galaxy.createTransferInteraction(planet.getZone(5, 2), planet.getZone(5, 5), { type: ShapeEnum.Sector, variant: 0 });
  //galaxy.createTransferInteraction(planet.getZone(7, 1), planet.getZone(8, 3), { type: ShapeEnum.Sector, variant: 0 });

  // Projectiles
  galaxy.createProjectile(ProjectileEnum.GaussCannon, planet.getZone(3, 3), planet.getZone(7,10), { type: ShapeEnum.QuadrupleArea, variant: 0 }, 200, 900);
}


const setupPlanetFive = (galaxy) =>
{
  const planet = galaxy.getPlanet(4);
}


const setupInterplanetaryInteractions = (galaxy) =>
{
  const planetOne = galaxy.getPlanet(0);
  const planetTwo = galaxy.getPlanet(1);

  // Spaceship targets
  galaxy.getSpaceship(0).setTarget(planetTwo.getZone(13, 6))
  galaxy.getSpaceship(1).setTarget(planetTwo.getZone( 5, 6))

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, galaxy.getSpaceship(0), planetTwo.getZone(13, 6), { type: ShapeEnum.Single, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, galaxy.getSpaceship(1), planetTwo.getZone( 5, 6), { type: ShapeEnum.Single, variant: 0 }, { value: Influence.fromPercentage(6)});

  // Projectiles
  galaxy.createProjectile(ProjectileEnum.LaserBattery, planetOne.getZone(3, 0), planetTwo.getZone(13, 6), { type: ShapeEnum.TripleArea, variant: 4 }, 50, 900);
  galaxy.createProjectile(ProjectileEnum.GaussCannon, planetOne.getZone(1, 8), planetTwo.getZone(4, 6), { type: ShapeEnum.QuadrupleArea, variant: 3 }, 600, 900);
}
