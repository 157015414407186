/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */

import Event from 'fierry/flux/entity/event';
import Screen from 'galax/state/screen';


export default new Event ([ Screen.Online ], (setScreenOnline) =>
{
  const eventListener = () =>
  {
    setScreenOnline({ online: window.navigator.onLine });
  };

  window.addEventListener('online', eventListener);
  window.addEventListener('offline', eventListener);

  return () =>
  {
    window.removeEventListener('online', eventListener);
    window.removeEventListener('offline', eventListener);
  }
});
