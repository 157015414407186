/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern } from 'fierry/react';

import TemporaryContent from 'fierry/react/skeleton/sidebar/content/temporary'


export default (props) =>
(
  <Modern.Stack.Child>
    <TemporaryContent {...props} />
  </Modern.Stack.Child>
);
