/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Model from 'fierry/flux/model';


export default class extends Model
{
  getOne (table, selector)
  {
    for (let record of table.values())
    {
      if (record && selector(record))
      {
        return record;
      }
    }

    return null;
  }


  getAll (table, selector)
  {
    let array = [];

    for (let record of table.values())
    {
      if (record && selector(record))
      {
        array.push(record);
      }
    }

    return array;
  }


};
