/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Queries from 'fierry/flux/queries';

import ParentEnum from 'galax/enum/parent';


export default class extends Queries
{
  getCurrentTime ()
  {
    return this.getSessionRpc().get('currentTime');
  }


  getCurrentGalaxy ()
  {
    return this.getSessionRpc().get('currentGalaxy');
  }


  getCurrentUser ()
  {
    return this.getUsersRpc().get('currentUser');
  }


  getPlanets ()
  {
    let planets = [];

    for (const planet of this.getSessionRpc().get('planets').values())
    {
      planets.push(planet.state.get('currentPlanet'));
    }

    return planets;
  }


  getSpaceshipByID (id)
  {
    return this.getSpaceshipRpc(id).get('currentSpaceship');
  }


  getSpaceshipByZone (zone)
  {
    for (const spaceshipRpc of this.getSessionRpc().get('spaceships').values())
    {
      const spaceship = spaceshipRpc.get('currentSpaceship');

      if (spaceship.zone === zone)
      {
        return spaceship;
      }
    }

    return null;
  }


  getSpaceships ()
  {
    let spaceships = [];

    for (const spaceship of this.getSessionRpc().get('spaceships').values())
    {
      spaceships.push(spaceship.get('currentSpaceship'));
    }

    return spaceships;
  }


  getUser (id)
  {
    return this.getOne(this.getUsersRpc().get('users'), user => user.id === id);
  }


  getUsers ()
  {
    return this.getAll(this.getUsersRpc().get('users'), () => true);
  }


  getUsersRpc ()
  {
    return this.getSessionRpc().get('users');
  }


  getPlanetRpc (id)
  {
    return this.getSessionRpc().get('planets').get(id).state;
  }


  getSpaceshipRpc (id)
  {
    return this.getSessionRpc().get('spaceships').get(id);
  }


  getSessionRpc ()
  {
    return this.props;
  }


};
