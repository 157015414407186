/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ShapeEnum from 'galax/enum/shape';


export const getShapeData = (target, type) =>
{
  switch (type)
  {
    case ShapeEnum.Single:          return getSingleData(target);
    case ShapeEnum.DoubleBasic:     return getDoubleBasicData(target);

    case ShapeEnum.TripleArea:      return getTripleAreaData(target);
    case ShapeEnum.TripleLine:      return getTripleLineData(target);
    case ShapeEnum.TripleAngle:     return getTripleAngleData(target);

    case ShapeEnum.QuadrupleArea:   return getQuadrupleAreaData(target);
    case ShapeEnum.QuadrupleStar:   return getQuadrupleStarData(target);
    case ShapeEnum.QuadrupleLTail:  return getQuadrupleLTailData(target);
    case ShapeEnum.QuadrupleRTail:  return getQuadrupleRTailData(target);

    case ShapeEnum.QuintupleArea:   return getQuintupleAreaData(target);
    case ShapeEnum.QuintupleStar:   return getQuintupleStarData(target);
    case ShapeEnum.QuintupleSplash: return getQuintupleSplashData(target);

    case ShapeEnum.SextupleArea:    return getSextupleAreaData(target);
    case ShapeEnum.SeptupleArea:    return getSeptupleAreaData(target);
  }

  return [];
}


// t
export const getSingleData = (target) =>
{
  return [ target ];
}

// t x
const getDoubleBasicData = (target) =>
{
  return [ target, target.getCenterRight() ];
}


//  t
// x x
const getTripleAreaData = (target) =>
{
  return [ target, target.getBottomRight(), target.getBottomLeft() ];
}


// x t x
const getTripleLineData = (target) =>
{
  return [ target, target.getCenterRight(), target.getCenterLeft() ];
}


//   t x
// x
const getTripleAngleData = (target) =>
{
  return [ target, target.getCenterRight(), target.getBottomLeft() ];
}


//  t x
// x x
const getQuadrupleAreaData = (target) =>
{
  return [ target, target.getCenterRight(), target.getBottomRight(), target.getBottomLeft() ];
}


//    x
// x t
//    x
const getQuadrupleStarData = (target) =>
{
  return [ target, target.getTopRight(), target.getBottomRight(), target.getCenterLeft() ];
}


//  x
//   t
//  x x
const getQuadrupleLTailData = (target) =>
{
  return [ target, target.getBottomRight(), target.getBottomLeft(), target.getTopLeft() ];
}


//    x
//   t
//  x x
const getQuadrupleRTailData = (target) =>
{
  return [ target, target.getTopRight(), target.getBottomRight(), target.getBottomLeft() ];
}


//    x
//   t x
//  x x
const getQuintupleAreaData = (target) =>
{
  return [ target, target.getTopRight(), target.getCenterRight(), target.getBottomRight(), target.getBottomLeft() ];
}


//    x
// x t x
//  x
const getQuintupleStarData = (target) =>
{
  return [ target, target.getTopRight(), target.getCenterRight(), target.getBottomLeft(), target.getCenterLeft() ];
}


//    x
// x t x
//    x
const getQuintupleSplashData = (target) =>
{
  return [ target, target.getTopRight(), target.getCenterRight(), target.getBottomRight(), target.getCenterLeft() ];
}


//    x
// x t x
//  x x
const getSextupleAreaData = (target) =>
{
  return [ target, target.getTopRight(), target.getCenterRight(), target.getBottomRight(), target.getBottomLeft(), target.getCenterLeft() ];
}


//  x x
// x t x
//  x x
const getSeptupleAreaData = (target) =>
{
  return [ target, target.getTopRight(), target.getCenterRight(), target.getBottomRight(), target.getBottomLeft(), target.getCenterLeft(), target.getTopLeft() ];
}
