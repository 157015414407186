/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { css, styled } from 'fierry/react/styled';


export default styled.div`
  padding: 0 0.25rem;

  border-bottom: solid 1px;

  display: flex;

  :hover {
    cursor: pointer;
  }
`;
