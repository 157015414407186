/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default new class
{
  label (value)
  {
    return value === true;
  }


  value (value)
  {
    return value && value !== true;
  }


}();
