/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator';


export default class extends Validator
{
  isRouteVisible ()
  {
    return this.isSpaceshipLocation() && this.isUserOwner() && !this.isDockLocation();
  }


  isRouteDisabled ()
  {
    return (!this.isSpaceshipStatusDefault() && !this.isSpaceshipStatusAttack()) || this.isSpaceshipCriticalEnergy();
  }


  isRouteActivated ()
  {
    return this.isSpaceshipStatusAttack();
  }


  isSpaceshipStatusAttack ()
  {
    const spaceship = this.currentLocation.getSpaceship();

    return spaceship && spaceship.getStatus().isAttack();
  }


  isSpaceshipStatusDefault ()
  {
    const spaceship = this.currentLocation.getSpaceship();

    return spaceship && spaceship.getStatus().isDefault();
  }


  isSpaceshipCriticalEnergy ()
  {
    const spaceship = this.currentLocation.getSpaceship();

    return spaceship && spaceship.isCriticalEnergy();
  }


};
