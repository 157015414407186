/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Points from 'galax/geo/points';
import * as Bezier from 'galax/geo/bezier';


export default class
{
  constructor (source, target, parent)
  {
    this.parent = parent;

    this.source = this.parent.getLocalHex(source);
    this.target = this.parent.getLocalHex(target);
  }


  getClosestPointsWithOffset (sourceOffset, targetOffset)
  {
    const controlPoint = this.__getControlPoint__(this.source.getAbsolute(), this.target.getAbsolute())

    const sourcePoints = this.source.getPointsFromBorder(sourceOffset);
    const targetPoints = this.target.getPointsFromBorder(targetOffset);

    let minSourcePoint = this.__getClosestPointToControl__(sourcePoints, controlPoint);
    let minTargetPoint = this.__getClosestPointToControl__(targetPoints, controlPoint);

    return { source: minSourcePoint, target: minTargetPoint };
  }


  __getClosestPointToControl__ (points, control)
  {
    let minPoint = null;
    let minDistance = null;

    for (let i = 0; i < points.length; ++i)
    {
      if (minDistance == null || Points.distance(points[i], control) < minDistance)
      {
        minPoint = points[i];

        minDistance = Points.distance(points[i], control);
      }
    }

    return minPoint;
  }


  getFlightCurves (source, target, progress)
  {
    const control = this.__getControlPoint__(source, target);

    if (progress === 0)
    {
      return { current: null, future: Bezier.create(source, control, target) };
    }

    if (progress === 1)
    {
      return { current: Bezier.create(source, control, target), future: null };
    }

    const { left, right } = Bezier.split(source, control, target, progress);

    return { current: left, future: right };
  }


  __getControlPoint__ (source, target)
  {
    const offset = this.__getControlOffset__(source, target);

    return Bezier.getPerpendicularBisector(source, target, offset);
  }


  __getControlOffset__ (source, target)
  {
    const revertNegative = source.x < target.x ? 1 : -1;

    return Points.distance(source, target) * 1/4 * revertNegative;
  }


};
