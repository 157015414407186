/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, useFlux, withValue } from 'fierry/react';

import Now from 'galax/query/now';

import UtilDate from 'fierry/util/date';

import ClockIcon from 'svg/shared/modern/clock';


// TODO Maybe if value is not a number we want to use it as a default value? Or we may expect default display from here or client upwards?
const getValue = ({ value, now, ...props }) =>
{
  value = withValue(value, props, now);

  return UtilDate.getTimeString(value);
}


const Component = (props) =>
(
  <Element.Group param width = '5.0' {...props} >

    <Element.Icon value = {<ClockIcon />} />
    <Element.Text value = {getValue(props)} />

  </Element.Group>
);


export default (props) =>
{
  const [ now ] = useFlux(Now);

  return <Component {...props} now = {now} />;
}
