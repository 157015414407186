/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Client from 'fierry/flux/entity/client';

import Server from 'galax/server';
import Now from 'galax/state/now';



export default new Client ([ Now, Server ], class
{
  constructor ([ state, dispatch ], server)
  {
    this.state = state;
    this.dispatch = dispatch;

    this.record = server ? server.getCurrentTime() : null;
  }


  getClientDate ()
  {
    if (!this.record)
    {
      return null;
    }

    return this.isRealTime() ? this.record.getClientDate(this.state) : this.record.getClientDate();
  }


  getServerDate ()
  {
    if (!this.record)
    {
      return null;
    }

    return this.isRealTime() ? this.record.getServerDate(this.state) : this.record.getServerDate();
  }


  isRealTime ()
  {
    return this.record ? this.record.isRealTime() : false;
  }


  isServerDelayed ()
  {
    return this.record ? this.record.isServerDelayed() : false;
  }


  getServerDelay ()
  {
    return this.record.getServerDelay();
  }


});
