/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class Batch
{
  constructor (id, rpc)
  {
    this.id = id;
    this.rpc = rpc;

    this.ready = false;
    this.notifications = [];
  }


  commit ()
  {
    this.ready = true;
  }


  push (routing, data)
  {
    this.notifications.push({routing, data});
  }


  execute ()
  {
    for (let notification of this.notifications)
    {
      this.rpc.elements.notify(notification.routing, notification.data);
    }
  }


  getId ()
  {
    return this.id;
  }


  isReady ()
  {
    return this.ready;
  }


};
