/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, css } from 'fierry/react';


const dimensionConfig = ({ dimension }) => dimension && css`
  --svg-dash-array-a: ${dimension / 12} ${dimension / 12};
`;


export default styled.g`
  ${dimensionConfig}
`;
