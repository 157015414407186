/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Header from 'fierry/react/header';

import DoneButton from 'shared/react/button/behavior.done';


const getName = ({ constants }) =>
{
  return constants.getName();
}


const Component = (props) =>
(
  <Header headline = {getName(props)} >
    <DoneButton {...props} />
  </Header>
);


export default (props) =>
{
  const { galaxy, type, currentArgs } = props;

  return <Component {...props} constants = {galaxy.getConstants().getSpaceship(type || currentArgs.type)} />;
}
