/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { animate, css, styled, withRef, Transition } from 'fierry/react';

import Container from 'fierry/react/skeleton/sidebar/container';


const MobileContainer = styled(Container)`
  border-top: 1px solid var(--fierry-divider-color);
`;


const Animation = animate(MobileContainer,
{
  transition: ['height'],

  enter: css`
    height: var(--fierry-pane-height-expanded);
  `,

  exit: css`
    height: var(--fierry-pane-height-collapsed);
  `,
});


export default withRef(({ collapsed, ...props }, ref) =>
{
  const transitionCallback = (transitionProps) =>
  (
    <Animation ref = {ref} {...props} {...transitionProps} />
  );

  return <Transition active = {!collapsed} callback = {transitionCallback} />;
})
