/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Model from 'fierry/rpc/model';

import General from 'galax/server/constants/general';

import Projectile from 'galax/server/constants/projectile';
import Spaceship from 'galax/server/constants/spaceship';
import Building from 'galax/server/constants/building';
import Theme from 'galax/server/constants/theme';
import Unit from 'galax/server/constants/unit';


export default class extends Model
{
  constructor (state, hierarchy)
  {
    super(state, hierarchy);

    this.projectiles = new Map();
    this.spaceships = new Map();
    this.buildings = new Map();
    this.themes = new Map();
    this.units = new Map();

    this.general = new General();

    for (const constants of this.state.get('projectiles').values())
    {
      this.projectiles.set(constants.type, new Projectile({ constants }));
    }

    for (const constants of this.state.get('spaceships').values())
    {
      this.spaceships.set(constants.type, new Spaceship({ constants }));
    }

    for (const constants of this.state.get('buildings').values())
    {
      this.buildings.set(constants.type, new Building({ constants }));
    }

    for (const constants of this.state.get('themes').values())
    {
      this.themes.set(constants.type, new Theme({ constants }));
    }

    for (const constants of this.state.get('units').values())
    {
      this.units.set(constants.type, new Unit({ constants }));
    }
  }


  getProjectile (type)
  {
    return this.projectiles.get(type);
  }


  getSpaceship (type)
  {
    return this.spaceships.get(type);
  }


  getBuilding (type)
  {
    return this.buildings.get(type);
  }


  getTheme (type)
  {
    return this.themes.get(type);
  }


  getUnit (type)
  {
    return this.units.get(type);
  }


  getGeneral ()
  {
    return this.general;
  }


}
