/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Fortified from 'galax/react/map/util/fortified';
import Dashed from 'galax/react/map/util/dashed';
import Invert from 'galax/react/map/util/invert';

import Unit from 'galax/react/icon/unit';


const getDimension = () =>
{
  return 2 * 26 + 2 * 20;
}


const Component = ({ type, grid }) =>
{
  const size = grid.getGeneralHex().getEntitySize();

  return <Unit center size = {size} type = {type} />;
}


export default ({ dashed, fortified, inverted, ...props }) =>
{
  if (dashed)
  {
    return <Dashed dimension = {getDimension()}> <Component {...props} /> </Dashed>;
  }

  if (fortified)
  {
    return <Fortified> <Component {...props} /> </Fortified>;
  }

  if (inverted)
  {
    return <Invert> <Component {...props} /> </Invert>;
  }

  return <Component {...props} />;
};
