/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Modern from 'fierry/react/modern';

import { styled } from 'fierry/react/styled';


const Component = styled(Modern.Group)`

  margin-right: -1.0rem;

  :empty
  {
    display: none;
  }

  --fierry-theme-foreground: var(--fierry-theme-primary-foreground);
`;


export default ({ buttons : Buttons, children, ...props }) =>
{
  if (Buttons)
  {
    children = <Buttons {...props} />;
  }

  return <Component children = {children} />;
}
