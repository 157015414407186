/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, Modern, useFlux } from 'fierry/react';

import Now from 'galax/query/now';

import * as EnergyValue from 'galax/value/energy';
import * as InfluenceValue from 'galax/value/influence';

import DefenceIcon from 'svg/shared/modern/defence.js';
import ResistanceIcon from 'svg/shared/modern/resistance.js';

import IncreaseIcon from 'svg/shared/unit/increase.js';
import DecreaseIcon from 'svg/shared/unit/decrease.js';

import PerUnit from 'svg/shared/unit/per.js';
import HourUnit from 'svg/shared/unit/hour.js';
import PercentUnit from 'svg/shared/unit/percent.js';


const getResistanceEfficiency = ({ currentZone, currentUser, now }) =>
{
  const efficiency = 1 - currentZone.getResistance().getEfficiency(currentUser.getID(), now);

  return (Math.round(efficiency * 100)) + '';
}


const getResistanceProgress = ({ currentZone, currentUser, now }) =>
{
  return currentZone.getResistance().getProgress(currentUser.getID(), now);
}


const getResistanceDamage = ({ currentZone, currentUser, now }) =>
{
  const damage = currentZone.getResistance().getDamage(currentUser.getID(), now);

  return EnergyValue.toPercentage(damage) + '';
}


const getResistanceFlow = ({ currentZone, currentUser }) =>
{
  const flow = currentZone.getResistance().getFlow(currentUser.getID());

  return Math.abs(InfluenceValue.toPercentage(flow)) + '';
}


const FlowIcon = ({ currentZone, currentUser, ...props }) =>
{
  const value = currentZone.getResistance().getFlow(currentUser.getID());

  return (value < 0) ? <DecreaseIcon {...props} /> : <IncreaseIcon {...props} />;
};


const ResistanceComponent = (props) =>
(
  <Modern.Group vertical>

    <Modern.Group>

      <Element.Group param width = '7.0'>

        <Element.Icon value = {<ResistanceIcon />} />
        <Element.Text value = {getResistanceDamage(props)} />

        <Element.Unit value = {<FlowIcon {...props} />} />
        <Element.Text value = {getResistanceFlow(props)} />
        <Element.Unit value = {[ <PerUnit />, <HourUnit /> ]} />

      </Element.Group>

      <Modern.Progress width = '6' value = {getResistanceProgress(props)} />

      <Element.Group param width = '4'>

        <Element.Icon value = {<DefenceIcon />} />
        <Element.Text value = {getResistanceEfficiency(props)} />
        <Element.Unit value = {<PercentUnit />} />

      </Element.Group>


    </Modern.Group>

  </Modern.Group>
);


export default (props) =>
{
  const { currentZone, currentUser } = props;

  const [ now ] = useFlux(Now);

  const damage = currentZone.getResistance().getDamage(currentUser.getID(), now);

  return damage ? <ResistanceComponent {...props} now = {now} /> : null;
}
