/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Points from 'galax/geo/points';


export const getPoints = (width, height) =>
{
  return [
    { x:  0,           y: -0.5  * height },
    { x:  0.5 * width, y: -0.25 * height },
    { x:  0.5 * width, y:  0.25 * height },
    { x:  0,           y:  0.5  * height },
    { x: -0.5 * width, y:  0.25 * height },
    { x: -0.5 * width, y: -0.25 * height }
  ];
}


export const getBorders = (points) =>
{
  let borders = [];

  for (let i = 0; i < getPointCount(); ++i)
  {
    const pointA = points[getPointIndex(i)];
    const pointB = points[getPointIndex(i + 1)];

    borders.push({ pointA, pointB });
  }

  return borders;
}


export const getCenter = (points) =>
{
  let x = 0;
  let y = 0;

  for (const point of points)
  {
    x += point.x / points.length;
    y += point.y / points.length;
  }

  return { x, y };
}


export const getIntersectionPoints = (lhsPoints, rhsPoints) =>
{
  const points = [];

  for (let i = 0; i < getPointCount(); ++i)
  {
    let prev = getPointIndex(i - 1);
    let next = getPointIndex(i + 1);

    points[i] = Points.getIntersectionPoint(lhsPoints[prev], lhsPoints[i], rhsPoints[i], rhsPoints[next]);
  }

  return points;
}


export const getPointIndex = (index) =>
{
  return (index + getPointCount()) % getPointCount();
}


export const getPointCount = () =>
{
  return 6;
}
