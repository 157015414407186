/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern, styled } from 'fierry/react';
import { List, Item, Link, Bold } from 'galax/react/element/welcome';

import NextButton from 'galax/react/button/general/next'


import * as Manual from 'galax/fake/manual';


const WelcomeContainer = styled.div`
  user-select: text;
`;


export default (props) =>
(
  <WelcomeContainer>

    <Card headline = {Manual.getHeader(0)} >
      <Modern.Text param>{Manual.getText(0)}</Modern.Text>
    </Card>

    <Modern.Card spacing = '1.0'>
      <Modern.Text headline = '6'>Your decisions matter!</Modern.Text>
      <Modern.Text param>Once you make your action - construct a new building, produce a spaceship or conquer another player’s territory - you cannot take it back! You will have to live with the consequences. That makes it <Bold>real</Bold>!</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1.0'>
      <Modern.Text headline = '6'>The game doesn’t stop when you do</Modern.Text>
      <Modern.Text param>Things can happen to you while you are offline. Other players can attack your zones, destroy your spaceships or conquer your territory. You need to take this into consideration and plan your actions accordingly.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1.0'>
      <Modern.Text headline = '6'>Not a click, click, click RTS game</Modern.Text>
      <Modern.Text param>You don’t have to quickly flip between many different tasks in order to succeed. You can take your time to devise and execute a strategy and beat your enemies.</Modern.Text>
    </Modern.Card>

  </WelcomeContainer>
);
