/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { styled, useFlux } from 'fierry/react';

import Center from 'galax/react/map/zone/center';



const getUnderlineWidth = ({ grid }) =>
{
  return grid.getGeneralHex().getExternalWidth(0.5);
}


const getUnderlineHeight = ({ grid }) =>
{
  return grid.getGeneralHex().getExternalHeight(0.25);
}


const getUnderlineStrokeWidth = ({ grid }) =>
{
  return grid.getGeneralHex().getExternalSize(0.05);
}


const Underline = styled.line`
  stroke: var(--svg-always-other);
  stroke-width: ${props => getUnderlineStrokeWidth(props)};
  stroke-linecap: round;
`;


const getUnderlineProps = (props) =>
{
  const width = getUnderlineWidth(props);
  const height = getUnderlineHeight(props);

  const x1 = -1/2 * width;
  const x2 = 1/2 * width;

  const y1 = height;
  const y2 = height;

  return { x1, y1, x2, y2 };
}


const Component = (props) =>
(
  <Center local {...props} >
    <Underline {...props} {...getUnderlineProps(props)} />
  </Center>
);


export default ({ currentLocation, ...props }) =>
{
  return <Component {...props} location = {currentLocation} />;
};
