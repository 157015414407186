/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Element from 'fierry/rpc/element';


export default class extends Element
{
  constructor (constructorCallback)
  {
    super();

    this.__constructorCallback__ = constructorCallback;
  }


  onElementDetach (name)
  {
    super.onElementDetach(name);

    this.__elements__.get(name).dispose();

    this.__elements__.delete(name);
  }


  getElement (name)
  {
    if (!this.__elements__.has(name))
    {
      this.createElement(name, this.__constructorCallback__(name));
    }

    return this.__elements__.get(name);
  }


};
