/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator';


export default class extends Validator
{
  isRouteVisible ()
  {
    return this.isZoneLocation() && this.isUserOwner() && this.isZoneNotCenterAndUnitEmpty();
  }


  isRouteDisabled ()
  {
    return false;
  }


  isRouteActivated ()
  {
    return this.isUnitStatusProduction();
  }


  isZoneNotCenterAndUnitEmpty ()
  {
    const currentZone = this.currentLocation.getZone();

    return !currentZone.getCass().isCenter() && currentZone.getUnit() == null;
  }


  isUnitStatusProduction ()
  {
    const unit = this.currentLocation.getZone().getUnit();

    return unit && unit.getStatus().isProduction();
  }


};
