/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import {styled } from 'fierry/react';

import Container from 'fierry/react/skeleton/pane/container';


export default styled(Container)`
`;
