/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, Modern } from 'fierry/react';

import RangeIcon from 'svg/shared/modern/attack-stroke';
import DamageIcon from 'svg/shared/modern/damage';
import EfficiencyIcon from 'svg/shared/modern/efficiency';

import MultiplyUnit from 'svg/shared/unit/multiply';
import PercentUnit from 'svg/shared/unit/percent';


const RangeElement = ({ constants }) =>
(
  <Element.Group width = '3' param tooltip = 'Maximum Range' >
    <Element.Icon value = {<RangeIcon />} />
    <Element.Text value = {constants.getWeaponRange()} />
  </Element.Group>
);


const DamageElement = ({ constants }) =>
(
  <Element.Group width = '4' param tooltip = 'Target Count x Damage' >
    <Element.Icon value = {<DamageIcon />} />
    <Element.Text value = {constants.getWeaponTargetCount()} />
    <Element.Unit value = {<MultiplyUnit />}/>
    <Element.Text value = {constants.getWeaponTargetDamage()} />
  </Element.Group>

);


const Component = (props) =>
(
  <Modern.Group spacing = '1.5'>

    <DamageElement {...props} />
    <RangeElement {...props} />

  </Modern.Group>
);


export default (props) =>
{
  const { type, constants } = props;

  return constants.canFireProjectiles() ? <Component {...props} /> : null;
}
