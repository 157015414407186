/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, css } from 'fierry/react';

import * as Props from 'svg/props';


export const SvgSize = (props) => css`
  width: ${Props.getWidth(props)}px;
  height: ${Props.getHeight(props)}px;
`;


export const Svg24per32Ratio = (props) => Props.isRatio(props, 24/32) && css`
  --svg-line-stroke-width-default-10: ${Props.getRatioLineStroke(props)}px;
`;


export const Svg18per32Ratio = (props) => Props.isRatio(props, 18/32) && css`
  --svg-line-stroke-width-default-10: 0;
  --svg-line-stroke-width-default-20: ${Props.getRatioLineStroke(props)}px;
`;


export const noneShape = css`
  fill: var(--svg-transparent-default);
  stroke: var(--svg-transparent-default);

  stroke-width: 0px;
`;


export const noneLine = css`
  fill: var(--svg-transparent-default);
  stroke: var(--svg-transparent-default);

  stroke-linecap: round;
  stroke-linejoin: round;
`;


export const blackLine = css`
  ${noneLine}

  stroke: var(--svg-black-default);
`;


export const otherLine = css`
  ${noneLine}

  stroke: var(--svg-other-default);
`;


export const whiteLine = css`
  ${noneLine}

  stroke: var(--svg-white-default);
`;

