/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import NativeUnit from 'galax/react/map/entity/unit.native';


export default ({ zone, ...props }) =>
{
  const unit = zone.getUnit();
  const type = unit.getType();

  if (unit.getStatus().isProduction() || unit.getStatus().isDisband())
  {
    return <NativeUnit {...props} dashed type = {type} />;
  }

  if (unit.getStatus().isFortify())
  {
    return <NativeUnit {...props} fortified type = {type} />;
  }

  if (unit.hasTarget())
  {
    return <NativeUnit {...props} inverted type = {type} />;
  }

  return <NativeUnit {...props} type = {type} />;
};
