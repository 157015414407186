/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux, Modern } from 'fierry/react';

import View from 'fierry/client/view';

import Close from 'svg/shared/modern/close';


const OnClick = (event, view) =>
{
  view.pushArgs({ current: null, target: null });
}


export default (props) =>
{
  const [ view ] = useFlux(View);

  const onClick = useCallback(OnClick, [ view ]);

  return <Modern.Button icon = {<Close />} tooltip = 'Close' onClick = {onClick} />;
}

