/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Header from 'fierry/react/header';

import UnitCreateButton from 'galax/react/button/unit/create';
import UnitDisbandButton from 'galax/react/button/unit/disband';


const getEntityHeadline = ({ currentPlatform }) =>
{
  return currentPlatform.getUnit().getConstants().getName();
}


const UnitHeader = (props) =>
(
  <Header headline = {getEntityHeadline(props)} >

    <UnitDisbandButton {...props} />

  </Header>
);


const ConstructionHeader = (props) =>
(
  <Header headline = {getEntityHeadline(props)} >

    <UnitCreateButton {...props} />

  </Header>
)


const PlatformHeader = (props) =>
(
  <Header headline = 'Platform' >

    <UnitCreateButton {...props} />

  </Header>
);



export default (props) =>
{
  const { currentPlatform } = props;

  if (currentPlatform.getUnit() && currentPlatform.getUnit().getStatus().isProduction())
  {
    return <ConstructionHeader {...props} />;
  }

  if (currentPlatform.getUnit())
  {
    return <UnitHeader {...props} />;
  }

  return <PlatformHeader {...props} />;
}
