/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import makeStatus from 'galax/server/building/status/make';

import FriendlyAttributeSource from 'galax/server/adapter/attribute.source/friendly';
import HostileAttributeSource from 'galax/server/adapter/attribute.source/hostile';


export default class
{
  constructor (building, planet)
  {
    this.building = building;
    this.planet = planet;

    this.status = makeStatus(this.building.status, this);
  }


  install ()
  {
    this.status.install();
  }


  installActiveSource ()
  {
    // no-op.
  }


  installPassiveSource ()
  {
    // no-op.
  }


  installActiveTarget (zone)
  {
    // no-op
  }


  installPassiveTarget (zone)
  {
    // no-op
  }


  getFriendlyAttribute (value)
  {
    return new FriendlyAttributeSource(this.getUserID(), this.__getAttributeLabel__(), value);
  }


  getHostileAttribute (value)
  {
    return new HostileAttributeSource(this.getUserID(), this.__getAttributeLabel__(), value);

  }


  __getAttributeLabel__ ()
  {
    return 'building/' + this.getConstants().getType();
  }


  getZone ()
  {
    return this.planet.getZone(this.building.coords);
  }


  getSector ()
  {
    return this.getZone().getSector();
  }


  getLevel ()
  {
    return this.building.args.level;
  }


  getCoords ()
  {
    return this.building.coords;
  }


  getType ()
  {
    return this.building.variant;
  }


  getConstants ()
  {
    return this.planet.getConstants().getBuilding(this.getCass().variant);
  }


  getUserID ()
  {
    return this.getZone().getUserID();
  }


  getCass ()
  {
    return this.building;
  }


  getPlanet ()
  {
    return this.planet;
  }


  getCreateDate ()
  {
    return this.building.create_date;
  }


  getStatus ()
  {
    return this.status;
  }


  canFireProjectiles ()
  {
    return false;
  }


}
