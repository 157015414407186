/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';


export default (planet) =>
{
  let state = new Immutable.Map();

  for (const sector of planet.getSectors())
  {
    state = state.set(sector.getID(), sector.getType());
  }

  return state;
}
