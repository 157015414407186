/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Axial from 'galax/geo/coords/axial';


export const fromEmpty = () =>
{
  return new Axial(0, 0);
}


export const fromOffset = (x, y) =>
{
  const q = x - (y - (y & 1)) / 2;
  const r = y;

  return new Axial(q, r);
};


export const fromString = (value) =>
{
  const [ x, y ] = value.split('.');

  return value ? fromOffset(parseInt(x), parseInt(y)) : null;
};


export const fromPixel = (x, y, xSize, ySize) =>
{
  const q = Math.sqrt(3)/3 * x / xSize - 1/3 * y / ySize;
  const r = 2/3 * y / ySize;

  return round(new Axial(q, r));
};


export const add = (lhs, rhs) =>
{
  const q = lhs.getQ() + rhs.getQ();
  const r = lhs.getR() + rhs.getR();

  return new Axial(q, r);
};


export const substract = (lhs, rhs) =>
{
  const q = lhs.getQ() - rhs.getQ();
  const r = lhs.getR() - rhs.getR();

  return new Axial(q, r);
};


export const round = (coords) =>
{
  let roundQ = Math.round(coords.getQ())
  let roundR = Math.round(coords.getR())
  let roundS = Math.round(coords.getS())

  const diffQ = Math.abs(roundQ - coords.getQ());
  const diffR = Math.abs(roundR - coords.getR());
  const diffS = Math.abs(roundS - coords.getS());

  if (diffQ > diffR && diffQ > diffS)
  {
    roundQ = - roundS - roundR;
  }

  else if (diffR > diffS)
  {
    roundR = -roundQ - roundS;
  }
  else
  {
    roundS = - roundQ - roundR;
  }

  return new Axial(roundQ, roundR);
}



export const roundAlternative = (coords) =>
{
  let q = coords.getQ();
  let r = coords.getR();

  const roundQ = Math.round(q)
  const roundR = Math.round(r);

  q -= roundQ;
  r -= roundR;

  const diffQ = Math.round(q + 0.5 * r) * (q * q >= r * r);
  const diffR = Math.round(r + 0.5 * q) * (q * q <  r * r);

  return new Axial(roundQ + diffQ, roundR + diffR);
}


export const indexOf = (array, coords) =>
{
  return array.findIndex(element => element.isEqual(coords));
}


const rotateVectorLeft = (vector) =>
{
  let q = -vector.getS()
  let s = -vector.getR()
  let r = -vector.getQ()

  return new Axial(q, r)
}


export const rotateLeft = (coords, center, count = 1) =>
{
  const vector = substract(coords, center);

  for (let i = 0; i < count; ++i)
  {
    vector = rotateVectorLeft(vector);
  }

  return add(center, vector);
}


const rotateVectorRight = (vector) =>
{
  let q = -vector.getR()
  let s = -vector.getQ()
  let r = -vector.getS()

  return new Axial(q, r)
}


export const rotateRight = (coords, center, count = 1) =>
{
  let vector = substract(coords, center);

  for (let i = 0; i < count; ++i)
  {
    vector = rotateVectorRight(vector);
  }

  return add(center, vector);
}


export const getDistance = (lhs, rhs) =>
{
  const x = Math.abs(lhs.getQ() - rhs.getQ());
  const y = Math.abs(lhs.getS() - rhs.getS());
  const z = Math.abs(lhs.getR() - rhs.getR());

  return (x + y + z) / 2;
}


export const getNeighborsInRange = (coords, range = 1) =>
{
  let neighbors = [];

  for (let x = -range; x <= range; ++x)
  {
    for (let y = Math.max(-range, -x - range); y <= Math.min(range, -x + range); ++y)
    {
      if (x === 0 && y === 0)
      {
        continue;
      }

      neighbors.push(add(coords, new Axial(x, - x - y)));
    }
  }

  return neighbors;
}


export const getNeighborsInOrder = (coords) =>
{
  const [ n0, n1, n2, n3, n4, n5 ] = getNeighborsInRange(coords);

  return [ n5, n4, n2, n0, n1, n3 ];
}


export { Axial };
