/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import AvailableEnergyDistribution from 'galax/server/sector/distribution/available.energy.distribution';
import MissingEnergyDistribution from 'galax/server/sector/distribution/missing.energy.distribution';
import SpendingEnergyDistribution from 'galax/server/sector/distribution/spending.energy.distribution';
import SplashDistribution from 'galax/server/sector/distribution/splash.distribution';

import Zone from 'galax/server/zone';


export default class
{
  constructor (scope)
  {
    this.scope = scope;

    this.collection = [];

    for (const type of this.scope.getCass().getZoneCollection())
    {
      this.collection.push(new Zone(type, this.scope));
    }

    for (const zone of this.collection)
    {
      this.scope.getPlanet().getZoneCollection().insertZone(zone);
    }
  }


  setup ()
  {
    for (const zone of this.collection)
    {
      zone.setup();
    }
  }


  install ()
  {
    for (const zone of this.collection)
    {
      zone.install();
    }
  }


  eachZone (callback)
  {
    for (const zone of this.collection)
    {
      callback(zone);
    }
  }


  getSplashDistribution (source)
  {
    return new SplashDistribution(this.scope, source);
  }


  getAvailableEnergyDistribution (source)
  {
    return new AvailableEnergyDistribution(this.scope, source);
  }


  getMissingEnergyDistribution (source)
  {
    return new MissingEnergyDistribution(this.scope, source);
  }


  getSpendingEnergyDistribution (source, value)
  {
    return new SpendingEnergyDistribution(source, this, value);
  }


  getProjectedTimeToFlip (user, maxDamage)
  {
    let results = [];

    for (const zone of this.collection)
    {
      // TODO maybe this needs full date too because how to know what time means...
      results.push({ zone, time: zone.getProjectedTimeToFlip(user, maxDamage) });
    }

    const sortPredicate = (lhs, rhs) =>
    {
      return lhs.time - rhs.time;
    };

    return results.sort(sortPredicate);
  }


  getTotalCount ()
  {
    return this.collection.length;
  }


  getMajorityCount ()
  {
    return Math.ceil(0.5 * this.getTotalCount());
  }


  getCriticalCount ()
  {
    let count = 0;

    for (const zone of this.collection)
    {
      if (zone.isCritical())
      {
        ++count;
      }
    }

    return count;
  }


  getOverheatCount ()
  {
    let count = 0;

    for (const zone of this.collection)
    {
      if (zone.isOverheat())
      {
        ++count;
      }
    }

    return count;
  }


  getZone (coords)
  {
    const id = coords.toString();

    for (const zone of this.collection)
    {
      if (zone.getType().getID() === id)
      {
        return zone;
      }
    }

    return null;
  }


};
