/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'fierry/react/native';

import * as Element from 'fierry/react/element';


const Children = ({ icon, text, underline, children, ...props }) =>
{
  let elements = [];

  if (icon)
  {
    elements.push(<Element.Icon {...props} value = {icon} />);
  }

  if (text != null || children != null)
  {
    elements.push(<Element.Text {...props}  value = {text} children = {children} />)
  }

  elements = React.Children.toArray(elements);

  return underline ? <Element.Link {...props}  children = {elements} /> : elements;
}


export default ({ icon, text, underline, nowrap, children, ...props }) =>
(
  <Element.Group {...props} >
    <Children icon = {icon} text = {text} underline = {underline} nowrap = {nowrap} children = {children} />
  </Element.Group>
);
