/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';


export default (spaceships) =>
{
  let state = new Immutable.Map();

  for (const spaceship of spaceships)
  {
    state = state.set(spaceship.type, spaceship);
  }

  return state;

}
