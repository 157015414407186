/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Contexts from 'fierry/flux/contexts'


export default () =>
{
  const Context = Contexts.get({});

  const Component = React.memo( ({ children, ...props }) =>
  {
    return <Context.Provider value = {props} children = {children} />
  });

  Contexts.set(Component, Context);

  return Component;
}
