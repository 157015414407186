/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { withMemo } from 'fierry/react';

import Center from 'galax/react/map/zone/center';
import Border from 'galax/react/map/zone/border';
import Background from 'galax/react/map/zone/background';

import AttackIndicator from 'galax/react/map/zone/attack.indicator';
import TransferIndicator from 'galax/react/map/zone/transfer.indicator';


const Component = (props) =>
(
  <Center {...props} >

    <Background {...props} />
    <Border {...props} />

    <AttackIndicator {...props} />
    <TransferIndicator {...props} />

  </Center>
)

export default withMemo(props =>
{
  const { zone } = props;

  return <Component {...props} location = {zone.getLocation()} />;
});
