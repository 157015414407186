/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import attributes, * as elements from 'fierry/react/styled/attributes';


const DIV = attributes(elements.DIV);

const A = attributes(elements.A);
const SPAN = attributes(elements.SPAN);

const OL = attributes(elements.OL);
const UL = attributes(elements.UL);
const LI = attributes(elements.LI);

const SVG = attributes(elements.SVG);
const G = attributes(elements.G);

const PATH = attributes(elements.PATH);
const POLYGON = attributes(elements.POLYGON);
const POLYLINE = attributes(elements.POLYLINE);

const LINE = attributes(elements.LINE);
const RECT = attributes(elements.RECT);
const CIRCLE = attributes(elements.CIRCLE);
const ELLIPSE = attributes(elements.ELLIPSE);


export default new class
{
  div (props)
  {
    return this.$filter(props, DIV);
  }


  a (props)
  {
    return this.$filter(props, A);
  }


  span (props)
  {
    return this.$filter(props, SPAN);
  }


  ol (props)
  {
    return this.$filter(props, OL);
  }


  ul (props)
  {
    return this.$filter(props, UL);
  }


  li (props)
  {
    return this.$filter(props, LI);
  }


  svg (props)
  {
    return this.$filter(props, SVG)
  }


  g (props)
  {
    return this.$filter(props, G)
  }


  path (props)
  {
    return this.$filter(props, PATH);
  }


  polygon (props)
  {
    return this.$filter(props, POLYGON);
  }


  polyline (props)
  {
    return this.$filter(props, POLYLINE);
  }


  line (props)
  {
    return this.$filter(props, LINE);
  }


  rect (props)
  {
    return this.$filter(props, RECT);
  }


  circle (props)
  {
    return this.$filter(props, CIRCLE);
  }


  ellipse (props)
  {
    return this.$filter(props, ELLIPSE);
  }


  $filter (props, attributes)
  {
    let attrs = {};

    for (let name in props)
    {
      if (attributes[name])
      {
        attrs[name] = props[name];
      }
    }

    return attrs;
  }


}();
