/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor ()
  {
    this.collection = new Map();
    this.platforms = new Map();
  }


  insertZone (zone)
  {
    this.collection.set(zone.getLocation().getID(), zone);
  }


  insertPlatform (zone)
  {
    this.platforms.set(zone.getLocation().getID(), zone);
  }


  getZone (coords)
  {
    return this.collection.get(coords.toString());
  }


  getPlatform (coords)
  {
    return this.platforms.get(coords.toString());
  }


  getPlatforms ()
  {
    return this.platforms.values();
  }


}
