/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern } from 'fierry/react';

import Params from 'galax/react/card/sector.transfer.edit/params';
import Slider from 'galax/react/card/sector.transfer.edit/slider';

import ReverseButton from 'galax/react/button/sector.transfer/reverse';


const Buttons = (props) =>
(
  <ReverseButton {...props} />
);


export default (props) =>
(
  <Card {...props} headline = 'Transfer Settings' buttons = {Buttons} >

    <Params {...props} />
    <Slider {...props} />

  </Card>
);


/*
const ReverseTransfer = (props) =>
(
  <Card.Action {...props} icon = {<ReverseTransferIcon />} tooltip = 'Reverse Transfer' />
);


const OnReverseTransferClick = (event, state) =>
{
  state.setReverse(!state.getReverse());
};


const ReverseTransferButton = ({ state, ...props }) =>
{
  const onClick = useCallback(OnReverseTransferClick, [ state ]);

  return <ReverseTransferButton user onClick = {onClick} active = {state.getReverse()} />;
}


const Buttons = (props) =>
(
  <>
    <ReverseTransferButton {...props} />
  </>
)
*/
