/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';

import * as Array from 'fierry/util/array';

import Data from 'fierry/rpc/data';
import Element from 'fierry/rpc/element';


export default class extends Element
{
  constructor (keyCallback, translators)
  {
    super();

    this.__keyCallback__ = keyCallback;
    this.__translators__ = translators;

    this.__ready__ = false;
    this.__records__ = new Map();

    this.__table__ = new Immutable.Map();

    this.createNotification('replace-all', (value) => this.onReplaceAll(value));
    this.createNotification('replace-one', (value) => this.onReplaceOne(value));
    this.createNotification('remove-one', (value) => this.onRemoveOne(value));
  }


  detach ()
  {
    this.__ready__ = false;
    this.__records__ = new Map();

    this.__table__ = new Immutable.Map();

    super.detach();
  }


  getLocalState ()
  {
    return this.__table__;
  }


  onReplaceAll (values)
  {
    if (!this.__ready__)
    {
      this.__ready__ = true;
    }

    for (let key of this.__getRemoveKeys__(values))
    {
      this.__removeOne__(key);
    }

    for (let value of values)
    {
      this.__replaceOne__(this.__getKey__(value), value);
    }

    if (!this.isGlobal())
    {
      this.transitionToGlobal();
    }
    else
    {
      this.setState(this.getLocalState());
    }
  }


  onReplaceOne (value)
  {
    if (!this.isReady())
    {
      throw new Error('Cannot onReplaceOne on table with no data.');
    }

    this.__replaceOne__(this.__getKey__(value), value);

    this.setState(this.getLocalState());
  }


  onRemoveOne (value)
  {
    if (!this.isReady())
    {
      throw new Error('Cannot onRemoveOne on table with no data.');
    }

    this.__removeOne__(this.__getKey__(value));

    this.setState(this.getLocalState());
  }


  addLocalLie (value)
  {
    if (!this.isReady())
    {
      throw new Error('Cannot addLocalLie on table with no data.');
    }

    const key = this.__getKey__(value);
    const lie = this.__getRecord__(key).addLocalLie(value);

    this.__updateLocalState__(key);

    this.setState(this.getLocalState());

    return () => this.removeLocalLie(key, lie);
  }


  removeLocalLie (key, lie)
  {
    if (!this.isReady())
    {
      throw new Error('Cannot removeLocalLie on table with no data.');
    }

    this.__getRecord__(key).removeLocalLie(lie);

    this.__updateLocalState__(key);

    this.setState(this.getLocalState());
  }


  __getRemoveKeys__ (values)
  {
    let keys = [];

    for (let key of this.__records__.keys())
    {
      if (values.findIndex(value => this.__getKey__(value) === key) === -1)
      {
        keys.push(key);
      }
    }

    return keys;
  }


  __replaceOne__ (key, value)
  {
    for (const translator of this.__translators__)
    {
      translator(value);
    }

    this.__getRecord__(key).setState(value);

    this.__updateLocalState__(key);
  }


  __removeOne__ (key)
  {
    this.__getRecord__(key).setState(null);

    this.__updateLocalState__(key);
  }


  __updateLocalState__(key)
  {
    const state = this.__getRecord__(key).getState();

    if (state === null)
    {
      this.__table__ = this.__table__.delete(key);
    }
    else
    {
      this.__table__ = this.__table__.set(key, state);
    }
  }


  __getRecord__ (key)
  {
    if (!this.__records__.has(key))
    {
      this.__records__.set(key, new Data());
    }

    return this.__records__.get(key);
  }


  __getKey__ (value)
  {
    if (this.__keyCallback__)
    {
      return this.__keyCallback__(value);
    }

    return value.id;
  }


  isReady ()
  {
    return super.isReady() && this.__ready__;
  }


};


  // TODO move them to queries state wrapper somehow?
  /*all (selector)
  {
    if (!this.__ready__)
    {
      throw new Error('Cannot get data on table with no data.');
    }

    this.trackAccess();

    let array = [];

    for (let data of this.__table__.values())
    {
      if (data.getSilent() && selector(data.getSilent()))
      {
        array.push(data.getSilent());
      }
    }

    return array;
  }


  one (selector)
  {
    if (!this.__ready__)
    {
      throw new Error('Cannot get data on table with no data.');
    }

    for (let data of this.__table__.values())
    {
      if (data.getSilent() && selector(data.getSilent()))
      {
        return data.get();
      }
    }

    this.trackAccess();

    return null;
  }*/
