/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { CardButtons, Modern, styled, useCallback, useFlux } from 'fierry/react';

import * as InfluenceValue from 'galax/value/influence';

import TransferBetween from 'galax/react/element/transfer/between';
import TransferEfficiency from 'galax/react/element/efficiency';
import TransferValue from 'galax/react/element/transfer/value';

import DeleteIcon from 'svg/shared/modern/delete-stroke';
import TransferToIcon from 'svg/shared/modern/transfer-to.js';
import TransferFromIcon from 'svg/shared/modern/transfer-from.js';


const getTransferName = ({ source, target, reverse }) =>
{
  return reverse ? source.getCass().getName() : target.getCass().getName();
}


const getTransferValue = ({ transfer }) =>
{
  return InfluenceValue.toPercentage(transfer.getTransferValue());
}


const getTransferEfficiency = ({ transfer, source }) =>
{
  const planet = source.getPlanet();

  return Math.round(transfer.getTransferEfficiency(planet) * 100);
}



const TransferIcon = ({ reverse, ...props }) =>
{
  return reverse ? <TransferFromIcon {...props} /> : <TransferToIcon {...props} />;
}


const TransferActions = ({ onCancelClick }) =>
(
  <CardButtons>

    {/* TODO ideally button can easily specify if it is visible for currentUser, notCurrentUser, everybody ?*/}
    <Modern.Button primary tooltip = 'Cancel Transfer'
      icon = {<DeleteIcon />}
      onClick = {onCancelClick}
    />

  </CardButtons>
);


const TransferActionsOpt = (props) =>
{
  const { currentUser, transfer } = props;

  return (currentUser.getID() === transfer.getUserID()) ? <TransferActions {...props} /> : null;
}


const Component = (props) =>
(
  <Modern.Group>

    <TransferBetween {...props}
      value = {getTransferName}
    />

    <TransferValue {...props}
      value = {getTransferValue}
    />

    <TransferEfficiency {...props} tooltip = 'Transfer Efficiency'
      value = {getTransferEfficiency}
    />

    <TransferActionsOpt {...props} />

  </Modern.Group>
);


const OnClick = (event, galaxy, source, target) =>
{
  galaxy.cancelSectorTransfer(source, target);
};


export default (props) =>
{
  const { galaxy, transfer } = props;

  const source = galaxy.getLocation(transfer.getSource());
  const target = galaxy.getLocation(transfer.getTarget());

  const onClick = useCallback(OnClick, [galaxy, source, target]);

  return <Component {...props} source = {source.getZone().getSector()} target = {target.getZone().getSector()} onCancelClick = {onClick} />;
};
