/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, Modern } from 'fierry/react';

import BuildingEnum from 'galax/enum/building';

import ResourceElement from 'galax/react/element/resource';

import EnergyIcon from 'svg/shared/modern/energy';
import EfficiencyIcon from 'svg/shared/modern/efficiency';
import ResistanceIcon from 'svg/shared/modern/resistance.js';
import DefenceIcon from 'svg/shared/modern/defence';
import TransferIcon from 'svg/shared/modern/transfer-to';
import ProductionIcon from 'svg/shared/modern/production-stroke';

import PlusUnit from 'svg/shared/unit/plus';
import MinusUnit from 'svg/shared/unit/minus';
import PercentUnit from 'svg/shared/unit/percent';


const getBenefitIcon = ({ type, active }) =>
{
  switch (type)
  {
    case BuildingEnum.DistributionCenter: return active ? <ResistanceIcon /> : <EnergyIcon />;
    case BuildingEnum.RoboticFactory:     return active ? <DefenceIcon /> : <ProductionIcon />;
    case BuildingEnum.TransferStation:    return <EfficiencyIcon />;
    case BuildingEnum.Outpost:            return active ? <ProductionIcon /> : <ResistanceIcon />;
    case BuildingEnum.Garrison:           return active ? <AttritionIcon /> : <DefenceIcon />;
    case BuildingEnum.Spaceport:          return <ProductionIcon />;
  }

  return null;
}


const getDistributionCenterBenefit = ({ level, active }) =>
{
  return 100;
  /*if (active)
  {
    return level * 5;
  }

  return Math.round(Math.pow(Math.pow(2, 1/3), level) * 100) - 100;*/
}


const getRoboticFactoryBenefit = ({ level, active }) =>
{
  return 100;
  /*if (active)
  {
    return level * 5;
  }

  return Math.round(Math.pow(Math.pow(2, 1/3), level) * 100) - 100;*/
}


const getTransferStationBenefit = ({ level, active }) =>
{
  return level;
}


const getOutpostBenefit = ({ level, active }) =>
{
  return 25;
  /*if (active)
  {
    return Math.round(Math.pow(Math.pow(Math.sqrt(2), 1/3), level) * 100) - 100;
  }

  return level * 10;*/
}


const getGarrisonBenefit = ({ level, active }) =>
{
  if (active)
  {
    return Math.round(Math.pow(Math.pow(Math.sqrt(2), 1/3), level) * 100) - 100;
  }

  return level * 10;
}


const getSpaceportBenefit = ({ level, active }) =>
{
  return Math.round(Math.pow(Math.sqrt(1.5), level - 1) * 100) - 100;
}


const getBenefitValue = ({ type, ...props }) =>
{
  switch (type)
  {
    case BuildingEnum.DistributionCenter: return getDistributionCenterBenefit(props);
    case BuildingEnum.RoboticFactory:     return getRoboticFactoryBenefit(props);
    case BuildingEnum.TransferStation:    return getTransferStationBenefit(props);
    case BuildingEnum.Outpost:            return getOutpostBenefit(props);
    case BuildingEnum.Garrison:           return getGarrisonBenefit(props);
    case BuildingEnum.Spaceport:          return getSpaceportBenefit(props);
  }

  return null;
}


const getBenefitTooltip = ({ type, active }) =>
{
  switch (type)
  {
    case BuildingEnum.DistributionCenter: return active ? 'Zone Resistance' : 'Energy Generation';
    case BuildingEnum.RoboticFactory:     return active ? 'Unit Defence' : 'Unit Production Speed';
    case BuildingEnum.TransferStation:    return 'Transfer Efficiency';
    case BuildingEnum.Outpost:            return active ? 'Unit Production Speed' : 'Zone Resistance';
    case BuildingEnum.Garrison:           return active ? 'Unit Energy Efficiency' : 'Unit Defence';
    case BuildingEnum.Spaceport:          return 'Spaceship Production Speed';
  }

  return null;
}


const Component = (props) =>
(
  <Element.Group param width = '4.5' tooltip = {getBenefitTooltip(props)} >
    <Element.Icon value = {getBenefitIcon(props)} />
    <Element.Unit value = {<PlusUnit />}/>
    <Element.Text value = {getBenefitValue(props)} />
    <Element.Unit value = {<PercentUnit />} />
  </Element.Group>
);


// TODO do not display if building is being upgraded or on cooldown!
export default (props) =>
{
  const { constants } = props;

  return !constants.canFireProjectiles() ? <Component {...props} /> : null;
}
