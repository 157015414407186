/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (user, planet)
  {
    this.user = user;
    this.planet = planet;
  }


  getID ()
  {
    return this.user.id;
  }


  getTheme ()
  {
    return this.planet.getConstants().getTheme(this.user.theme);
  }


  getLocalCount ()
  {
    return this.user.local_count;
  }


  getTotalCount ()
  {
    return this.user.total_count;
  }


  getUpdateDate ()
  {
    return this.user.update_date;
  }


  getReference ()
  {
    return { id: this.getID(), strength: this.getTotalCount() };
  }


  getZoneYield ()
  {
    const count = this.getTotalCount();

    return Math.round(Math.pow(count, this.getConstants().getZoneYieldExponent()) / count * this.getConstants().getZoneYieldConstant());
  }


  getConstants ()
  {
    return this.planet.getConstants().getGeneral();
  }


};
