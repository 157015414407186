/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Event } from 'fierry/flux';

import Server from 'galax/server/root';
import Auth from 'fierry/client/auth';


const getLocationString = () =>
{
  let searchQuery = [];

  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has('fbclid'))
  {
    searchQuery.push('facebook');
  }

  if (searchParams.has('desktop'))
  {
    searchQuery.push('desktop');
  }

  if (searchParams.has('mobile'))
  {
    searchQuery.push('mobile');
  }

  if (searchQuery.length)
  {
    searchQuery = ' ' + searchQuery.join(', ')
  }

  return window.location.pathname + window.location.hash.substr(1) + searchQuery;
}


export default new Event ([ Auth, Server ], (auth, server) =>
{
  if(!auth.isLoggedOut() || !server.isReady())
  {
    return;
  }

  server.log(getLocationString());

  const eventListener = (event) =>
  {
    server.log(getLocationString());
  }

  window.addEventListener('hashchange', eventListener);

  return () =>
  {
    window.removeEventListener('hashchange', eventListener);
  }
});
