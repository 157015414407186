/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as EnergyValue from 'galax/value/energy';
import * as InfluenceValue from 'galax/value/influence';


const RESISTANCE_BRACKET_EFFICIENCY = 0.75;
const RESISTANCE_BRACKET_SIZE = EnergyValue.fromConstants(100);
const RESISTANCE_ATTENUATE = InfluenceValue.fromConstants(150);


export default class
{
  constructor (user, scope)
  {
    this.user = user;
    this.scope = scope;

    this.damage = this.scope.getType().getResistance(this.user);
  }


  updateResistance (duration)
  {
    const bracket = this.getBracket();

    this.damage += duration * this.getFlow();

    if (this.damage < 0)
    {
      this.damage = 0;
    }

    return bracket !== this.getBracket();
  }


  increaseResistance (value)
  {
    let effective = 0;
    let remainer = this.getBracketRemainder();

    while (value > remainer)
    {
      value -= remainer;

      effective += remainer * this.getEfficiency();
      this.damage += remainer * this.getIncreaseEfficiency();

      remainer = this.getBracketRemainder();
    }

    effective += value * this.getEfficiency();
    this.damage += value * this.getIncreaseEfficiency();

    return effective;
  }


  getDamage (date)
  {
    if (date == null)
    {
      return this.damage;
    }

    const value = this.damage + (date - this.scope.getType().getUpdateDate()) * this.getFlow();

    return value > 0 ? value : 0;
  }


  getEfficiency (date)
  {
    return this.getDefaultEfficiency() * Math.pow(RESISTANCE_BRACKET_EFFICIENCY, this.getBracket(date));
  }


  getTimeToBracket (date)
  {
    const flow = this.getFlow();

    return flow ? Math.ceil((this.getBracketNextValue(date) - this.getDamage(date)) / flow) : -1;
  }


  getFlow ()
  {
    return this.getIncreaseFlow() - this.getDecreaseFlow();
  }


  getIncreaseFlow ()
  {
    return this.getIncreaseEfficiency() * this.scope.getAttributes().getActiveInfluence(this.user);
  }


  getDecreaseFlow ()
  {
    return this.getDecreaseEfficiency() * RESISTANCE_ATTENUATE;
  }


  getDefaultEfficiency ()
  {
    return this.scope.getAttributes().getResistanceEfficiency(this.user);
  }


  getIncreaseEfficiency ()
  {
    return this.scope.getAttributes().getResistanceIncrease(this.user);
  }


  getDecreaseEfficiency ()
  {
    return this.scope.getAttributes().getResistanceDecrease(this.user);
  }


  getBracket (date)
  {
    return Math.floor(this.getDamage(date) / RESISTANCE_BRACKET_SIZE);
  }


  getBracketRemainder (date)
  {
    return RESISTANCE_BRACKET_SIZE - this.getDamage(date) % RESISTANCE_BRACKET_SIZE;
  }


  getBracketNextValue (date)
  {
    const value = this.getBracket(date) * RESISTANCE_BRACKET_SIZE;

    return (this.getFlow() > 0) ? value + RESISTANCE_BRACKET_SIZE : value;
  }


  getBracketProgress (date)
  {
    return Math.abs(this.getBracketNextValue(date) - this.getDamage(date)) / RESISTANCE_BRACKET_SIZE;
  }


};
