/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import DynamicElement from 'fierry/rpc/dynamic-element';
import SessionModel from 'galax/server/session';

import Constants from 'galax/rpc/constants';

import Users from 'galax/rpc/users';
import Planet from 'galax/rpc/planet';
import Spaceship from 'galax/rpc/spaceship';

import * as Key from 'galax/rpc/key';
import * as Translator from 'galax/rpc/translator';


export default class extends DynamicElement
{
  constructor ()
  {
    super(SessionModel);

    this.constants = this.createElement('constants', new Constants());
    this.users = this.createElement('users', new Users());

    this.planets = this.createCollection('planets', (name) => new Planet(this.constants));
    this.spaceships = this.createCollection('spaceships', (name) => new Spaceship(this));

    this.createRecord('currentTime', [ Translator.CurrentTime ]);
    this.createRecord('currentGalaxy');
  }


  isReady ()
  {
    return super.isReady() && this.constants.isReady() && this.users.isReady();
  }

  // TODO can define different rpc execute methods here instead on the server ?
  // these would be visible from hierarchy but not state...
};
