/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Element, Modern, styled, useFlux } from 'fierry/react';

import Now from 'galax/query/now';

import EnergyElement from 'galax/react/element/energy';
import UnitParams from 'galax/react/group/unit/params';
import StatusProgress from 'galax/react/group/progress/unit'

import UnitIcon from 'galax/react/icon/unit';

import HealthIcon from 'svg/shared/modern/health.js';
import PercentUnit from 'svg/shared/unit/percent.js';


const getIcon = ({ unit }) =>
{
  return <UnitIcon type = {unit.getType()} />;
}


const getName = ({ unit }) =>
{
  return unit.getConstants().getName();
}


const getEnergy = ({ unit, now }) =>
{
  return unit.getEnergy(now);
}


const Headline = (props) =>
(
  <Modern.Text headline icon = {getIcon(props)} text = {getName(props)} />
);


const Subline = (props) =>
(
  <EnergyElement {...props}
    icon = {<HealthIcon />}
    energy = {getEnergy(props)}
  />
)


const Component = (props) =>
(
  <Card {...props} headline = {Headline} >

    <UnitParams {...props} constants = {props.unit.getConstants()} />

    <StatusProgress {...props} />

  </Card>
);


export default (props) =>
{
  const [ now ] = useFlux(Now);

  const unit = props.currentLocation.getObject().getUnit();

  return unit ? <Component {...props} now = {now} unit = {unit} /> : null;
}
