/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */

import * as Hex from 'galax/geo/hex';


export default class
{
  constructor (zoom)
  {
    this.zoom = zoom;

    this.pointsExternal = Hex.getPoints(this.getExternalWidth(), this.getExternalHeight());
    this.pointsInternal = Hex.getPoints(this.getInternalWidth(), this.getInternalHeight());

    this.pointsExternalInternal = Hex.getIntersectionPoints(this.pointsExternal, this.pointsInternal);
    this.pointsInternalExternal = Hex.getIntersectionPoints(this.pointsInternal, this.pointsExternal);
  }


  getExternalSize (ratio = 1)
  {
    const VALUES = [ 21, 28, 37, 48, 64, 96 ];

    return VALUES[this.zoom.getValue()] / Math.sqrt(3) * ratio;
  }


  getExternalWidth (ratio = 1)
  {
    return Math.sqrt(3) * this.getExternalSize(ratio) * this.getStretchX();
  }


  getExternalHeight (ratio = 1)
  {
    return 2 * this.getExternalSize(ratio) * this.getStretchY();
  }


  getInternalSize (ratio = 1)
  {
    const VALUES = [ 18, 24, 32, 42, 56, 84 ];

    return VALUES[this.zoom.getValue()] / Math.sqrt(3) * ratio;
  }


  getInternalWidth (ratio = 1)
  {
    return Math.sqrt(3) * this.getInternalSize(ratio) * this.getStretchX();
  }


  getInternalHeight (ratio = 1)
  {
    return 2 * this.getInternalSize(ratio) * this.getStretchY();
  }


  getEntitySize ()
  {
    const VALUES = [ 0, 0, 18, 24, 32, 48 ];

    return VALUES[this.zoom.getValue()];
  }


  getUserBorderWidth ()
  {
    const VALUES = [ 1, 1.5, 1.5, 2, 3, 4 ];

    return VALUES[this.zoom.getValue()];
  }


  getSectorBorderWidth ()
  {
    const VALUES = [ 0, 1, 1, 1, 2, 2 ];

    return VALUES[this.zoom.getValue()];
  }


  getZoneBorderWidth ()
  {
    const VALUES = [ 0, 0, 0, 0.25, 0.35, 0.5 ];

    return VALUES[this.zoom.getValue()];
  }


  getDockBorderWidth ()
  {
    const VALUES = [ 0, 0.5, 0.5, 0.5, 1, 2 ];

    return VALUES[this.zoom.getValue()];
  }


  // TODO maybe should have overrides for the widths of 1px and 2px lines here too?


  getHorizontalDistance ()
  {
    return this.getExternalWidth();
  }


  getVerticalDistance ()
  {
    return 3/4 * this.getExternalHeight();
  }


  getStretchX ()
  {
    return 1;
  }


  getStretchY ()
  {
    return 1;
  }


  getZonePoints (location, neighbors)
  {
    return this.getPointsWithCallback(location, neighbors, (current, neighbor) =>
    {
      const currentZone = current.getZone();
      const neighborDock = neighbor.getDock();
      const neighborZone = neighbor.getZone();

      return (neighborDock && neighborDock.isActive()) || (currentZone && neighborZone && currentZone.getUserID() !== neighborZone.getUserID());
    });
  }


  getDockPoints (location, neighbors)
  {
    return this.getPointsWithCallback(location, neighbors, (location, neighbor) =>
    {
      return location.getDock() && neighbor.getZone();
    });
  }


  getPlatformPoints (location, neighbors)
  {
    return this.getPointsWithCallback(location, neighbors, (location, neighbor) =>
    {
      return location.getPlatform() && neighbor.getZone();
    });
  }


  getPointsWithCallback (location, neighbors, isInternalCallback)
  {
    let points = [];

    for (let i = 0; i < Hex.getPointCount(); ++i)
    {
      let prevInternal = isInternalCallback(location, neighbors[Hex.getPointIndex(i - 1)]);
      let nextInternal = isInternalCallback(location, neighbors[Hex.getPointIndex(i)]);

      if (prevInternal && nextInternal)
      {
        points.push(this.pointsInternal[i]);
      }

      else if (prevInternal && !nextInternal)
      {
        points.push(this.pointsInternalExternal[i]);
      }

      else if (!prevInternal && nextInternal)
      {
        points.push(this.pointsExternalInternal[i]);
      }

      else if (!prevInternal && !nextInternal)
      {
        points.push(this.pointsExternal[i]);
      }
    }

    return points;
  }


  getVerticalBorderSize ()
  {
    return this.getExternalSize();
  }


  getDiagonalBorderSize ()
  {
    const width = this.getExternalWidth();
    const height = this.getExternalHeight();

    return Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 4, 2));
  }


};
