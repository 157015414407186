/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { CardButtons, Modern, css, styled, useState, useCallback } from 'fierry/react';

import ExpandIcon from 'svg/shared/modern/expand';
import CollapseIcon from 'svg/shared/modern/collapse';


const collapsedConfig = ({ expand }) => !expand && css`
  opacity: 0;
  margin: 0;
  height: 0;
`;


const Details = styled(Modern.Group)`

  height: auto;
  overflow: hidden;

  padding-left: 1.75rem;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  transition: margin .3s , opacity .3s .3s;

  ${collapsedConfig}
`;


const Separator = styled(Modern.Separator)`
  margin-top: 0.5rem;
`;


const ExpandCollapseIcon = ({ expand, ...props }) =>
{
  return expand ? <CollapseIcon {...props} /> : <ExpandIcon {...props} />;
}


const SeparatorOpt = ({ expand }) =>
{
  return expand ? <Separator /> : null;
}


const Component = ({ header, details, onClick, ...props }) =>
(
  <Modern.Group vertical>

    <Modern.Group>

      {header}

      <CardButtons>
        <Modern.Button primary
          icon = {<ExpandCollapseIcon {...props} />}
          onClick = {onClick}
        />
      </CardButtons>

    </Modern.Group>

    <SeparatorOpt {...props} />

    <Details {...props} vertical>
      {details}
    </Details>

  </Modern.Group>
);


const OnClick = (event, expand, setExpand) =>
{
  setExpand(!expand);
}


export default (props) =>
{
  const [expand, setExpand] = useState(false);

  const onClick = useCallback(OnClick, [ expand, setExpand ]);

  return <Component {...props} expand = {expand} onClick = {onClick} />;
}
