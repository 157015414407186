/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, useFlux } from 'fierry/react';

import Grid from 'galax/client/map/grid';

import Theme from 'galax/react/map/util/theme';

import * as Points from 'galax/geo/points';


const CurrentPath = styled.path`
  fill: var(--svg-always-white);
  stroke: var(--svg-always-other);

  stroke-width: 2px;
  stroke-linecap: round;
`;


const FuturePath = styled.path`
  fill: var(--svg-always-white);
  stroke: var(--svg-always-other);

  opacity: 0.3;

  stroke-width: 2px;
  stroke-linecap: round;
`;


const DirectionPath = styled.path`
  fill: var(--svg-always-other);
  stroke: var(--svg-always-other);

  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
`;


const CurrentPositionCircle = styled.circle`
  fill: var(--svg-always-other);
`;


const getCurrentPath = ({ current }) =>
{
  return current ? current.toSVG() : '';
}


const getFuturePath = ({ future }) =>
{
  return future ? future.toSVG() : '';
}


const getCurrentPositionProps = ({ current, future }) =>
{
  const point = current ? current.points[2] : future.points[0];

  return { cx: point.x, cy: point.y, r: 4 };
}


const getDirectionTransform = ({ current, future }) =>
{
  const curve = current || future;

  const point = curve.points[2];
  const angle = Points.getLineAngleDegrees(curve.points[2], curve.points[1]);

  return `translate(${point.x}, ${point.y}) rotate(${angle})`
}


const getDirectionPath = (props) =>
{
  return 'M 0,0 L 6,-3 L 6,3 L 0,0 Z';
}


const Direction = ({ indeterminate, ...props }) =>
{
  return !indeterminate ? null : <DirectionPath transform = {getDirectionTransform(props)} d = {getDirectionPath(props)} />;
}


const CurrentPosition = ({ indeterminate, ...props }) =>
{
  return indeterminate ? null : <CurrentPositionCircle {...getCurrentPositionProps(props)} />;
}


const Component = (props) =>
(
  <Theme user = {props.user} >

    <CurrentPath {...props} d = {getCurrentPath(props)} />
    <FuturePath {...props} d = {getFuturePath(props)} />

    <CurrentPosition {...props} />
    <Direction {...props} />

  </Theme>
);


export default ({ sourceLocation, targetLocation, ...props }) =>
{
  const [ grid ] = useFlux(Grid);

  const flight = grid.getFlight(sourceLocation, targetLocation);

  const { source, target } = flight.getClosestPointsWithOffset(2, 10);
  const { current, future } = flight.getFlightCurves(source, target, props.progress);

  return <Component {...props} current = {current} future = {future} />
};
