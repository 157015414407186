/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ReactDOM from 'react-dom';

import Application from 'application';

import 'global/auth.js';
import 'global/favicon.js';
import 'global/cookies.js';
import 'global/analytics.js';

import 'index.scss';


ReactDOM.render(<Application />, document.getElementById('app'));

//ReactDOM.unstable_createRoot(document.getElementById('app')).render(<Application />);
