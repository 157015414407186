/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import compareDeep from 'deep-equal';


const hasOwnProperty = Object.prototype.hasOwnProperty;


export default new class Compare
{
  deep (lhs, rhs)
  {
    return compareDeep(lhs, rhs, { strict: true });
  }


  shallow (lhs, rhs, callback)
  {
    if (Object.is(lhs, rhs))
    {
      return true;
    }

    if (typeof lhs !== 'object' || lhs === null || typeof rhs !== 'object' || rhs === null)
    {
      return false;
    }

    const keysLhs = Object.keys(lhs);
    const keysRhs = Object.keys(rhs);

    if (keysLhs.length !== keysRhs.length)
    {
      return false;
    }

    for (let i = 0; i < keysLhs.length; i++)
    {
      const key = keysLhs[i];

      if (!hasOwnProperty.call(rhs, key))
      {
        return false;
      }

      const valueLhs = lhs[key];
      const valueRhs = rhs[key];

      if (!Object.is(valueLhs, valueRhs) && (callback ? !callback(valueLhs, valueRhs) : true))
      {
        return false;
      }
    }

    return true;
  }


}();
