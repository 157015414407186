/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const Enum =
{
  Empty:              'empty',

  DistributionCenter: 'distribution-center',
  Garrison:           'garrison',
  Outpost:            'outpost',
  RoboticFactory:     'robotic-factory',
  Spaceport:          'spaceport',
  TransferStation:    'transfer-station',
  Shield:             'shield',
  Gate:               'gate',

  LaserBattery:       'laser-battery',
  GaussCannon:        'gauss-cannon',
  GaussArtillery:     'gauss-artillery',
};


export const canActivate = (type) =>
{
  /*switch (type)
  {
    case Enum.DistributionCenter: return true;
    case Enum.Garrison:           return true;
    case Enum.Outpost:            return true;
    case Enum.RoboticFactory:     return true;
  }*/

  return false;
}


export default Enum;
