/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import '@material/mwc-icon';

import { Children, cloneElement, useFlux } from 'fierry/react/native';
import { styled, css } from 'fierry/react/styled';

import { isString } from 'fierry/util/lang';

import Context from 'fierry/react/element/context';


const getIconSize = ({ variant, unit }) =>
{
  switch (variant)
  {
    case 'headline5': return unit ? 1.5 : 2.5;
    case 'headline6': return unit ? 1.5 : 2.0;

    case 'subtitle1': return unit ? 1.0 : 1.5;
    case 'subtitle2': return unit ? 1.0 : 1.5;

    case 'body1':     return unit ? 1.0 : 1.5;
    case 'body2':     return unit ? 1.0 : 1.5;

    case 'overline':  return unit ? 1.0 : 2.0;
  }

  return unit ? 1.0 : 1.5;
}


const iconSize = (props) => css`
  --fierry-icon-size: ${getIconSize(props)}rem;
`;


const Container = styled.div`

  ${iconSize}

  --mdc-icon-size: var(--fierry-icon-size);

  --svg-always-black: var(--fierry-theme-foreground);
  --svg-always-other: var(--fierry-theme-foreground);
  --svg-always-white: var(--fierry-theme-background);

  /*height: var(--fierry-icon-size);*/

  flex-shrink: 0;
`;


const FontIcon = ({ value, ...props }) =>
(
  <Container {...props} >
    <mwc-icon> {value} </mwc-icon>
  </Container>
);


const SvgIcon = ({ value, ...props }) =>
{
  const size = getIconSize(props) * 16;

  value = Children.map(value, element => cloneElement(element, { size }));

  return <Container {...props} children = {value} />
};


export default (props) =>
{
  const [ context ] = useFlux(Context);

  const { value } = props;

  return isString(value) ? <FontIcon {...props} {...context} /> : <SvgIcon {...props} {...context} />;
};
