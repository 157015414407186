/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Dock from 'galax/react/card/dock.overview';


export default (props) =>
(
  <>
    <Dock {...props}/>
  </>
);
