/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux }  from 'fierry/react';

import Behavior from 'galax/client/behavior';
import Selection from 'galax/client/selection';

import Flight from 'galax/react/map/flight';


export default ({ currentLocation, ...props }) =>
{
  const [ behavior, selection ] = useFlux(Behavior, Selection);

  if (!behavior.canSelect() || behavior.isInvalid())
  {
    return null;
  }

  const user = currentLocation.getUser();
  const targetLocation = selection.getLocation();

  return <Flight user = {user} sourceLocation = {currentLocation} targetLocation = {targetLocation} progress = {1} indeterminate />;
}
