/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><g><path d="M12.31,6.82l.44,1.62c.67,0,3.32-1.7,3.7-.48.22.7,1.52,3.28.4,3.72l-4.42,1.9c-.91.35-1.39-1.25-.61-1.53l3.8-1.64L15.23,9c-1.53.6-9.59,3.64-8.81,3.37-1.91.67-1.9,2.32-.5,4C7.72,17.73,7.07,20,7,22H15.3c-.54-5.54,1-5.58,4.47-9.24a1.19,1.19,0,0,0,.3-.89c-.8-2-2.29-7-2.29-7" transform="translate(0 0)" className="black-shape"/><path d="M5.75,4.5,5,5A2.28,2.28,0,0,0,4,6.93c.31,1.26.8,4.13,2,4,.16-.06,1-.3,1.63-.55Z" transform="translate(0 0)" className="black-shape"/><path d="M11.11,2.55,12,5.46h0c1-.28,3.25-1.22,4.22-1.64-.64-1.5-1-2.08-2.6-1.71" transform="translate(0 0)" className="black-shape"/><path d="M9.68,3c-.79.25-1.9.67-2.62,1L9,9.85l2.43-.93Z" transform="translate(0 0)" className="black-shape"/></g></Svg>
  );
}
