/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import BuildingEnum from 'galax/enum/building';
import ParentEnum from 'galax/enum/parent';

import * as Coords from 'galax/geo/coords';
import * as User from 'galax/value/user';


export default class
{
  constructor (coords, planet)
  {
    this.coords = coords;
    this.planet = planet;
  }


  getSpaceport ()
  {
    return this.getDockSector() ? this.getDockSector().getBuilding().getZone() : null;
  }


  getDockSector ()
  {
    let sector = null;

    for (const neighbor of this.getLocation().getNeighbors().getSectors())
    {
      const building = neighbor.getBuilding();

      if (building && building.getType() === BuildingEnum.Spaceport && building.getLevel() > 0)
      {
        if (!sector || neighbor.getBuilding().getCreateDate() < sector.getBuilding().getCreateDate())
        {
          sector = neighbor;
        }
      }
    }

    return sector;
  }


  getLocation (resolver = this.planet)
  {
    return resolver.getLocation(this.coords);
  }


  getUser (resolver = this.planet)
  {
    return resolver.getUser(this.getUserID());
  }


  getInteractionsTo ()
  {
    return [];
  }


  getInteractionsFrom ()
  {
    return [];
  }


  getProjectilesTo ()
  {
    return [];
  }


  getProjectilesFrom ()
  {
    return [];
  }


  getUserID ()
  {
    const sector = this.getDockSector();

    return sector ? sector.getCass().getUser() : User.getEmpty();
  }


  isActive ()
  {
    return this.getDockSector() != null;
  }


  isLocal ()
  {
    return true;
  }


  getCoords ()
  {
    return this.coords;
  }


  getRelativeCoords ()
  {
    return Coords.substract(this.getCoords(), this.planet.getCoords());
  }


  getID ()
  {
    return this.coords.toString();
  }


  valueOf ()
  {
    return this.getID();
  }


};
