/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import UnitEnum from 'galax/enum/unit';

import BasicAttack from 'svg/galax/unit/basic-attack';
import BasicDefence from 'svg/galax/unit/basic-defence';
import RangeAttack from 'svg/galax/unit/range-attack';
import ConeAttack from 'svg/galax/unit/cone-attack';
import AreaAttack from 'svg/galax/unit/area-attack';


export default ({ unit, type, ...props }) =>
{
  if (unit && type === undefined)
  {
    type = unit.getType();
  }

  switch (type)
  {
    case UnitEnum.BasicAttack:  return <BasicAttack {...props} />;
    case UnitEnum.BasicDefence: return <BasicDefence {...props} />;
    case UnitEnum.RangeAttack:  return <RangeAttack {...props} />;
    case UnitEnum.ConeAttack:   return <ConeAttack {...props} />;
    case UnitEnum.AreaAttack:   return <AreaAttack {...props} />;
  }

  return null;
}
