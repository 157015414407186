/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Query from 'fierry/flux/entity/query'

import View from 'fierry/client/view';

import Server from 'galax/server';


export default new Query([ Server, View ], (server, view) =>
{
  return server ? server.getLocation(view.getArg('current')) : null;
});
