/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Entity from 'fierry/flux/entity'


export default class extends Entity
{
  constructor(dependences, targets, definition)
  {
    super(dependences);

    this.targets = targets;
    this.definition = definition;
  }



  install (collection)
  {
    for (const dependency of collection)
    {
      if (this.__isTarget__(dependency))
      {
        dependency.setVisibility(this);
      }
    }
  }


  __isTarget__ (dependency)
  {
    return this.targets.indexOf(dependency.getEntity()) !== -1;
  }


  getDefinition ()
  {
    return this.definition;
  }


};
