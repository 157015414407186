/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const ID = ({ id }) => id;

export const Zone = ({ zone }) => zone;

export const Type = ({ type }) => type;

export const Variant = ({ variant }) => variant;

export const TargetSourceID = ({ target, source }) => target.parent.id + source.parent.id + target.id + source.id;
