/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { useFlux } from 'fierry/react';

import Auth from 'fierry/client/auth';
import Observer from 'fierry/flux/observer';

import Server from 'galax/server/root';


const canDispatch = ({ auth, server }) =>
{
  return auth.isLoggedOut() && server.isSessionReady();
}


const executeDispatch = ({ server }) =>
{
  server.logoutUser();
}


// Because of logout redirect this will never hit.
export default (props) =>
{
  const [auth, server] = useFlux(Auth, Server);

  return <Observer auth = {auth} server = {server} canDispatch = {canDispatch} executeDispatch = {executeDispatch} />
}
