/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><polygon points="16 27.73 29 10.26 23.04 4.25 8.96 4.25 3 10.26 16 27.73" className="white-shape"/><g data-name="no-invert"><polygon points="16 28 29 10.53 23.04 4.52 8.96 4.52 3 10.53 16 28" className="black-line-20"/></g><line x1="15.75" y1="16.75" x2="19.25" y2="12.25" className="black-line-10"/></Svg>
  );
}
