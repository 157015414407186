/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor ()
  {
    this.__data__ = null;

    this.__localLiesList__ = [];
    this.__localLiesSequence__ = 0;
  }


  setState (data)
  {
    this.__data__ = data;
  }


  getState ()
  {
    if (this.__localLiesList__.length)
    {
      return this.__localLiesList__[this.__localLiesList__.length - 1].data;
    }

    return this.__data__;
  }


  addLocalLie (data)
  {
    const id = ++this.__localLiesSequence__;

    this.__localLiesList__.push({ id, data });

    return id;
  }


  removeLocalLie (lie)
  {
    const index = this.__localLiesList__.findIndex( ({ id, data }) =>
    {
      return id === lie;
    });

    if (index === -1)
    {
      // TODO may need to ignore if data has changed / deleted or record / table is detached!
      // TODO can propagate this information to unregister function so it doesn't invoke removeLocalLie if outdated?
      //throw new Error(`Local lie not found: ${lie}`);

      return;
    }

    this.__localLiesList__.splice(index, 1);
  }


};
