/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useLayoutEffect, useRef } from 'react';


export default (callback, dependences) =>
{
  if (callback === undefined)
  {
    callback = () => {}
  }

  if (dependences === undefined)
  {
    dependences = [];
  }

  // Binding dependences to the callback.
  const callbackWithDeps = (...args) => callback(...args, ...dependences);

  // Initial value needed to workaround ref = {callback} scenario.
  let ref = useRef(callbackWithDeps);

  // Update happens after render phase (otherwise can be faulty in concurrent mode).
  useLayoutEffect(() => { ref.current = callbackWithDeps });

  // Returned callback will never change.
  return useCallback((...args) => (0, ref.current)(...args), [])
}
