/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import BorderTranslate from 'galax/react/map/zone/border.translate';


export default (props) =>
(
  <>
    <BorderTranslate {...props} index = {0} />
    <BorderTranslate {...props} index = {1} />
    <BorderTranslate {...props} index = {2} />
    <BorderTranslate {...props} index = {3} />
    <BorderTranslate {...props} index = {4} />
    <BorderTranslate {...props} index = {5} />
  </>
);
