/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Status from 'galax/server/unit/status';


export default class extends Status
{
  install ()
  {
    // no-op.
  }


}
