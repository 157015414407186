/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class Notification
{
  constructor (callback)
  {
    this.__callback__ = callback;

    this.__attached__ = false;
  }


  dispose ()
  {
    // no-op.
  }


  attach ()
  {
    this.__attached__ = true;
  }


  detach ()
  {
    this.__attached__ = false;
  }


  notify (data)
  {
    if (this.__attached__ === false)
    {
      throw Error("Can't notify on detached Notification.'");
    }

    this.__callback__(data);
  }


};
