/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern } from 'fierry/react';

import Transfer from 'galax/react/card/sector.transfer.list/transfer';


const getSectorTransfers = ({ currentLocation }) =>
{
  return currentLocation.getZone().getSector().getTransfers();
}


export default (props) =>
{
  let children = [];

  for (const transfer of getSectorTransfers(props).getTransfersTo())
  {
    children.push(<Transfer {...props} key = {transfer.getKey()} transfer = {transfer} />);
  }

  for (const transfer of getSectorTransfers(props).getTransfersFrom())
  {
    children.push(<Transfer {...props} key = {transfer.getKey()} transfer = {transfer} reverse />);
  }

  return <Modern.Group vertical children = {children} />
}
