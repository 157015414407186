  /*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Building from 'galax/react/icon/building';

import Dashed from 'galax/react/map/util/dashed';
import Invert from 'galax/react/map/util/invert';


const getDimension = () =>
{
  return 2 * Math.PI * 13;
}


const Component = ({ type, grid }) =>
{
  const size = grid.getGeneralHex().getEntitySize();

  return <Building center size = {size} type = {type} />;
}


export default ({ dashed, inverted, ...props }) =>
{
  if (dashed)
  {
    return <Dashed dimension = {getDimension()}> <Component {...props} /> </Dashed>;
  }

  if (inverted)
  {
    return <Invert> <Component {...props} /> </Invert>
  }

  return <Component {...props} />;
}
