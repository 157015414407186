/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><path d="M11.06,3.5,5.5,14.12l13-4.24L11.06,20.5" transform="translate(0 0)" className="black-line-30"/></Svg>
  );
}
