/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Connection from 'fierry/rpc/event.connection';


export default class
{
  constructor ()
  {
    this.count = 0;
    this.connections = {};
  }


  dispose ()
  {
    this.connections = {};
  }


  connect (callback)
  {
    let id = this.count++;
    let connection = new Connection(id, this, callback);

    this.connections[id] = connection;

    return connection;
  }


  disconnect (connection)
  {
    delete this.connections[connection.id];
  }


  raise ()
  {
    for (let id in this.connections)
    {
      this.connections[id].raise(arguments);
    }
  }
};
