/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


class Connection
{
  constructor (id, event, callback)
  {
    this.id = id;
    this.event = event;
    this.callback = callback;
  }


  // TODO make sure can dispose connection even when event is already disposed.
  //      basically we could just drop this.event on event.dispose() and have tangling connections?
  //      and connection.dispose() would not touch this.event - voila!
  dispose ()
  {
    this.event.disconnect(this);

    this.id = null;
    this.event = null;
    this.callback = null;
  }


  raise (args)
  {
    this.callback.apply(null, args);
  }
};


export default Connection;
