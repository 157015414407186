/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Entity from 'fierry/flux/entity'


export default class extends Entity
{
  constructor (state, actions, reducers)
  {
    super([]);

    this.state = state;

    this.reducers = {};
    this.dispatchContext = {};

    this.__initializeActions__(actions);
    this.__initializeReducers__(reducers);
    this.__initializeDependences__(reducers);
  }


  __initializeActions__ (actions)
  {
    for (const name in actions)
    {
      actions[name].setStore(this);
    }

    Object.assign(this, actions);
  }


  __initializeReducers__ (reducers)
  {
    for (const [action, callback] of reducers)
    {
      this.reducers[action.getType()] = callback;
    }
  }


  __initializeDependences__ (reducers)
  {
    for (const [action] of reducers)
    {
      const store = action.getStore();

      if (!store)
      {
        throw new Error('Cannot use action that does not belong to a store.');
      }

      if (store === this)
      {
        continue;
      }

      this.__insertDependency__(store);

      for (const dependency of store.getDependences())
      {
        this.__insertDependency__(dependency);
      }
    }
  }


  __insertDependency__ (dependency)
  {
    if (!this.dependences.includes(dependency))
    {
      this.dependences.push(dependency);
    }
  }


  getState ()
  {
    return this.state;
  }


  getDependences ()
  {
    return this.dependences;
  }


  getDispatchDependences ()
  {
    let collection = [];

    for (const dependency of this.dependences)
    {
      collection.push(dependency.getDispatchContext());
    }

    return collection;
  }


  getDispatchContext ()
  {
    return this.dispatchContext;
  }


  isExportable ()
  {
    return true;
  }


  onChange = (state, action) =>
  {
    const { type, payload, dispatch } = action;

    if (!this.reducers.hasOwnProperty(type))
    {
      return state;
    }

    return this.reducers[type](state, payload, dispatch);
  }


};
