/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><g><path d="M6,19.25C6,13.25,16,3,16,3S26,13.25,26,19.25C26,24.93,21.92,29,16,29S6,24.93,6,19.25" className="white-shape"/><g data-name="no-invert"><path d="M6,19.25C6,13.25,16,3,16,3S26,13.25,26,19.25C26,24.93,21.92,29,16,29S6,24.93,6,19.25" className="black-line-20"/></g></g><path d="M11.75,19.5c0,3.47,1.1,4,3.5,4" className="black-line-10"/></Svg>
  );
}
