/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Batches from 'fierry/rpc/batches';
import Commands from 'fierry/rpc/commands';
import Elements from 'fierry/rpc/elements';
import Protocol from 'fierry/rpc/protocol';
import Socket from 'fierry/rpc/socket';

import State from 'fierry/rpc/state';
import StateTracker from 'fierry/rpc/state.tracker';


export default class
{
  constructor ({ location, hierarchy })
  {
    this.location = location;
    this.hierarchy = hierarchy;

    this.state = new State(this);
    this.stateTracker = new StateTracker(this);

    this.socket = new Socket(this);
    this.elements = new Elements(this);

    this.batches = new Batches(this);
    this.commands = new Commands(this);
    this.protocol = new Protocol(this);

    this.socket.onOpen.connect(this.onOpen);
    this.socket.onClose.connect(this.onClose);

    this.socketOpen = false;
  }


  dispose ()
  {
    this.socket.dispose();
  }


  setDispatch (dispatch)
  {
    this.socket.setDispatch(dispatch);
  }


  getLocation ()
  {
    return this.location;
  }


  getHierarchy ()
  {
    return this.hierarchy;
  }


  getState ()
  {
    return this.state;
  }


  getStateTracker ()
  {
    return this.stateTracker;
  }


  getScheduler ()
  {
    return this.commands;
  }


  onOpen = (event) =>
  {
    this.socketOpen = true;

    console.log("connection opened", new Date());
  }


  onClose = (event) =>
  {
    console.log("connection closed", new Date(), event.code);

    if (this.socketOpen)
    {
      window.location.reload();
    }
  }


  onMessage = (state, message) =>
  {
    // By default react reducer will eat the exception and dispatch again with the same message.
    // Rpc needs to log the error but not allow it to propagate upwards.
    try
    {
      this.socket.executeMessage(message);
    }
    catch (error)
    {
      console.error(error);
    }

    return this.hierarchy.getState();
  }


};
