/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import View from 'fierry/client/view';
import Server from 'galax/server';

import Icon from 'svg/shared/modern/construction-fill';


const OnClick = (event, currentZone, view, server) =>
{
  if (currentZone.getBuilding())
  {
    const building = currentZone.getBuilding();

    server.cancelEntityAction(building.getZone());
  }
  else
  {
    view.pushForeground('/building.create');
  }
};


export default ({ currentZone, currentUser, ...props }) =>
{
  const [ view, server ] = useFlux(View, Server);

  const onClick = useCallback(OnClick, [ currentZone, view, server ]);

  const building = currentZone.getBuilding();

  const selected = building && building.getStatus().isConstruction();
  const enabled = !currentZone.getUnit();

  if (currentZone.getUser().getID() !== currentUser.getID() || !currentZone.getCass().isCenter())
  {
    return null;
  }

  let tooltip = selected ? 'Cancel Construction' : 'Create Building';

  return <Modern.Button tooltip = {tooltip} icon = {<Icon />} contained = {selected}  disabled = {!enabled} onClick = {onClick} />;
}
