/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { Modern, useFlux } from 'fierry/react';

import Now from 'galax/query/now';

import DefenceElement from 'galax/react/element/defence';
import EnergyElement from 'galax/react/element/energy';

import String from 'fierry/util/string';

import SpaceshipIcon from 'galax/react/icon/spaceship';


const getType = ({ targetSpaceship }) =>
{
  return targetSpaceship.getType();
}


const getName = ({ targetSpaceship }) =>
{
  return String.capitalize(targetSpaceship.getName());
}


const getDefenceValue = ({ targetSpaceship, now }) =>
{
  return 0;
}


const getEnergyValue = ({ targetSpaceship, now }) =>
{
  return targetSpaceship.getEnergy(now);
}


const getInfluenceValue = ({ targetSpaceship }) =>
{
  return targetSpaceship.getInfluence();
}


const Component = (props) =>
(
  <Modern.Group>

    <Modern.Text param width = '8.5'
      icon = {<SpaceshipIcon type = {getType(props)} />}
      text = {getName(props)}
    />

    <DefenceElement {...props}
      value = {getDefenceValue}
    />

    <EnergyElement {...props}
      energy = {getEnergyValue(props)}
      influence = {getInfluenceValue(props)}
    />

  </Modern.Group>
);


export default (props) =>
{
  const [ now ] = useFlux(Now);

  return props.targetSpaceship ? <Component {...props} now = {now} /> : null;
}
