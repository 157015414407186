/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import RouteEnum from 'galax/enum/route';

import Client from 'fierry/flux/entity/client';
import Store from 'fierry/client/url/store';
import * as State from 'fierry/client/url/state';

import AuthClient from 'fierry/client/auth';
import Compare from 'fierry/util/compare';

import makeValidator from 'galax/validator/make';

import Level from 'galax/enum/route.level';


export default new Client ([ Store, AuthClient ], class
{
  constructor ([ state, dispatch ], auth)
  {
    this.state = state;
    this.dispatch = dispatch;

    this.auth = auth;
  }


  reconcile ({ history })
  {
    const { id } = history[history.length - 1] || { id: 1 };

    this.dispatch(Store.Reconcile, { history, id });
  }


  pushForeground (route, args = {}, context = {})
  {
    this.pushState({ route, args, context, level: Level.Foreground });
  }


  pushBackground (route, args = {}, context = {})
  {
    this.pushState({ route, args, context, level: Level.Background });
  }


  pushArgs (args, context = {})
  {
    this.pushState({ args, context, level: Level.Args });
  }


  pushState (state)
  {
    this.dispatch(Store.PushState, state);
  }


  popForeground ()
  {
    this.dispatch(Store.PopState, { level: Level.Foreground });
  }


  getRoute ()
  {
    return this.getPermanentState().route;
  }


  getArgs ()
  {
    return this.getPermanentState().args;
  }


  getArg (key)
  {
    return this.getArgs()[key];
  }


  getContextArg (key)
  {
    return this.getPermanentState().context[key];
  }


  // TODO this is now internal.
  getPermanentState ()
  {
    const { permanent } = this.state;

    return permanent[permanent.length - 1];
  }


  getDefaultState ()
  {
    return State.getDefaultState();
  }


  getPopCountBefore (level)
  {
    return State.getPopCountBefore(this.state, level);
  }


  getSessionURL ()
  {
    return State.getSessionURL(this.state);
  }


  getSessionState ()
  {
    return State.getSessionState(this.state);
  }


  // TODO should this simply go to galaxy?
  isNavigationValid ({ route, args }, galaxy)
  {
    if (galaxy == null || !galaxy.isReady())
    {
      return true;
    }

    const currentLocation = galaxy.getLocation(args.current);
    const targetLocation = galaxy.getLocation(args.target);

    return makeValidator(route.getType(), route.getArgs(), galaxy, currentLocation, targetLocation).isRouteValid();
  }


  isNavigationBack (history)
  {
    const { id } = this.getPermanentState();

    return history[history.length - 1].id <= id;
  }


  isNavigationForward (history)
  {
    const { id } = this.getPermanentState();

    return history[history.length - 1].id > id;
  }


  isCardToPaneTransition ()
  {
    return false;
  }


  isPaneToCardTransition ()
  {
    return false;
  }


  isPaneToPaneTransition ()
  {
    return false;
  }


  startAnimation ()
  {
    // no-op.
  }


  endAnimation ()
  {
    // no-op.
  }


});
