/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import GalaxyBasic from 'galax/fake/galaxy.basic';

import SpaceshipEnum from 'galax/enum/spaceship';
import BuildingEnum from 'galax/enum/building';
import UnitEnum from 'galax/enum/unit';

import InteractionEnum from 'galax/enum/interaction';
import ProjectileEnum from 'galax/enum/projectile';
import ShapeEnum from 'galax/enum/shape';

import * as Influence from 'galax/value/influence';


export default () =>
{
  const galaxy = GalaxyBasic();

  setupPlanetOne(galaxy);
  setupPlanetTwo(galaxy);
  setupPlanetThree(galaxy);
  setupPlanetFour(galaxy);
  setupPlanetFive(galaxy);

  galaxy.finalize();

  return galaxy;
}


const setupPlanetOne = (galaxy) =>
{
  const planet = galaxy.getPlanet(0);

  // 1
  planet.getSector(0).assignUser(galaxy.getUser(1));
  planet.getSector(1).assignUser(galaxy.getUser(1));
  planet.getSector(4).assignUser(galaxy.getUser(2));
  planet.getSector(7).assignUser(galaxy.getUser(2));

  // Energy
  planet.getZone(1, 0).assignEnergy([ [ galaxy.getUser(1), 83 ] ]);
  planet.getZone(2, 0).assignEnergy([ [ galaxy.getUser(1), 86 ] ]);
  planet.getZone(1, 1).assignEnergy([ [ galaxy.getUser(1), 74 ] ]);
  planet.getZone(2, 1).assignEnergy([ [ galaxy.getUser(1), 82 ] ]);
  planet.getZone(1, 2).assignEnergy([ [ galaxy.getUser(1), 80 ] ]);
  planet.getZone(2, 2).assignEnergy([ [ galaxy.getUser(1), 78 ] ]);
  planet.getZone(1, 3).assignEnergy([ [ galaxy.getUser(1), 74 ] ]);
  planet.getZone(2, 3).assignEnergy([ [ galaxy.getUser(1), 77 ] ]);
  planet.getZone(3, 3).assignEnergy([ [ galaxy.getUser(1), 74 ] ]);

  planet.getZone(4, 2).assignEnergy([ [ galaxy.getUser(1), 88 ] ]);
  planet.getZone(3, 2).assignEnergy([ [ galaxy.getUser(1), 90 ] ]);
  planet.getZone(5, 1).assignEnergy([ [ galaxy.getUser(1), 92 ] ]);
  planet.getZone(4, 1).assignEnergy([ [ galaxy.getUser(1), 94 ] ]);

  planet.getZone(0, 4).assignEnergy([ [ galaxy.getUser(0), 79 ], [ galaxy.getUser(1), 21 ] ]);
  planet.getZone(1, 4).assignEnergy([ [ galaxy.getUser(0), 79 ], [ galaxy.getUser(1), 21 ] ]);
  planet.getZone(2, 4).assignEnergy([ [ galaxy.getUser(0), 79 ], [ galaxy.getUser(1), 21 ] ]);
  planet.getZone(3, 4).assignEnergy([ [ galaxy.getUser(0), 79 ], [ galaxy.getUser(1), 21 ] ]);

  planet.getZone(1, 5).assignEnergy([ [ galaxy.getUser(0), 85 ], [ galaxy.getUser(1), 15 ] ]);
  planet.getZone(0, 6).assignEnergy([ [ galaxy.getUser(0), 85 ], [ galaxy.getUser(1), 15 ] ]);
  planet.getZone(1, 6).assignEnergy([ [ galaxy.getUser(0), 85 ], [ galaxy.getUser(1), 15 ] ]);

  planet.getZone(5, 7).assignEnergy([ [ galaxy.getUser(2), 74 ] ]);
  planet.getZone(6, 7).assignEnergy([ [ galaxy.getUser(2), 76 ] ]);
  planet.getZone(5, 6).assignEnergy([ [ galaxy.getUser(2), 75 ] ]);
  planet.getZone(6, 6).assignEnergy([ [ galaxy.getUser(2), 82 ] ]);
  planet.getZone(6, 5).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);
  planet.getZone(7, 5).assignEnergy([ [ galaxy.getUser(2), 88 ] ]);
  planet.getZone(6, 4).assignEnergy([ [ galaxy.getUser(2), 92 ] ]);

  planet.getZone(3, 9).assignEnergy([ [ galaxy.getUser(0), 94 ], [ galaxy.getUser(2), 6 ] ]);
  planet.getZone(4, 9).assignEnergy([ [ galaxy.getUser(0), 94 ], [ galaxy.getUser(2), 6 ] ]);
  planet.getZone(3,10).assignEnergy([ [ galaxy.getUser(0), 94 ], [ galaxy.getUser(2), 6 ] ]);
  planet.getZone(4,10).assignEnergy([ [ galaxy.getUser(0), 94 ], [ galaxy.getUser(2), 6 ] ]);

  // Buildings
  planet.createBuilding([ 2, 2 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 3, 0 ], BuildingEnum.LaserBattery).setStatus('create');
  planet.createBuilding([ 6, 2 ], BuildingEnum.DistributionCenter).setStatus('create');

  // Units
  planet.createUnit([ 3, 3 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 1, 3 ], UnitEnum.BasicAttack).setStatus('attack')
  planet.createUnit([ 1, 1 ], UnitEnum.RangeAttack).setStatus('create');
  planet.createUnit([ 4, 2 ], UnitEnum.RangeAttack).setStatus('attack');

  planet.createUnit([ 5, 7 ], UnitEnum.ConeAttack).setStatus('attack');
  planet.createUnit([ 5, 6 ], UnitEnum.RangeAttack).setStatus('create');
  planet.createUnit([ 6, 7 ], UnitEnum.RangeAttack).setStatus('create');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(1, 3), planet.getZone(0, 4), { type: ShapeEnum.DoubleBasic, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(3, 3), planet.getZone(2, 4), { type: ShapeEnum.DoubleBasic, variant: 0 }, { value: Influence.fromPercentage(9)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(4, 2), planet.getZone(1, 4), { type: ShapeEnum.TripleArea, variant: 1 }, { value: Influence.fromPercentage(9)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(5, 7), planet.getZone(4, 9), { type: ShapeEnum.QuadrupleArea, variant: 1 }, { value: Influence.fromPercentage(9)});
}


const setupPlanetTwo = (galaxy) =>
{
  const planet = galaxy.getPlanet(1);

  // 1
  planet.getSector(2).assignUser(galaxy.getUser(3));
  planet.getSector(8).assignUser(galaxy.getUser(3));

  // Energy
  planet.getZone( 7, 1).assignEnergy([ [ galaxy.getUser(3), 91 ] ]);
  planet.getZone( 8, 3).assignEnergy([ [ galaxy.getUser(3), 79 ] ]);
  planet.getZone( 9, 2).assignEnergy([ [ galaxy.getUser(3), 80 ] ]);
  planet.getZone( 8, 2).assignEnergy([ [ galaxy.getUser(3), 85 ] ]);
  planet.getZone( 9, 3).assignEnergy([ [ galaxy.getUser(3), 82 ] ]);
  planet.getZone( 9, 4).assignEnergy([ [ galaxy.getUser(3), 89 ] ]);
  planet.getZone(10, 2).assignEnergy([ [ galaxy.getUser(3), 90 ] ]);
  planet.getZone( 9, 1).assignEnergy([ [ galaxy.getUser(3), 94 ] ]);
  planet.getZone( 8, 1).assignEnergy([ [ galaxy.getUser(3), 93 ] ]);

  planet.getZone(10, 4).assignEnergy([ [ galaxy.getUser(3), 85 ] ]);
  planet.getZone(10, 5).assignEnergy([ [ galaxy.getUser(3), 95 ] ]);
  planet.getZone( 9, 5).assignEnergy([ [ galaxy.getUser(3), 96 ] ]);
  planet.getZone(11, 4).assignEnergy([ [ galaxy.getUser(3), 93 ] ]);
  planet.getZone(10, 3).assignEnergy([ [ galaxy.getUser(3), 94 ] ]);

  planet.getZone( 8, 4).assignEnergy([ [ galaxy.getUser(0), 85 ], [ galaxy.getUser(3), 15 ] ]);
  planet.getZone( 7, 3).assignEnergy([ [ galaxy.getUser(0), 85 ], [ galaxy.getUser(3), 15 ] ]);
  planet.getZone( 6, 4).assignEnergy([ [ galaxy.getUser(0), 79 ], [ galaxy.getUser(3), 21 ] ]);
  planet.getZone( 6, 5).assignEnergy([ [ galaxy.getUser(0), 78 ], [ galaxy.getUser(3), 22 ] ]);
  planet.getZone( 7, 4).assignEnergy([ [ galaxy.getUser(0), 79 ], [ galaxy.getUser(3), 21 ] ]);

  // Buildings
  planet.createBuilding([ 9, 3 ], BuildingEnum.DistributionCenter);

  // Units
  planet.createUnit([ 8, 3 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 9, 2 ], UnitEnum.RangeAttack).setStatus('attack');
  planet.createUnit([10, 4 ], UnitEnum.RangeAttack).setStatus('attack');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 8, 3), planet.getZone( 7, 3), { type: ShapeEnum.DoubleBasic, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 9, 2), planet.getZone( 7, 4), { type: ShapeEnum.TripleArea, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone(10, 4), planet.getZone( 7, 4), { type: ShapeEnum.TripleArea, variant: 1 }, { value: Influence.fromPercentage(6)});
}


const setupPlanetThree = (galaxy) =>
{
  const planet = galaxy.getPlanet(2);
}


const setupPlanetFour = (galaxy) =>
{
  const planet = galaxy.getPlanet(3);

  // 1
  planet.getSector(0).assignUser(galaxy.getUser(5));
  planet.getSector(1).assignUser(galaxy.getUser(5));

  // Energy
  planet.getZone( 6, 0).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 5, 0).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 4, 1).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 4, 0).assignEnergy([ [ galaxy.getUser(5), 89 ] ]);
  planet.getZone( 5, 1).assignEnergy([ [ galaxy.getUser(5), 89 ] ]);
  planet.getZone( 5, 2).assignEnergy([ [ galaxy.getUser(5), 89 ] ]);
  planet.getZone( 6, 1).assignEnergy([ [ galaxy.getUser(5), 89 ] ]);
  planet.getZone( 6, 2).assignEnergy([ [ galaxy.getUser(5), 94 ] ]);
  planet.getZone( 4, 2).assignEnergy([ [ galaxy.getUser(5), 94 ] ]);

  planet.getZone( 7, 2).assignEnergy([ [ galaxy.getUser(0), 51 ], [ galaxy.getUser(5), 49 ] ]);
  planet.getZone( 7, 3).assignEnergy([ [ galaxy.getUser(0), 51 ], [ galaxy.getUser(5), 49 ] ]);
  planet.getZone( 8, 2).assignEnergy([ [ galaxy.getUser(0), 51 ], [ galaxy.getUser(5), 49 ] ]);

  // Buildings
  planet.createBuilding([ 5, 2 ], BuildingEnum.DistributionCenter);

  // Units
  planet.createUnit([ 5, 0 ], UnitEnum.RangeAttack).setStatus('attack');
  planet.createUnit([ 6, 0 ], UnitEnum.RangeAttack).setStatus('attack');
  planet.createUnit([ 4, 1 ], UnitEnum.RangeAttack).setStatus('attack');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 5, 0), planet.getZone( 7, 2), { type: ShapeEnum.TripleArea, variant: 5 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 6, 0), planet.getZone( 7, 2), { type: ShapeEnum.TripleArea, variant: 5 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 4, 1), planet.getZone( 7, 2), { type: ShapeEnum.TripleArea, variant: 5 }, { value: Influence.fromPercentage(6)});
}


const setupPlanetFive = (galaxy) =>
{
  const planet = galaxy.getPlanet(4);
}
