/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, Modern, withRef } from 'fierry/react';


// TODO better; add expand support ?
const NormalDiv = styled(Modern.Stack.Parent)`
  height: 100%;

  overflow: overlay;

  background: var(--fierry-theme-background);
`;


export default withRef( ({ ...props }, ref) =>
{
  return <NormalDiv ref = {ref} {...props} />;
});
