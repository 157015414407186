/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import GalaxyBasic from 'galax/fake/galaxy.basic';

import SpaceshipEnum from 'galax/enum/spaceship';
import BuildingEnum from 'galax/enum/building';
import UnitEnum from 'galax/enum/unit';

import InteractionEnum from 'galax/enum/interaction';
import ProjectileEnum from 'galax/enum/projectile';
import ShapeEnum from 'galax/enum/shape';

import * as Influence from 'galax/value/influence';


export default () =>
{
  const galaxy = GalaxyBasic();

  setupPlanetOne(galaxy);
  setupPlanetTwo(galaxy);
  setupPlanetThree(galaxy);
  setupPlanetFour(galaxy);
  setupPlanetFive(galaxy);

  setupInterplanetaryInteractions(galaxy);

  galaxy.finalize();

  return galaxy;
}


const setupPlanetOne = (galaxy) =>
{
  const planet = galaxy.getPlanet(0);

  // 1
  planet.getSector(0).assignUser(galaxy.getUser(1));
  planet.getSector(1).assignUser(galaxy.getUser(1));
  planet.getSector(4).assignUser(galaxy.getUser(2));
  planet.getSector(7).assignUser(galaxy.getUser(2));

  // 2
  planet.getSector(2).assignUser(galaxy.getUser(1));

  // 3
  planet.getSector(5).assignUser(galaxy.getUser(1));
  planet.getSector(8).assignUser(galaxy.getUser(1));
  planet.getSector(3).assignUser(galaxy.getUser(2));
  planet.getSector(6).assignUser(galaxy.getUser(2));

  // 4
  planet.getSector(5).assignUser(galaxy.getUser(2));

  // 5
  planet.getSector(8).assignUser(galaxy.getUser(2));
  // User 5 is starting to attack sector 8 from spaceships, etc.

  // Energy
  planet.getZone( 1, 0).assignEnergy([ [ galaxy.getUser(1),100 ] ]);
  planet.getZone( 2, 0).assignEnergy([ [ galaxy.getUser(1),100 ] ]);
  planet.getZone( 1, 1).assignEnergy([ [ galaxy.getUser(1),95 ] ]);
  planet.getZone( 2, 1).assignEnergy([ [ galaxy.getUser(1),95 ] ]);
  planet.getZone( 1, 2).assignEnergy([ [ galaxy.getUser(1),95 ] ]);
  planet.getZone( 2, 2).assignEnergy([ [ galaxy.getUser(1),95 ] ]);
  planet.getZone( 1, 3).assignEnergy([ [ galaxy.getUser(1), 89 ] ]);
  planet.getZone( 2, 3).assignEnergy([ [ galaxy.getUser(1),95 ] ]);
  planet.getZone( 3, 3).assignEnergy([ [ galaxy.getUser(1), 93 ] ]);

  planet.getZone( 4, 2).assignEnergy([ [ galaxy.getUser(1), 91 ] ]);
  planet.getZone( 3, 2).assignEnergy([ [ galaxy.getUser(1), 92 ] ]);
  planet.getZone( 5, 1).assignEnergy([ [ galaxy.getUser(1), 95 ] ]);
  planet.getZone( 4, 1).assignEnergy([ [ galaxy.getUser(1), 90 ] ]);
  planet.getZone( 3, 0).assignEnergy([ [ galaxy.getUser(1), 87 ] ]);
  planet.getZone( 3, 1).assignEnergy([ [ galaxy.getUser(1), 86 ] ]);
  planet.getZone( 4, 0).assignEnergy([ [ galaxy.getUser(1),95 ] ]);

  planet.getZone( 0, 4).assignEnergy([ [ galaxy.getUser(1), 70 ] ]);
  planet.getZone( 1, 4).assignEnergy([ [ galaxy.getUser(1), 89 ] ]);
  planet.getZone( 2, 4).assignEnergy([ [ galaxy.getUser(1), 98 ] ]);
  planet.getZone( 1, 5).assignEnergy([ [ galaxy.getUser(1), 78 ] ]);
  planet.getZone( 0, 6).assignEnergy([ [ galaxy.getUser(1), 95 ] ]);
  planet.getZone( 1, 6).assignEnergy([ [ galaxy.getUser(1), 87 ] ]);
  planet.getZone( 0, 5).assignEnergy([ [ galaxy.getUser(1), 73 ] ]);
  planet.getZone( 2, 5).assignEnergy([ [ galaxy.getUser(1), 80 ] ]);
  planet.getZone( 3, 5).assignEnergy([ [ galaxy.getUser(1),100 ] ]);

  planet.getZone( 7, 4).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 7, 3).assignEnergy([ [ galaxy.getUser(2), 91 ] ]);
  planet.getZone( 6, 3).assignEnergy([ [ galaxy.getUser(2), 94 ] ]);
  planet.getZone( 8, 3).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 7, 2).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 6, 2).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 5, 2).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 6, 1).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 7, 1).assignEnergy([ [ galaxy.getUser(2),100 ] ]);

  planet.getZone( 3, 4).assignEnergy([ [ galaxy.getUser(2), 94 ] ]);
  planet.getZone( 5, 5).assignEnergy([ [ galaxy.getUser(2), 79 ] ]);
  planet.getZone( 5, 4).assignEnergy([ [ galaxy.getUser(2), 94 ] ]);
  planet.getZone( 5, 3).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 3, 6).assignEnergy([ [ galaxy.getUser(2), 91 ] ]);
  planet.getZone( 4, 6).assignEnergy([ [ galaxy.getUser(2), 89 ] ]);
  planet.getZone( 4, 5).assignEnergy([ [ galaxy.getUser(2), 95 ] ]);
  planet.getZone( 4, 4).assignEnergy([ [ galaxy.getUser(2), 93 ] ]);
  planet.getZone( 4, 3).assignEnergy([ [ galaxy.getUser(2), 99 ] ]);

  planet.getZone( 5, 7).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 6, 7).assignEnergy([ [ galaxy.getUser(2), 89 ] ]);
  planet.getZone( 5, 6).assignEnergy([ [ galaxy.getUser(2), 93 ] ]);
  planet.getZone( 6, 6).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 6, 5).assignEnergy([ [ galaxy.getUser(2), 90 ] ]);
  planet.getZone( 7, 5).assignEnergy([ [ galaxy.getUser(2), 90 ] ]);
  planet.getZone( 6, 4).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);

  planet.getZone( 3, 9).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 4, 9).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 3,10).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 4,10).assignEnergy([ [ galaxy.getUser(2), 93 ] ]);
  planet.getZone( 3, 8).assignEnergy([ [ galaxy.getUser(2), 84 ] ]);
  planet.getZone( 4, 8).assignEnergy([ [ galaxy.getUser(2), 90 ] ]);
  planet.getZone( 4, 7).assignEnergy([ [ galaxy.getUser(2), 85 ] ]);
  planet.getZone( 5, 8).assignEnergy([ [ galaxy.getUser(2),100 ] ]);
  planet.getZone( 5, 9).assignEnergy([ [ galaxy.getUser(2), 87 ] ]);

  planet.getZone( 1, 7).assignEnergy([ [ galaxy.getUser(2), 77 ] ]);
  planet.getZone( 2, 7).assignEnergy([ [ galaxy.getUser(2), 80 ] ]);
  planet.getZone( 2, 8).assignEnergy([ [ galaxy.getUser(2), 67 ] ]);
  planet.getZone( 1, 8).assignEnergy([ [ galaxy.getUser(2), 70 ] ]);
  planet.getZone( 0, 8).assignEnergy([ [ galaxy.getUser(2), 62 ] ]);
  planet.getZone( 1, 9).assignEnergy([ [ galaxy.getUser(2), 65 ] ]);
  planet.getZone( 2, 9).assignEnergy([ [ galaxy.getUser(2), 76 ] ]);
  planet.getZone( 2, 6).assignEnergy([ [ galaxy.getUser(2), 83 ] ]);
  planet.getZone( 3, 7).assignEnergy([ [ galaxy.getUser(2), 78 ] ]);

  planet.getZone( 2,11).assignEnergy([ [ galaxy.getUser(2), 57 ], [ galaxy.getUser(5), 35 ] ]);
  planet.getZone( 1,11).assignEnergy([ [ galaxy.getUser(2), 95 ] ]);
  planet.getZone( 1,10).assignEnergy([ [ galaxy.getUser(2), 95 ] ]);
  planet.getZone( 2,10).assignEnergy([ [ galaxy.getUser(2), 95 ] ]);
  planet.getZone( 0,10).assignEnergy([ [ galaxy.getUser(2), 57 ], [ galaxy.getUser(5), 35 ] ]);
  planet.getZone( 0,12).assignEnergy([ [ galaxy.getUser(2), 40 ], [ galaxy.getUser(5), 45 ] ]);
  planet.getZone( 1,12).assignEnergy([ [ galaxy.getUser(2), 40 ], [ galaxy.getUser(5), 45 ] ]);

  // Buildings
  planet.createBuilding([ 1, 2 ], BuildingEnum.TransferStation).setStatus('upgrade');
  planet.createBuilding([ 3, 1 ], BuildingEnum.Outpost);
  planet.createBuilding([ 0, 4 ], BuildingEnum.Spaceport);

  planet.createBuilding([ 1, 8 ], BuildingEnum.Outpost);
  planet.createBuilding([ 0,10 ], BuildingEnum.Spaceport).setStatus('upgrade');
  planet.createBuilding([ 6, 2 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 6, 5 ], BuildingEnum.GaussArtillery);
  planet.createBuilding([ 4, 9 ], BuildingEnum.GaussArtillery);
  planet.createBuilding([ 4, 4 ], BuildingEnum.Outpost);

  // Units
  planet.createUnit([ 6, 4 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 5, 5 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 4, 7 ], UnitEnum.AreaAttack).setStatus('attack');

  // Spaceships
  galaxy.createSpaceship([ -3,  0 ], SpaceshipEnum.FighterBasic, galaxy.getUser(1)).setStatus('dock');
  galaxy.createSpaceship([ -4,  1 ], SpaceshipEnum.FighterBasic, galaxy.getUser(1)).setStatus('dock');
  galaxy.createSpaceship([ -3,  6 ], SpaceshipEnum.FighterBasic, galaxy.getUser(2)).setStatus('create');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 6, 4), planet.getZone( 4, 1), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 5, 5), planet.getZone( 1, 2), { type: ShapeEnum.SextupleArea, variant: 5 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 4, 7), planet.getZone( 1, 5), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});

  //galaxy.createTransferInteraction(planet.getZone(3, 1), planet.getZone(1, 2), { type: ShapeEnum.Sector, variant: 0 });
  //galaxy.createTransferInteraction(planet.getZone(0, 4), planet.getZone(1, 2), { type: ShapeEnum.Sector, variant: 0 });
}


const setupPlanetTwo = (galaxy) =>
{
  const planet = galaxy.getPlanet(1);

  // 1
  planet.getSector(2).assignUser(galaxy.getUser(3));
  planet.getSector(8).assignUser(galaxy.getUser(3));

  // 2
  planet.getSector(5).assignUser(galaxy.getUser(3));

  // 3
  planet.getSector(1).assignUser(galaxy.getUser(3));

  // 4
  planet.getSector(9).assignUser(galaxy.getUser(3));
  planet.getSector(4).assignUser(galaxy.getUser(1));

  // 5
  planet.getSector(3).assignUser(galaxy.getUser(1));
  planet.getSector(6).assignUser(galaxy.getUser(1));
  planet.getSector(7).assignUser(galaxy.getUser(1));
  planet.getSector(0).assignUser(galaxy.getUser(3));
  planet.getSector(10).assignUser(galaxy.getUser(3));
  planet.getSector(11).assignUser(galaxy.getUser(3));

  // Energy
  planet.getZone( 3, 0).assignEnergy([ [ galaxy.getUser(3), 42 ] ]);
  planet.getZone( 4, 0).assignEnergy([ [ galaxy.getUser(3), 70 ] ]);
  planet.getZone( 5, 0).assignEnergy([ [ galaxy.getUser(3), 88 ] ]);
  planet.getZone( 2, 1).assignEnergy([ [ galaxy.getUser(3), 46 ] ]);
  planet.getZone( 3, 1).assignEnergy([ [ galaxy.getUser(3), 83 ] ]);
  planet.getZone( 4, 1).assignEnergy([ [ galaxy.getUser(3), 92 ] ]);
  planet.getZone( 2, 2).assignEnergy([ [ galaxy.getUser(3), 70 ] ]);
  planet.getZone( 3, 2).assignEnergy([ [ galaxy.getUser(3), 70 ] ]);
  planet.getZone( 4, 2).assignEnergy([ [ galaxy.getUser(3), 83 ] ]);

  planet.getZone( 1, 3).assignEnergy([ [ galaxy.getUser(3), 66 ] ]);
  planet.getZone( 2, 3).assignEnergy([ [ galaxy.getUser(3), 61 ] ]);
  planet.getZone( 3, 3).assignEnergy([ [ galaxy.getUser(3), 62 ] ]);
  planet.getZone( 2, 4).assignEnergy([ [ galaxy.getUser(3), 27 ] ]);
  planet.getZone( 3, 4).assignEnergy([ [ galaxy.getUser(3), 60 ] ]);
  planet.getZone( 1, 5).assignEnergy([ [ galaxy.getUser(3), 30 ] ]);
  planet.getZone( 2, 5).assignEnergy([ [ galaxy.getUser(3), 18 ] ]);

  planet.getZone( 7, 1).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone( 8, 3).assignEnergy([ [ galaxy.getUser(3), 85 ] ]);
  planet.getZone( 9, 2).assignEnergy([ [ galaxy.getUser(3), 86 ] ]);
  planet.getZone( 8, 2).assignEnergy([ [ galaxy.getUser(3), 90 ] ]);
  planet.getZone( 9, 3).assignEnergy([ [ galaxy.getUser(3), 90 ] ]);
  planet.getZone( 9, 4).assignEnergy([ [ galaxy.getUser(3), 90 ] ]);
  planet.getZone(10, 2).assignEnergy([ [ galaxy.getUser(3), 95 ] ]);
  planet.getZone( 9, 1).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone( 8, 1).assignEnergy([ [ galaxy.getUser(3), 92 ] ]);

  planet.getZone(10, 4).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(10, 5).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone( 9, 5).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(11, 4).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(10, 3).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(10, 6).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(11, 5).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(11, 6).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(12, 6).assignEnergy([ [ galaxy.getUser(3),100 ] ]);

  planet.getZone( 8, 4).assignEnergy([ [ galaxy.getUser(3), 90 ] ]);
  planet.getZone( 7, 3).assignEnergy([ [ galaxy.getUser(3), 85 ] ]);
  planet.getZone( 6, 4).assignEnergy([ [ galaxy.getUser(3), 76 ] ]);
  planet.getZone( 6, 5).assignEnergy([ [ galaxy.getUser(3), 77 ] ]);
  planet.getZone( 7, 4).assignEnergy([ [ galaxy.getUser(3), 85 ] ]);
  planet.getZone( 7, 5).assignEnergy([ [ galaxy.getUser(3), 82 ] ]);
  planet.getZone( 8, 5).assignEnergy([ [ galaxy.getUser(3), 89 ] ]);

  planet.getZone( 5, 3).assignEnergy([ [ galaxy.getUser(3), 85 ] ]);
  planet.getZone( 6, 3).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone( 7, 2).assignEnergy([ [ galaxy.getUser(3), 92 ] ]);
  planet.getZone( 6, 1).assignEnergy([ [ galaxy.getUser(3), 91 ] ]);
  planet.getZone( 6, 2).assignEnergy([ [ galaxy.getUser(3), 82 ] ]);
  planet.getZone( 5, 2).assignEnergy([ [ galaxy.getUser(3), 88 ] ]);
  planet.getZone( 5, 1).assignEnergy([ [ galaxy.getUser(3), 83 ] ]);
  planet.getZone( 4, 3).assignEnergy([ [ galaxy.getUser(3), 65 ] ]);
  planet.getZone( 5, 4).assignEnergy([ [ galaxy.getUser(3), 56 ] ]);

  planet.getZone(11, 2).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(11, 3).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(10, 1).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(12, 4).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(12, 2).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(12, 3).assignEnergy([ [ galaxy.getUser(3), 96 ] ]);
  planet.getZone(12, 1).assignEnergy([ [ galaxy.getUser(3), 92 ] ]);
  planet.getZone(13, 2).assignEnergy([ [ galaxy.getUser(3), 85 ] ]);
  planet.getZone(11, 1).assignEnergy([ [ galaxy.getUser(3), 80 ] ]);

  planet.getZone(13, 6).assignEnergy([ [ galaxy.getUser(3), 85 ] ]);
  planet.getZone(14, 6).assignEnergy([ [ galaxy.getUser(3), 79 ] ]);
  planet.getZone(13, 5).assignEnergy([ [ galaxy.getUser(3), 94 ] ]);
  planet.getZone(13, 3).assignEnergy([ [ galaxy.getUser(3), 84 ] ]);
  planet.getZone(13, 4).assignEnergy([ [ galaxy.getUser(3), 89 ] ]);
  planet.getZone(12, 5).assignEnergy([ [ galaxy.getUser(3), 81 ] ]);
  planet.getZone(14, 4).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(14, 5).assignEnergy([ [ galaxy.getUser(3),100 ] ]);
  planet.getZone(13, 7).assignEnergy([ [ galaxy.getUser(3), 75 ] ]);

  planet.getZone(5, 6).assignEnergy([ [ galaxy.getUser(1), 79 ], [ galaxy.getUser(3), 15 ] ]);
  planet.getZone(4, 6).assignEnergy([ [ galaxy.getUser(1), 75 ], [ galaxy.getUser(3), 15 ] ]);
  planet.getZone(4, 5).assignEnergy([ [ galaxy.getUser(1), 82 ], [ galaxy.getUser(3), 10 ] ]);
  planet.getZone(3, 5).assignEnergy([ [ galaxy.getUser(1), 65 ], [ galaxy.getUser(3), 10 ] ]);
  planet.getZone(3, 6).assignEnergy([ [ galaxy.getUser(1), 51 ], [ galaxy.getUser(3), 10 ] ]);
  planet.getZone(5, 7).assignEnergy([ [ galaxy.getUser(1), 92 ] ]);
  planet.getZone(5, 7).assignEnergy([ [ galaxy.getUser(1), 93 ] ]);
  planet.getZone(4, 4).assignEnergy([ [ galaxy.getUser(1), 45 ], [ galaxy.getUser(3), 10 ] ]);
  planet.getZone(5, 5).assignEnergy([ [ galaxy.getUser(1), 42 ], [ galaxy.getUser(3), 10 ] ]);
  planet.getZone(6, 6).assignEnergy([ [ galaxy.getUser(1), 37 ], [ galaxy.getUser(3), 10 ] ]);
  planet.getZone(4, 7).assignEnergy([ [ galaxy.getUser(1), 30 ], [ galaxy.getUser(3), 15 ] ]);
  planet.getZone(3, 7).assignEnergy([ [ galaxy.getUser(1), 65 ] ]);

  planet.getZone( 2, 6).assignEnergy([ [ galaxy.getUser(1), 76 ] ]);
  planet.getZone( 2, 7).assignEnergy([ [ galaxy.getUser(1), 76 ] ]);
  planet.getZone( 2, 8).assignEnergy([ [ galaxy.getUser(1), 92 ] ]);
  planet.getZone( 1, 8).assignEnergy([ [ galaxy.getUser(1), 95 ] ]);
  planet.getZone( 3, 8).assignEnergy([ [ galaxy.getUser(1), 45 ] ]);
  planet.getZone( 1, 7).assignEnergy([ [ galaxy.getUser(1), 51 ] ]);
  planet.getZone( 1, 6).assignEnergy([ [ galaxy.getUser(1), 45 ] ]);

  planet.getZone( 4, 8).assignEnergy([ [ galaxy.getUser(1), 75 ] ]);
  planet.getZone( 5, 8).assignEnergy([ [ galaxy.getUser(1), 71 ] ]);
  planet.getZone( 3, 9).assignEnergy([ [ galaxy.getUser(1), 72 ] ]);
  planet.getZone( 4, 9).assignEnergy([ [ galaxy.getUser(1), 65 ] ]);
  planet.getZone( 5, 9).assignEnergy([ [ galaxy.getUser(1), 48 ] ]);
  planet.getZone( 4,10).assignEnergy([ [ galaxy.getUser(1), 50 ] ]);
  planet.getZone( 5,10).assignEnergy([ [ galaxy.getUser(1), 43 ] ]);

  planet.getZone( 0, 9).assignEnergy([ [ galaxy.getUser(1), 35 ] ]);
  planet.getZone( 1, 9).assignEnergy([ [ galaxy.getUser(1), 65 ] ]);
  planet.getZone( 2, 9).assignEnergy([ [ galaxy.getUser(1), 50 ] ]);
  planet.getZone( 1,10).assignEnergy([ [ galaxy.getUser(1), 46 ] ]);
  planet.getZone( 2,10).assignEnergy([ [ galaxy.getUser(1), 55 ] ]);
  planet.getZone( 3,10).assignEnergy([ [ galaxy.getUser(1), 61 ] ]);
  planet.getZone( 1,11).assignEnergy([ [ galaxy.getUser(1), 19 ] ]);
  planet.getZone( 2,11).assignEnergy([ [ galaxy.getUser(1), 17 ] ]);
  planet.getZone( 2,12).assignEnergy([ [ galaxy.getUser(1), 15 ] ]);

  // Buildings
  planet.createBuilding([ 3, 1 ], BuildingEnum.GaussCannon);
  planet.createBuilding([ 2, 3 ], BuildingEnum.Outpost).setStatus('create');
  planet.createBuilding([ 5, 2 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 9, 3 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 9, 5 ], BuildingEnum.LaserBattery);
  planet.createBuilding([ 7, 4 ], BuildingEnum.TransferStation);
  planet.createBuilding([12, 2 ], BuildingEnum.GaussCannon);
  planet.createBuilding([13, 7 ], BuildingEnum.Spaceport);

  planet.createBuilding([ 4, 6 ], BuildingEnum.Outpost).setStatus('upgrade');
  planet.createBuilding([ 3, 8 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 4, 9 ], BuildingEnum.GaussArtillery);

  planet.createUnit([ 6, 5 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 5, 4 ], UnitEnum.BasicAttack).setStatus('attack');
  planet.createUnit([ 2, 5 ], UnitEnum.BasicAttack).setStatus('attack');

  planet.createUnit([ 8, 3 ], UnitEnum.RangeAttack).setStatus('attack');
  planet.createUnit([ 7, 3 ], UnitEnum.RangeAttack).setStatus('attack');

  // Spaceships
  galaxy.createSpaceship([ 2, -5 ], SpaceshipEnum.FighterBasic, galaxy.getUser(3)).setStatus('dock');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 6, 5), planet.getZone( 5, 5), { type: ShapeEnum.DoubleBasic, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 5, 4), planet.getZone( 4, 4), { type: ShapeEnum.DoubleBasic, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 2, 5), planet.getZone( 3, 5), { type: ShapeEnum.DoubleBasic, variant: 2 }, { value: Influence.fromPercentage(6)});

  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 8, 3), planet.getZone( 5, 6), { type: ShapeEnum.TripleArea, variant: 1 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 7, 3), planet.getZone( 5, 6), { type: ShapeEnum.TripleArea, variant: 1 }, { value: Influence.fromPercentage(6)});

  // Projectiles
  galaxy.createProjectile(ProjectileEnum.LaserBattery, planet.getZone( 9, 5), planet.getZone(5, 6), { type: ShapeEnum.TripleArea, variant: 1 }, 400, 900);
  galaxy.createProjectile(ProjectileEnum.GaussCannon, planet.getZone(12, 2), planet.getZone(5, 8), { type: ShapeEnum.QuadrupleArea, variant: 1 }, 200, 900);
  galaxy.createProjectile(ProjectileEnum.GaussCannon, planet.getZone( 3, 1), planet.getZone(4, 8), { type: ShapeEnum.QuadrupleArea, variant: 0 }, 200, 900);

  galaxy.createProjectile(ProjectileEnum.GaussCannon, planet.getZone( 4, 9), planet.getZone(5, 6), { type: ShapeEnum.SeptupleArea, variant: 0 }, 700, 900);
}


const setupPlanetThree = (galaxy) =>
{
  const planet = galaxy.getPlanet(2);

  // 2
  planet.getSector(2).assignUser(galaxy.getUser(4));
  planet.getSector(3).assignUser(galaxy.getUser(4));

  // 3
  planet.getSector(6).assignUser(galaxy.getUser(4));

  // 4
  planet.getSector(5).assignUser(galaxy.getUser(4));
  planet.getSector(8).assignUser(galaxy.getUser(4));

  // 5
  planet.getSector(1).assignUser(galaxy.getUser(4));

  // Energy
  planet.getZone( 5, 9).assignEnergy([ [ galaxy.getUser(4), 95 ] ]);
  planet.getZone( 6, 9).assignEnergy([ [ galaxy.getUser(4), 95 ] ]);
  planet.getZone( 6,10).assignEnergy([ [ galaxy.getUser(4), 95 ] ]);
  planet.getZone( 7,10).assignEnergy([ [ galaxy.getUser(4), 95 ] ]);
  planet.getZone( 4, 9).assignEnergy([ [ galaxy.getUser(4), 75 ] ]);
  planet.getZone( 4,10).assignEnergy([ [ galaxy.getUser(4), 75 ] ]);
  planet.getZone( 5,10).assignEnergy([ [ galaxy.getUser(4), 75 ] ]);
  planet.getZone( 5,11).assignEnergy([ [ galaxy.getUser(4), 65 ] ]);
  planet.getZone( 6,11).assignEnergy([ [ galaxy.getUser(4), 65 ] ]);

  planet.getZone( 5, 3).assignEnergy([ [ galaxy.getUser(4), 77 ] ]);
  planet.getZone( 6, 3).assignEnergy([ [ galaxy.getUser(4), 77 ] ]);
  planet.getZone( 5, 4).assignEnergy([ [ galaxy.getUser(4), 77 ] ]);
  planet.getZone( 6, 4).assignEnergy([ [ galaxy.getUser(4), 77 ] ]);
  planet.getZone( 7, 4).assignEnergy([ [ galaxy.getUser(4), 77 ] ]);
  planet.getZone( 5, 5).assignEnergy([ [ galaxy.getUser(4), 77 ] ]);
  planet.getZone( 6, 5).assignEnergy([ [ galaxy.getUser(4), 77 ] ]);
  planet.getZone( 5, 2).assignEnergy([ [ galaxy.getUser(4),  5 ] ]);
  planet.getZone( 4, 3).assignEnergy([ [ galaxy.getUser(4),  5 ] ]);

  // Buildings
  planet.createBuilding([ 9, 4 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([11, 6 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 5, 9 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 7, 9 ], BuildingEnum.Spaceport);
  planet.createBuilding([ 6, 4 ], BuildingEnum.DistributionCenter).setStatus('upgrade');

  // Spaceships
  galaxy.createSpaceship([15, 6 ], SpaceshipEnum.FighterBasic, galaxy.getUser(4)).setStatus('dock');
  galaxy.createSpaceship([15, 5 ], SpaceshipEnum.FighterBasic, galaxy.getUser(4)).setStatus('dock');
}


const setupPlanetFour = (galaxy) =>
{
  const planet = galaxy.getPlanet(3);

  // 1
  planet.getSector(0).assignUser(galaxy.getUser(5));
  planet.getSector(1).assignUser(galaxy.getUser(5));

  // 2
  planet.getSector(2).assignUser(galaxy.getUser(5));
  planet.getSector(3).assignUser(galaxy.getUser(5));

  // 3
  planet.getSector(4).assignUser(galaxy.getUser(5));
  planet.getSector(5).assignUser(galaxy.getUser(5));

  // 4
  planet.getSector(8).assignUser(galaxy.getUser(5));
  planet.getSector(9).assignUser(galaxy.getUser(5));

  // Energy
  planet.getZone( 2, 1).assignEnergy([ [ galaxy.getUser(5), 85 ] ]);
  planet.getZone( 3, 1).assignEnergy([ [ galaxy.getUser(5), 95 ] ]);
  planet.getZone( 2, 2).assignEnergy([ [ galaxy.getUser(5), 95 ] ]);
  planet.getZone( 3, 2).assignEnergy([ [ galaxy.getUser(5), 95 ] ]);
  planet.getZone( 2, 3).assignEnergy([ [ galaxy.getUser(5), 95 ] ]);
  planet.getZone( 3, 3).assignEnergy([ [ galaxy.getUser(5), 95 ] ]);
  planet.getZone( 3, 4).assignEnergy([ [ galaxy.getUser(5), 90 ] ]);

  planet.getZone( 4, 0).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 6, 0).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 4, 1).assignEnergy([ [ galaxy.getUser(5), 95 ] ]);
  planet.getZone( 5, 0).assignEnergy([ [ galaxy.getUser(5), 95 ] ]);
  planet.getZone( 5, 1).assignEnergy([ [ galaxy.getUser(5), 95 ] ]);
  planet.getZone( 6, 1).assignEnergy([ [ galaxy.getUser(5), 95 ] ]);
  planet.getZone( 4, 2).assignEnergy([ [ galaxy.getUser(5),100 ] ]);
  planet.getZone( 5, 2).assignEnergy([ [ galaxy.getUser(5),100 ] ]);
  planet.getZone( 6, 2).assignEnergy([ [ galaxy.getUser(5),100 ] ]);

  planet.getZone( 6, 3).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);
  planet.getZone( 7, 1).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 7, 2).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 8, 2).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);
  planet.getZone( 7, 3).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);

  planet.getZone( 4, 3).assignEnergy([ [ galaxy.getUser(5),100 ] ]);
  planet.getZone( 5, 3).assignEnergy([ [ galaxy.getUser(5),100 ] ]);
  planet.getZone( 4, 4).assignEnergy([ [ galaxy.getUser(5),100 ] ]);
  planet.getZone( 5, 4).assignEnergy([ [ galaxy.getUser(5),100 ] ]);
  planet.getZone( 6, 4).assignEnergy([ [ galaxy.getUser(5),100 ] ]);
  planet.getZone( 4, 5).assignEnergy([ [ galaxy.getUser(5),100 ] ]);
  planet.getZone( 5, 5).assignEnergy([ [ galaxy.getUser(5),100 ] ]);
  planet.getZone( 5, 6).assignEnergy([ [ galaxy.getUser(5), 85 ] ]);
  planet.getZone( 6, 6).assignEnergy([ [ galaxy.getUser(5), 85 ] ]);
  planet.getZone( 6, 5).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);
  planet.getZone( 7, 4).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);

  planet.getZone( 7, 6).assignEnergy([ [ galaxy.getUser(5), 95 ] ]);
  planet.getZone( 6, 7).assignEnergy([ [ galaxy.getUser(5), 90 ] ]);
  planet.getZone( 7, 7).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 8, 7).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 7, 8).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 8, 8).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 7, 9).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);

  planet.getZone( 8, 3).assignEnergy([ [ galaxy.getUser(5), 85 ] ]);
  planet.getZone( 9, 3).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 8, 4).assignEnergy([ [ galaxy.getUser(5), 85 ] ]);
  planet.getZone( 9, 4).assignEnergy([ [ galaxy.getUser(5), 55 ] ]);
  planet.getZone( 7, 5).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 8, 5).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 9, 5).assignEnergy([ [ galaxy.getUser(5), 65 ] ]);
  planet.getZone( 8, 6).assignEnergy([ [ galaxy.getUser(5), 75 ] ]);
  planet.getZone( 9, 6).assignEnergy([ [ galaxy.getUser(5), 65 ] ]);

  planet.getZone( 6, 9).assignEnergy([ [ galaxy.getUser(5), 30 ] ]);
  planet.getZone( 7,10).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 6,11).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 7,11).assignEnergy([ [ galaxy.getUser(5), 80 ] ]);
  planet.getZone( 6,10).assignEnergy([ [ galaxy.getUser(5), 60 ] ]);
  planet.getZone( 8,10).assignEnergy([ [ galaxy.getUser(5), 40 ] ]);
  planet.getZone( 5,11).assignEnergy([ [ galaxy.getUser(5), 30 ] ]);

  planet.getZone( 5, 7).assignEnergy([ [ galaxy.getUser(5), 35 ] ]);
  planet.getZone( 4, 8).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);
  planet.getZone( 5, 8).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);
  planet.getZone( 6, 8).assignEnergy([ [ galaxy.getUser(5), 35 ] ]);
  planet.getZone( 3, 9).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);
  planet.getZone( 4, 9).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);
  planet.getZone( 5, 9).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);
  planet.getZone( 4,10).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);
  planet.getZone( 5,10).assignEnergy([ [ galaxy.getUser(5), 70 ] ]);

  // Buildings
  planet.createBuilding([ 5, 2 ], BuildingEnum.DistributionCenter);
  planet.createBuilding([ 3, 3 ], BuildingEnum.GaussArtillery);
  planet.createBuilding([ 5, 5 ], BuildingEnum.GaussArtillery);
  planet.createBuilding([ 7, 1 ], BuildingEnum.Spaceport);
  planet.createBuilding([ 8, 8 ], BuildingEnum.Spaceport);
  planet.createBuilding([ 8, 5 ], BuildingEnum.GaussArtillery);
  planet.createBuilding([ 4, 9 ], BuildingEnum.GaussArtillery);
  planet.createBuilding([ 7,10 ], BuildingEnum.GaussArtillery);

  // Spaceships
  galaxy.createSpaceship([-6,12 ], SpaceshipEnum.FighterBasic, galaxy.getUser(5)).setStatus('create');

  // Interactions
  //galaxy.createTransferInteraction(planet.getZone(3, 3), planet.getZone(5, 5), { type: ShapeEnum.Sector, variant: 0 });
  //galaxy.createTransferInteraction(planet.getZone(5, 2), planet.getZone(5, 5), { type: ShapeEnum.Sector, variant: 0 });
  //galaxy.createTransferInteraction(planet.getZone(7, 1), planet.getZone(8, 3), { type: ShapeEnum.Sector, variant: 0 });
  //galaxy.createTransferInteraction(planet.getZone(5, 5), planet.getZone(8, 3), { type: ShapeEnum.Sector, variant: 0 });
}


const setupPlanetFive = (galaxy) =>
{
  const planet = galaxy.getPlanet(4);

  // 5
  planet.getSector(4).assignUser(galaxy.getUser(5));
  planet.getSector(3).assignUser(galaxy.getUser(5));

  planet.getSector(0).assignUser(galaxy.getUser(2));

  // Energy
  planet.getZone( 2, 7).assignEnergy([ [ galaxy.getUser(5), 59 ] ]);
  planet.getZone( 3, 7).assignEnergy([ [ galaxy.getUser(5), 46 ] ]);
  planet.getZone( 2, 8).assignEnergy([ [ galaxy.getUser(5), 46 ] ]);
  planet.getZone( 3, 8).assignEnergy([ [ galaxy.getUser(5), 69 ] ]);
  planet.getZone( 1, 9).assignEnergy([ [ galaxy.getUser(5), 79 ] ]);
  planet.getZone( 2, 9).assignEnergy([ [ galaxy.getUser(5), 79 ] ]);
  planet.getZone( 3, 9).assignEnergy([ [ galaxy.getUser(5), 25 ] ]);

  planet.getZone( 4, 6).assignEnergy([ [ galaxy.getUser(5), 79 ] ]);
  planet.getZone( 4, 7).assignEnergy([ [ galaxy.getUser(5), 66 ] ]);
  planet.getZone( 4, 8).assignEnergy([ [ galaxy.getUser(5), 71 ] ]);
  planet.getZone( 4, 8).assignEnergy([ [ galaxy.getUser(5), 71 ] ]);
  planet.getZone( 5, 6).assignEnergy([ [ galaxy.getUser(5), 41 ] ]);
  planet.getZone( 5, 7).assignEnergy([ [ galaxy.getUser(5), 37 ] ]);
  planet.getZone( 5, 8).assignEnergy([ [ galaxy.getUser(5), 30 ] ]);
  planet.getZone( 5, 5).assignEnergy([ [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 6, 6).assignEnergy([ [ galaxy.getUser(5), 10 ] ]);
  planet.getZone( 6, 7).assignEnergy([ [ galaxy.getUser(5), 15 ] ]);

  planet.getZone( 2, 0).assignEnergy([ [ galaxy.getUser(2), 10 ] ]);
  planet.getZone( 1, 1).assignEnergy([ [ galaxy.getUser(2), 40 ], [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 2, 1).assignEnergy([ [ galaxy.getUser(2), 40 ], [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 1, 2).assignEnergy([ [ galaxy.getUser(2), 53 ], [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 2, 2).assignEnergy([ [ galaxy.getUser(2), 47 ], [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 3, 2).assignEnergy([ [ galaxy.getUser(2), 40 ], [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 1, 3).assignEnergy([ [ galaxy.getUser(2), 50 ], [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 2, 3).assignEnergy([ [ galaxy.getUser(2), 56 ], [ galaxy.getUser(5), 15 ] ]);
  planet.getZone( 3, 3).assignEnergy([ [ galaxy.getUser(2),  5 ] ]);

  // Buildings
  planet.createBuilding([ 2, 2 ], BuildingEnum.DistributionCenter).setStatus('create');
  planet.createBuilding([ 3, 8 ], BuildingEnum.Outpost);

  // Units
  planet.createUnit([ 2, 7 ], UnitEnum.AreaAttack).setStatus('attack');
  planet.createUnit([ 4, 7 ], UnitEnum.AreaAttack).setStatus('attack');

  // Interactions
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 2, 7), planet.getZone( 2, 2), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, planet.getZone( 4, 7), planet.getZone( 2, 2), { type: ShapeEnum.SeptupleArea, variant: 0 }, { value: Influence.fromPercentage(6)});
}


const setupInterplanetaryInteractions = (galaxy) =>
{
  const planetOne = galaxy.getPlanet(0);
  const planetTwo = galaxy.getPlanet(1);
  const planetFour = galaxy.getPlanet(3);
  const planetFive = galaxy.getPlanet(4);

  // User(2) attacks planet(5)
  const spaceshipOne = galaxy.createSpaceship([ 0,11 ], SpaceshipEnum.FighterBasic, galaxy.getUser(2));
  const spaceshipTwo = galaxy.createSpaceship([ 1,10 ], SpaceshipEnum.FighterBasic, galaxy.getUser(2));

  spaceshipOne.setStatus('attack');
  spaceshipTwo.setStatus('attack');

  spaceshipOne.setTarget(planetFive.getZone( 1, 3))
  spaceshipTwo.setTarget(planetFive.getZone( 2, 3))

  galaxy.createInteraction(InteractionEnum.AttackEnergy, spaceshipOne, planetFive.getZone( 1, 3), { type: ShapeEnum.Single, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, spaceshipTwo, planetFive.getZone( 2, 3), { type: ShapeEnum.Single, variant: 0 }, { value: Influence.fromPercentage(6)});

  // User(5) attacks planet(1) user(2)
  const spaceshipsFive =
  [
    galaxy.createSpaceship([ -4, 8 ], SpaceshipEnum.FighterBasic, galaxy.getUser(5)),
    galaxy.createSpaceship([ -5,11 ], SpaceshipEnum.FighterBasic, galaxy.getUser(5)),
    galaxy.createSpaceship([ -5, 9 ], SpaceshipEnum.FighterBasic, galaxy.getUser(5)),
    galaxy.createSpaceship([ -4,10 ], SpaceshipEnum.FighterBasic, galaxy.getUser(5)),
  ];

  for (const spaceship of spaceshipsFive)
  {
    spaceship.setStatus('attack');
  }

  spaceshipsFive[0].setTarget(planetOne.getZone( 0,10));
  spaceshipsFive[1].setTarget(planetOne.getZone( 0,12));
  spaceshipsFive[2].setTarget(planetOne.getZone( 1,12));
  spaceshipsFive[3].setTarget(planetOne.getZone( 2,11));

  galaxy.createInteraction(InteractionEnum.AttackEnergy, spaceshipsFive[0], planetOne.getZone( 0,10), { type: ShapeEnum.Single, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, spaceshipsFive[1], planetOne.getZone( 0,12), { type: ShapeEnum.Single, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, spaceshipsFive[2], planetOne.getZone( 1,12), { type: ShapeEnum.Single, variant: 0 }, { value: Influence.fromPercentage(6)});
  galaxy.createInteraction(InteractionEnum.AttackEnergy, spaceshipsFive[3], planetOne.getZone( 2,11), { type: ShapeEnum.Single, variant: 0 }, { value: Influence.fromPercentage(6)});

  galaxy.createProjectile(ProjectileEnum.GaussArtillery, planetFour.getZone( 3, 3), spaceshipOne, { type: ShapeEnum.SeptupleArea, variant: 0 }, 150, 900);
  galaxy.createProjectile(ProjectileEnum.GaussArtillery, planetFour.getZone( 5, 5), spaceshipOne, { type: ShapeEnum.SeptupleArea, variant: 0 }, 250, 900);
  galaxy.createProjectile(ProjectileEnum.GaussArtillery, planetFour.getZone( 8, 5), spaceshipOne, { type: ShapeEnum.SeptupleArea, variant: 0 }, 350, 900);
  galaxy.createProjectile(ProjectileEnum.GaussArtillery, planetFour.getZone( 4, 9), spaceshipTwo, { type: ShapeEnum.SeptupleArea, variant: 0 }, 200, 900);
  galaxy.createProjectile(ProjectileEnum.GaussArtillery, planetFour.getZone( 7,10), spaceshipTwo, { type: ShapeEnum.SeptupleArea, variant: 0 }, 200, 900);
}
