/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Action from 'fierry/flux/entity/action';
import Store from 'fierry/flux/entity/store';

import * as State from 'fierry/client/url/state';

import Route from 'galax/value/route';


const Actions =
{
  Reconcile:    new Action(),

  ReplaceState: new Action(),
  PushState:    new Action(),
  PopState:     new Action(),
};


const InitialState = { permanent: [ State.getDefaultState() ], future: [], temporary: null };


const reconcile = (state, { history, id }) =>
{
  const { temporary } = state;

  state = moveHistory(loadHistory(state, history), id);

  return temporary ? pushState(state, temporary) : state;
}


const moveHistory = (state, id) =>
{
  let permanent = [...state.permanent, ...state.future];

  let future = permanent.splice(State.indexOf(permanent, id) + 1);

  return { ...state, permanent, future };
}


const loadHistory = (state, history) =>
{
  const { id } = State.getPermanent(state);

  let { permanent, future } = state;

  for (const entry of history)
  {
    if (entry.id <= id)
    {
      permanent = loadHistoryEntry(entry, permanent);
    }
    else
    {
      future = loadHistoryEntry(entry, future);
    }
  }

  return { ...state, permanent, future };
}


const loadHistoryEntry = (lhs, collection) =>
{
  if (State.indexOf(collection, lhs.id) === -1)
  {
    // History does not preserve class definition - needs to upcast manually.
    lhs.route = new Route(lhs.route.value);

    collection = [ ...collection, lhs ];
  }

  collection.sort((lhs, rhs) => lhs.id - rhs.id);

  return collection;
}


const replaceState = (state, action) =>
{
  const permanent = State.popPermanent(state);

  return replaceHistoryState({ permanent: [ ...permanent, State.getFutureState(state, action) ], future: [], temporary: null });
}


const replaceHistoryState = (state) =>
{
  window.history.replaceState(State.getSessionState(state), '', State.getSessionURL(state));

  return state;
}


const pushState = (state, action) =>
{
  const { permanent } = state;

  return pushHistoryState({ permanent: [ ...permanent, State.getFutureState(state, action) ], future: [], temporary: null });
}


const pushHistoryState = (state) =>
{
  window.history.pushState(State.getSessionState(state), '', State.getSessionURL(state));

  return state;
}


const popState = (state, { level }) =>
{
  const popCount = State.getPopCountBefore(state, level);

  return popHistoryState({ ...state, temporary: State.getPermanent(state, popCount) }, popCount);
}


const popHistoryState = (state, popCount) =>
{
  if (popCount < state.permanent.length - 1)
  {
    ++popCount;
  }

  window.history.go(- popCount)

  return state;
}


export default new Store (InitialState, Actions,
[
  [Actions.Reconcile,    reconcile],

  [Actions.ReplaceState, replaceState],
  [Actions.PushState,    pushState],
  [Actions.PopState,     popState],
]);
