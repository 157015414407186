/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, withValue } from 'fierry/react';

import EnergyIcon from 'svg/shared/modern/energy';

import PerUnit from 'svg/shared/unit/per';
import HourUnit from 'svg/shared/unit/hour';


const Component = ({ value, ...props }) =>
(
  <Element.Group param width = '5' tooltip = 'Transfer Value' {...props}>

    <Element.Icon value = {<EnergyIcon />} />
    <Element.Text value = {withValue(value, props)} />
    <Element.Unit value = {[ <PerUnit />, <HourUnit /> ]} />

  </Element.Group>
);


const Invalid = (props) =>
(
  <Element.Group param width = '5' tooltip = 'Transfer Value' {...props}>
    <Element.Icon value = {<EnergyIcon />} />
  </Element.Group>
);


export default ({ invalid, ...props }) =>
{
  return invalid ? <Invalid {...props} /> : <Component {...props} />;
}
