/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import GalaxyOne from 'galax/fake/galaxy.one';
import GalaxyTwo from 'galax/fake/galaxy.two';
import GalaxyThree from 'galax/fake/galaxy.three';
import GalaxyFour from 'galax/fake/galaxy.four';
import GalaxyFive from 'galax/fake/galaxy.five';

import GalaxyState from 'galax/fake/state/galaxy';


let GALAXIES = [];


const createGalaxy = (type) =>
{
  switch (type)
  {
    case 1: return GalaxyState(GalaxyOne());
    case 2: return GalaxyState(GalaxyTwo());
    case 3: return GalaxyState(GalaxyThree());
    case 4: return GalaxyState(GalaxyFour());
    case 5: return GalaxyState(GalaxyFive());
  }
}


const getLazyGalaxy = (type) =>
{
  if (GALAXIES[type] == null)
  {
    GALAXIES[type] = createGalaxy(type);
  }

  return GALAXIES[type];
}


export const getGalaxy = (type) =>
{
  return getLazyGalaxy(type || 5);
}
