/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element } from 'fierry/react';

import * as EnergyValue from 'galax/value/energy';

import Influence from 'galax/react/element/influence';

import EnergyIcon from 'svg/shared/modern/energy.js';


const getWidth = ({ influence }) =>
{
  return (influence != null) ? 6.5 : 3;
}

const getEnergyValue = ({ energy }) =>
{
  const value = EnergyValue.toPercentage(energy);

  return (value < 10) ? '0' + value : value;
}


const getEnergyIcon = ({ icon }) =>
{
  return icon ? icon : <EnergyIcon />;
}


const InfluenceOpt = ({ influence, ...props }) =>
{
  return (influence != null) ? <Influence {...props} value = {influence} /> : null;
}


// TODO provide energy without influence also ? How about influene without energy?
export default (props) =>
(
  <Element.Group param width = {getWidth(props)}>

    <Element.Icon value = {getEnergyIcon(props)} />
    <Element.Text value = {getEnergyValue(props)} />

    <InfluenceOpt {...props} />

  </Element.Group>
);
