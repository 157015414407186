/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><path d="M4,4q8,5.08,16,0C20,25.33,4,25.33,4,4" transform="translate(0 0)" className="black-line-20"/></Svg>
  );
}
