/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const getHeader = (type) =>
{
  switch (type)
  {
    case 0: return 'Space Strategy Game'
    case 1: return 'Start Small';
    case 2: return 'Expand';
    case 3: return 'Don’t Over-Extend';
    case 4: return 'Play For Free';
  }
}


export const getText = (type) =>
{
  switch (type)
  {
    case 0: return 'Wage total war against other players over days and weeks. Carry out complex attacks with units, spaceships and projectiles to conquer new territory and gain points.';
    case 1: return 'Construct buildings to gain new abilities. Use units to invade neighboring sectors and enlarge your nascent empire. (Tap on zones and pan the map to explore).';
    case 2: return 'Fire projectiles to hit distant targets. Employ spaceships to attack on multiple fronts. Transfer energy between sectors to sustain the invasion and defend your territory.';
    case 3: return 'Attacking uses the same energy your sectors need for defense. Take breaks between fights to allow for your energy production to keep up. Strategize where you need it the most.';
    case 4: return 'Log in with Google or Facebook and start playing! This game is a lot of fun! Join hundreds of other players in the struggle for control and survival of your new and rising empire!';
  }

}



