/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux, Modern } from 'fierry/react';

import Drawer from 'fierry/react/skeleton/drawer';

import Screen from 'galax/client/screen';
import DrawerS from 'galax/state/drawer';

import LogIn from 'galax/react/drawer/general/login';
import LogOut from 'galax/react/drawer/general/logout';

import Facebook from 'galax/react/drawer/help/facebook';
import Mastodon from 'galax/react/drawer/help/mastodon';

import HowToPlay from 'galax/react/drawer/help/how.to.play';
import Manual from 'galax/react/drawer/help/manual';
import WelcomePage from 'galax/react/drawer/help/welcome.page';
import PrivacyStatement from 'galax/react/drawer/help/privacy.statement';
import TermsOfService from 'galax/react/drawer/help/terms.of.service';


const Component = ({ open, onOpenChange, ...props }) =>
(
  <Drawer title = 'Fierry' subtitle = 'Navigation' open = {open} onOpenChange = {onOpenChange} >

    <HowToPlay />
    <Manual />
    <TermsOfService />
    <PrivacyStatement />

    <Modern.Separator />

    <Facebook />
    <Mastodon />

    <Modern.Separator />

    <LogIn />
    <LogOut />

  </Drawer>
);


const OnOpenChange = (open, screen, drawer) =>
{
  screen.setDrawer(open);
}


export default (props) =>
{
  const [ screen, drawer ] = useFlux(Screen, DrawerS);

  const onOpenChange = useCallback(OnOpenChange, [ screen ]);

  return <Component {...props} open = {screen.getDrawer()} onOpenChange = {onOpenChange} />
}
