/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import BuildingEnum from 'galax/enum/building';
import InteractionEnum from 'galax/enum/interaction';

import * as Coords from 'galax/geo/coords';

import * as InfluenceValue from 'galax/value/influence';


export default class
{
  constructor (scope)
  {
    this.scope = scope;
  }


  getTransferStrength (target)
  {
    if (this.scope.getNeighbors().isNeighbor(target))
    {
      return this.scope.getNeighbors().getNeighborSectorStrength(target);
    }

    const building = this.scope.getBuilding();

    if (building && building.getType() === BuildingEnum.TransferStation)
    {
      return building.getLevel();
    }

    return 0;
  }


  getTransferDistance (target)
  {
    if (this.scope.getNeighbors().isNeighbor(target))
    {
      return 0;
    }

    const sourceBuilding = this.scope.getBuilding();

    if (sourceBuilding && sourceBuilding.getType() === BuildingEnum.TransferStation)
    {
      return Coords.getDistance(sourceBuilding.getCoords(), target.getCass().getCenter());
    }

    return Number.MAX_SAFE_INTEGER;
  }


  getTransferRatio (target, state)
  {
    if (state.getValue())
    {
      return state.getValue();
    }

    const transfer = this.getTransferTo(target);

    if (transfer == null)
    {
      return 5;
    }

    const args = transfer.getArgs();
    const strength = this.getTransferStrength(target);

    const value = Math.floor(args.strength_constant * args.transfer_strength * args.transfer_ratio);

    return InfluenceValue.toPercentage(value / strength);
  }


  getTransferTo (target)
  {
    const collection = this.getTransfersTo(interaction =>
    {
      const cass = target.getCass();

      return interaction.target.parent.id === cass.getPlanet() && interaction.target.id.isEqual(cass.getCenter());
    });

    return collection.length ? collection[0] : null;
  }


  getTransferFrom (source)
  {
    const collection = this.getTransfersFrom(interaction =>
    {
      const cass = source.getCass();

      return interaction.source.parent.id === cass.getPlanet() && interaction.source.id.isEqual(cass.getCenter());
    });

    return collection.length ? collection[0] : null;
  }


  getTransfersTo (predicate)
  {
    const center = this.scope.getCass().getCenter();

    const filterCallback = (interaction) =>
    {
      interaction = interaction.getCass();

      return interaction.type === InteractionEnum.TransferEnergy && interaction.source.id.isEqual(center) && (!predicate || predicate(interaction));
    };

    return this.__getPlanetInteractions__().allSourceInteractions(filterCallback);
  }


  getTransfersFrom (predicate)
  {
    const center = this.scope.getCass().getCenter();

    const filterCallback = (interaction) =>
    {
      interaction = interaction.getCass();

      return interaction.type === InteractionEnum.TransferEnergy && interaction.target.id.isEqual(center) && (!predicate || predicate(interaction));
    };

    return this.__getPlanetInteractions__().allTargetInteractions(filterCallback);
  }


  getTransferBorders (zone)
  {
    let borders = [];

    const neighbors = zone.getLocation().getNeighbors().getZones();

    for (const interaction of this.getTransfersTo())
    {
      const target = interaction.getTarget();

      if (this.scope.getPlanet().getID() !== target.parent.id)
      {
        continue;
      }

      const targetSector = this.scope.getPlanet().getZone(target.id).getSector();

      for (let i = 0; i < neighbors.length; ++i)
      {
        if (neighbors[i] && neighbors[i].getSector() === targetSector)
        {
          borders.push(i);
        }
      }
    }

    return borders;
  }


  __getPlanetInteractions__ ()
  {
    return this.scope.getPlanet().getInteractions();
  }


};
