/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, css } from 'fierry/react/styled';


const widthState = ({ width }) => width && css`
  width: ${width}rem;
`;


const leftAlign = ({ leftAlign }) => leftAlign && css`
  justify-content: flex-start;
`;


const rightAlign = ({ rightAlign }) => rightAlign && css`
  justify-content: flex-end;
`;


const centerAlign = ({ centerAlign }) => centerAlign && css`
  justify-content: center;
  text-align: center;
`;



const childrenSpacing = ({ spacing = 0.25 }) => css`
  > :not(:last-child)
  {
    margin-right: ${spacing}rem;
  }
`;


export default styled.div`

  --fierry-theme-foreground: var(--fierry-element-foreground);
  --fierry-theme-opacity: var(--fierry-element-opacity);

  ${widthState}

  ${leftAlign}
  ${rightAlign}
  ${centerAlign}

  ${childrenSpacing}

  display: flex;
  align-items: center;

  min-height: 2rem;

  color: var(--fierry-theme-foreground);
  opacity: var(--fierry-theme-opacity);
`;
