/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { css } from 'fierry/react/styled';


const defaultColor = () => css`
  --fierry-button-default-foreground: var(--fierry-theme-foreground);
  --fierry-button-default-background: var(--fierry-theme-background);
`;


const primaryColor = ({ primary, disabled }) => primary && !disabled && css`
  --fierry-button-default-foreground: var(--fierry-theme-primary-foreground);
`;


const secondaryColor = ({ secondary, disabled }) => secondary && !disabled && css`
  --fierry-button-default-foreground: var(--fierry-theme-secondary-foreground);
`;


const buttonSize = ({ buttonSize = '3' }) => buttonSize && css`
  --fierry-icon-button-size: ${buttonSize}rem;
`;


const normalState = () => css`
  --fierry-button-opacity: 1.0;

  --fierry-button-foreground: var(--fierry-button-default-foreground);
  --fierry-button-background: transparent;

  --fierry-button-border-width: 0px;

  -webkit-tap-highlight-color: transparent;
`;


const outlinedState = ({ outlined }) => outlined && css`
  --fierry-button-border-width: 1px;
`;


const containedState = ({ contained }) => contained && css`
  --fierry-button-foreground: var(--fierry-button-default-background);
  --fierry-button-background: var(--fierry-button-default-foreground);
`;


const raisedState = ({ raised }) => raised && css`
  --fierry-button-foreground: var(--fierry-button-default-background);
  --fierry-button-background: var(--fierry-button-default-foreground);

  --fierry-button-box-shadow:        0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --fierry-button-box-shadow-hover:  0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --fierry-button-box-shadow-focus:  0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --fierry-button-box-shadow-active: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;


const disabledState = ({ disabled, contained, raised }) => disabled && css`
  --fierry-button-opacity: ${(contained || raised) ? 0.25 : 0.38 };

  --fierry-button-box-shadow: none;
  --fierry-button-box-shadow-hover: none;
  --fierry-button-box-shadow-focus: none;
  --fierry-button-box-shadow-active: none;
`;


const childrenColor = () => css`
  > *
  {
    --fierry-theme-foreground: var(--fierry-button-foreground);
    --fierry-theme-background: var(--fierry-button-background);
  }
`;


export default css`

  ${defaultColor}
  ${primaryColor}
  ${secondaryColor}

  ${buttonSize}

  ${normalState}
  ${outlinedState}
  ${containedState}
  ${raisedState}
  ${disabledState}

  ${childrenColor}
`;
