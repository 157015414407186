/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><g><polygon points="10.44 3.5 6 10.56 6 21.43 10.44 28.5 21.56 28.5 26 21.43 26 10.56 21.56 3.5 10.44 3.5" className="white-shape"/><g data-name="no-invert"><polygon points="10.44 3.5 6 10.56 6 21.43 10.44 28.5 21.56 28.5 26 21.43 26 10.56 21.56 3.5 10.44 3.5" className="black-line-20"/></g></g><line x1="11.5" y1="18" x2="14.5" y2="22.5" className="black-line-10"/><line x1="17.5" y1="9.5" x2="20.5" y2="14" className="black-line-10"/></Svg>
  );
}
