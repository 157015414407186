/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux } from 'fierry/react';

import Auth from 'fierry/client/auth';
import View from 'fierry/client/view';
import Server from 'galax/server/root';

import Item from 'fierry/react/skeleton/drawer/item';


const OnClick = (event, view, server) =>
{
  view.pushForeground('/login');

  if (server.isReady())
  {
    server.log('/login drawer');
  }
}


export default (props) =>
{
  const [ auth, view, server ] = useFlux(Auth, View, Server);

  const onClick = useCallback(OnClick, [ view, server ] );

  if (!auth.isLoggedOut())
  {
    return null;
  }

  return <Item text = 'Log In' icon = 'login' onClick = {onClick} />
};
