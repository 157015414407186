/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as UserValue from 'galax/value/user';


export default class
{
  constructor (zone, weigth)
  {
    this.zone = zone;
    this.weigth = weigth;

    this.requiredEnergy = 0;
    this.availableEnergy = this.zone.getType().getEnergy(this.zone.getUserID());
  }


  distributeEnergy (value)
  {
    this.requiredEnergy += value;
  }


  clearOverflow ()
  {
    if (this.isAvailable())
    {
      return 0;
    }

    const overflow = this.requiredEnergy - this.availableEnergy;

    this.requiredEnergy = this.availableEnergy;

    return overflow;
  }


  isAvailable ()
  {
    return this.requiredEnergy < this.availableEnergy;
  }


  isOverflow ()
  {
    return this.requiredEnergy > this.availableEnergy;
  }


  isCritical ()
  {
    return this.weigth === 0 || this.requiredEnergy >= this.availableEnergy;
  }


  isChallenger ()
  {
    return this.zone.getEnergy().getCurrentChallenger(this.availableEnergy - this.requiredEnergy) !== UserValue.getEmpty();
  }


  getWeight ()
  {
    return this.weigth;
  }


  getZone ()
  {
    return this.zone;
  }


};
