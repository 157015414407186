/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Query from 'fierry/flux/entity/query'

import Server from 'galax/server';


export default new Query([ Server ], (server) =>
{
  return server ? server.getCurrentUser() : null;
});
