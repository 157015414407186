/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  None:      0,
  Temporary: 1,
  Permanent: 2,
};
