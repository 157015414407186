/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Building from 'galax/server/building';


export default class extends Building
{
  canFireProjectiles ()
  {
    return true;
  }


  getProjectileConstants ()
  {
    return this.planet.getConstants().getProjectile(this.getProjectileType());
  }


};
