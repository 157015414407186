/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import Server from 'galax/server';

import BuildingInfo from 'galax/react/card/building/info'
import BuildingDescription from 'galax/react/card/building/description'

import Error from 'galax/react/card/shared/error';


const getType = ({ constants }) =>
{
  return constants.getType();
}


const Component = (props) =>
(
  <>
    <BuildingInfo {...props} type = {getType(props)} />
    <BuildingDescription type = {getType(props)} />

    <Error {...props} />
 </>
);


export default (props) =>
{
  const [ server ] = useFlux(Server);

  const constants = server.getConstants().getBuilding(props.currentArgs.type);

  return <Component {...props} constants = {constants} />;
}
