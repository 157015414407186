/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, useFlux } from 'fierry/react';

import Behavior from 'galax/client/behavior';
import CurrentLocation from 'galax/query/current.location';

import BorderTranslate from 'galax/react/map/zone/border.translate';


const TransferPath = styled.path`
  fill: var(--svg-always-white);
  stroke: var(--svg-always-black);

  stroke-width: 1.5px;
  stroke-linecap: round;
  stroke-linejoin: round;
`;


const getTransferPath = ({ grid }) =>
{
  const xOffset = - grid.getGeneralHex().getExternalWidth() / 2;

  return `M ${xOffset},1.5 l -3,-1.5 l 3,-1.5 m -3 1.5 l 6 0`;
}


const Indicator = (props) =>
{
  return <TransferPath {...props} d = {getTransferPath(props)} />;
}


const Component = ({ borders, ...props }) =>
{
  let children = [];

  for (const index of borders)
  {
    children.push(<BorderTranslate {...props} key = {index} Component = {Indicator} index = {index} />);
  }

  return children;
}


const ComponentOptional = (props) =>
{
  const [ behavior, currentLocation ] = useFlux(Behavior, CurrentLocation);

  const { zone } = props;

  // TODO should use target locations and findIndex.
  if (currentLocation && behavior.getZoneTargetIDs(currentLocation).includes(zone.getID()))
  {
    return null;
  }

  return <Component {...props} />;
}


export default (props) =>
{
  const { grid, zone } = props;

  if (!grid.getZoom().isEntityVisible())
  {
    return null;
  }

  const borders = zone.getSector().getTransfers().getTransferBorders(zone);

  if (borders.length)
  {
    return <ComponentOptional {...props} borders = {borders} />
  }

  return null;
}
