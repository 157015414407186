/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Coords from 'galax/geo/coords';


export default class
{
  constructor (scope, source)
  {
    this.scope = scope;
    this.source = source;
  }


  eachZone (callback)
  {
    this.scope.getZones().eachZone(zone =>
    {
      callback(zone, this.getZone(zone));
    });
  }


  getTotal ()
  {
    let total = 0;

    this.scope.getZones().eachZone(zone =>
    {
      total += this.getZone(zone);
    });

    return total;
  }


  getZone (zone)
  {
    const distance = Coords.getDistance(zone.getCoords(), this.source.getCoords());

    return zone.isCritical() ? 0 : Math.pow(this.scope.getConstants().getSectorBaseWeight(), distance);
  }


};
