/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Command from 'fierry/rpc/command';


export default class Commands
{
  constructor (rpc)
  {
    this.rpc = rpc;
    this.context = [];
    this.commands = {};
  }


  schedule (routing, action, args)
  {
    let command = new Command(routing.join('/'), action, args, this.rpc.socket);

    command.on_schedule(this.context[this.context.length - 1], this.rpc);

    this.commands[command.get_id()] = command;

    this.execute(command);

    return command;
  }


  execute (command)
  {
    this.context.push(command);

    try
    {
      let managed = command.execute();

      if (managed && !command.has_dependency())
      {
        this.complete_internal(command, true, undefined);
      }

    }
    catch (error)
    {
      this.complete_internal(command, false, error);
    }
    finally
    {
      this.context.pop();
    }
  }


  complete (json)
  {
    let {id, success, error} = json;

    this.complete_internal(this.commands[id], success, error);
  }


  complete_internal (command, success, error)
  {
    this.context.push(command.parent);

    delete this.commands[command.get_id()];

    try
    {
      command.complete(success, error);

      if (command.parent && !command.parent.has_dependency())
      {
        this.complete_internal(command.parent, true, undefined);
      }
    }
    catch (error)
    {
      if (command.parent)
      {
        this.complete_internal(command.parent, false, error);
      }
      else
      {
        throw error;
      }
    }
    finally
    {
      this.context.pop();
    }
  }


};
