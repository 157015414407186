/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element } from 'fierry/react';

import LocationIcon from 'svg/shared/modern/move-stroke';


const getCoord = (value) =>
{
  return value < 10 ? `0${value}0` : `${value}0`
}


const getCoordX = (props) =>
{
  return getCoord(getCoords(props).getX());
}


const getCoordY = (props) =>
{
  return getCoord(getCoords(props).getY());
}


const getCoords = ({ location }) =>
{
  if (location.getDock())
  {
    return location.getDock().getRelativeCoords();
  }

  if (location.getZone())
  {
    return location.getZone().getRelativeCoords();
  }

  return location.getCoords();
}


const getIcon = ({ icon }) =>
{
  return icon ? icon : <LocationIcon />;
}


export default (props) =>
(
  <Element.Group variant = 'body' width = '6' tooltip = 'Coordinates' {...props} >

    <Element.Icon value = {getIcon(props)} />
    <Element.Text value = {`${getCoordX(props)}, ${getCoordY(props)}`} />

  </Element.Group>
);
