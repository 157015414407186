/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ParentEnum from 'galax/enum/parent';
import ResourceEnum from 'galax/enum/resource';

import * as Energy from 'galax/value/energy';


export default class
{
  constructor (coords, sector)
  {
    this.sector = sector;

    this.zone = { coords, id: coords.toString(), location: { id: coords.toString(), parent: { id: sector.parent.getID(), type: ParentEnum.Planet }}, energy: [], resistance: [], overheat: false, critical: false, resource: ResourceEnum.None };
  }


  assignEnergy (rawEnergy)
  {
    let total = 0;
    let collection = [];

    for (const [user, value] of rawEnergy)
    {
      total += value;

      collection.push({ user: user.getID(), value: Energy.fromPercentage(value) });

      this.sector.getParent().createLocalUser(user);
    }

    if (total < 100)
    {
      collection.push({ user: '', value: Energy.fromPercentage(1 - total) });
    }

    if (total > 100)
    {
      throw new Error('Tried to assign too much energy: ' + total);
    }

    this.zone.energy = collection;
  }


  setResource (type)
  {
    this.zone.resource = type;
  }


  setUpdateDate (date)
  {
    this.zone.update_date = date;
  }


  getPlanet ()
  {
    return this.sector.getParent();
  }


  getUserID ()
  {
    return this.sector.getUserID();
  }


  getType ()
  {
    return this.zone;
  }


  getCoords ()
  {
    return this.zone.coords;
  }


  getLocation ()
  {
    return { id: this.getCoords(), parent: { id: this.getPlanet().getID(), type: ParentEnum.Planet }};
  }


}
