/*
 * Copyright (C) 2019 Marek Kuzora - All Rights Reserved.
 */


import InfluenceEnum from 'galax/enum/influence';


export default class
{
  constructor ()
  {
    this.collection = [];
  }


  push (influence)
  {
    this.collection.push(influence);
  }


  eachInfluence (callback)
  {
    for (const influence of this.collection)
    {
      callback(influence);
    }
  }


  getPassiveInfluence (user)
  {
    let value = 0;

    for (const influence of this.collection)
    {
      if (influence.getUserID() === user && influence.getType() !== InfluenceEnum.Attack)
      {
        value += influence.getValue();
      }
    }

    return value;
  }


  getAggressiveInfluence (user)
  {
    let value = 0;

    for (const influence of this.collection)
    {
      if (influence.getUserID() === user && influence.getType() === InfluenceEnum.Attack)
      {
        value += influence.getValue();
      }
    }

    return value;
  }


}
