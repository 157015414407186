/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { css, styled } from 'fierry/react';

import { isLightOverride, isDarkOverride } from 'galax/util/theme';


const lightConfig = isLightOverride() && css`

  --fierry-theme-primary-foreground:   ${props => props.theme.getPrimaryLight()};
  --fierry-theme-secondary-foreground: ${props => props.theme.getSecondaryLight()};
`;


const darkConfig = isDarkOverride() && css`

  --fierry-theme-primary-foreground:   ${props => props.theme.getPrimaryDark()};
  --fierry-theme-secondary-foreground: ${props => props.theme.getSecondaryDark()};
`;


const defaultConfig = !isLightOverride() && !isDarkOverride() && css`

  @media (prefers-color-scheme: light) {
    --fierry-theme-primary-foreground:   ${props => props.theme.getPrimaryLight()};
    --fierry-theme-secondary-foreground: ${props => props.theme.getSecondaryLight()};
  }

  @media (prefers-color-scheme: dark) {
    --fierry-theme-primary-foreground:   ${props => props.theme.getPrimaryDark()};
    --fierry-theme-secondary-foreground: ${props => props.theme.getSecondaryDark()};
  }

`

const config = ({ theme }) => theme && css`

  ${defaultConfig}
  ${lightConfig}
  ${darkConfig}

  --mdc-theme-primary:                 var(--fierry-theme-primary-foreground);
  --mdc-theme-secondary:               var(--fierry-theme-secondary-foreground);

  --mdc-theme-on-primary:              var(--fierry-theme-background);
  --mdc-theme-on-secondary:            var(--fierry-theme-background);
`;


const Div = styled.div`
  ${config}
`;


const Group = styled.g`
  ${config}
`;


export default { Div, Group };
