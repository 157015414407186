/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Event from 'fierry/rpc/event';


export default class
{
  constructor (context)
  {
    this.context = context;

    this.onOpen = new Event();
    this.onClose = new Event();
  }


  setDispatch (dispatch)
  {
    this.dispatch = dispatch;

    this.socket = new WebSocket(this.getSocketUrl(), 'fierry-websockets');

    this.socket.onopen = this.onSocketOpen;
    this.socket.onclose = this.onSocketClose;
    this.socket.onmessage = this.onSocketMessage;
  }


  getSocketUrl ()
  {
    if (window.location.protocol === 'http:')
    {
      return `ws://${window.location.hostname}/${this.context.getLocation()}`
    }

    if (window.location.protocol === 'https:')
    {
      return `wss://${window.location.hostname}/${this.context.getLocation()}`
    }

    throw new Error (`Unsupported protocol ${window.location.protocol}`);
  }


  dispose ()
  {
    this.socket.close();
  }


  executeCommand (id, routing, command, json)
  {
    let message = [{id: id, routing: routing, command: command, json: json}];

    this.socket.send(JSON.stringify(message));
  }


  executeMessage (message)
  {
    const protocol = this.context.protocol;

    let { attach, detach, notify, create_batch, commit_batch, commands } = JSON.parse(message);

    for (let routing of detach || [])
    {
      protocol.onDetach(routing);
    }

    for (let {routing, notifications} of attach || [])
    {
      protocol.onAttach(routing, notifications);
    }

    for (let {id} of create_batch || [])
    {
      protocol.onCreateBatch(id);
    }

    for (let {routing, batch, data} of notify || [])
    {
      protocol.onNotify(routing, batch, data);
    }

    for (let {id} of commit_batch || [])
    {
      protocol.onCommitBatch(id);
    }

    for (let command of commands || [])
    {
      protocol.onCommand(command);
    }

    protocol.finalize();
  }


  onSocketOpen = (event) =>
  {
    this.onOpen.raise(event);
  }


  onSocketClose = (event) =>
  {
    this.onClose.raise(event);
  }


  onSocketMessage = (message) =>
  {
    this.dispatch(message.data);
  }


};
