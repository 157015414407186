/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><g><polygon points="2 27 30 27 16 1 2 27" className="white-shape"/><g data-name="no-invert"><g data-name="line-dash-a"><polygon points="2 27 30 27 16 1 2 27" className="black-line-20"/></g></g></g><rect x="13" y="15" width="6" height="6" className="black-shape"/></Svg>
  );
}
