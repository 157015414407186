/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><line x1="20.25" y1="6.25" x2="3.75" y2="6.25" className="black-line-15"/><polyline points="17.76 3.77 20.24 6.25 17.76 8.72" className="black-line-15"/><line x1="19.5" y1="17" x2="4.5" y2="17" className="black-line-30"/><polyline points="6.99 19.48 4.51 17 6.99 14.53" className="black-line-30"/></Svg>
  );
}
