/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Event from 'fierry/flux/entity/event';

import Url from 'fierry/client/url';


export default new Event ([ Url ], (url) =>
{
  const eventListener = (event) =>
  {
    const history = event.state || [ url.getDefaultState() ];

    if (url.getSessionURL() === (window.location.pathname + window.location.search))
    {
      if (url.isNavigationBack(history))
      {
        window.history.back();
      }

      if (url.isNavigationForward(history))
      {
        window.history.forward();
      }
    }

    url.reconcile({ history });
  };

  window.addEventListener('popstate', eventListener);

  return () =>
  {
    window.removeEventListener('popstate', eventListener);
  }
});
