/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, styled } from 'fierry/react';
import { List, Item, Link, Bold } from 'galax/react/element/welcome';


const WelcomeContainer = styled.div`
  user-select: text;
`;


export default (props) =>
(
  <WelcomeContainer>

    <Modern.Card spacing = '1'>
      <Modern.Text param>These Terms of Service (“<Bold>Terms</Bold>”) govern your access to and use of our services, including our websites, online games, applications, forums and email notifications (collectively, the “<Bold>Services</Bold>”). By using the Services, you agree to these Terms and our Privacy Statement (the “<Bold>Agreement</Bold>”). If you do not agree to both our Terms and the Privacy Statement you may not use the Services.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>WHO MAY USE THE SERVICES</Modern.Text>
      <Modern.Text param>You may use the Services only if you agree to form a binding contract with Fierry, LLC (“<Bold>Fierry</Bold>”) and are not a person barred from receiving services under the laws of the applicable jurisdiction. In any case, you must be at least thirteen (13) years of age, or sixteen (16) years of age if you live in the European Union, to use the Services.</Modern.Text>
      <Modern.Text param>The Services are solely intended for your personal entertainment and non-commercial use. Exceptions to this require the express, prior written consent of Fierry.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>PRIVACY & COOKIES</Modern.Text>
      <Modern.Text param>Our “<Bold>Privacy Statement</Bold>” (<Link href = 'https://fierry.com/#privacy' target = '_blank' title = 'Privacy Statement'>fierry.com/#privacy</Link>) describes how we handle the information you provide to us when you use our Services. You must consent to the collection and use (as set forth in the Privacy Statement) of this information, including the transfer of this information to the United States, and/or other countries for storage, processing and use by Fierry and its affiliates.</Modern.Text>
      <Modern.Text param>We utilize cookies and similar technologies to recognize you when visiting our website or using our Services. Please review the “<Bold>Cookies</Bold>” section of our Privacy Statement which further explains our use of these technologies, why we use them, your rights to control, and your information sent to us.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>USING THE SERVICES</Modern.Text>
      <Modern.Text param>Our Services evolve constantly. As such, the Services may change from time to time, at our discretion. We may stop (permanently or temporarily) providing the Services or any features within the Services to you or to users generally. We reserve the right to interrupt the operation of the Services with or without prior notice for any or no reason, including, but not limited to: (i) fixing urgent problems, (ii) necessary maintenance work, (iii) service updates. We retain the right to create limits on use and storage at our sole discretion. We may also remove or refuse to distribute any Content on the Services, suspend or terminate users, and reclaim usernames without liability to you.</Modern.Text>
      <Modern.Text param>In consideration for Fierry granting you access to and use of the Services, you agree not to misuse our Services (“<Bold>Prohibited Use</Bold>”), for example, by interfering with them or accessing them using a method other than the interface and the instructions that we provide. You may not do any of the following while accessing or using the Services: (i) access, tamper with, or use non-public areas of the Services, Fierry’s computer systems, or the technical delivery systems of Fierry’s providers; (ii) probe, scan, or test the vulnerability of any system or network, or breach, or circumvent any security or authentication measures; (iii) use any robot, spider, scraper or other automated means to access the Services; (iv) use any data mining, data gathering or extraction method; (v) forge any TCP/IP packet header or any part of the header information in any email or posting, or use the Services to send altered, deceptive or false source-identifying information; (vi) decompile, reverse engineer or disassemble any software or other products or processes accessible through the Services; (vii) insert any code or product or manipulate the content of the Services in any way; or (viii) interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Services, or by scripting the usage of Services in such a manner as to interfere with or create an undue burden on the Services. Any Prohibited Use of the Services is strictly prohibited and will terminate all licenses granted.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Your Account</Modern.Text>
      <Modern.Text param>You may need to create an account to use some of our Services. You are responsible for protecting your account. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above. Your account may not be transferred or made available to other parties without the consent of Fierry. You must notify us immediately upon learning of any breach of security or unauthorized use of your account.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Your License to Use the Services</Modern.Text>
      <Modern.Text param>Conditioned on your agreement to these Terms we grant you a personal, worldwide, non-exclusive, non-assignable, revocable limited license to access and use our Services (“<Bold>License</Bold>”). Unless explicitly stated, nothing in these Terms shall be construed to grant you any right or license to our, or any third-party, intellectual property (defined below). You may use the Services only in compliance with these Terms and all applicable laws, rules and regulations.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Communication Preferences</Modern.Text>
      <Modern.Text param>You can control most communications from the Services. We may need to provide you with certain communications, such as service announcements, administrative messages and requests for feedback. These communications are considered part of the Services and your account, and you may not opt-out from receiving them.</Modern.Text>
      <Modern.Text param>We will send you information relating to your account in electronic form only, for example via emails to your email address provided during registration. You agree that any notices, agreements, disclosures or other communications we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>User Feedback</Modern.Text>
      <Modern.Text param>Fierry is free to use comments, questions, suggestions, ideas, techniques, original or creative materials or other information about our Services (“<Bold>Feedback</Bold>”), including responses to questionnaires or through postings to the Services, worldwide and in perpetuity without further compensation, acknowledgement or payment to you for any purpose whatsoever including, but not limited to, developing, manufacturing and marketing products and creating, modifying or improving Services.</Modern.Text>
      <Modern.Text param>By providing Feedback you warrant and represent that you own or otherwise control all rights to the Feedback, including without limitation all rights necessary for you to provide the Feedback. Further, you warrant and represent that we are not under any obligation of confidentiality relating to any Feedback. You agree not to enforce any "moral rights" in and to the Feedback, to the extent permitted by applicable law.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>CONTENT ON THE SERVICES</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Company Content</Modern.Text>
      <Modern.Text param>“<Bold>Company Content</Bold>” includes all information, text, graphics, user and visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code, including the design, structure, selection, coordination, expression, look and feel and arrangement of content which we have created, provided, uploaded as part of the Services. Company Content may not be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed for any commercial publication or distribution without our express prior written consent. You may remove no proprietary marks or notices or use Company Content for commercial purposes. We do not warrant the accuracy, completeness, or usefulness of Company Content.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>User Content</Modern.Text>
      <Modern.Text param>“<Bold>User Content</Bold>” is what you and other users create, generate, post, share or store when interacting and using the Services. You represent and warrant that you have obtained all rights, licenses, consents, permissions, power and/or authority and grant Fierry a worldwide, non-exclusive, non-revocable, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute User Content in any media or distribution methods (now known or later developed) without limitation and for any purpose.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Prohibited Use of Content and Services</Modern.Text>
      <Modern.Text param>The following uses are strictly prohibited and may result in a termination of your account:</Modern.Text>
      <Modern.Text param>
        <List>
          <Item>You may not threaten, insult, harass or incite violence against an individual or a group of people (including on the basis of race, ethnicity, national origin, sexual orientation, gender, gender identity, religious affiliation, age, disability, or serious disease).</Item>
          <Item>You may not threaten or promote terrorism or violent extremism.</Item>
          <Item>You may not promote or encourage suicide or self-harm.</Item>
          <Item>You may not promote or disseminate any political, religious or sexual content.</Item>
          <Item>You may not exploit, harm or attempt to exploit or harm minors in any way by exposing them to inappropriate content or otherwise. There is zero tolerance for child sexual exploitation.</Item>
          <Item>You may not use offensive, vulgar or obscene language.</Item>
          <Item>You may not use our Services for any unlawful purpose or to further illegal activities.</Item>
          <Item>You may not infringe on trademarks, patents, copyright, other industrial property rights, business secrets or other third-party rights.</Item>
          <Item>You may not publish third parties private or personal information without their consent (such as phone number, home address or intimate photos or videos of someone).</Item>
          <Item>You may not impersonate individuals, groups of people or entities (including Fierry, Fierry employees) in a way that is intended to mislead, confuse or deceive others.</Item>
          <Item>You may not promote or disseminate any advertising or promotional material, including any “junk mail, “chain letters,” “spam”, or any other similar solicitation.</Item>
          <Item>You may not engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Services, or which, as determined by us, may harm or offend Fierry or users of Services or expose them to liability.</Item>
        </List>
      </Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Third-Party Content</Modern.Text>
      <Modern.Text param>You understand that by using the Services, you may be exposed to potentially offensive, harmful, inaccurate, deceptive or otherwise inappropriate content posted by another Fierry user (“<Bold>Third-Party Content</Bold>”). Any use or reliance on Third-Party Content is at your own risk. Fierry does not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any Third-Party Content.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Our Rights Concerning User and Third-Party Content</Modern.Text>
      <Modern.Text param>Although we have no obligation to screen, edit or monitor User Content, we reserve the right to access, read, edit, remove, preserve, and disclose User Content we reasonably believe is necessary to: (i) satisfy any applicable law, regulation, legal process or governmental request; (ii) enforce these Terms, including investigation of potential violations; (iii) detect, prevent, or otherwise address fraud, security or technical issues; (iv) respond to user support requests; or (v) protect the rights, property or safety of Fierry, its users and the public. Fierry does not disclose personally-identifying information to third parties except in accordance with our Privacy Statement.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>RULES FOR ONLINE GAMES</Modern.Text>
      <Modern.Text param>You may participate in each round of an online game (“<Bold>Online Game</Bold>”) using one user account only. It is not permissible to use multiple user accounts.</Modern.Text>
      <Modern.Text param>You are prohibited from manipulating Online Games in any way, in particular, employing measures, mechanisms or software that could interfere with the functionality or the course of the Services, or taking any actions which could cause an unreasonable or excessive load on the technical capacities.</Modern.Text>
      <Modern.Text param>You are prohibited from running Online Games apart from the internet browser or the supported client applications. This especially refers to bots or other tools designed to replace or augment the web interface.</Modern.Text>
      <Modern.Text param>You may not exploit any bugs in our Services or use scripts and programs to gain an unfair advantage over other users. This includes auto-refresh functions and other integrated mechanisms of the internet browser to the extent these concern automated processes. Login is only permitted via the Services.</Modern.Text>
      <Modern.Text param>You may not communicate the existence of any exploitable bug to others directly or through public forums. Bugs should be reported through email to <Link href='mailto:feedback@fierry.com' target = '_blank' title = 'Feedback email'>feedback@fierry.com</Link>.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>LINKING; THIRD-PARTY CONTENT AND SERVICES</Modern.Text>
      <Modern.Text param>Our Services may contain links to third-party websites or services not owned or controlled by Fierry. If you access third-party website from our Services, you acknowledge that: (i) Fierry, as an intermediary service provider does not control the website and accepts no responsibility or liability for, third-party content and services; (ii)  the website may collect data or solicit personal information from you in which case Fierry is not responsible for how your personal information is collected, processed, stored or used; and (iii) it is your responsibility to read the terms of service  and privacy statement for each third-party website or service you access through our Services.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>TERMINATION</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Termination of Services</Modern.Text>
      <Modern.Text param>We reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to determine if an account should be terminated or suspended. We reserve the right to terminate or suspend an account without prior notice and at our sole discretion (or cease providing you with all or part of the Services) at any time for any or no reason, including, but not limited to, if we reasonably believe: (i) you have violated these Terms, (ii) you create risk or possible legal exposure for us; (iii) your account should be removed due to prolonged inactivity; (iv) we receive a request from an authority or governmental agency; or (v) our provision of the Services to you is no longer commercially viable. We will make reasonable efforts to notify you via the email address associated with your account or the next time you attempt to access your account, depending on the circumstances.</Modern.Text>
      <Modern.Text param>Upon termination we reserve the right to delete all User Content and reclaim usernames without liability to you, and to further take legal action against you because of your violation of these Terms, we may recover, and you agree to pay reasonable attorneys’ fees and costs incurred from such action.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>User Right To Terminate</Modern.Text>
      <Modern.Text param>You may end your legal agreement with Fierry at any time by deactivating your account and discontinuing your use of the Services. You can request deactivation of your account by sending an email to <Link href='mailto:support@fierry.com' target = '_blank' title = 'Support email'>support@fierry.com</Link>.</Modern.Text>
      <Modern.Text param>We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal requirements, we will delete your personal data within 90 days of cancellation or termination. Please review the “DATA STORAGE, RETENTION AND DELETION” section of our Privacy Statement for more details.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>INTELLECTUAL PROPERTY AND DMCA NOTICE</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Fierry Intellectual Property</Modern.Text>
      <Modern.Text param>Fierry “<Bold>Intellectual Property</Bold>” includes the Services, the website, its features and functionality,  Company Content, software, text, displays, images, video, audio and design. Fierry, its licensors own all Intellectual Property which is protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property rights.</Modern.Text>
      <Modern.Text param>Nothing in these Terms gives you a right to use the Fierry name or any of the Fierry trademarks, logos, domain names, and other distinctive brand features. All right, title and interest in and to the Services and Company Content are and will remain the exclusive property of Fierry and its licensors.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Use of Fierry Intellectual Property</Modern.Text>
      <Modern.Text param>You may only use the Services and Company Content for personal non-commercial use as granted in the License. You cannot copy, reproduce, print, download transmit, distribute, modify or create any derivative works of the Services or Company Content. You must not remove or alter copyright, trademark or other proprietary rights, marks or notices. All content, including Company Content, that you download, or share may not be reproduced, transmitted, distributed or otherwise provided or used for any commercial purpose. You will not delete or alter any proprietary rights or attribution notices in any content.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Use Restrictions</Modern.Text>
      <Modern.Text param>The “<Bold>fierry.com</Bold>”, “<Bold>Fierry</Bold>” names, and any other Fierry product or service names, logos or slogans that may appear on our website, products or Services are trademarks of Fierry and may not be copied, imitated or used, in whole or in part, without our prior written permission. You may not use any metatags or other “hidden text” utilizing “Fierry” or any other name, trademark or product or service name of Fierry without our prior written permission. In addition, the look and feel of the Services including, without limitation, all page headers, custom graphics, button icons and scripts, constitute the service mark, trademark or trade dress of Fierry and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and company names or logos mentioned on the site or associated with a product or service offered are the property of their respective owners and may not be copied, imitated or used, in whole or in part, without the permission of the trademark holder.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Digital Millennium Copyright Act</Modern.Text>
      <Modern.Text param>We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with the law. If you believe any materials accessible on or from the Site infringe your copyright and/or trademark you own or control, you may file a notification of such infringement with our designated agent at Fierry: Marek Kuzora, 5555 14th Ave NW Unit 226 Seattle, WA 98107. Your notification should contain:</Modern.Text>
      <Modern.Text param>
        <List>
          <Item>A physical or electronic signature of the owner, or a person authorized to act on behalf of the owner of the copyright allegedly infringed;</Item>
          <Item>Identification of the copyrighted work claimed to have been infringed;</Item>
          <Item>Identification, with information reasonably sufficient to allow its location, of the material claimed to be infringing;</Item>
          <Item>Information reasonably sufficient to permit us to contact you;</Item>
          <Item>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;</Item>
          <Item>A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</Item>
        </List>
      </Modern.Text>
      <Modern.Text param>You acknowledge that if you: (i) fail to comply with the requirements of this policy, your DMCA notice may not be valid; and (ii) knowingly make a material misrepresentation in your notification, you will be liable for any damages, including, without limitation, costs and attorneys’ fees, incurred by us or the alleged infringer as the result of our relying upon such misrepresentation in removing or disabling access to the material or activity claimed to be infringing.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>DISCLAIMERS AND LIMITATIONS OF LIABILITY</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Disclaimer</Modern.Text>
      <Modern.Text param>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW FIERRY: (A) DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE; (B) DOES NOT PROMISE THE SERVICES OR ANY COMPANY CONTENT, OR FEATURE WILL BE ERROR-FREE OR UNINTERRUPTED, THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE WILL PROVIDE SPECIFIC RESULTS. THE SERVICES AND ALL COMPANY CONTENT ARE DELIVERED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS; AND (C) CANNOT ENSURE ANY COMPANY CONTENT, FILE OR OTHER DATA WILL BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES; (D) DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD-PARTY IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SERVICES.</Modern.Text>
      <Modern.Text param>YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SERVICES AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST FIERRY FOR DISSATISFACTION WITH THE SERVICES OR ANY COMPANY CONTENT IS TO STOP USING THE SITE.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Limitations on Damages</Modern.Text>
      <Modern.Text param>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL FIERRY BE LIABLE FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO LOSS OF REVENUE, INCOME OR PROFITS, LOSS OF USE OR DATA, LOSS OR DIMINUTION IN VALUE OF ASSETS OR SECURITIES, OR DAMAGES FOR BUSINESS INTERRUPTION) ARISING OUT OF OR IN ANY WAY RESULTING FROM THE ACCESS OR USE OF THE SERVICES INCLUDING BUT NOT LIMITED TO ANY DAMAGES CAUSED FROM USING THE SERVICES INCLUDING FROM FIERRY’S MISTAKES, OMISSIONS, INTERRUPTIONS, DELETIONS OF FILES OR USER CONTENT, ERRORS, DEFECTS, BUGS, VIRUSES, TROJAN HORSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO FIERRY’ RECORDS, PROGRAMS OR SYSTEMS. REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), STRICT PRODUCT LIABILITY OR ANY OTHER LEGAL OR EQUITABLE THEORY (EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE).</Modern.Text>
      <Modern.Text param>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Sole Remedy</Modern.Text>
      <Modern.Text param>YOU ACKNOWLEDGE AND AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH FIERRY IS TO STOP USING THE SERVICE, AND TO CANCEL ANY AND ALL OF YOUR ACCOUNTS, IF APPLICABLE. YOU ACKNOWLEDGE AND AGREE THAT FIERRY IS NOT LIABLE FOR ANY ACT OR FAILURE TO ACT ON ITS OWN PART, OR FOR ANY CONDUCT OF, OR COMMUNICATION OR CONTENT POSTED BY FIERRY OR OTHER USERS. IN NO EVENT SHALL FIERRY OR ITS EMPLOYEES', CONTRACTORS', OFFICERS', DIRECTORS' OR SHAREHOLDERS’ LIABILITY TO YOU EXCEED THE AMOUNT THAT YOU PAID TO FIERRY FOR YOUR USE OF THE SERVICES. IN NO CASE SHALL FIERRY OR ITS EMPLOYEES, CONTRACTORS, OFFICERS, DIRECTORS OR SHAREHOLDERS BE LIABLE FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING FROM YOUR USE OF THE SERVICES. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, SUCH LIABILITY SHALL BE LIMITED TO THE FULL EXTENT PERMITTED BY LAW.</Modern.Text>
      <Modern.Text param>YOU FURTHER SPECIFICALLY ACKNOWLEDGE THAT FIERRY IS NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD FIERRY LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OTHER USERS OF THE SERVICES AS WELL AS OPERATORS OF SOCIAL NETWORKING AND OTHER EXTERNAL SITES, AND YOU ACKNOWLEDGE THE RISK OF USING OR ACCESSING OUR SERVICES, USING SOCIAL NETWORKING SITES AND OTHER EXTERNAL SITES, AND OF INJURY FROM THE FOREGOING, RESTS ENTIRELY WITH YOU.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Indemnification</Modern.Text>
      <Modern.Text param>You agree to defend, indemnify and hold harmless Fierry, its affiliates, licensors, service providers, employees, contractors, suppliers, officers or directors against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms, your use of the Services or any User Content you submit. Fierry reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with Fierry in asserting any defenses.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>CHANGES AND REVISIONS</Modern.Text>
      <Modern.Text param>We may revise these Terms from time to time. The changes will not be retroactive, and the most current version of the Terms will govern our relationship with you. We will try to notify you of material revisions, for example via a service notification or an email to the email associated with your account. By continuing to access or use the Services after those revisions become effective, you agree to be bound by the revised Terms.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>GOVERNING LAW, VENUE AND STATUTE OF LIMITATIONS</Modern.Text>
      <Modern.Text param>These Terms and the rights of the parties shall be governed by and construed under the laws of the State of Washington, exclusive of conflict or choice of law rules. The parties acknowledge these Terms evidence a transaction involving interstate commerce. Notwithstanding the provision in the preceding paragraph regarding substantive law, any arbitration conducted under these Terms shall be governed by the Federal Arbitration Act (9 U.S.C., §§ 1-16). You and Fierry irrevocably consent to the exclusive jurisdiction and venue of the state or federal courts in King County, Washington, for all disputes arising out of or relating to these Terms, the subject of these Terms, or your access to and use of any Fierry service, that are heard in court (not arbitration).</Modern.Text>
      <Modern.Text param>YOU AND FIERRY AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO THESE TERMS OR SERVICES MUST BE FILED WITHIN ONE YEAR AFTER SUCH CLAIM AROSE; AND IS THEREAFTER FOREVER BARRED.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>DISPUTE RESOLUTION BY BINDING ARBITRATION</Modern.Text>
      <Modern.Text param>Most customer concerns can be resolved quickly and to the customer’s satisfaction by contacting our customer service department at <Link href='mailto:support@fierry.com' target = '_blank' title = 'Support email'>support@fierry.com</Link>.</Modern.Text>
      <Modern.Text param>PLEASE READ THIS "DISPUTE RESOLUTION BY BINDING ARBITRATION" PROVISION CAREFULLY, BECAUSE IT REQUIRES YOU TO ARBITRATE DISPUTES WITH FIERRY AND IT LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF.</Modern.Text>
      <Modern.Text param>THIS PROVISION PRECLUDES YOU FROM BRINGING ANY CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION AGAINST FIERRY. IT ALSO PRECLUDES YOU FROM PARTICIPATING IN OR RECOVERING RELIEF UNDER ANY CURRENT OR FUTURE CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION AGAINST FIERRY BY SOMEONE ELSE. IN ADDITION, ARBITRATION PRECLUDES YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL.</Modern.Text>
      <Modern.Text param>WHETHER TO AGREE TO ARBITRATION IS AN IMPORTANT DECISION. IT IS YOUR DECISION TO MAKE AND YOU SHOULD NOT RELY SOLELY ON THE INFORMATION PROVIDED IN THIS AGREEMENT, AS IT IS NOT INTENDED TO CONTAIN A COMPLETE EXPLANATION OF THE CONSEQUENCES OF ARBITRATION. YOU SHOULD TAKE REASONABLE STEPS TO CONDUCT FURTHER RESEARCH AND TO CONSULT WITH OTHERS REGARDING THE CONSEQUENCES OF YOUR DECISION. YOU MAY OPT OUT OF THIS ARBITRATION PROVISION BY FOLLOWING THE INSTRUCTIONS BELOW.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Scope of Arbitration Provision</Modern.Text>
      <Modern.Text param>You and Fierry agree that any dispute, claim or controversy arising out of or relating to your access to or use of any Fierry Services or to these Terms of Service, (including without limitation any dispute about the breach, enforcement, construction, validity, interpretation, enforceability, or arbitrability of these Terms of Service) (a "<Bold>Dispute</Bold>"), no matter when such Dispute arose or arises, shall be determined by arbitration, except that you and Fierry need not arbitrate any Dispute in which either party seeks equitable and other relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, or patents.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Location of Arbitration and Applicable Rules</Modern.Text>
      <Modern.Text param>You and Fierry agree that such arbitration shall occur in King County, Washington. You may request to appear in such proceedings telephonically. You and Fierry agree that such arbitration shall be conducted by a single arbitrator under the rules of the Judicial Arbitration and Mediation Service ("<Bold>JAMS</Bold>"), as modified by these Terms of Service.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Authority of Arbitrator</Modern.Text>
      <Modern.Text param>Except for class procedures and remedies as discussed below under "Waiver of Class Relief," the arbitrator shall have the authority to grant any remedy that would otherwise be available in court.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Confidentiality</Modern.Text>
      <Modern.Text param>You and Fierry shall maintain the confidential nature of the arbitration proceedings and the arbitration award, including the arbitration hearing, except as may be necessary to prepare for or conduct the arbitration hearing on the merits, or except in connection with a court application for a preliminary remedy, a judicial challenge to an award or its enforcement, or unless otherwise required by law or judicial decision.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Allocation of Arbitration Fees</Modern.Text>
      <Modern.Text param>If you assert a Dispute as a consumer, you will only be required to pay arbitration fees of $250 of the fees charged by JAMS with any arbitration under this section, and Fierry will bear all other costs charged by JAMS or the arbitrator, including any remaining JAMS Case Management Fee and all professional fees for the arbitrator's services. You will still be responsible for paying your own attorneys’ fees.</Modern.Text>
      <Modern.Text param>WAIVER OF CLASS RELIEF. WHETHER THE DISPUTE IS HEARD IN ARBITRATION OR IN COURT, YOU AGREE THAT YOU AND FIERRY WILL NOT COMMENCE AGAINST THE OTHER A CLASS ACTION, CLASS ARBITRATION OR OTHER REPRESENTATIVE ACTION OR PROCEEDING. YOU AND FIERRY ARE EACH WAIVING RESPECTIVE RIGHTS TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY PAST, PENDING, OR FUTURE CLASS ACTION OR ANY OTHER CONSOLIDATED OR REPRESENTATIVE PROCEEDING.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '6'>Procedure to Opt Out of Arbitration Provision</Modern.Text>
      <Modern.Text param>You may opt out of this arbitration provision only by written Notice via U.S. Mail, or by any nationally recognized delivery service (e.g., UPS, Federal Express, etc.) to Fierry, Attn: Support at 5555 14th Ave NW Unit 226 Seattle, WA 98107. You must send such Notice within thirty (30) days of your acceptance of these Terms of Service. You must sign and date the Notice, and include in it your name, address, and a clear statement you do not wish to resolve disputes with Fierry through arbitration. If you do not follow this procedure by your thirty (30) day deadline to do so, then you and Fierry shall both be bound by the terms of this section entitled Dispute Resolution by Binding Arbitration.</Modern.Text>
      <Modern.Text param>If any portion of this section entitled "Dispute Resolution by Binding Arbitration" is determined by a court to be inapplicable or invalid, then the remainder shall still be given full force and effect.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>SEVERABILITY AND ENTIRE AGREEMENT</Modern.Text>
      <Modern.Text param>Except as otherwise provided in these Terms, if any provision of these Terms is held to be invalid, void or unenforceable, such provision shall be struck out and shall not affect the validity and enforceability of the remaining provisions. These Terms including the Privacy Statement set forth the entire understanding between you and Fierry and supersede all prior understandings and agreements of the parties. For purposes of this Agreement the following terms shall survive termination of this Agreement: Disclaimer and Limitations of Liability; Arbitration; and Intellectual Property.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text headline = '5'>CALIFORNIA NOTICE</Modern.Text>
      <Modern.Text param>IF YOU ARE A CALIFORNIA RESIDENT, UNDER CAL. CIV. CODE §1789.3, YOU MAY REPORT COMPLAINTS TO THE COMPLAINT ASSISTANCE UNIT OF THE DIVISION OF CONSUMER SERVICES OF THE CALIFORNIA DEPARTMENT OF CONSUMER AFFAIRS BY CONTACTING THEM IN WRITING AT 1625 N. MARKET BLVD., SUITE N-112, SACRAMENTO, CALIFORNIA 95834, OR BY TELEPHONE AT 1 (800) 952-5210.</Modern.Text>
    </Modern.Card>

    <Modern.Card spacing = '1'>
      <Modern.Text param>If you have any questions or concerns about the Terms of Service please email us at <Link href='mailto:support@fierry.com' target = '_blank' title = 'Support email'>support@fierry.com</Link>.</Modern.Text>
      <Modern.Text param><Bold>Effective</Bold> Oct 21, 2019.</Modern.Text>
      <Modern.Text param><Bold>Copyright ©</Bold> 2019 Fierry, LLC. All rights reserved.</Modern.Text>
    </Modern.Card>

  </WelcomeContainer>
);
