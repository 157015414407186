/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Interaction from 'galax/server/interaction';

import { getShapeToCount } from 'galax/enum/shape';
import * as Influence from 'galax/util/influence';


export default class extends Interaction
{
  installTargetPlanet (planet)
  {
    const influence = Influence.makeAttackInfluence(this.cass.user, this.cass.source, this.cass.args.value);

    planet.getAttributes().installDirectInfluence(this.cass.target, this.cass.shape, influence);
  }


  installTargetSpaceship (spaceship)
  {
    const influence = Influence.makeAttackInfluence(this.cass.user, this.cass.source, this.__getTotalValue__());

    spaceship.installInfluence(influence);
  }


  installSourcePlanet (planet)
  {
  }


  installSourceSpaceship (spaceship)
  {
    /*const influence = Influence.makeAttritionInfluence(this.cass.source, this.__getTotalValue__());

    spaceship.installInfluence(influence);*/
  }


  __getTotalValue__ ()
  {
    const { shape, args } = this.cass;

    return getShapeToCount(shape.type) * args.value;
  }


};


