/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Element from 'fierry/rpc/element';


export default class extends Element
{
  constructor (sourceElement)
  {
    super();

    this.__sourceElement__ = sourceElement;
  }


  onTransitionToGlobal ()
  {
    this.__sourceElement__.createPropagationTarget(this.getRouting());
  }


  onTransitionToLocal ()
  {
    this.__sourceElement__.destroyPropagationTarget(this.getRouting());
  }


  getLocalState ()
  {
    return this.__sourceElement__.isStateStable() ? this.__sourceElement__.getState() : null;
  }


};
