/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import SpendingEnergyEntry from 'galax/server/sector/distribution/spending.energy.entry';


export default class
{
  constructor (source, zones, value)
  {
    this.source = source;

    this.collection = [];

    zones.getSplashDistribution(this.source).eachZone( (zone, weight) =>
    {
      this.collection.push(new SpendingEnergyEntry(zone, weight));
    });

    this.distributeValue(value);

    while (!this.isDistributionBalanced())
    {
      this.distributeValue(this.clearOverflow());
    }
  }


  distributeValue (value)
  {
    let totalWeight = this.getTotalWeight();

    if (totalWeight === 0)
    {
      return;
    }

    for (const entry of this.collection)
    {
      if (entry.isAvailable() && entry.getWeight !== 0)
      {
        const entryValue = Math.round(value * entry.getWeight() / totalWeight);

        value -= entryValue;
        totalWeight -= entry.getWeight();

        entry.distributeEnergy(entryValue);
      }
    }
  }


  clearOverflow ()
  {
    let value = 0;

    for (const entry of this.collection)
    {
      value += entry.clearOverflow();
    }

    return value;
  }


  isDistributionBalanced ()
  {
    for (const entry of this.collection)
    {
      if (entry.isOverflow())
      {
        return false;
      }
    }

    return true;
  }


  canSpendEnergy ()
  {
    const isSourceCritical = this.getEntryByZone(this.source).isCritical();

    return !isSourceCritical && this.getCriticalCount() < this.getMajorityCount() && this.getChallengerCount() < this.getMajorityCount();
  }


  getTotalWeight ()
  {
    let value = 0;

    for (const entry of this.collection)
    {
      if (entry.isAvailable())
      {
        value += entry.getWeight();
      }
    }

    return value;
  }


  getCriticalCount ()
  {
    let count = 0;

    for (const entry of this.collection)
    {
      if (entry.isCritical())
      {
        ++count;
      }
    }

    return count;
  }


  getChallengerCount ()
  {
    let count = 0;

    for (const entry of this.collection)
    {
      if (entry.isChallenger())
      {
        ++count;
      }
    }

    return count;
  }


  getMajorityCount ()
  {
    return Math.ceil(0.5 * this.collection.length);
  }


  getEntryByZone (zone)
  {
    for (const entry of this.collection)
    {
      if (entry.getZone() === zone)
      {
        return entry;
      }
    }

    throw new Error('Entry not found for zone: ' + zone.getType().getID())
  }


}
