/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Client } from 'fierry/flux';


export default new Client([ ], class
{
  constructor ()
  {
  }


  canSelect ()
  {
    return false;
  }


  canNavigate ()
  {
    return true;
  }


  isPaneCollapsed ()
  {
    return true;
  }


});
