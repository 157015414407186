/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, withValue } from 'fierry/react';

import DefenceIcon from 'svg/shared/modern/defence';
import PercentUnit from 'svg/shared/unit/percent';


const getValue = ({ value, ...props }) =>
{
  return Math.round(100 * withValue(value, props));
}


export default (props) =>
(
  <Element.Group param width = '4' {...props} >
    <Element.Icon value = {<DefenceIcon />} />
    <Element.Text value = {getValue(props)} />
    <Element.Unit value = {<PercentUnit />} />
  </Element.Group>
);
