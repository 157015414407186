/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';


export default (planet) =>
{
  let state = new Immutable.Map();

  for (const entity of planet.getBuildings())
  {
    state = state.set(entity.getID(), entity.getType());
  }

  for (const entity of planet.getResources())
  {
    state = state.set(entity.getID(), entity.getType());
  }

  for (const entity of planet.getUnits())
  {
    state = state.set(entity.getID(), entity.getType());
  }

  return state;
}
