/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import SpaceshipCard from 'galax/react/card/spaceship/overview';
import TargetCard from 'galax/react/card/target';

import Error from 'galax/react/card/shared/error';



const SourceCard = (props) =>
{
  const { currentLocation } = props;

  return <SpaceshipCard {...props} currentSpaceship = {currentLocation.getSpaceship()} />
}


export default (props) =>
(
  <>
    <SourceCard {...props} />
    <TargetCard {...props} />

    <Error {...props} />
  </>
);
