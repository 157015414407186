/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  Overview:            '/',

  BuildingAttack:      '/building.attack',
  BuildingCreateList:  '/building.create',
  BuildingCreateType:  '/building.create/:type',
  BuildingUpgrade:     '/building.upgrade',

  UnitAttack:         '/unit.attack',
  UnitCreateList:     '/unit.create',
  UnitCreateType:     '/unit.create/:type',

  SpaceshipAttack:     '/spaceship.attack',
  SpaceshipCreateList: '/spaceship.create',
  SpaceshipCreateType: '/spaceship.create/:type',
  SpaceshipMove:       '/spaceship.move',

  ProjectileList:      '/projectile.list',

  TransferEdit:        '/sector.transfer.edit',
  TransferList:        '/sector.transfer.list',

  WelcomeLogin:        '/login',
  WelcomeAbout:        '/about',
  WelcomeManual:       '/manual/:index',

  WelcomeTerms:        '/terms',
  WelcomePrivacy:      '/privacy',
};
