/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  Args:       0,
  Background: 1,
  Foreground: 2
};

