/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Entity from 'fierry/flux/entity'

import Query from 'fierry/flux/entity/query'
import Visibility from 'fierry/flux/entity/visibility'


export default class extends Entity
{
  constructor(dependences, entity, predicate)
  {
    super([]);

    this.Entity = entity;

    this.Visibility = new Visibility(dependences, [ entity ], (...dependences) =>
    (
      predicate(...dependences) ? entity : null
    ));
  }


  getEntities ()
  {
    return [this.Visibility, this.Entity];
  }


  getDefaultEntity ()
  {
    return this.Entity;
  }


};
