/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Flux, useFlux } from 'fierry/react';

import Map from 'galax/react/map';
import Theme from 'galax/react/theme';
import Drawer from 'galax/react/drawer';
import Pane from 'fierry/react/skeleton/pane';
import Strip from 'fierry/react/skeleton/strip';
import Scrim from 'fierry/react/skeleton/scrim';
import Banner from 'fierry/react/skeleton/banner';

import GalaxCard from 'galax/react/card';
import GalaxHeader from 'galax/react/header';
import GalaxSidebar from 'galax/react/sidebar';
import GalaxStrip from 'galax/react/strip';

import Rpc from 'galax/rpc';
import ServerRoot from 'galax/server/root';
import Server from 'galax/server';

import OffsetState from 'galax/state/map/offset';
import NavigationState from 'galax/state/navigation';
import NowState from 'galax/state/now';
import SelectionState from 'galax/state/selection';
import ScreenState from 'galax/state/screen';
import ZoomState from 'galax/state/zoom';

import DrawerState from 'galax/state/drawer';
import SectorTransferState from 'galax/state/optional/sector.transfer';

import BehaviorSelect from 'galax/behavior';
import BehaviorClient from 'galax/client/behavior';

import AuthClient from 'fierry/client/auth';
import GridClient from 'galax/client/map/grid';
import OffsetClient from 'galax/client/map/offset';
import TimeClient from 'galax/client/time';
import NavigationClient from 'galax/client/navigation';
import SelectionClient from 'galax/client/selection';
import ScreenClient from 'galax/client/screen';
import ZoomClient from 'galax/client/zoom';

import NowQuery from 'galax/query/now';
import CurrentLocationQuery from 'galax/query/current.location';
import CurrentUserQuery from 'galax/query/current.user';

import ScreenResize from 'galax/event/screen.resize';
import ScreenOnline from 'galax/event/screen.online';
import NowRefresh from 'galax/event/now.refresh';
import VisitorLog from 'galax/event/visitor.log';

import UserLoginObserver from 'galax/observer/user.login';
import UserLogoutObserver from 'galax/observer/user.logout';
import ViewValidateTrigger from 'galax/observer/view.validate.trigger';


const State =
[
  NavigationState,
  SelectionState,
  ZoomState,
  ScreenState,
  NowState,

  OffsetState,

  DrawerState,
  SectorTransferState,
];


const Select =
[
  BehaviorSelect,
];


const Client =
[
  TimeClient,
  NavigationClient,
  SelectionClient,
  ZoomClient,
  ScreenClient,
  BehaviorClient,

  GridClient,
  OffsetClient,
];


const Query =
[
  NowQuery,
  CurrentLocationQuery,
  CurrentUserQuery,
];


const Event =
[
  ScreenResize,
  ScreenOnline,
  NowRefresh,
  VisitorLog,
];


const AuthBarrier = (props) =>
{
  const [ auth ] = useFlux(AuthClient);

  return auth.isQueryComplete() ? props.children : null;
}


export default () =>
(
  <Flux dependences = {[ Rpc, ServerRoot, Server, ...State, ...Select, ...Client, ...Query, ...Event, ]} >

    <UserLoginObserver />
    <UserLogoutObserver />
    <ViewValidateTrigger />

    <AuthBarrier>

      <Map />

      <Theme>

        <Pane
          card = {GalaxCard}
          header = {GalaxHeader}
          sidebar = {GalaxSidebar}
        />

        <Strip
          content = {GalaxStrip}
        />

        <Drawer />
        <Scrim />
        <Banner />

      </Theme>

    </AuthBarrier>

  </Flux>
);
