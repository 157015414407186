/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, Modern } from 'fierry/react';

import EnergyElement from 'galax/react/element/energy';

import { getShapeToCount } from 'galax/enum/shape';

import * as InfluenceValue from 'galax/value/influence';

import HealthIcon from 'svg/shared/modern/health';
import RangeIcon from 'svg/shared/modern/attack-stroke';
import DamageIcon from 'svg/shared/modern/damage';
import DefenceIcon from 'svg/shared/modern/defence';

import MultiplyUnit from 'svg/shared/unit/multiply';
import PercentUnit from 'svg/shared/unit/percent';
import MinusUnit from 'svg/shared/unit/minus';
import PerUnit from 'svg/shared/unit/per';
import HourUnit from 'svg/shared/unit/hour';


const getHealth = ({ constants, unit, now }) =>
{
  return unit ? unit.getEnergy(now) : constants.getEnergy();
}


const HealthElement = (props) =>
(
  <EnergyElement icon = {<HealthIcon />} energy = {getHealth(props)} />
)


// getTargetShape // getPrimaryAttack to percent ?
const DamageElement = ({ constants }) =>
(
  <Element.Group width = '5.5' param tooltip = 'Target Count x Damage' >
    <Element.Icon value = {<DamageIcon />} />
    <Element.Text value = {getShapeToCount(constants.getTargetShape())} />
    <Element.Unit value = {<MultiplyUnit />}/>
    <Element.Text value = {InfluenceValue.toPercentage(constants.getPrimaryAttack())} />
    <Element.Unit value = {[ <PerUnit />, <HourUnit /> ]} />
  </Element.Group>
);


const RangeElement = ({ constants }) =>
(
  <Element.Group width = '4.5' param tooltip = 'Min - Max Range' >
    <Element.Icon value = {<RangeIcon />} />
    <Element.Text value = {constants.getTargetMinRange()} />
    <Element.Icon value = {<MinusUnit />} />
    <Element.Text value = {constants.getTargetMaxRange()} />
  </Element.Group>
);


const Component = (props) =>
(
  <Modern.Group spacing = '2.5'>

    <HealthElement {...props} />
    <DamageElement {...props} />
    <RangeElement {...props} />

  </Modern.Group>
);


export default (props) =>
{
  return <Component {...props} />;
}
