/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Time from 'fierry/util/time';


export default class
{
  constructor (status, parent)
  {
    this.status = status;
    this.parent = parent;
  }


  getProgressPercentage (date)
  {
    /*if (this.isDefault())
    {
      return 0;
    }*/

    return this.getEndDate() ?  (1 - this.getRemainingTime(date) / this.getTotalTime()) : 1;
  }


  getTotalTime ()
  {
    if (this.getStartDate() && this.getEndDate())
    {
      return this.getEndDate() - this.getStartDate();
    }

    return 0;
  }


  getRemainingTime (date)
  {
    if (this.getEndDate())
    {
      return this.getEndDate() - date;
    }

    if (this.getStartDate())
    {
      return date - this.getStartDate();
    }

    return 0;
  }


  getStartDate ()
  {
    return (this.status != null) ? this.status.date : null;
  }


  getEndDate ()
  {
    return (this.status != null && this.status.event != null) ? this.status.event.date : null;
  }


  getParent ()
  {
    return this.parent;
  }


  isDefault ()
  {
    return !this.isTerminal() && this.status.type === 'default';
  }


  isTerminal ()
  {
    return this.status == null;
  }


  isType (type)
  {
    return !this.isTerminal() && this.status.type === type;
  }


};
