/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import RouteEnum from 'galax/enum/route';

import DefaultValidator from 'galax/validator';

import BuildingAttackValidator from 'galax/validator/building.attack';
import BuildingCreateListValidator from 'galax/validator/building.create.list';
import BuildingCreateTypeValidator from 'galax/validator/building.create.type';
import BuildingUpgradeValidator from 'galax/validator/building.upgrade';

import UnitAttackValidator from 'galax/validator/unit.attack';
import UnitCreateListValidator from 'galax/validator/unit.create.list';
import UnitCreateTypeValidator from 'galax/validator/unit.create.type';

import SpaceshipAttackValidator from 'galax/validator/spaceship.attack';
import SpaceshipCreateListValidator from 'galax/validator/spaceship.create.list';
import SpaceshipCreateTypeValidator from 'galax/validator/spaceship.create.type';

import SpaceshipMoveValidator from 'galax/validator/spaceship.move';

import TransferCreateValidator from 'galax/validator/transfer.create';


const getValidatorClass = (route) =>
{
  switch (route)
  {
    case RouteEnum.BuildingAttack:      return BuildingAttackValidator;
    case RouteEnum.BuildingCreateList:  return BuildingCreateListValidator;
    case RouteEnum.BuildingCreateType:  return BuildingCreateTypeValidator;
    case RouteEnum.BuildingUpgrade:     return BuildingUpgradeValidator;

    case RouteEnum.UnitAttack:          return UnitAttackValidator;
    case RouteEnum.UnitCreateList:      return UnitCreateListValidator;
    case RouteEnum.UnitCreateType:      return UnitCreateTypeValidator;

    case RouteEnum.SpaceshipAttack:     return SpaceshipAttackValidator;
    case RouteEnum.SpaceshipCreateList: return SpaceshipCreateListValidator;
    case RouteEnum.SpaceshipCreateType: return SpaceshipCreateTypeValidator;
    case RouteEnum.SpaceshipMove:       return SpaceshipMoveValidator;

    case RouteEnum.TransferEdit:        return TransferCreateValidator;
  }

  return DefaultValidator;
};


export default (route, args, galaxy, currentLocation, targetLocation) =>
{
  const cls = getValidatorClass(route);

  return new cls(args, galaxy, currentLocation, targetLocation);
}
