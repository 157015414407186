/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useCallback, useFlux, Modern } from 'fierry/react';

import Screen from 'galax/client/screen';

import Menu from 'svg/shared/modern/menu';


const OnClick = (event, screen) =>
{
  screen.setDrawer(true);
}


export default (props) =>
{
  const [ screen ] = useFlux(Screen);

  const onClick = useCallback(OnClick, [ screen ]);

  return <Modern.Button icon = {<Menu />} tooltip = 'Menu' onClick = {onClick} />;
}
