/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { unique } from 'fierry/util/array';


export default class
{
  constructor (scope)
  {
    this.scope = scope;
  }


  getDocks ()
  {
    let collection = [];

    for (const dock of this.getMaybeDocks())
    {
      if (dock)
      {
        const dockSector = dock.getDockSector();

        if (dockSector && dockSector.getID() === this.scope.getID())
        {
          collection.push(dock);
        }
      }
    }

    return collection;
  }


  getMaybeDocks ()
  {
    let collection = [];

    this.scope.getZones().eachZone(zone =>
    {
      for (const dock of zone.getLocation().getNeighbors().getDocks())
      {
        if (dock != null)
        {
          collection.push(dock);
        }
      }
    });

    return unique(collection);
  }


  getNeighborSectorStrength (sector)
  {
    let count = 0;

    this.scope.getZones().eachZone(zone =>
    {
      for (const neighbor of zone.getLocation().getNeighbors().getZones())
      {
        if (neighbor && neighbor.getSector().getCass().getID() === sector.getCass().getID())
        {
          ++count;

          return;
        }
      }
    });

    return count;
  }


  isNeighbor (sector)
  {
    return this.getNeighborSectorStrength(sector) !== 0;
  }


};
