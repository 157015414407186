/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor(dependences = [])
  {
    this.dependences = dependences;
  }


  install (collection)
  {
    // no-op.
  }


  getDependences ()
  {
    return this.dependences;
  }


  getEntities ()
  {
    return [];
  }


  getDefaultEntity()
  {
    return null;
  }


  isExportable ()
  {
    return true;
  }


};
