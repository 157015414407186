/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor ({ constants })
  {
    this.constants = constants;
  }


  getType ()
  {
    return this.constants.type;
  }


  getName ()
  {
    return this.constants.name;
  }


  getDescription ()
  {
    return this.constants.description;
  }


  getTargetShape ()
  {
    return this.constants.target_shape;
  }


  isRangeAttack ()
  {
    return this.getTargetMaxRange() > 1;
  }


  getTargetMinRange ()
  {
    return this.constants.target_min_range;
  }


  getTargetMaxRange ()
  {
    return this.constants.target_max_range;
  }


  getEnergy ()
  {
    return this.constants.energy;
  }


  getAttrition ()
  {
    return this.constants.attrition;
  }


  getPrimaryAttack ()
  {
    return this.constants.attack;
  }


  getSecondaryAttack ()
  {
    return this.getPrimaryAttack();
  }


  getConstructionCost ()
  {
    return this.constants.construction_cost;
  }


  getConstructionTime ()
  {
    // TODO hours(...);
    return this.constants.construction_time;
  }


  getDisbandTime ()
  {
    // TODO hours(...);
    return this.constants.disband_time;
  }


};
