/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const getCenterPosition = (props) =>
{
  const width = getWidth(props);
  const height = getHeight(props);

  const x = - width / 2;
  const y = - height / 2;

  return { x, y, width, height };
}


export const getWidth = (props) =>
{
  return getRatio(props) * getViewBoxWidth(props);
}


export const getHeight = ({ size }) =>
{
  return size;
}


export const getRatio = (props) =>
{
  return getHeight(props) / getViewBoxHeight(props);
}


export const getViewBoxWidth = ({ viewBox }) =>
{
  return viewBox.split(' ')[2];
}


export const getViewBoxHeight = ({ viewBox }) =>
{
  return viewBox.split(' ')[3];
}


export const getRatioLineStroke = (props) =>
{
  const ratio = getRatio(props);

  return ratio < 1 ? 1 / ratio : 1;
}


export const isRatio = (props, ratio) =>
{
  return getRatio(props) === ratio;
}
