/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { styled, css } from 'fierry/react';

import * as EnergyValue from 'galax/value/energy';
import * as InfluenceValue from 'galax/value/influence';


const getEnergyAdvantage = ({ zone }) =>
{
  return EnergyValue.toPercentage(zone.getType().getEnergy(zone.getUserID())) / 100;
  //return EnergyValue.toPercentage(zone.getEnergy().getEnergy(zone.getUserID())) / 100;
}


const getFlowDisadvantage = ({ zone }) =>
{
  throw new Error('Not Implemented');

  /*const owner = zone.getUserID();

  const flow = InfluenceValue.toPercentage(zone.getEnergy().getFlow(owner)) / 100;

  if (zone.getEnergy().isEmptyEnergy(owner) || flow > 0)
  {
    return 0;
  }

  return Math.min(Math.sqrt(-flow) * 4, 1);*/
}


const getBackgroundOpacity = (props) =>
{
  const value = getEnergyAdvantage(props);

  if (value >= 0.95) { return 0.00; }
  if (value >= 0.80) { return 0.05; }
  if (value >= 0.60) { return 0.10; }
  if (value >= 0.50) { return 0.15; }
  if (value >= 0.40) { return 0.20; }
  if (value >= 0.20) { return 0.25; }

  return 0.4;
}


const opacityConfig = (props) => css`
  --svg-opacity: ${getBackgroundOpacity(props)};
`;


const ownerNotWinnerConfig = ({ zone }) => !zone.getEnergy().isCurrentOwnerWinner() && css`
  mask: url(#decrease.stripe);
`;


const Polygon = styled.polygon`
  fill: var(--svg-always-black);
  fill-opacity: var(--svg-opacity);

  ${opacityConfig}
  ${ownerNotWinnerConfig}
`;


const getPolygonPoints = ({ grid, location }) =>
{
  const callback = ({ x, y }) => `${x},${y}`;

  return grid.getLocalHex(location).getZonePoints().map(callback).join(' ');
}


export default (props) =>
(
  <Polygon {...props}
    points = {getPolygonPoints(props)}
  />
);
