/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Status from 'galax/server/status';


export default class extends Status
{
  isConstruction ()
  {
    return this.isType('create') && this.parent.getLevel() === 0;
  }


  isUpgrade ()
  {
    return this.isType('upgrade') && this.parent.getLevel() !== 0;
  }


  isSetup ()
  {
    return this.isType('setup');
  }


  isActive ()
  {
    return this.isType('active');
  }


  isCooldown ()
  {
    return this.isType('cooldown');
  }


  isDisband ()
  {
    return this.isType('disband');
  }


};
