/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 32 32" {...props}><defs/><rect width="32" height="32" className="none"/><g><circle cx="16" cy="16" r="13" className="white-shape"/><g data-name="no-invert line-dash-a"><circle cx="16" cy="16" r="13" className="black-line-20"/></g></g><g><polyline points="9 17.5 17.4 9 23 14.6 14.6 23" className="black-line-10"/><line x1="16" y1="14.5" x2="14" y2="12.5" className="black-line-10"/><line x1="18" y1="19.5" x2="16" y2="17.5" className="black-line-10"/><line x1="12.5" y1="18" x2="10.5" y2="16" className="black-line-10"/></g></Svg>
  );
}
