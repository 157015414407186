/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Element from 'fierry/rpc/element';

import Link from 'fierry/rpc/link.element';
import Collection from 'fierry/rpc/collection-element';
import Record from 'fierry/rpc/record-element';
import Table from 'fierry/rpc/table-element';


const DEFAULT_TRANSLATORS = [];

const DEFAULT_KEY_CALLBACK = (data) => data.id;


export default class extends Element
{
  createLink (name, sourceElement)
  {
    return this.createElement(name, new Link(sourceElement));
  }


  createCollection (name, constructorCallback)
  {
    return this.createElement(name, new Collection(constructorCallback));
  }


  createRecord (name, translators = DEFAULT_TRANSLATORS)
  {
    return this.createElement(name, new Record(translators));
  }


  createTable (name, keyCallback = DEFAULT_KEY_CALLBACK, translators = DEFAULT_TRANSLATORS)
  {
    return this.createElement(name, new Table(keyCallback, translators));
  }


  isReady ()
  {
    for (let element of this.__elements__.values())
    {
      if ((element instanceof Record || element instanceof Table) && !element.isReady())
      {
        return false;
      }
    }

    return super.isReady();
  }


};
