/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react';


const Parent = styled.div`
  display: grid;

  grid-template-areas: "stack";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;


const Child = styled.div`
  grid-area: stack;
`;


export default { Parent, Child };
