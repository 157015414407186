/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useEffect, withMemo } from 'fierry/react';
import { getConfig, getInstance } from 'global/auth';


const LoginComponent = withMemo( (props) =>
{
  useEffect(() =>
  {
    getInstance().reset();
    getInstance().start('#firebase-auth-container', getConfig());
  });

  return <div id = 'firebase-auth-container' />;
});


export default (props) =>
(
  <LoginComponent />
);
