/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  FighterBasic:     'fighter-basic',
  FighterButterfly: 'fighter-butterfly',
  BomberMoth:       'bomber-moth',
  CruiserDove:      'cruiser-dove',
};
