/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Batch from 'fierry/rpc/batch';


export default class Batches
{
  constructor (rpc)
  {
    this.rpc = rpc;

    // queue keeps order of execution while map allows to easily collect notifications.
    this.queue = [];
    this.map = new Map();
  }


  create (id)
  {
    let batch = new Batch(id, this.rpc);

    this.queue.push(batch);
    this.map.set(id, batch);
  }


  commit (id)
  {
    this.map.get(id).commit();
  }


  notify (routing, id, data)
  {
    this.map.get(id).push(routing, data);
  }


  execute ()
  {
    while (this.queue.length && this.queue[0].isReady())
    {
      let batch = this.queue.shift();

      batch.execute();

      this.map.delete(batch.getId())
    }
  }


};
