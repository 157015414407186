/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  Carbon: 0,
  Silicon: 1,
  Hydrogen: 2,
  Currency: 3,
};
