/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Cache from 'fierry/flux/cache';
import Client from 'fierry/flux/entity/client';

import Zoom from 'galax/client/zoom';
import Server from 'galax/server';

import HexGeneral from 'galax/client/map/hex.general';
import HexLocal from 'galax/client/map/hex.local';
import Flight from 'galax/client/map/flight';


export default new Client ([ Zoom, Server /* needed to clear caches */ ], class
{
  constructor (zoom, server)
  {
    this.zoom = zoom;
    this.hexGeneral = new HexGeneral(this.zoom);

    this.hexLocalCache = new Cache( (location) =>
    {
      return new HexLocal(location, this);
    });

    this.flightCache = new Cache( (source, target) =>
    {
      return new Flight(source, target, this);
    });
  }


  getZoom ()
  {
    return this.zoom;
  }


  getGeneralHex ()
  {
    return this.hexGeneral;
  }


  getLocalHex (location)
  {
    // FIXME can't use zone.getID() because cache factory will only receive the ID and not the model!
    // Is there a better way to handle this? Factory args should be then different then the key args.
    // Ability to override the key? Now we use valueOf but that puts implementation with the model.
    // Could we define it for the cache itself?
    return this.hexLocalCache.get(location);
  }


  getFlight (source, target)
  {
    return this.flightCache.get(source, target);
  }


});
