/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react';


export default styled.g`
  --svg-line-stroke-increase: 1px;
`;
