/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg data-name="&lt;Layer&gt;" viewBox="0 0 13 16" {...props}><defs/><rect width="13" height="16" className="none"/><g><line x1="9" y1="8" x2="4" y2="8" className="black-line-20"/><line x1="4" y1="11.5" x2="4" y2="4.5" className="black-line-20"/><line x1="9" y1="11.5" x2="9" y2="4.5" className="black-line-20"/></g></Svg>
  );
}
