/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const getMaximum = () =>
{
  return Math.pow(10, 15);
};


export const getConstantsBase = () =>
{
  return Math.pow(10, 12);
};


export const getPercentageBase = () =>
{
  return Math.pow(10, 13);
};


export const fromConstants = (value) =>
{
  return value * getConstantsBase();
}


export const fromPercentage = (value) =>
{
  return value * getPercentageBase();
}


export const toConstants = (value) =>
{
  return Math.floor(value / getConstantsBase());
}


export const toPercentage = (value) =>
{
  return Math.round(value / getPercentageBase());
}
