/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, Modern, useCallback, useFlux, Native } from 'fierry/react';

import '@material/mwc-icon';
import '@material/mwc-list/mwc-list-item';

import Screen from 'galax/client/screen';


const Container = styled.div`
  mwc-list-item
  {
    --mdc-ripple-color: transparent;
  }

  mwc-list-item:hover, mwc-list-item:focus {
    background-color: var(--fierry-theme-background-hover);
  }

  mwc-list-item:active {
    background-color: var(--fierry-theme-background-active);
  }
`;


const Component = ({ text, icon, onClick }) =>
(
  <Container>
    <mwc-list-item graphic = 'icon' onClick = {onClick} >

      <Modern.Text body text = {text} />
      <Modern.Text slot = 'graphic' icon = {icon} />

    </mwc-list-item>
  </Container>
);


const OnClick = (event, onClickCallback, screen) =>
{
  if (onClickCallback)
  {
    onClickCallback(event);
  }

  screen.setDrawer(false);
}


export default ({ link, onClick, ...props }) =>
{
  const [ screen ] = useFlux(Screen);

  onClick = useCallback(OnClick, [ onClick, screen ]);

  return <Component {...props} onClick = {onClick} />;
}
