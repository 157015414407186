/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export const add = (lhs, rhs) =>
{
  return { x: lhs.x + rhs.x, y: lhs.y + rhs.y };
}


export const substract = (lhs, rhs) =>
{
  return { x: lhs.x - rhs.x, y: lhs.y - rhs.y };
}


export const distance = (lhs, rhs) =>
{
  const x = lhs.x - rhs.x;
  const y = lhs.y - rhs.y;

  return Math.sqrt(x * x + y * y);
}


export const scale = (point, scale) =>
{
  return { x: point.x * scale, y: point.y * scale };
}


// TODO should be Line. ; can generalize to any point on line and any angle - not only mid-point -Math.PI/2 angle!
export const getPerpendicularBisector = (lhs, rhs, offset) =>
{
  const mid = scale(add(lhs, rhs), 0.5);

  const theta = getLineAngleRadians(lhs, rhs) - Math.PI / 2;

  return { x: mid.x + offset * Math.cos(theta), y: mid.y + offset * Math.sin(theta) };
}


export const getLineAngleRadians = (lhs, rhs) =>
{
  return Math.atan2(rhs.y - lhs.y, rhs.x - lhs.x);
}


export const getLineAngleDegrees = (lhs, rhs) =>
{
  return getLineAngleRadians(lhs, rhs) * 180 / Math.PI
}


// TODO should be Line.
export const getPointWithOffset = (lhs, rhs, offset) =>
{
  const length = (offset < 0) ? distance(lhs, rhs) + offset : offset;

  return getPointWithScale(lhs, rhs, length / distance(lhs, rhs));
}


// TODO should be Line.
export const getPointWithScale = (lhs, rhs, ratio) =>
{
  return add(scale(substract(lhs, rhs), ratio), rhs);
}


export const getIntersectionPoint = (pointA, pointB, pointC, pointD) =>
{
  const pointAB = substract(pointA, pointB);
  const pointCD = substract(pointC, pointD);

  const c = pointAB.x * pointCD.y - pointAB.y * pointCD.x;

  if (Math.abs(c) < 0.0001) // TODO better handle precision
  {
    return null;
  }

  const a = pointA.x * pointB.y - pointA.y * pointB.x;
  const b = pointC.x * pointD.y - pointC.y * pointD.x;

  const x = (a * pointCD.x - b * pointAB.x) / c;
  const y = (a * pointCD.y - b * pointAB.y) / c;

  return { x, y };
}
