/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux }  from 'fierry/react';

import Grid from 'galax/client/map/grid';
import CurrentLocation from 'galax/query/current.location';

import BehaviorFlight from 'galax/react/map/flight/behavior';
import InteractionsFlight from 'galax/react/map/flight/interactions';
import ProjectilesFlight from 'galax/react/map/flight/projectiles';


const Component = (props) =>
(
  <>
    <InteractionsFlight {...props} />
    <ProjectilesFlight {...props} />
    <BehaviorFlight {...props} />
  </>
);


export default (props) =>
{
  const [ currentLocation, grid ] = useFlux(CurrentLocation, Grid);

  if (currentLocation == null || !grid.getZoom().isEntityVisible())
  {
    return null;
  }

  return <Component {...props} currentLocation = {currentLocation} grid = {grid} />;
}
