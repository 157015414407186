/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Element, withValue } from 'fierry/react';

import EfficiencyIcon from 'svg/shared/modern/efficiency.js';

import PercentUnit from 'svg/shared/unit/percent.js';


const Component = ({ value, ...props }) =>
(
  <Element.Group param width = '4' tooltip = 'Efficiency' {...props}>

    <Element.Icon value = {<EfficiencyIcon />} />
    <Element.Text value = {withValue(value, props)} />
    <Element.Unit value = {[ <PercentUnit /> ]} />

  </Element.Group>
);


const Invalid = (props) =>
(
  <Element.Group param width = '4' tooltip = 'Efficiency' {...props}>
    <Element.Icon value = {<EfficiencyIcon />} />
  </Element.Group>
);


export default ({ invalid, ...props }) =>
{
  return invalid ? <Invalid {...props} /> : <Component {...props} />;
}
