/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import AccessEnum from 'galax/enum/access';

import Access from 'galax/react/skeleton/access';

import Zoom from 'galax/react/strip/zoom';
import Resources from 'galax/react/strip/resources';


const Component = (props) =>
(
  <>
    <Resources {...props} />
    <Zoom {...props} />
  </>
);


export default (props) =>
{
  return <Access component = {Component} minAccess = {AccessEnum.Galaxy} />;
}
