/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import SourceCard from 'galax/react/card/zone.overview';
import TargetCard from 'galax/react/card/target';
import AttackCost from 'galax/react/card/building/attack.cost';

import Error from 'galax/react/card/shared/error';


export default (props) =>
(
  <>
    <AttackCost {...props} />
    <SourceCard {...props} header = 'Source' />
    <TargetCard {...props} />

    <Error {...props} />
  </>
);
