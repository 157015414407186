/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Entity from 'fierry/flux/entity'


export default class extends Entity
{
  constructor(dependences, definition)
  {
    super(dependences);

    this.definition = definition;
  }


  getDefinition ()
  {
    return this.definition;
  }


};
