/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><path d="M12,20,5.22,13.16C-.58,7.28,6.17.43,12,6.31c5.9-6,12.65.86,6.75,6.85Z" transform="translate(0 0)" className="black-line-20"/></Svg>
  );
}
