/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import nativeStyled, { css, keyframes } from 'styled-components';

import styledFactory from 'fierry/react/styled/factory';
import elements from 'fierry/react/styled/elements';

export { default as animate } from 'fierry/react/styled/animate';
export { default as animateOnce } from 'fierry/react/styled/animate.once';

export { default as Transition } from 'fierry/react/styled/transition';

// TODO remove these once we figure out who uses it ...
export { default as is } from 'fierry/react/styled/is';
export { default as filter } from 'fierry/react/styled/filter';


// TODO move to a separate file and name it better!
const styled = (...args) =>
{
  return nativeStyled(...args);
};


elements.forEach(tag =>
{
  styled[tag] = styledFactory(tag);
});


export { css, keyframes, styled };

export default styled;
