/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { React, useCache, useFlux } from 'fierry/react';

import AccessEnum from 'galax/enum/access';

import Url from 'fierry/client/url';

import Access from 'galax/react/skeleton/access';


const makeRouteConfig = (children) =>
{
  let config = {};

  React.Children.forEach(children, (element) =>
  {
    insertRoute(config, element.props);
  });

  return config;
}


const insertRoute = (config, entry) =>
{
  if (config[entry.type] == null)
  {
    config[entry.type] = [];
  }

  let index = 0;

  for (const { access } of config[entry.type])
  {
    if (entry.access > access)
    {
      break;
    }

    ++index;
  }

  config[entry.type].splice(index, 0, entry);
}


const getRouteWithAccess = (collection, access) =>
{
  for (const entry of collection)
  {
    if (access & entry.access)
    {
      return entry.component;
    }
  }

  return null;
}


const RouteComponent = ({ access, routes, ...props }) =>
{
  const [ url ] = useFlux(Url);

  const Component = getRouteWithAccess(routes[url.getRoute().getType()] || [], access);

  return Component ? <Component {...props} /> : null;
}


export default ({ children, ...props }) =>
{
  const routes = useCache(() => makeRouteConfig(children), [ ]);

  return <Access {...props} routes = {routes} component = {RouteComponent} minAccess = {AccessEnum.None} />
}
