/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  None:           1,
  Galaxy:         2,
  Location:       4,

  Dock:          16,
  Zone:          32,
  Platform:      64,
  Spaceship:    128,

  Unauthorized: 256,
};
