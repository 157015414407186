/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Element, Modern, useFlux } from 'fierry/react';

import Server from 'galax/server';

import SpaceshipParams from 'galax/react/group/spaceship/params';

import ClockElement from 'galax/react/element/clock';
import EnergyElement from 'galax/react/element/energy';

import CurrencyIcon from 'svg/galax/resource/currency';
import SpaceshipIcon from 'galax/react/icon/spaceship';


const getIcon = ({ constants, ...props }) =>
{
  return <SpaceshipIcon type = {constants.getType()} />;
}


const getName = ({ constants }) =>
{
  return constants.getName();
}


const getCurrencyCost = ({ constants }) =>
{
  return constants.getConstructionCost();
}


const getTime = ({ currentDock, constants }) =>
{
  const efficiency = currentDock.getSpaceport().getAttributes().getSpaceshipCreateEfficiency();

  return constants.getConstructionTime() * efficiency;
}


const Headline = (props) =>
(
  <Modern.Text headline icon = {getIcon(props)} text = {getName(props)} />
);


const Subline = (props) =>
(
  <Modern.Group width = '9' >
    <Modern.Param icon = {<CurrencyIcon />} text = {getCurrencyCost(props)} tooltip = 'Currency Cost' />
    <ClockElement {...props} value = {getTime} tooltip = 'Production Time' />
  </Modern.Group>
)

const Component = (props) =>
(
  <Card {...props} headline = {Headline} subline = {Subline} >
    <SpaceshipParams {...props} />
  </Card>
);


export default (props) =>
{
  const { galaxy, type, currentArgs } = props;

  return <Component {...props} constants = {galaxy.getConstants().getSpaceship(type || currentArgs.type)} />;
}
