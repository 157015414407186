/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import LocalUser from 'galax/server/user/local';

import * as UserValue from 'galax/value/user';


export default class
{
  constructor (parent, users)
  {
    this.users = new Map();

    for (const user of users.values())
    {
      this.users.set(user.id, new LocalUser(user, parent));
    }

    this.users.set(UserValue.getEmpty(), this.__createEmptyUser__(parent));
    this.users.set(UserValue.getAncient(), this.__createAncientUser__(parent));
  }


  __createEmptyUser__ (parent)
  {
    let type = this.__createStubUserType__(parent);

    return new LocalUser({ id: UserValue.getEmpty(), total_count: 0, ...type }, parent);
  }


  __createAncientUser__ (parent)
  {
    let type = this.__createStubUserType__(parent);

    return new LocalUser({ id: UserValue.getAncient(), total_count: 100, ...type }, parent);
  }


  __createStubUserType__ (parent)
  {
    const { galaxy, id, update_date, } = parent.getCass();

    return { galaxy, planet: id, update_date, active_count: 0, local_count: 0, theme: 0 };
  }


  getUser (id)
  {
    return this.users.get(id);
  }


}
