/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { Modern } from 'fierry/react';

import DefenceElement from 'galax/react/element/defence';
import EnergyElement from 'galax/react/element/energy';

import String from 'fierry/util/string';

import UnitIcon from 'galax/react/icon/unit';


const getType = ({ targetUnit }) =>
{
  return targetUnit.getType();
}


const getName = ({ targetUnit }) =>
{
  return targetUnit.getConstants().getName();
}


const getDefenceValue = ({ targetUnit, now }) =>
{
  return 0;
}


const getEnergyValue = ({ targetUnit, now }) =>
{
  return targetUnit.getEnergy(now);
}


const Component = (props) =>
(
  <Modern.Group>

    <Modern.Text param width = '8.5'
      icon = {<UnitIcon type = {getType(props)} />}
      text = {getName(props)}
    />

    <DefenceElement {...props}
      value = {getDefenceValue}
    />

    <EnergyElement {...props}
      energy = {getEnergyValue(props)}
    />

  </Modern.Group>
);


export default (props) =>
{
  return props.targetUnit ? <Component {...props} /> : null;
}
