/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import WeaponBuilding from 'galax/server/building/weapon.building';

import ProjectileEnum from 'galax/enum/projectile';


export default class extends WeaponBuilding
{
  getProjectileType ()
  {
    return ProjectileEnum.GaussArtillery;
  }


};
