/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react';

import Theme from 'fierry/react/skeleton/theme';
import ThemeConstants from 'galax/server/constants/theme';

import CurrentUser from 'galax/query/current.user';
import CurrentLocation from 'galax/query/current.location';


// TODO is there a better way to provide this ?
const DEFAULT_CONSTANTS = new ThemeConstants({ constants: { type: 0, primary_light: '#757575', secondary_light: '#616161', primary_dark: '#BDBDBD', secondary_dark: '#E0E0E0' } });


const getTheme = (currentLocation, currentUser) =>
{
  if (!currentLocation)
  {
    if (!currentUser)
    {
      return DEFAULT_CONSTANTS;
    }

    return currentUser.getTheme() || DEFAULT_CONSTANTS;
  }

  return currentLocation.getUser().getTheme() || DEFAULT_CONSTANTS;
}


export default (props) =>
{
  const [ currentLocation, currentUser ] = useFlux(CurrentLocation, CurrentUser);

  return <Theme.Div {...props} theme = {getTheme(currentLocation, currentUser)} />
}
