/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { getShapeToCount } from 'galax/enum/shape'

import * as Coords from 'galax/geo/coords';
import * as Shapes from 'galax/geo/shapes';


export default class
{
  constructor (projectile, parent)
  {
    this.projectile = projectile;
    this.parent = parent;
  }


  getUser ()
  {
    return this.parent.getGalaxy().getUser(this.getUserID());
  }


  getTargetCount ()
  {
    return getShapeToCount(this.projectile.shape.type);
  }


  getFlightProgress (now)
  {
    return 1 - this.getRemainingTime(now) / this.getTotalTime();
  }


  getRemainingTime (now)
  {
    return this.projectile.event.date - now;
  }


  getTotalTime ()
  {
    return this.projectile.event.date - this.projectile.launch_date;
  }


  getConstants ()
  {
    return this.parent.getConstants().getProjectile(this.projectile.type);
  }


  getType ()
  {
    return this.projectile;
  }


  getShape ()
  {
    return this.projectile.shape;
  }


  getSource ()
  {
    return this.projectile.source;
  }


  getTarget ()
  {
    return this.projectile.target;
  }


  getUserID ()
  {
    return this.projectile.user.id;
  }


  getID ()
  {
    return this.projectile.id;
  }


  isSourceOf (coords)
  {
    return this.projectile.source.id.isEqual(coords);
  }


  isTargetOf (coords)
  {
    const { target, shape } = this.projectile;

    return Coords.indexOf(Shapes.getCoords(target.id, shape), coords) !== -1;
  }


};
