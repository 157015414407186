/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Children } from 'fierry/react/native';
import { isArray } from 'fierry/util/lang';

import Icon from 'fierry/react/element/icon';


const getValue = ({ value }) =>
{
  return isArray(value) ? Children.toArray(value) : value;
}


export default (props) =>
(
  <Icon {...props} unit value = {getValue(props)} />
);
