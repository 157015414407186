/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { styled } from 'fierry/react';


// TODO this is a little broken - actual border length can be between internal & external size.
const getInternalBorderDasharray = ({ grid, diagonal }) =>
{
  const size = diagonal ? grid.getGeneralHex().getDiagonalBorderSize() : grid.getGeneralHex().getVerticalBorderSize();

  return size / 3;
}


const UserBorderSide = styled.line`

  stroke: var(--svg-always-other);

  stroke-width: ${props => props.grid.getGeneralHex().getDockBorderWidth()};

  stroke-linecap: round;
`;


const ZoneBorderSide = styled.line`

  stroke: var(--svg-always-other);

  stroke-width: ${props => props.grid.getGeneralHex().getDockBorderWidth()};
  stroke-dasharray: ${props => getInternalBorderDasharray(props)};

  stroke-linecap: round;
`;


const getLineProps = ({ x: x1, y: y1 }, { x: x2, y: y2 }) =>
{
  return { x1, y1, x2, y2 };
}


const isDiagonal = (index) =>
{
  return index != 1 && index != 4;
}


const isUserBorder = (current, neighbor) =>
{
  const currentPlatform = current.getPlatform();
  const neighborPlatform = neighbor.getPlatform();

  return currentPlatform && (!neighborPlatform || currentPlatform.getUserID() != neighborPlatform.getUserID());
}


export default ({ location, grid }) =>
{
  const zoom = grid.getZoom();
  const hex = grid.getLocalHex(location);

  return hex.getPlatformPoints().map( (_, index, points) =>
  {
    const neighbors = hex.getNeighbors();

    const props = getLineProps(points[index], points[(index + 1) % points.length]);

    if (isUserBorder(location, neighbors[index]))
    {
      return <UserBorderSide {...props} key = {index} grid = {grid} />;
    }

    if (index > 2)
    {
      return null;
    }

    const diagonal = isDiagonal(index);

    if (zoom.isZoneVisible())
    {
      return <ZoneBorderSide {...props} key = {index} grid = {grid} diagonal = {diagonal} />;
    }

    return null;
  });
}
