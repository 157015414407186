/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, styled, useFlux } from 'fierry/react';
import { List, Item, Link, Bold } from 'galax/react/element/welcome';

import View from 'fierry/client/view';

import NextButton from 'galax/react/button/general/next'
import LogInButton from 'galax/react/button/general/login'

import * as Manual from 'galax/fake/manual';


const WelcomeContainer = styled.div`
  user-select: text;
`;


const Card = styled(Modern.Group)`
  padding: 1.5rem; // 3rem right

  --native-foreground-color: var(--fierry-theme-foreground, black);
  --native-background-color: var(--fierry-theme-background, white);
`;


const Component = ({ index, ...props }) =>
(
  <WelcomeContainer>

    <Card>
      <Modern.Text param>{Manual.getText(index)}</Modern.Text>
    </Card>

  </WelcomeContainer>
);


export default (props) =>
{
  const [ view ] = useFlux(View);

  const index = Number.parseInt(view.getRoute().getArgs().index);

  return <Component {...props} index = {index || 0} />;
}
