/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><g><path d="M4.6,18.3l3.11-6.09,2.23,1.14L8.31,20.21Z" transform="translate(0 0)" className="black-shape"/><path d="M11.56,4.41l3.58,1.88L13.9,9.78c-1.19-.62-2-1-2.6.71L8.91,9.23l.62-1.74c-.64-1.72-2.44-2-5.38-.76C5.39,3.24,10,3.58,11.56,4.41" transform="translate(0 0)" className="black-shape"/><path d="M17.46,7.76l2.21,1.11-1.11,3-2.21-1.11Z" transform="translate(0 0)" className="black-shape"/></g><g><path d="M4.89,18.3,8,12.2l2.23,1.15L8.6,20.21Z" transform="translate(0 0)" className="black-line-20"/><path d="M11.88,4.41l3.57,1.88L14.21,9.78c-1.19-.62-2-1-2.6.71L9.23,9.23l.62-1.74c-.65-1.72-2.45-2-5.39-.76,1.24-3.49,5.83-3.15,7.42-2.32" transform="translate(0 0)" className="black-line-20"/><path d="M17.74,7.76,20,8.87l-1.1,3-2.22-1.11Z" transform="translate(0 0)" className="black-line-20"/></g></Svg>
  );
}
