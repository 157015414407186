/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import View from 'fierry/react/skeleton/card/view';

import SpaceshipEnum from 'galax/enum/spaceship';
import SpaceshipInfoCard from 'galax/react/card/spaceship/info'


const getRoute = ({ type }) =>
{
  return '/spaceship.create/' + type;
}


const SpaceshipCard = (props) =>
(
  <View {...props} item card = {SpaceshipInfoCard} route = {getRoute(props)} />
);

export default (props) =>
(
  <>
    <SpaceshipCard {...props} type = {SpaceshipEnum.FighterBasic} />
    <SpaceshipCard {...props} type = {SpaceshipEnum.FighterButterfly} />
    <SpaceshipCard {...props} type = {SpaceshipEnum.BomberMoth} />
    <SpaceshipCard {...props} type = {SpaceshipEnum.CruiserDove} />
  </>
);
