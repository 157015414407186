/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import GoogleAnalytics from 'react-ga';


if (window.location.hostname !== 'localhost')
{
  GoogleAnalytics.initialize('UA-148150744-1');

  // Setting IP anonymization.
  GoogleAnalytics.set({ anonymizeIp: true });


  // For now we don't log location.search - no point keeping all the refresh tokens!
  GoogleAnalytics.pageview(window.location.pathname + window.location.hash);

  window.onhashchange = (event) =>
  {
    GoogleAnalytics.pageview(window.location.pathname + window.location.hash + window.location.search);
  }
}
