/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, Modern, useCallback, useFlux } from 'fierry/react';

import Resources from 'galax/react/group/resources';


const Container = styled(Modern.Group)`
  padding: 0.5rem 1rem 0.5rem 1rem;

  width: 20rem;

  background: var(--fierry-theme-background);
  border: 1px solid var(--fierry-divider-color);
`;


export default (props) =>
(
  <Container direction = 'vertical'>
    <Resources {...props} />
  </Container>
);
