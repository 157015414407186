/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import uuid from 'uuid/v4';


export default class
{
  constructor (id, theme)
  {
    this.user = { id, theme, local_count: 0, total_count: 0 };
  }


  setGalaxy (galaxy)
  {
    this.user.galaxy = galaxy;
  }


  setPlanet (planet)
  {
    this.user.planet = planet;
  }


  getType ()
  {
    return this.user;
  }


  getID ()
  {
    return this.user.id;
  }


  getTheme ()
  {
    return this.user.theme;
  }


}
