/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux, withClass } from 'fierry/react/native';
import { css, styled } from 'fierry/react/styled';

import Context from 'fierry/react/element/context';

import 'fierry/react/element/text.scss'


const widthConfig = ({ width }) => width && css`
  width: ${width}rem;
`;


const nowrapConfig = ({ nowrap }) => nowrap && css`
  white-space: nowrap;
`;


const leftAlign = ({ leftAlign }) => leftAlign && css`
  text-align: leftAlign;
`;


const rightAlign = ({ rightAlign }) => rightAlign && css`
  text-align: right;
`;


const centerAlign = ({ centerAlign }) => centerAlign && css`
  text-align: center;
`;


const Text = styled.div`

  ${widthConfig}
  ${nowrapConfig}

  ${leftAlign}
  ${rightAlign}
  ${centerAlign}
`;


const getClassName = ({ variant }) =>
{
  return withClass('mdc-typography', `mdc-typography--${variant}`);
}


export default ({ value, children, ...props }) =>
{
  const [ context ] = useFlux(Context);

  children = children || value;

  return <Text {...props} className = {getClassName(context)} children = {children} />
}
