/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */



import Query from 'fierry/flux/entity/query'

import Auth from 'fierry/client/auth';
import View from 'fierry/client/view';

import Server from 'galax/server/root';

import * as Fake from 'galax/fake';


export default new Query([ Server, Auth, View ], (server, auth, view) =>
{
  const index = Number.parseInt(view.getRoute().getArgs().index);

  if (!Number.isNaN(index))
  {
    return Fake.getGalaxy(index);
  }

  if (!auth.isQueryComplete())
  {
    return null;
  }

  if (auth.isLoggedIn())
  {
    return server.isSessionReady() ? server.getSession() : null;
  }

  return Fake.getGalaxy(5);
});
