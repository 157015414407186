/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import uuid from 'uuid/v4';

import Sector from 'galax/fake/builder/sector';
import Resource from 'galax/fake/builder/resource';
import Building from 'galax/fake/builder/building';
import Unit from 'galax/fake/builder/unit';
import LocalUser from 'galax/fake/builder/local.user';

import * as User from 'galax/value/user';
import * as Coords from 'galax/geo/coords';


const SECTOR_NAME_START_CHAR = 'A'.charCodeAt(0);


export default class
{
  constructor (id, coords, parent)
  {
    this.coords = coords;
    this.parent = parent;

    this.currentPlanet = { id, name: '' };

    this.interactionsTo = [];
    this.interactionsFrom = [];

    this.projectilesTo = [];
    this.projectilesFrom = [];

    this.sectors = [];
    this.resources = [];
    this.buildings = [];
    this.units = [];
    this.users = [];
  }


  createSectorRaw ()
  {
    const sector = new Sector(uuid(), this.makeSectorName(), this);

    this.sectors.push(sector);

    return sector;
  }


  createSector (zones)
  {
    const sector = this.createSectorRaw();

    for (const [x, y] of zones)
    {
      sector.createZone(this.getAbsoluteCoords(x, y));
    }

    sector.assignUser(this.parent.getAncientUser());

    return sector;
  }


  makeSectorName ()
  {
    return String.fromCharCode(SECTOR_NAME_START_CHAR + this.sectors.length);
  }


  createLocalUser (globalUser)
  {
    if (globalUser.getID() === User.getAncient())
    {
      return;
    }

    for (const localUser of this.users)
    {
      if (globalUser.getID() === localUser.getID())
      {
        return;
      }
    }

    const localUser = new LocalUser(globalUser.getID(), globalUser.getTheme());

    this.users.push(localUser);

    return localUser;
  }


  createResource ([x, y], type)
  {
    const zone = this.getZone(x, y);

    zone.setResource(type);
  }


  createBuilding ([x, y], type)
  {
    const building = new Building(type, this.getAbsoluteCoords(x, y));

    this.buildings.push(building);

    return building;
  }


  createUnit ([x, y], type)
  {
    const unit = new Unit(type, this.getAbsoluteCoords(x, y));

    this.units.push(unit);

    return unit;
  }


  insertInteractionTo (interaction)
  {
    this.interactionsTo.push(interaction);
  }


  insertInteractionFrom (interaction)
  {
    this.interactionsFrom.push(interaction);
  }


  insertProjectileTo (interaction)
  {
    this.projectilesTo.push(interaction);
  }


  insertProjectileFrom (interaction)
  {
    this.projectilesFrom.push(interaction);
  }


  setGalaxy (galaxy)
  {
    this.currentPlanet.galaxy = galaxy;

    for (const interaction of this.interactionsTo)
    {
      interaction.setGalaxy(galaxy);
    }

    for (const interaction of this.interactionsFrom)
    {
      interaction.setGalaxy(galaxy);
    }

    for (const projectile of this.projectilesTo)
    {
      projectile.setGalaxy(galaxy);
    }

    for (const projectile of this.projectilesFrom)
    {
      projectile.setGalaxy(galaxy);
    }

    for (const sector of this.sectors)
    {
      sector.setGalaxy(galaxy);
    }

    for (const resource of this.resources)
    {
      resource.setGalaxy(galaxy);
    }

    for (const building of this.buildings)
    {
      building.setGalaxy(galaxy);
    }

    for (const unit of this.units)
    {
      unit.setGalaxy(galaxy);
    }

    for (const user of this.users)
    {
      user.setGalaxy(galaxy);
    }
  }


  setUpdateDate (date)
  {
    this.currentPlanet.update_date = date;

    for (const projectile of this.projectilesTo)
    {
      projectile.setUpdateDate(date);
    }

    for (const projectile of this.projectilesFrom)
    {
      projectile.setUpdateDate(date);
    }

    for (const sector of this.sectors)
    {
      sector.setUpdateDate(date);
    }

    for (const resource of this.resources)
    {
      resource.setUpdateDate(date);
    }

    for (const building of this.buildings)
    {
      building.setUpdateDate(date);
    }

    for (const unit of this.units)
    {
      unit.setUpdateDate(date);
    }
  }


  finalize ()
  {
    this.finalizeSectors();
    this.finalizeResources();
    this.finalizeBuildings();
    this.finalizeUnits();
    this.finalizeUsers();

    this.finalizeCurrentPlanet();
  }


  finalizeSectors ()
  {
    for (const sector of this.sectors)
    {
      sector.setPlanet(this.currentPlanet.id);
    }

    for (const sector of this.sectors)
    {
      sector.finalize();
    }
  }


  finalizeResources ()
  {
    for (const resource of this.resources)
    {
      resource.setPlanet(this.currentPlanet.id);
    }
  }


  finalizeBuildings ()
  {
    for (const building of this.buildings)
    {
      building.setPlanet(this.currentPlanet.id);
    }
  }


  finalizeUnits ()
  {
    for (const unit of this.units)
    {
      unit.setPlanet(this.currentPlanet.id);
    }
  }


  finalizeUsers ()
  {
    for (const user of this.users)
    {
      user.setPlanet(this.currentPlanet.id);
    }
  }


  finalizeCurrentPlanet ()
  {
    this.currentPlanet.x = this.getPlanetMinX();
    this.currentPlanet.y = this.getPlanetMinY();

    this.currentPlanet.width = this.getPlanetMaxX() - this.currentPlanet.x;
    this.currentPlanet.height = this.getPlanetMaxY() - this.currentPlanet.y;
  }


  getPlanetMinX ()
  {
    let x = Number.MAX_SAFE_INTEGER;

    this.foreachZone(zone =>
    {
      x = Math.min(x, zone.getCoords().getX());
    });

    return x;
  }


  getPlanetMaxX ()
  {
    let x = Number.MIN_SAFE_INTEGER;

    this.foreachZone(zone =>
    {
      x = Math.max(x, zone.getCoords().getX());
    });

    return x;
  }


  getPlanetMinY ()
  {
    let y = Number.MAX_SAFE_INTEGER;

    this.foreachZone(zone =>
    {
      y = Math.min(y, zone.getCoords().getY());
    });

    return y;
  }


  getPlanetMaxY ()
  {
    let y = Number.MIN_SAFE_INTEGER;

    this.foreachZone(zone =>
    {
      y = Math.max(y, zone.getCoords().getY());
    });

    return y;
  }


  foreachZone (callback)
  {
    for (const sector of this.sectors)
    {
      for (const zone of sector.getZones())
      {
        callback(zone)
      }
    }
  }


  printSectorsAndResources ()
  {
    console.log('Planet', this.getID(), '[ ' + this.currentPlanet.x + ', ' + this.currentPlanet.y + ' ]');

    for (let i = 0; i < this.sectors.length; ++i)
    {
      let zones = [];

      for (const zone of this.sectors[i].getZones())
      {
        const coords = zone.getCoords();
        zones.push('[ ' + (coords.getX() - this.currentPlanet.x) + ', ' + (coords.getY() - this.currentPlanet.y) + ' ]');
      }

      console.log('planet.createSector([ ' + zones.join(', ') + ' ]);')
    }

    for (const resource of this.resources)
    {
      const coords = resource.getType().coords;

      console.log('planet.createResource([ ' + (coords.getX() - this.currentPlanet.x) + ', ' + (coords.getY() - this.currentPlanet.y) + ' ], ' + resource.getType().type + ');')
    }
  }


  getCurrentPlanet ()
  {
    return this.currentPlanet;
  }


  getInteractionsTo ()
  {
    return this.interactionsTo;
  }


  getInteractionsFrom ()
  {
    return this.interactionsFrom;
  }


  getProjectilesTo ()
  {
    return this.projectilesTo;
  }


  getProjectilesFrom ()
  {
    return this.projectilesFrom;
  }


  getSectors ()
  {
    return this.sectors;
  }


  getSector (index)
  {
    return this.sectors[index];
  }


  getZone (x, y)
  {
    const expected = this.getAbsoluteCoords(x, y);

    for (const sector of this.sectors)
    {
      for (const zone of sector.getZones())
      {
        const coords = zone.getCoords();

        if (coords.getX() === expected.getX() && coords.getY() === expected.getY())
        {
          return zone;
        }
      }
    }

    return null;
  }


  getResources ()
  {
    return this.resources;
  }


  getBuildings ()
  {
    return this.buildings;
  }


  getUnits ()
  {
    return this.units;
  }


  getUsers ()
  {
    return this.users;
  }


  getConstants ()
  {
    return this.parent.getConstants();
  }


  getAbsoluteCoords (x, y)
  {
    return Coords.fromOffset(this.coords.getX() + x, this.coords.getY() + y);
  }


  getID ()
  {
    return this.currentPlanet.id;
  }


}
