/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux, useCallback } from 'fierry/react';

import Auth from 'fierry/client/auth';
import View from 'fierry/client/view';

import Item from 'fierry/react/skeleton/drawer/item';


const OnClick = (event, auth, view) =>
{
  auth.logout();

  view.pushForeground('/');
}


export default (props) =>
{
  const [ auth, view ] = useFlux(Auth, View);

  const onClick = useCallback(OnClick, [ auth, view ] );

  if (!auth.isLoggedIn())
  {
    return null;
  }

  return <Item text = 'Log Out' icon = 'logout' onClick = {onClick} />
};
