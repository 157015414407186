/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Map } from 'immutable';

import Model from 'fierry/rpc/model';


export default class
{
  constructor (context)
  {
    this.state = {};

    this.context = context;
  }


  track (routing)
  {
    let parent = this.state;

    for (const key of routing)
    {
      if (parent[key] == null)
      {
        parent[key] = {};
      }

      parent = parent[key];
    }
  }


  execute ()
  {
    while (!this.isEmpty())
    {
      this.executeDeep(this.advanceTrackState(), this.context.getHierarchy());
    }
  }


  executeDeep (parent, hierarchy)
  {
    // Execute children first.
    for (const name in parent)
    {
      this.executeDeep(parent[name], hierarchy.getElement(name));
    }

    // Upcast state to model representation.
    if (hierarchy.getConstructor())
    {
      const state = this.context.getState();
      const routing = hierarchy.getRouting();

      state.setIn(routing, new (hierarchy.getConstructor())(state.getIn(routing), hierarchy), true /*quiet*/);
    }

    // Propagate state to registered targets.
    for (const target of hierarchy.getPropagationTargets())
    {
      state.setIn(target, state.getIn(routing));
    }
  }


  advanceTrackState ()
  {
    const state = this.state;

    this.state = {};

    return state;
  }


  isEmpty ()
  {
    return Object.keys(this.state).length === 0;
  }


};
