/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Flux } from 'fierry/react';

import PaneState from 'fierry/state/pane';

import UrlStore from 'fierry/client/url/store';
import UrlClient from 'fierry/client/url';
import ViewClient from 'fierry/client/view';

import AuthState from 'fierry/state/auth';
import AuthClient from 'fierry/client/auth';

import AuthChangeEvent from 'fierry/event/auth.change';
import HistoryChangeEvent from 'fierry/event/history.change';

import ColorScheme from 'shared/react/color.scheme';

import Galax from 'galax';


const State =
[
  AuthState,
  PaneState,
  UrlStore,
];


const Client =
[
  AuthClient,
  UrlClient,
  ViewClient,
];


const Event =
[
  AuthChangeEvent,
  HistoryChangeEvent,
];


export default (props) =>
(
  <Flux dependences = {[ ...State, ...Client, ...Event ]} >

    <ColorScheme>
      <Galax />
    </ColorScheme>

  </Flux>
);
