/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { useCallback, useFlux } from 'fierry/react';

import Server from 'galax/server';

import Progress from 'galax/react/group/progress';

import IdleIcon from 'svg/shared/modern/efficiency';
import ProductionIcon from 'svg/shared/modern/production-stroke';
import DockIcon from 'svg/shared/modern/dock-stroke';
import AttackIcon from 'svg/shared/modern/attack-stroke';
import MovementIcon from 'svg/shared/modern/move-stroke';


const getHeader = ({ status }) =>
{
  if (status.isDefault())    { return 'Idle'; }
  if (status.isProduction()) { return 'Production'; }
  if (status.isDock())       { return 'Dock'; }
  if (status.isAttack())     { return 'Attack'; }
  if (status.isMove())       { return 'Movement'; }

  return null;
};


const getIcon = ({ status }) =>
{
  if (status.isDefault())    { return <IdleIcon />; }
  if (status.isProduction()) { return <ProductionIcon />; }
  if (status.isDock())       { return <DockIcon />; }
  if (status.isAttack())     { return <AttackIcon />; }
  if (status.isMove())       { return <MovementIcon />; }

  return null;
};


const OnCancel = (event, spaceship, server) =>
{
  server.cancelSpaceshipAction(spaceship);
};


export default ({ spaceship, ...props }) =>
{
  const [ server ] = useFlux(Server);

  const onCancel = useCallback(OnCancel, [ spaceship, server ]);

  return <Progress {...props} icon = {getIcon} header = {getHeader} status = {spaceship.getStatus()} onCancel = {onCancel} />;
}
