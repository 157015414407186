/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Interaction from 'galax/server/interaction';
import PlatformZone from 'galax/server/zone/platform';

import ParentEnum from 'galax/enum/parent';

import * as Shapes from 'galax/geo/shapes';


export default class extends Interaction
{
  installTargetPlanet (planet)
  {
    for (const coordinate of Shapes.getCoords(this.cass.target.id, this.cass.shape))
    {
      const location = { id: coordinate.toString(), parent: { id: planet.getID(), type: ParentEnum.Planet } };

      const zone = new PlatformZone(location, this.cass.user, planet);

      planet.getZoneCollection().insertZone(zone);
      planet.getZoneCollection().insertPlatform(zone);
    }
  }


  installTargetSpaceship (spaceship)
  {
    // no-op.
  }


  installSourcePlanet (planet)
  {
    const gate = planet.getBuilding(this.cass.source.id.toString());

    for (const coordinate of Shapes.getCoords(this.cass.target.id, this.cass.shape))
    {
      gate.installPlatformCoords(coordinate);
    }
  }


  installSourceSpaceship (spaceship)
  {
    // no-op.
  }


};
