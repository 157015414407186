/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import extract from 'fierry/flux/dependency/extract';
import isVisible from 'fierry/flux/hook/is.visible';


export default (dependency, props) =>
{
  const dependences = extract(dependency.getDependences(), props);
  const visible = isVisible(dependency);

  const effectCallback = () =>
  {
    if (visible)
    {
      return dependency.getEntity().getDefinition()(...dependences);
    }
  }

  return React.useEffect(effectCallback, dependences);
}
