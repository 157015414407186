/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import DynamicElement from 'fierry/rpc/dynamic-element';

import * as Key from 'galax/rpc/key';
import * as Translator from 'galax/rpc/translator';


export default class extends DynamicElement
{
  constructor ()
  {
    super();

    this.createRecord('currentSpaceship', [ Translator.Spaceship, Translator.StatusDate, Translator.UpdateDate, Translator.CreateDate ]);

    this.createTable('interactionsTo', Key.TargetSourceID, [ Translator.Interaction ]);
    this.createTable('interactionsFrom', Key.TargetSourceID, [ Translator.Interaction ]);

    this.createTable('projectilesTo', Key.ID, [ Translator.Projectile ]);
    this.createTable('projectilesFrom', Key.ID, [ Translator.Projectile ]);

  }
}
