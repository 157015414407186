/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ResourceEnum from 'galax/enum/resource';

import Carbon from 'svg/galax/resource/carbon';
import Silicon from 'svg/galax/resource/silicon';
import Hydrogen from 'svg/galax/resource/hydrogen';


export default ({ type, ...props }) =>
{
    switch (type)
  {
    case ResourceEnum.Carbon:   return <Carbon {...props} />;
    case ResourceEnum.Silicon:  return <Silicon {...props} />;
    case ResourceEnum.Hydrogen: return <Hydrogen {...props} />;
  }

  return null;
}
