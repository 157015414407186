/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react';


export default styled.g`

  --svg-black: var(--fierry-theme-background);
  --svg-other: var(--fierry-theme-background);
  --svg-white: var(--fierry-theme-primary-foreground);
`;
