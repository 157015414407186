/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><rect x="3.42" y="3.11" width="18" height="18" className="none"/><rect x="3" y="3" width="18" height="18" className="none"/><polygon points="4 15 4 9 9 9 9 4 15 4 15 9 20 9 20 15 15 15 15 20 9 20 9 15 4 15" className="black-line-20"/></Svg>
  );
}
