/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useEffect, useRef } from 'fierry/react/native';


export default () =>
{
  const isMounted = useRef(false);

  const unsubscribe = () =>
  {
    isMounted.current = false;
  }

  const subscribe = () =>
  {
    isMounted.current = true;

    return unsubscribe;
  };

  useEffect(subscribe, []);

  return isMounted.current;
}
