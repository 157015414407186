/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { styled } from 'fierry/react';


const Rect = styled.rect`
  fill: white;
`;


const Line = styled.line`
  stroke: black;
  stroke-width: 1;
  stroke-linecap: round;
`;


const DashedLine = styled(Line)`
  stroke-dasharray: 1 5;
`;


const getRectProps = ({ grid }) =>
{
  const hex = grid.getGeneralHex();

  const width = hex.getExternalWidth();
  const height = hex.getExternalHeight();

  return { x: -1/2 * width - 1, y: -1/2 * height - 1, width: width + 2, height: height + 2 };
}


const getVerticalLineProps = ({ grid }, delta) =>
{
  const hex = grid.getGeneralHex();

  const width = hex.getExternalWidth();
  const height = hex.getExternalHeight();

  return { x1: delta * width, y1: -1/2 * height, y2: 1/2 * height, x2: delta * width };
}


const getHorizontalLineProps = ({ grid }, delta) =>
{
  const hex = grid.getGeneralHex();

  const width = hex.getExternalWidth();
  const height = hex.getExternalHeight();

  return { x1: -1/2 * width, y1: delta * height, y2: delta * height, x2: 1/2 * width };
}


const getDecreaseLineProps = ({ grid }, delta) =>
{
  const hex = grid.getGeneralHex();

  const width = hex.getExternalWidth();
  const height = hex.getExternalHeight();

  return { x1: -1/2 * width, y1: (-1/4 + delta) * height, y2: (1/4 + delta) * height, x2: 1/2 * width };
}


const getIncreaseLineProps = ({ grid }, delta) =>
{
  const hex = grid.getGeneralHex();

  const width = hex.getExternalWidth();
  const height = hex.getExternalHeight();

  return { x1: -1/2 * width, y1: (1/4 + delta) * height, y2: (-1/4 + delta) * height, x2: 1/2 * width };
}


export const VerticalStripe = (props) =>
(
  <mask id='vertical.stripe'>

    <Rect {...getRectProps(props)} />

    <Line {...getVerticalLineProps(props, -5/60)} />
    <Line {...getVerticalLineProps(props,     0)} />
    <Line {...getVerticalLineProps(props,  5/60)} />
  </mask>
);


export const HorizontalStripe = (props) =>
(
  <mask id='horizontal.stripe'>

    <Rect {...getRectProps(props)} />

    <Line {...getHorizontalLineProps(props, -5/60)} />
    <Line {...getHorizontalLineProps(props,     0)} />
    <Line {...getHorizontalLineProps(props,  5/60)} />
  </mask>
);


export const DecreaseStripe = (props) =>
(
  <mask id='decrease.stripe'>

    <Rect {...getRectProps(props)} />

    <Line {...getDecreaseLineProps(props, -1/4)} />
    <Line {...getDecreaseLineProps(props,    0)} />
    <Line {...getDecreaseLineProps(props,  1/4)} />
  </mask>
);


export const IncreaseStripe = (props) =>
(
  <mask id='increase.stripe'>

    <Rect {...getRectProps(props)} />

    <Line {...getIncreaseLineProps(props, -1/4)} />
    <Line {...getIncreaseLineProps(props,    0)} />
    <Line {...getIncreaseLineProps(props,  1/4)} />
  </mask>
);


export const IncreaseDashStripe = (props) =>
(
  <mask id='increase.dash.stripe'>

    <Rect {...getRectProps(props)} />

    <DashedLine {...getIncreaseLineProps(props, -1/4)} />
    <DashedLine {...getIncreaseLineProps(props,    0)} />
    <DashedLine {...getIncreaseLineProps(props,  1/4)} />
  </mask>
);
