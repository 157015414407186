/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react';


export default styled.div`

  --fierry-pane-width: 25rem;
  --fierry-pane-height: 100%;
`;
