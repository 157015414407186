/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';

import UsersState from 'galax/fake/state/users/users';


export default (users) =>
{
  let state = new Immutable.Map();

  state = state.set('currentUser', users[0].getType());
  state = state.set('users', UsersState(users));

  return state;
}

