/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (context)
  {
    this.context = context;
  }


  attach (routing, notifications)
  {
    let element = this.getElement(routing);

    element.setRouting(routing);
    element.setContext(this.context);

    for (let name of notifications)
    {
      element.getNotification(name).attach();
    }

    if (element.getNotificationCount() !== notifications.length)
    {
      throw new Error(`Notifications missmatch; routing: '${element.getRoutingString()}'.`);
    }

    element.attach();
  }


  detach (routing)
  {
    let element = this.getElement(routing);

    element.detach();

    element.setRouting(undefined);
    element.setContext(undefined);
  }


  notify (routing, data)
  {
    this.getParentElement(routing).getNotification(routing[routing.length - 1]).notify(data);
  }


  getParentElement (routing)
  {
    let element = this.context.getHierarchy();

    for (let i = 0, l = routing.length - 1; i < l; ++i)
    {
      element = element.getElement(routing[i]);
    }

    return element;
  }


  getElement (routing)
  {
    let element = this.context.getHierarchy();

    for (let name of routing)
    {
      element = element.getElement(name);
    }

    return element;
  }


};
