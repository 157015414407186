/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import Offset from 'galax/client/map/offset';

import Icon from 'svg/shared/modern/move-stroke';


const OnClick = (event, offset, x, y) =>
{
  offset.setAbsolute(x, y);
};


export default ({ x, y, ...props }) =>
{
  const [ offset ] = useFlux(Offset);

  const onClick = useCallback(OnClick, [ offset, x, y ]);

  return <Modern.Button tooltip = 'Center Location' icon = 'my_location' onClick = {onClick} />;
}
