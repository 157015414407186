/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react';


export default styled.div`

  --fierry-pane-width: 100%;
  --fierry-pane-height: 100%;

  --fierry-pane-height-expanded: 100%;
  --fierry-pane-height-collapsed: 12rem;
`;
