/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Immutable from 'immutable';

import PlanetModel from 'galax/server/planet';

import InteractionsToState from 'galax/fake/state/interactions.to';
import InteractionsFromState from 'galax/fake/state/interactions.from';

import ProjectilesToState from 'galax/fake/state/projectiles.to';
import ProjectilesFromState from 'galax/fake/state/projectiles.from';

import EntitiesState from 'galax/fake/state/entities';
import SectorsState from 'galax/fake/state/sectors';
import UsersState from 'galax/fake/state/users/local';

import ConstantsState from 'galax/fake/state/constants';


export default (planet) =>
{
  let state = new Immutable.Map();

  state = state.set('currentPlanet', planet.getCurrentPlanet());

  state = state.set('interactionsTo', InteractionsToState(planet));
  state = state.set('interactionsFrom', InteractionsFromState(planet));

  state = state.set('projectilesTo', ProjectilesToState(planet));
  state = state.set('projectilesFrom', ProjectilesFromState(planet));

  state = state.set('entities', EntitiesState(planet));
  state = state.set('sectors', SectorsState(planet));
  state = state.set('users', UsersState(planet));

  state = state.set('constants', ConstantsState(planet.getConstants()));

  return new PlanetModel(state, null);
}
