/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Context from 'fierry/react/skeleton/card/context';

import * as Route from 'galax/value/route';


const getVariant = ({ item, link }) =>
{
  if (link) { return 'link'; }
  if (item) { return 'item'; }

  return 'link';
}


const Component = ({ card: Card, route, variant, ...props }) =>
(
  <Context route = {route} variant = {variant} >
    <Card {...props} />
  </Context>
);


export default (props) =>
{
  const { args } = Route.parse(props.route);

  return <Component {...props} variant = {getVariant(props)} currentArgs = {args} />
}
