/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import Server from 'galax/server';

import Icon from 'svg/shared/modern/delete-stroke';


const OnClick = (event, unit, server) =>
{
  if (unit.getStatus().isDisband())
  {
    server.cancelEntityAction(unit.getZone());
  }
  else
  {
    server.disbandUnit(unit.getZone());
  }
};


export default ({ currentLocation, currentUser, ...props }) =>
{
  const [ server ] = useFlux(Server);

  const unit = currentLocation.getObject().getUnit();

  const selected = unit.getStatus().isDisband();
  const disabled = !selected && !unit.getStatus().isDefault();

  const onClick = useCallback(OnClick, [ unit, server ]);

  if (currentLocation.getUser().getID() !== currentUser.getID())
  {
    return null;
  }

  const tooltip = selected ? 'Cancel Disband' : 'Disband';

  return <Modern.Button tooltip = {tooltip} icon = {<Icon />} contained = {selected} disabled = {disabled} onClick = {onClick} />;
}
