/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Modern from 'fierry/react/modern';
import * as Element from 'fierry/react/element';

import { useFlux } from 'fierry/react/native';
import { isString } from 'fierry/util/lang'

import Pane from 'fierry/state/pane';


const NormalHeadline = ({ headline : Headline, ...props }) =>
{
  return isString(Headline) ? <Modern.Text headline text = {Headline} /> : <Headline {...props} />;
}


const LinkHeadline = ({ headline, onClick, ...props }) =>
(
  <Element.Group onClick = {onClick}>
    <Element.Link>
      <NormalHeadline {...props} headline = {headline} />
    </Element.Link>
  </Element.Group>
);


export default (props) =>
{
  const { context, onExpand } = props;

  const [ pane ] = useFlux(Pane);

  if (context && context.variant === 'link' && !pane.getCollapsed())
  {
    return <LinkHeadline {...props} onClick = {onExpand} />;
  }

  return <NormalHeadline {...props} />;
}
