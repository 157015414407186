/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Card, Modern, useFlux } from 'fierry/react';

import Server from 'galax/server';

import User from 'galax/react/group/user.points';


const getUsers = ({ server }) =>
{
  return server.getUsers().getAll();
}


const Users = ({ users }) =>
{
  return users.map( (user, index) => <User key = {user.getID()} user = {user} index = {index} />);
};


const Component = (props) =>
(
  <Card {...props} headline = 'Points' >

    <Users users = {getUsers(props)} />

  </Card>
);


export default (props) =>
{
  const [ server ] = useFlux(Server);

  return <Component {...props} server = {server} />;
}
