/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import UserInfo from 'galax/react/card/user.info';
import UserResources from 'galax/react/card/user.resources';
import UserPoints from 'galax/react/card/user.points';
import NewbieHint from 'galax/react/card/newbie.hint';


export default (props) =>
(
  <>
    <UserInfo {...props} />
    <UserResources {...props} />
    <NewbieHint {...props} />
    <UserPoints {...props} />
  </>
);
