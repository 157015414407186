/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Coords from 'galax/geo/coords';
import * as Data from 'galax/geo/shapes/data'


export const getCoords = (target, shape) =>
{
  return getVariant(target, shape.type, shape.variant).data;
}


export const getVariant = (target, type, variant) =>
{
  const data = rotateDataRight(target, Data.getShapeData(target, type), variant);

  return { type, variant, data };
}


export const getVariants = (target, type) =>
{
  let variants = [ getVariant(target, type, 0) ];

  for (let i = 1; i < 6; ++i)
  {
    variants.push({ type, variant: i, data: rotateDataRight(target, variants[0].data, i) });
  }

  return variants;
}


export const rotateDataLeft = (target, data, count) =>
{
  let result = [];

  for (const element of data)
  {
    result.push(Coords.rotateLeft(element, target, count));
  }

  return result;
}


export const rotateDataRight = (target, data, count) =>
{
  let result = [];

  for (const element of data)
  {
    result.push(Coords.rotateRight(element, target, count));
  }

  return result;
}


export const substractFromData = (data, coords) =>
{
  let result = [];

  for (const element of data)
  {
    if (!element.isEqual(coords))
    {
      result.push(element);
    }
  }

  return result;
}


export const isEqualData = (lhs, rhs) =>
{
  if (lhs.length != rhs.length)
  {
    return false;
  }

  for (let i = 0; i < lhs.length; ++i)
  {
    const predicate = (rhsElement) =>
    {
      return rhsElement.isEqual(lhs[i]);
    };

    if (rhs.find(predicate) === undefined)
    {
      return false;
    }
  }

  return true;
}
