/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as Modern from 'fierry/react/modern';

import Navigation from 'fierry/react/skeleton/header/navigation';


export default ({ headline, children, forceDrawer }) =>
(
  <Modern.Group>

    <Modern.Group spacing = '0.5'>

      <Navigation forceDrawer = {forceDrawer} />

      <Modern.Text nowrap
        headline = '6'
        text = {headline}
      />

    </Modern.Group>

    <Modern.Group>
      {children}
    </Modern.Group>

  </Modern.Group>
);
