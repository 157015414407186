/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Action from 'fierry/flux/entity/action';
import Event from 'fierry/flux/entity/event';
import Client from 'fierry/flux/entity/client';
import Query from 'fierry/flux/entity/query';
import State from 'fierry/flux/entity/state';
import Store from 'fierry/flux/entity/store';
import Rpc from 'fierry/flux/entity/rpc';
import Visibility from 'fierry/flux/entity/visibility';

import useAction from 'fierry/flux/hook/use.action';
import useEvent from 'fierry/flux/hook/use.event';
import useClient from 'fierry/flux/hook/use.client';
import useQuery from 'fierry/flux/hook/use.query';
import useState from 'fierry/flux/hook/use.state';
import useStore from 'fierry/flux/hook/use.store';
import useRpc from 'fierry/flux/hook/use.rpc';
import useVisibility from 'fierry/flux/hook/use.visibility';


// Technically we calculate here VALUE of the entity and its Dependency wrapper.
export default (dependency, props) =>
{
  const entity = dependency.getEntity();

  // TODO remove? Action is not exportable at all. It does not have side effect to run here. 
  // Trying to use Action in Flux is a mistake!
  if (entity instanceof Action)
  {
    return useAction(entity, props);
  }

  if (entity instanceof Event)
  {
    return useEvent(dependency, props);
  }

  if (entity instanceof Client)
  {
    return useClient(dependency, props);
  }

  if (entity instanceof Query)
  {
    return useQuery(dependency, props);
  }

  if (entity instanceof State)
  {
    return useState(dependency, props);
  }

  if (entity instanceof Store)
  {
    return useStore(entity, props);
  }

  if (entity instanceof Rpc)
  {
    return useRpc(entity, props);
  }

  if (entity instanceof Visibility)
  {
    return useVisibility(entity, props);
  }

  throw new Error('Unrecognized dependency');
}
