/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled } from 'fierry/react';


const Polygon = styled.polygon`
  fill: var(--svg-always-other);
  fill-opacity: 0.1;
`;


const getPolygonPoints = ({ grid, location }) =>
{
  const callback = ({ x, y }) => `${x},${y}`;

  return grid.getLocalHex(location).getDockPoints().map(callback).join(' ');
}


export default (props) =>
{
  return <Polygon {...props} points = {getPolygonPoints(props)} />;
}
