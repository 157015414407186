/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import uuid from 'uuid/v4';


export default class
{
  constructor (id, theme, name)
  {
    this.user = { id, theme, name, auth: uuid(), resources: this.makeResources(), deposit: this.makeDeposit(), turn: 0, planets: 0, zones: 0, center: '0.0' };
  }


  makeResources ()
  {
    return { carbon: 3, silicon: 3, hydrogen: 0 };
  }


  makeDeposit ()
  {
    return { carbon: 1, silicon: 1, hydrogen: 0 };
  }


  setGalaxy (galaxy)
  {
    this.user.galaxy = galaxy;
  }


  setUpdateDate (date)
  {
    this.user.update_date = date;
    this.user.join_date = date;
  }


  getType ()
  {
    return this.user;
  }


  getID ()
  {
    return this.user.id;
  }


  getTheme ()
  {
    return this.user.theme;
  }

}
