/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Header from 'fierry/react/header';

import AboutButton from 'galax/react/button/general/about'
import LogInButton from 'galax/react/button/general/login'
import NextButton from 'galax/react/button/general/next'


export default (props) =>
(

  <Header headline = 'Welcome to Fierry!' >
    <NextButton {...props} icon = 'play_circle_outline' tooltip = 'Play' />
  </Header>
);
