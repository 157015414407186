/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Route from 'galax/value/route';
import Level from 'galax/enum/route.level';


const MAX_HISTORY_LENGTH = 100;


export const getStateFromURL = () =>
{
  let args = {};

  for (const [key, value] of new URLSearchParams(window.location.search))
  {
    args[key] = value;
  }

  const route = window.location.pathname;

  return { route: new Route(route), args, context: { absoluteArgs: true }, level: Level.Foreground };
}


export const getDefaultState = () =>
{
  const route = '/';

  return { id: 1, route: new Route(route), args: {}, context: {}, level: Level.Foreground };
}


export const getFutureState = (state, { route, args, level, context }) =>
{
  if (context == null)
  {
    context = {};
  }

  route = getFutureRoute(state, route);
  args = getFutureArgs(state, args, context);

  if (context.absoluteArgs !== true)
  {
    context.absoluteArgs = true;
  }

  return { id: getFutureID(state), route, args, level, context };
}


export const getFutureRoute = (state, route) =>
{
  if (!route)
  {
    route = getPermanent(state).route;
  }

  return (route instanceof Route) ? route : new Route(route);
}


export const getFutureArgs = (state, future, { absoluteArgs }) =>
{
  if (absoluteArgs)
  {
    return future;
  }

  let args = { ...getPermanent(state).args };

  for (const key in future || {})
  {
    if (future[key] == null)
    {
      delete args[key];
    }
    else
    {
      args[key] = future[key];
    }
  }

  return args;
}


export const getFutureID = (state) =>
{
  const { future, permanent } = state;

  if (future.length)
  {
    return future[future.length - 1].id + 1;
  }

  if (permanent.length)
  {
    return permanent[permanent.length - 1].id + 1;
  }

  return 1;
}


export const getSessionState = (state) =>
{
  const { permanent } = state;

  return permanent.length > MAX_HISTORY_LENGTH ? permanent.slice(permanent.length - MAX_HISTORY_LENGTH) : permanent;
}


export const getSessionURL = (state) =>
{
  const args = getStringArgs(state);
  const route = getStringRoute(state);

  return args ? (route + '?' + args) : route;
}


export const getStringArgs = (state) =>
{
  let { args } = getPermanent(state);

  return new URLSearchParams(args).toString();
}


export const getStringRoute = (state) =>
{
  let { route } = getPermanent(state);

  return route.getValue();
}


export const getPopCountBefore = (state, level) =>
{
  let popCount = 0;

  while (getPermanent(state, popCount).level < level)
  {
    ++popCount;
  }

  return popCount + 1;
}


export const getPermanent = (state, popCount = 0) =>
{
  const index = state.permanent.length - popCount - 1;

  return (index >= 0) ? state.permanent[index] : null;
}


export const popPermanent = (state, popCount = 0) =>
{
  const index = state.permanent.length - popCount - 1;

  return index >= 0 ? state.permanent.slice(0, index) : [];
}


export const indexOf = (collection, id) =>
{
  return collection.findIndex(other => other.id === id);
}


export const areEqual = (lhs, rhs) =>
{
  if (lhs.route.getValue() !== rhs.route.getValue())
  {
    return false;
  }

  return new URLSearchParams(lhs.args).toString() === new URLSearchParams(rhs.args).toString();
}
