/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator';


export default class extends Validator
{
  isRouteVisible ()
  {
    return !this.isSpaceshipLocation() && this.isDockLocation() && this.isUserOwner();
  }


  isRouteDisabled ()
  {
    return !this.isSpaceportStatusDefault();
  }


  isRouteActivated ()
  {
    return this.isSpaceshipStatusProduction();
  }


  isSpaceportStatusDefault ()
  {
    const zone = this.currentLocation.getDock().getSpaceport();

    return zone && zone.getBuilding().getStatus().isDefault();
  }


  isSpaceshipStatusProduction ()
  {
    const spaceship = this.currentLocation.getSpaceship();

    return spaceship && spaceship.getStatus().isProduction();
  }


};
