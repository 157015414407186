/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


const compare = (lhs, rhs) =>
{
  const lhsLength = lhs.length;
  const rhsLength = rhs.length;

  const length = Math.min(lhsLength, rhsLength);

  for (let i = 0; i < length; ++i)
  {
    let lhsItem = lhs[i];
    let rhsItem = rhs[i];

    // Null / undefined compare.
    if (lhsItem == null && rhsItem != null)
    {
      return -1;
    }

    if (lhsItem != null && rhsItem == null)
    {
      return 1;
    }

    // Array compare (recursive).
    if (lhsItem instanceof Array && rhsItem instanceof Array)
    {
      let result = compare(lhsItem, rhsItem);

      if (result !== 0)
      {
        return result;
      }
    }

    // Instance compare (complex objects need valueOf() to enable compare).
    if (lhsItem < rhsItem)
    {
      return -1;
    }

    if (lhsItem > rhsItem)
    {
      return 1;
    }
  }

  return lhsLength - rhsLength;
};


export default compare;
