/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator';


export default class extends Validator
{
  isRouteVisible ()
  {
    return this.isZoneLocation() && this.isUserOwner();
  }


  isRouteDisabled ()
  {
    return false;
  }


  isRouteActivated ()
  {
    return false;
  }


};
