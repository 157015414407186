/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */

import { Event } from 'fierry/flux';
import Time from 'fierry/util/time';

import Now from 'galax/state/now';


export default new Event ([ Now.Refresh ], (refreshNow) =>
{
  const callback = () =>
  {
    refreshNow({ now: new Date() });
  }

  const interval = window.setInterval(callback, Time.getSecondTicks());

  return () =>
  {
    window.clearInterval(interval);
  }
});
