/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor (state, hierarchy)
  {
    this.state = state;
    this.hierarchy = hierarchy;
  }


};
