/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import Svg from 'svg'


export default function (props)
{
  return (
    <Svg viewBox="0 0 24 24" {...props}><defs/><rect width="24" height="24" className="none"/><polygon points="7 19.94 7.98 14.17 4 10.05 9.53 9.24 12.03 4 14.48 9.27 20 10.15 15.98 14.22 16.89 20 11.96 17.24 7 19.94" className="black-shape"/><polygon points="7 19.94 7.98 14.17 4 10.05 9.53 9.24 12.03 4 14.48 9.27 20 10.15 15.98 14.22 16.89 20 11.96 17.24 7 19.94" className="black-line-20"/></Svg>
  );
}
