/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React, { useCache, withMemo, useFlux } from 'fierry/react';

import Zoom from 'galax/client/zoom';
import Grid from 'galax/client/map/grid.local';

import Entity from 'galax/react/map/entity';
import Zone from 'galax/react/map/zone';
import Dock from 'galax/react/map/dock';
import Platform from 'galax/react/map/platform';

import Center from 'galax/react/map/zone/center';
import Unit from 'galax/react/map/entity/unit';


const Zones = ({ planet, ...props }) =>
{
  let collection = [];

  for (const zone of planet.getZones())
  {
    collection.push(<Zone {...props} key = {zone.getID()} zone = {zone} />);
  }

  for (const platform of planet.getZoneCollection().getPlatforms())
  {
    collection.push(<Platform {...props} key = {platform.getLocation().getID()} location = {platform.getLocation()} />);
  }

  for (const sector of planet.getSectors())
  {
    for (const dock of sector.getNeighbors().getDocks())
    {
      collection.push(<Dock {...props} key = {dock.getLocation().getID()} location = {dock.getLocation()} />);
    }
  }

  return collection;
}


const Entities = ({ planet, ...props }) =>
{
  if (!props.grid.getZoom().isEntityVisible())
  {
    return null;
  }

  let collection = [];

  for (const zone of planet.getZones())
  {
    collection.push(<Entity {...props} key = {zone.getID()} zone = {zone} />);
  }

  for (const zone of planet.getZoneCollection().getPlatforms())
  {
    if (zone.getUnit())
    {
      const location = zone.getLocation();

      collection.push(<Center key = {zone.getID()} local {...props} location = {location} > <Unit {...props} location = {location} zone = {zone} /> </Center>);
    }
    //collection.push(<Entity {...props} key = {zone.getID()} zone = {zone} />);
  }

  return collection;
}


const Component = (props) =>
(
  <>
    <Zones {...props} />
    <Entities {...props} />
  </>
);


export default withMemo(props =>
{
  const [ zoom ] = useFlux(Zoom);

  const grid = useCache(() => new Grid(zoom), [zoom, props.planet]);

  return <Component {...props} grid = {grid} />;
});
