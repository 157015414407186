/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern } from 'fierry/react';

import PermanentContent from 'fierry/react/skeleton/header/content/permanent'


export default (props) =>
(
  <Modern.Stack.Child>
    <PermanentContent {...props} />
  </Modern.Stack.Child>
);
