/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Data from 'fierry/rpc/data';
import Element from 'fierry/rpc/element';


export default class extends Element
{
  constructor (translators)
  {
    super();

    this.__ready__ = false;
    this.__record__ = new Data();

    this.__translators__ = translators;

    this.createNotification('data', (value) => this.onChange(value));
  }


  detach ()
  {
    this.__ready__ = false;
    this.__record__ = new Data();

    super.detach();
  }


  getLocalState ()
  {
    return this.__record__.getState();
  }


  onChange (value)
  {
    if (!this.__ready__)
    {
      this.__ready__ = true;
    }

    if (value !== null)
    {
      for (const translator of this.__translators__)
      {
        translator(value);
      }
    }

    this.__record__.setState(value);

    if (!this.isGlobal())
    {
      this.transitionToGlobal();
    }
    else
    {
      this.setState(this.getLocalState());
    }
  }


  addLocalLie (value)
  {
    if (!this.isReady())
    {
      throw new Error('Cannot addLocalLie on record with no data.');
    }

    const lie = this.__record__.addLocalLie(value);

    this.setState(this.getLocalState());

    return () => this.removeLocalLie(lie);
  }


  removeLocalLie (lie)
  {
    if (!this.isReady())
    {
      throw new Error('Cannot removeLocalLie on record with no data.');
    }

    this.__record__.removeLocalLie(lie);

    this.setState(this.getLocalState());
  }


  isReady ()
  {
    return super.isReady() && this.__ready__;
  }


};

