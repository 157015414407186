/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { State } from 'fierry/flux';

import Navigation from 'galax/state/navigation';


export default new State([ Navigation ], { open: false });
