/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import * as UserValue from 'galax/value/user';


export default class
{
  constructor (scope)
  {
    this.scope = scope;

    this.isFinalUser = false;

    this.prevWinner = null;
    this.currentWinner = null;
  }


  canSpend (value)
  {
    const distribution = this.scope.getSector().getZones().getSpendingEnergyDistribution(this.scope, value);

    return distribution.canSpendEnergy();
  }


  refreshInfluence ()
  {
    this.scope.getUsers().eachEnergyUser( (user, value) =>
    {
      value.setActiveInfluence(this.scope.getAttributes().getActiveInfluence(user));
      value.setPassiveInfluence(this.scope.getAttributes().getPassiveInfluence(user));
    });
  }


  refreshFlows ()
  {
    this.scope.getUsers().eachEnergyUser( (user, userValue) =>
    {
      userValue.clearFlow();

      this.scope.getUsers().eachEnergyUser( (other, otherValue) =>
      {
        userValue.increaseFlow(this.__getRelativeFlow__(user, other));

      });
    });
  }


  refreshUsers ()
  {
    while (this.__hasNoopUser__())
    {
      const noop = this.__getNoopUser__();

      this.__balanceNoopFlow__(noop);
      this.__balanceNoopEnergy__(noop);

      this.scope.getUsers().uninstallEnergyUser(noop);

      this.refreshFlows();
    }

    if (!this.isFinalUser && this.scope.getUsers().getEnergyUserCount() == 1)
    {
      this.isFinalUser = true;
    }
  }


  refreshWinner ()
  {
    const winner = this.__getWinnerUser__();

    if (this.prevWinner === null)
    {
      this.prevWinner = winner;
    }

    if (this.currentWinner !== winner)
    {
      this.currentWinner = winner;
    }
  }


  updateEnergy (milliseconds)
  {
    this.scope.getUsers().eachEnergyUser( (user, value) =>
    {
      value.updateEnergy(milliseconds);
    });

    if (this.isOverheatStop())
    {
      // no-op.
    }

    if (this.isCriticalStop())
    {
      // no-op.
    }
  }


  transferEnergy (user, other, value)
  {
    const userValue = this.scope.getUsers().getEnergyUser(user);
    const otherValue = this.scope.getUsers().getEnergyUser(other);

    if (value < -userValue.getEnergy())
    {
      value = -userValue.getEnergy();
    }

    if (value > otherValue.getEnergy())
    {
      value = otherValue.getEnergy();
    }

    userValue.increaseEnergy(value);
    otherValue.decreaseEnergy(value);
  }


  getFutureEventTime ()
  {
    const reference = { time: Number.MAX_SAFE_INTEGER };

    this.__calculateUsersFutureTime__(reference);
    this.__calculateOwnerFutureTime__(reference);

    return reference.time !== Number.MAX_SAFE_INTEGER ? reference.time : -1;
  }


  __calculateUsersFutureTime__ (reference)
  {
    const winner = this.scope.getUsers().getEnergyUser(this.getCurrentWinner());

    this.scope.getUsers().eachEnergyUser( (user, userValue) =>
    {
      const timeToWinner = userValue.getTimeToWinner(winner);

      if (user !== UserValue.getEmpty() && timeToWinner > 0 && timeToWinner < reference.time)
      {
        reference.time = timeToWinner;
      }

      const timeToEmpty = userValue.getTimeToEmpty();

      if (timeToEmpty > 0 && timeToEmpty < reference.time)
      {
        reference.time = timeToEmpty;
      }
    });
  }


  __calculateOwnerFutureTime__ (reference)
  {
    const owner = this.scope.getUsers().getEnergyUser(this.getCurrentOwner());

    if (owner === null)
    {
      return;
    }

    const timeToStopOverheat = owner.getTimeToEnergy(this.__getConstants__().getZoneOverheatEnergy());

    if (this.getType().isOverheat() && timeToStopOverheat > 0 && timeToStopOverheat < reference.time)
    {
      reference.time = timeToStopOverheat;
    }

    const timeToStopCritical = owner.getTimeToEnergy(this.__getConstants__().getZoneCriticalEnergy());

    if(this.getType().isCritical() && timeToStopCritical > 0 && timeToStopCritical < reference.time)
    {
      reference.time = timeToStopCritical;
    }
  }


  getFlow (userID)
  {
    const userValue = this.scope.getUsers().getEnergyUser(userID);

    return userValue ? userValue.getFlow() : 0;
  }


  getEnergy (userID, date)
  {
    const userValue = this.scope.getUsers().getEnergyUser(userID);

    if (!userValue)
    {
      return 0;
    }

    if (date == null)
    {
      return userValue.getEnergy();
    }

    let energy = userValue.getEnergy() + (date - this.scope.getType().getUpdateDate()) * userValue.getFlow();

    energy = Math.min(energy, this.__getConstants__().getZoneFullEnergy());
    energy = Math.max(energy, this.__getConstants__().getZoneEmptyEnergy());

    return energy;
  }


  getInfluence (user)
  {
    return this.scope.getAttributes().getPassiveInfluence(user) + this.scope.getAttributes().getActiveInfluence(user) * this.scope.getResistance().getEfficiency(user);
  }


  getMissingInfluence (user)
  {
    if (!this.scope.getUsers().getEnemyCount(user))
    {
      return 0;
    }

    return Math.max(this.__getEnemyFlow__(user) / this.getRelativeStrength(user) - this.getInfluence(user), 0);
  }


  getRelativeStrength (user)
  {
    let relativeStrength = 0;

    this.scope.getUsers().eachEnergyUser((other, otherValue) =>
    {
      if (this.scope.getUsers().isEnemy(user, other))
      {
        relativeStrength += this.scope.getUsers().getRelativeStrength(user, other);
      }
    });

    const enemyCount = this.scope.getUsers().getEnemyCount(user);

    return enemyCount ? relativeStrength / enemyCount : 0;
  }


  __getEnemyFlow__ (user)
  {
    let value = 0;

    this.scope.getUsers().eachEnergyUser((other, otherValue) =>
    {
      if (this.scope.getUsers().isEnemy(user, other))
      {
        const relativeStrength = this.scope.getUsers().getRelativeStrength(other, user);

        value += Math.round(this.getInfluence(other) * relativeStrength / this.scope.getUsers().getEnemyCount(other));
      }
    });

    return value;
  }


  getOverheatProgress (date)
  {
    if (this.scope.getType().isOverheat())
    {
      return 1;
    }

    const constants = this.__getConstants__();

    const value = this.getEnergy(this.getCurrentOwner(), date) - constants.getZoneOverheatEnergy();
    const total = constants.getZoneFullEnergy() - constants.getZoneOverheatEnergy();

    return Math.max(value / total, 0);
  }


  getCriticalProgress (date)
  {
    if (this.scope.getType().isCritical())
    {
      return 1;
    }

    const constants = this.__getConstants__();

    const value = constants.getZoneCriticalEnergy() - this.getEnergy(this.getCurrentOwner(), date);
    const total = constants.getZoneOverheatEnergy() - constants.getZoneEmptyEnergy();

    return Math.max(value / total, 0);
  }


  getEnemyEnergyBalance (user)
  {
    let collection = [];

    this.scope.getUsers().eachEnergyUser( (other, otherValue) =>
    {
      if (this.scope.getUsers().isEnemy(user, other))
      {
        const energy = Math.floor(otherValue.getEnergy() / this.scope.getUsers().getRelativeStrength(user, other));

        collection.push({ user: other, value: energy });
      }
    });

    collection.sort( (lhs, rhs) =>
    {
      return lhs.value - rhs.value;
    });

    return collection;
  }


  getEnemyInfluenceBalance (user)
  {
    let collection = [];

    this.scope.getUsers().eachEnergyUser( (other, otherValue) =>
    {
      if (this.scope.getUsers().isEnemy(user, other))
      {
        const userStrength = this.scope.getUsers().getRelativeStrength(user, other);
        const otherStrength = this.scope.getUsers().getRelativeStrength(other, user);

        const influence = Math.floor(otherValue.getInfluence() / this.scope.getUsers().getEnemyCount(other) * otherStrength / userStrength);

        collection.push({ user: other, value: influence });
      }
    });

    collection.sort( (lhs, rhs) =>
    {
      return lhs.value - rhs.value;
    });

    return collection;
  }


  getCurrentOwner ()
  {
    return this.scope.getUserID();
  }


  getCurrentWinner ()
  {
    return this.currentWinner;
  }


  // TODO nothing to do with zone/energy here. Maybe this should be accessible from users then?
  getCurrentChallenger (energy)
  {
    let challenger = UserValue.getEmpty();

    this.scope.getUsers().eachEnergyUser( (user, userValue) =>
    {
      if (user != UserValue.getEmpty() && user !== this.scope.getUserID() && userValue.getEnergy() > energy)
      {
        challenger = user;
      }
    });

    return challenger;
  }

  isCurrentOwnerWinner ()
  {
    return this.getCurrentOwner() === this.getCurrentWinner();
  }


  __balanceNoopFlow__ (noop)
  {
    let noopValue = this.scope.getUsers().getEnergyUser(noop);
    let noopEnemyCount = this.scope.getUsers().getEnemyCount(noop);

    for (const { user, value: userBalance } of this.getEnemyInfluenceBalance(noop))
    {
      const userValue = this.scope.getUsers().getEnergyUser(user);

      const noopStrength = this.scope.getUsers().getRelativeStrength(noop, user);
      const userStrength = this.scope.getUsers().getRelativeStrength(user, noop);

      const noopInfluence = noopValue.getInfluence() / noopEnemyCount;

      if (noopInfluence > userBalance)
      {
        noopValue.increaseBalanceInfluence(userBalance);
        userValue.increaseBalanceInfluence(userValue.getInfluence() / this.scope.getUsers().getEnemyCount(user));

        --noopEnemyCount;
      }
      else
      {
        userValue.increaseBalanceInfluence(noopInfluence * noopStrength / userStrength);
      }
    }
  }


  __balanceNoopEnergy__ (noop)
  {
    let noopValue = this.scope.getUsers().getEnergyUser(noop);
    let noopEnemyCount = this.scope.getUsers().getEnemyCount(noop);

    this.scope.getUsers().eachEnergyUser( (user, userValue) =>
    {
      if (this.scope.getUsers().isEnemy(noop, user))
      {
        let balanceValue = Math.round(noopValue.getEnergy() / noopEnemyCount);

        userValue.increaseEnergy(balanceValue);
        noopValue.decreaseEnergy(balanceValue);

        --noopEnemyCount;
      }
    });
  }


  __getRelativeFlow__ (lhs, rhs)
  {
    if (!this.scope.getUsers().isEnemy(lhs, rhs))
    {
      return 0;
    }

    const lhsValue = this.scope.getUsers().getEnergyUser(lhs);
    const rhsValue = this.scope.getUsers().getEnergyUser(rhs);

    const lhsEnemyCount = this.scope.getUsers().getEnemyCount(lhs);
    const rhsEnemyCount = this.scope.getUsers().getEnemyCount(rhs);

    const lhsStrength = this.scope.getUsers().getRelativeStrength(lhs, rhs);
    const rhsStrength = this.scope.getUsers().getRelativeStrength(rhs, lhs);

    return Math.round(lhsValue.getInfluence() * lhsStrength / lhsEnemyCount - rhsValue.getInfluence() * rhsStrength / rhsEnemyCount);
  }


  isOverheatStart ()
  {
    const userValue = this.scope.getUsers().getEnergyUser(this.getCurrentOwner());

    return !this.scope.getType().isOverheat() && userValue && userValue.getEnergy() >= this.__getConstants__().getZoneFullEnergy();
  }


  isOverheatStop ()
  {
    const userValue = this.scope.getUsers().getEnergyUser(this.getCurrentOwner());

    return this.scope.getType().isOverheat() && userValue && userValue.getEnergy() <= this.__getConstants__().getZoneOverheatEnergy();
  }


  isCriticalStart ()
  {
    const userValue = this.scope.getUsers().getEnergyUser(this.getCurrentOwner());

    return !this.scope.getType().isCritical() && (!userValue || userValue.getEnergy() <= this.__getConstants__().getZoneEmptyEnergy());
  }


  isCriticalStop ()
  {
    const userValue = this.scope.getUsers().getEnergyUser(this.getCurrentOwner());

    return this.scope.getType().isCritical() && userValue && userValue.getEnergy() >= this.__getConstants__().getZoneCriticalEnergy();
  }


  isFullEnergy (user, date)
  {
    return this.getEnergy(user, date) >= this.__getConstants__().getZoneFullEnergy();
  }


  isEmptyEnergy (user, date)
  {
    return this.getEnergy(user, date) <= this.__getConstants__().getZoneEmptyEnergy();
  }


  __hasNoopUser__ ()
  {
    let result = false;

    this.scope.getUsers().eachEnergyUser( (user, userValue) =>
    {
      if (userValue.getEnergy() <= 0 && userValue.getFlow() <= 0)
      {
        result = true;
      }
    })

    return result;
  }


  __getNoopUser__ ()
  {
    let noop = UserValue.getEmpty();

    this.scope.getUsers().eachEnergyUser( (user, userValue) =>
    {
      if (userValue.getEnergy() <= 0 && userValue.getFlow() <= 0)
      {
        noop = user;
      }
    });

    return noop;
  }


  __getWinnerUser__ ()
  {
    let winner = this.getCurrentOwner();

    this.scope.getUsers().eachEnergyUser( (user, userValue) =>
    {
      if (user === UserValue.getEmpty())
      {
        return;
      }

      // Current owner may have 0 energy - needs to default to first real energy user.
      if (!this.scope.getUsers().getEnergyUser(winner))
      {
        winner = user;
      }

      const winnerValue = this.scope.getUsers().getEnergyUser(winner);

      if (userValue.getEnergy() === winnerValue.getEnergy() && userValue.getFlow() > winnerValue.getFlow())
      {
        winner = user;
      }

      else if (userValue.getEnergy() > winnerValue.getEnergy())
      {
        winner = user;
      }
    });

    return winner;
  }


  __getConstants__ ()
  {
    return this.scope.getPlanet().getConstants().getGeneral();
  }


  // TODO change to something better!
  getCurrentUsers ()
  {
    let users = new Set();

    for (const user of this.scope.getUsers().getEnergyUsers())
    {
      users.add(user);
    }

    for (const user of this.scope.getUsers().getResistanceUsers())
    {
      users.add(user);
    }

    users.add(this.getCurrentOwner());
    users.delete(UserValue.getEmpty());

    let array = Array.from(users);

    array.sort((lhs, rhs) =>
    {
      return this.getEnergy(rhs) - this.getEnergy(lhs);
    });

    return array;
  }


};
