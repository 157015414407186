/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import ParentEnum from 'galax/enum/parent';


export default class
{
  constructor (object, resolver)
  {
    this.object = object;
    this.resolver = resolver;
  }


  getUser ()
  {
    return this.resolver.getUser(this.object.getUserID());
  }


  getSource ()
  {
    return this.resolver.getLocation(this.object.getSource());
  }


  getTarget ()
  {
    return this.resolver.getLocation(this.object.getTarget());
  }


  getFullTarget ()
  {
    if (this.getTarget().getSpaceship())
    {
      return [ this.getTarget() ];
      //return [ this.getTarget().getSpaceship().getLocation() ]; // This displays correct zone but doesn't fix flight arrow...
    }

    return this.getTarget().getNeighbors().getShape(this.object.getShape());
  }


  getObject ()
  {
    return this.object;
  }


}
