/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Action from 'fierry/flux/entity/action';
import Store from 'fierry/flux/entity/store';


const State =
{
  width: document.documentElement.clientWidth,
  height: document.documentElement.clientHeight,

  online: window.navigator.onLine,

  drawer: false,
};


const Actions =
{
  Resize:    new Action(),
  Online:    new Action(),

  SetDrawer: new Action(),
};


const setSize = (state, { width, height }) =>
{
  return { ...state, width, height };
};


const setOnline = (state, { online }) =>
{
  return { ...state, online };
}


const setDrawer = (state, { drawer }) =>
{
  if (state.drawer === drawer)
  {
    return state;
  }

  return { ...state, drawer };
}


export default new Store(State, Actions,
[
  [Actions.Resize,     setSize],
  [Actions.Online,     setOnline],

  [Actions.SetDrawer,  setDrawer],
]);
