/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Map } from 'immutable';

import Model from 'fierry/rpc/model';


export default class
{
  constructor (context)
  {
    this.state = new Map();

    this.context = context;
  }


  setIn (routing, value, quiet = false)
  {
    this.state = this.setInDeep(this.getRawState(this.state), routing, 0, value);

    if (!quiet && value != null)
    {
      this.context.getStateTracker().track(routing);
    }
  }


  setInDeep (element, routing, index, value)
  {
    if (index == routing.length)
    {
      return value !== element ? value : element;
    }

    const childExisting = this.getRawState(element.get(routing[index]));

    const childUpdated = this.setInDeep(childExisting, routing, index + 1, value);

    if (childUpdated === childExisting)
    {
      return element;
    }

    if (childUpdated === null)
    {
      return element.delete(routing[index]);
    }

    return element.set(routing[index], childUpdated);
  }


  getRawState (element)
  {
    return element instanceof Model ? element.state : element;
  }


  getIn (routing)
  {
    return this.state.getIn(routing, null);
  }


};
