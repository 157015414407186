/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { useFlux } from 'fierry/react/native';

import styled from 'styled-components';
import isMounted from 'fierry/flux/hook/is.mounted';

import Url from 'fierry/client/url';


export default (container, definition) =>
{
  const Container = styled(container)`
    animation-name:            ${props => definition};
    animation-fill-mode:       forwards;
    animation-duration:        var(--fierry-transition-duration);
    animation-timing-function: var(--fierry-transition-function)
  `;

  return (props) =>
  {
    const [ url ] = useFlux(Url);

    const callback = (event) =>
    {
      url.endAnimation();

      event.stopPropagation();
    }

    if (!isMounted())
    {
      url.startAnimation();
    }

    return <Container {...props} onAnimationEnd = {callback} />;
  }
};
