/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import InteractionEnum from 'galax/enum/interaction';

import * as Coords from 'galax/geo/coords';
import * as Shapes from 'galax/geo/shapes';


export default class
{
  constructor (cass)
  {
    this.cass = cass;
  }


  getKey ()
  {
    const { source, target } = this.cass;

    return target.parent.id + source.parent.id + target.id + source.id;
  }


  getCass ()
  {
    return this.cass;
  }


  getType ()
  {
    return this.cass;
  }


  getArgs ()
  {
    return this.cass.args;
  }


  getShape ()
  {
    return this.cass.shape;
  }


  getSource ()
  {
    return this.cass.source;
  }


  getTarget ()
  {
    return this.cass.target;
  }


  getUserID ()
  {
    return this.cass.user.id;
  }


  getUserReference ()
  {
    return this.cass.user;
  }


  isSourceOf (coords)
  {
    return this.cass.source.id.isEqual(coords);
  }


  isTargetOf (coords)
  {
    const { target, shape } = this.cass;

    return Coords.indexOf(Shapes.getCoords(target.id, shape), coords) !== -1;
  }


  isAggressive ()
  {
    const type = this.cass.type;

    return type === InteractionEnum.AttackEnergy;
  }


  isFutureTarget ()
  {
    const type = this.cass.type;

    return type === InteractionEnum.FutureTarget;
  }


  isProjectPlatform ()
  {
    const type = this.cass.type;

    return type === InteractionEnum.ProjectPlatform;
  }


};

