/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useCallback, useFlux } from 'fierry/react';

import View from 'fierry/client/view';

import Icon from 'svg/shared/modern/plus';


const OnClick = (event, view) =>
{
  view.pushForeground('/sector.transfer.edit')
};


export default ({ currentZone, currentUser, ...props }) =>
{
  const [ view ] = useFlux(View);

  const onClick = useCallback(OnClick, [ view ]);

  if (!currentZone || currentZone.getUser().getID() !== currentUser.getID())
  {
    return null;
  }

  return <Modern.Button tooltip = 'Create Transfer' icon = {<Icon />} onClick = {onClick} />;
}
