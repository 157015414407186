/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


const canSelect = (targetLocation, currentLocation, behavior) =>
{
  return currentLocation.getSpaceship() || behavior.canSelectGalaxy() || currentLocation.getZone();
}


const canNavigate = (targetLocation) =>
{
  return (targetLocation.getSpaceship()) || targetLocation.getPlatform() || (targetLocation.getDock() && targetLocation.getDock().isActive()) || targetLocation.getZone();
}


export const OnZoneClick = (coords, galaxy, behavior, navigation, selection) =>
{
  const targetLocation = galaxy.getLocation(coords);

  if (targetLocation)
  {
    console.log('Zone (Absolute): ' + targetLocation.getCoords().toString() + ')');
  }

  if (behavior.canSelect())
  {
    if (canSelect(targetLocation, navigation.getLocation(), behavior))
    {
      selection.setSelection(targetLocation);
    }
  }

  else if (behavior.canNavigate())
  {
    if (canNavigate(targetLocation))
    {
      navigation.setZone(targetLocation);
    }
    else
    {
      navigation.clearZone();
    }
  }
}
