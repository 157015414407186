/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default class
{
  constructor ({ constants })
  {
    this.constants = constants;
  }


  getType ()
  {
    return this.constants.type;
  }


  getName ()
  {
    return this.constants.name;
  }


  getTargetShape ()
  {
    return this.constants.target_shape;
  }


  getTargetMinRange ()
  {
    return this.constants.target_min_range;
  }


  getTargetMaxRange ()
  {
    return this.constants.target_max_range;
  }


  getMovement ()
  {
    return this.constants.movement;
  }


  getMinEnergy ()
  {
    return this.constants.min_energy;
  }


  getMaxEnergy ()
  {
    return this.constants.max_energy;
  }


  getAttrition ()
  {
    return this.constants.attrition;
  }


  getPrimaryAttack ()
  {
    return this.constants.attack;
  }


  getConstructionCost ()
  {
    return this.constants.construction_cost;
  }


  getConstructionTime ()
  {
    // TODO hours(...);
    return this.constants.construction_time;
  }


};
