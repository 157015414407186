/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


const INFLUENCE_TIME_FRAME = 1000 * 60 * 60;


export default class
{
  constructor (scope)
  {
    this.scope = scope;
  }


  eachZone (callback)
  {
    this.scope.getZones().eachZone(zone =>
    {
      callback(zone, this.getZone(zone));
    });
  }


  getTotal ()
  {
    let total = 0;

    this.scope.getZones().eachZone(zone =>
    {
      total += this.getZone(zone);
    });

    return total;
  }


  getZone (zone)
  {
    const user = zone.getEnergy().getCurrentOwner();
    const energy = zone.getEnergy().getEnergy(user);
    const influence = zone.getEnergy().getMissingInfluence(user);

    return this.scope.getConstants().getZoneFullEnergy() - energy + influence * INFLUENCE_TIME_FRAME;
  }


};
