/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


export default
{
  AttackEnergy: 0,
  FutureTarget: 1,
  ProjectPlatform: 2,
};
