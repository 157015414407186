/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import React from 'react';

import StoreDispatcher from 'fierry/flux/store.dispatcher'

import extract from 'fierry/flux/dependency/extract';


export default (store, props) =>
{
  const [reactState, reactDispatch] = React.useReducer(store.onChange, store.getState());

  const dispatcher = React.useMemo(() => new StoreDispatcher(reactDispatch), []);

  const dependences = extract(store.getDispatchDependences(), props);

  const effectCallback = () =>
  {
    for (const dependency of dependences)
    {
      dependency.registerDispatch(dispatcher.getDispatch());
    }

    return () =>
    {
      for (const dependency of dependences)
      {
        dependency.unregisterDispatch(dispatcher.getDispatch());
      }
    }
  };

  React.useEffect(effectCallback, []);

  const dispatch = React.useCallback((action, args) => dispatcher.send(action, args), [dispatcher]);

  const state = React.useMemo(() => [reactState, dispatch], [reactState, dispatch]);

  return [ state, dispatcher ];
}
