/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, styled, useFlux } from 'fierry/react';

import Server from 'galax/server';
import Screen from 'galax/client/screen';

import isMobile from 'fierry/util/mobile';


const MobileBanner = styled.div`

  position: absolute;
  z-index: 5;

  top: 4rem;
  width: 100%;

  background: var(--fierry-theme-background);
`;


const DesktopBanner = styled.div`

  position: absolute;
  z-index: 5;

  top: 0;
  right: 0;
  width: calc(100% - 25rem);

  background: var(--fierry-theme-background);
`;


const Banner = isMobile() ? MobileBanner : DesktopBanner;


const BannerCard = styled(Modern.Card)`
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
`;


const Component = ({ onClick }) =>
(
  <Banner>
    <BannerCard>

      <Modern.Group>
        <Modern.Text param text = 'You are offline.' />
        <Modern.Button primary label = 'Refresh' onClick = {onClick} />
      </Modern.Group>

    </BannerCard>
  </Banner>
)


const reloadOnClick = () =>
{
  window.location.reload();
}


export default (props) =>
{
  const [ server, screen ] = useFlux(Server, Screen);

  if (!screen.isOnline())
  {
    return <Component onClick = {reloadOnClick} />
  }

  return null;
}
