/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { Modern, useFlux, withValue } from 'fierry/react';

import Now from 'galax/query/now';



export default ({ value, primary, secondary, ...props }) =>
{
  const [ now ] = useFlux(Now);

  value = withValue(value, props, now);
  primary = withValue(primary, props, now);
  secondary = withValue(secondary, props, now);

  return <Modern.Progress {...props} value = {value} primary = {primary} secondary = {secondary} />;
}
