/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import Validator from 'galax/validator';


export default class extends Validator
{
  isRouteVisible ()
  {
    return this.isZoneLocation() && this.isUserOwner() && this.isZoneCenterAndBuildingExists() && this.isBuildingNotLauncher();
  }


  isRouteDisabled ()
  {
    return !this.isBuildingStatusDefault() && !this.isBuildingStatusUpgrade();
  }


  isRouteActivated ()
  {
    return this.isBuildingStatusUpgrade();
  }


  isZoneCenterAndBuildingExists ()
  {
    const currentZone = this.currentLocation.getZone();

    return currentZone.getCass().isCenter() && currentZone.getBuilding() != null;
  }


  isBuildingNotLauncher ()
  {
    const building = this.currentLocation.getZone().getBuilding();

    return building && !building.canFireProjectiles();
  }


  isBuildingStatusUpgrade ()
  {
    const building = this.currentLocation.getZone().getBuilding();

    return building && building.getStatus().isUpgrade();
  }


  isBuildingStatusDefault ()
  {
    const building = this.currentLocation.getZone().getBuilding();

    return building && building.getStatus().isDefault();
  }


};
