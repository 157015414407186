/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


import { styled, useFlux } from 'fierry/react';

import Behavior from 'galax/client/behavior';


const Scrim = styled.div`

  position: absolute;

  width: 100%;
  height: 100%;

  opacity: var(--fierry-highligh-opacity);
  background: var(--fierry-theme-foreground);
`;


export default (props) =>
{
  const [ behavior ] = useFlux(Behavior);

  if (behavior.canSelect() || behavior.canNavigate())
  {
    return null;
  }

  return <Scrim />;
}
